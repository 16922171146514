import {
  ChangeDetectionStrategy,
  Component,
  effect,
  ElementRef,
  Inject,
  input,
  LOCALE_ID,
  OnInit,
  viewChild,
} from '@angular/core';
import { ECharts, init } from 'echarts';
import { WidgetDataService } from 'src/app/analytics/dashboards/dashboard/widget/widget-data.service';
import { WidgetConfig } from 'src/app/analytics/dashboards/shared/widget-config.model';
import { ReportFieldType } from 'src/app/analytics/shared/models/report-field-type.enum';
import { SourceData } from 'src/app/analytics/shared/models/source-data.model';
import { ReportSourceDescription } from 'src/app/analytics/shared/models/source-description/report-source-description.model';

@Component({
  selector: 'tmt-gauge',
  template: `<div #gaugeCanvas></div>`,
  standalone: false,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GaugeComponent implements OnInit {
  public sourceData = input<SourceData>();
  public widget = input<WidgetConfig>();
  public sourceDescription = input<ReportSourceDescription>();

  private gaugeCanvas = viewChild<ElementRef<HTMLDivElement>>('gaugeCanvas');
  private chart: ECharts | null = null;
  private fieldNames: string[];
  private format = '';
  private value = 0;
  private title = '';
  private segments: { from: number; to: number; color: 'string' }[] = [];
  private currentWidth: number;
  private currentHeight: number;
  private colors = {
    defaultBackground: '#e6ebf8',
    white: '#ffffff',
  };
  // Background with default color echart library.
  private defaultBackgroundData = [[1, this.colors.defaultBackground]];

  constructor(
    private widgetDataService: WidgetDataService,
    @Inject(LOCALE_ID) private locale: string,
  ) {
    effect(() => {
      const { data = [0], fieldNames = [] } = this.sourceData() || {};
      this.segments = this.widget().properties.segments;
      this.fieldNames = fieldNames;
      this.processData(data);
    });
  }
  public ngOnInit(): void {
    this.chart = init(this.gaugeCanvas()!.nativeElement);
  }

  public ngOnDestroy(): void {
    this.chart?.dispose();
  }

  /**
   * Resizes the chart.
   *
   * @param width The new width of the chart.
   * @param height The new height of the chart.
   */
  public resize(width: number, height: number): void {
    // Skip redraw if the size hasn't changed.
    if (width !== this.currentWidth || height !== this.currentHeight) {
      this.currentWidth = width;
      this.currentHeight = height;
      this.chart?.resize({ width, height });
    }
  }

  private updateChart(): void {
    const hasBackground = this.segments.length;
    const background = hasBackground
      ? this.getBackgroundData()
      : this.defaultBackgroundData;

    this.chart.setOption({
      tooltip: {
        formatter: `{a}: {c}${this.format}`,
      },
      series: [
        {
          name: this.title || '',
          type: 'gauge',
          progress: { show: !hasBackground },
          detail: {
            valueAnimation: true,
            formatter: (value) => `${this.formatNumber(value)}${this.format}`,
          },
          axisLine: {
            lineStyle: {
              width: 10,
              color: background,
            },
          },
          min: this.widget().properties.min,
          max: this.widget().properties.max,
          data: [
            {
              value: this.value || 0,
            },
          ],
        },
      ],
    });
  }

  /**
   * Processes the provided data and updates the configuration based on the widget's properties and the data source.
   *
   * @param data The data to be processed.
   */
  private processData(data): void {
    const valueFieldIndex = this.widgetDataService.getFieldIndex(
      this.widget().viewSettings.valueField?.name,
      this.fieldNames,
    );
    this.value = data.flat()[valueFieldIndex];

    const sourceField = this.sourceDescription()?.allFields.find(
      (f) => f.name === this.widget().viewSettings.valueField?.name,
    );
    this.title = sourceField?.title;

    switch (sourceField?.type) {
      case ReportFieldType.Percent:
        this.format = '%';
        this.value = +(this.value * 100).toFixed(2);
        break;
      case ReportFieldType.Decimal:
        this.format = '';
        break;
      default:
    }
    this.updateChart();
  }

  private getBackgroundData(): [number, string][] {
    const { max, min } = this.widget().properties;
    const range = max - min;

    if (range <= 0) {
      return [];
    }

    if (!this.segments || this.segments.length === 0) {
      return [];
    }

    const segmentsSorted = [...this.segments].sort((a, b) => a.to - b.to);
    const result: [number, string][] = [];
    let previousEnd = min;

    for (const segment of segmentsSorted) {
      let segmentStart = Math.max(segment.from, min);
      const segmentEnd = Math.min(segment.to, max);

      if (segmentStart > previousEnd) {
        const gapEndOffset = (segmentStart - min) / range;
        result.push([gapEndOffset, this.colors.white]);
      }

      if (segmentStart < previousEnd) {
        segmentStart = previousEnd;
      }

      if (segmentEnd > segmentStart) {
        const segmentEndOffset = (segmentEnd - min) / range;
        result.push([segmentEndOffset, segment.color]);
        previousEnd = segmentEnd;
      }
    }

    if (previousEnd < max) {
      result.push([1, this.colors.white]);
    }

    return result;
  }

  private formatNumber(value: number): string {
    return value.toLocaleString(this.locale);
  }
}
