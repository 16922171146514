/* eslint-disable @typescript-eslint/naming-convention */
import { Component, computed, effect, input, model } from '@angular/core';

import { map, Observable, timer } from 'rxjs';

import { SpinnerParams } from 'src/app/shared/components/features/loading-indicator/loading-indicator.model';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';

/** Loading indicator. */
@Component({
  selector: 'wp-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss'],
  standalone: false,
})
export class LoadingIndicatorComponent {
  public loading = model<boolean>(false);
  /** Delay in milliseconds */
  public delay = model<number>();

  public isSpinnerMode = input<boolean>(false);
  /** Works like `[isSpinnerMode] = true`. */
  public isOverlay = input<boolean>(false);
  public spinnerParams = input<SpinnerParams>();
  public state = input<CardState>();
  public progress = input<number | null>(null);

  public delayTimer$: Observable<boolean>;
  public progressWidthStyle = computed(() =>
    typeof this.progress() === 'number' ? this.progress() : 100,
  );

  /** Returns Record object for spinner styles. */
  public get spinnerStyleVariables(): Record<string, string> {
    const spinnerParams = this.spinnerParams();

    if (!spinnerParams || !Object.keys(spinnerParams).length) {
      return;
    }

    for (const key in spinnerParams) {
      if (spinnerParams[key]) {
        spinnerParams[key] = this.formatCssVar(spinnerParams[key]);
      }
    }

    return {
      '--spinner-size': spinnerParams?.spinnerSize,
      '--spinner-speed': spinnerParams?.spinnerSpeed,
      '--spinner-circle-color': spinnerParams?.spinnerCircleColor,
      '--spinner-spin-color': spinnerParams?.spinnerSpinColor,
      '--spinner-background-color': spinnerParams?.spinnerBackgroundColor,
      '--spinner-border-radius': spinnerParams?.spinnerBorderRadius,
      '--spinner-top': spinnerParams?.spinnerTopPosition,
    };
  }

  constructor() {
    effect(() => {
      const state = this.state();

      if (state !== undefined) {
        this.loading.set(state === CardState.Loading);
      }
    });
  }

  public ngOnInit(): void {
    if (this.delay() === undefined) {
      this.delay.set(this.isSpinnerMode() || this.isOverlay() ? 0 : 300);
    }

    this.delayTimer$ = timer(this.delay()).pipe(map(() => true));
  }

  /**
   * Formats spinner parameters.
   *
   * @param value spinner parameter value. Can be set as a concrete value or a variable.
   * Variable shall starts with '--', otherwise use var() function.
   * @returns formatted string.
   *
   * @example
   * const formattedValue1 = this.formatCssVar('--bs-primary');
   * const formattedValue2 = this.formatCssVar('var(--bs-border-radius)');
   * const formattedValue3 = this.formatCssVar('30px');
   */
  private formatCssVar(value: string): string {
    if (!value) return;
    const trimmed = value.trim();
    return trimmed.startsWith('--') ? `var(${trimmed})` : trimmed;
  }
}
