import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TasksToolbarComponent } from './tasks-toolbar/tasks-toolbar.component';
import { Subject, Subscription } from 'rxjs';
import {
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { ListService } from 'src/app/shared/services/list.service';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Project } from 'src/app/shared/models/entities/projects/project.model';
import { ProjectTeamService } from 'src/app/shared/services/project-team.service';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { ProjectVersionCardService } from 'src/app/projects/card/core/project-version-card.service';
import { AppConfigService } from 'src/app/core/app-config.service';
import { MessageService } from 'src/app/core/message.service';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { filter, map, pairwise, takeUntil } from 'rxjs/operators';
import { LIST, VIEW_NAME } from 'src/app/shared/tokens';
import { ProjectTasksListService } from './project-tasks-list.service';
import { PROJECT_TASK_LIST } from 'src/app/shared/lists/project-task.list';
import { TasksGridActionsService } from 'src/app/projects/card/project-tasks/core/tasks-grid-actions.service';
import { ProjectTasksDataService } from 'src/app/projects/card/project-tasks/core/project-tasks-data.service';
import { TranslateService } from '@ngx-translate/core';
import { PROJECT_TASK_TYPES } from 'src/app/shared/models/entities/projects/project-task.model';
import { GridComponent } from 'src/app/shared-features/grid/grid.component';
import {
  GridOptions,
  SelectionType,
} from 'src/app/shared-features/grid/models/grid-options.model';
import { ProjectCardService } from 'src/app/projects/card/core/project-card.service';
import { TasksReportModalComponent } from 'src/app/projects/card/project-tasks/shared/tasks-grid/tasks-report-modal/tasks-report-modal.component';
import { TimelineRightSideComponent } from 'src/app/projects/card/project-tasks/shared/tasks-grid/timeline-right-side/timeline-right-side.component';
import { UIRouterGlobals } from '@uirouter/core';
import {
  ProjectTaskTableView,
  ProjectTaskView,
} from 'src/app/projects/card/project-tasks/shared/models/project-task-view.enum';
import { TASKS_VIEW } from 'src/app/projects/card/project-tasks/shared/models/project-task-constants';
import { MembersBoxComponent } from 'src/app/projects/card/shared/members-box/members-box.component';
import { PredecessorBoxComponent } from 'src/app/projects/card/shared/predecessor-box/predecessor-box.component';
import {
  GridCurrencyColumn,
  GridSelectControlColumn,
} from 'src/app/shared-features/grid/models/grid-column.interface';
import { OffCanvasService } from 'src/app/core/off-canvas.service';

export const resolveTasksView = (info: UIRouterGlobals) =>
  info.current.url === '/timeline'
    ? ProjectTaskView.timeline
    : ProjectTaskView.table;

export const resolveAutoPlanningView = (
  info: ProjectCardService,
  info2: UIRouterGlobals,
) => {
  const isAutoPlanning = info.project.isAutoPlanning;

  const url = info2.current.url;
  if (url === '/timeline') {
    return isAutoPlanning
      ? ProjectTaskTableView.timelineDefault
      : ProjectTaskTableView.timelineNoAutoPlanning;
  }
  return isAutoPlanning
    ? ProjectTaskTableView.tableDefault
    : ProjectTaskTableView.tableNoAutoPlanning;
};

/** Project tasks. */
@Component({
  selector: 'wp-tasks-grid',
  templateUrl: './tasks-grid.component.html',
  styleUrls: ['./tasks-grid.component.scss'],
  providers: [
    ProjectTeamService,
    GridService,
    { provide: ListService, useClass: ProjectTasksListService },
    { provide: LIST, useValue: PROJECT_TASK_LIST },
    {
      provide: VIEW_NAME,
      useFactory: resolveAutoPlanningView,
      deps: [ProjectCardService, UIRouterGlobals],
    },
    {
      provide: TASKS_VIEW,
      useFactory: resolveTasksView,
      deps: [UIRouterGlobals],
    },
  ],
  standalone: false,
})
export class TasksGridComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() entityId: string;
  @ViewChild('grid') grid: GridComponent;

  /** Grid properties. */
  public gridOptions: GridOptions = {
    sorting: false,
    resizableColumns: true,
    toolbar: TasksToolbarComponent,
    rightSideComponent:
      this.tasksView === ProjectTaskView.timeline
        ? TimelineRightSideComponent
        : null,
    clientTotals: false,
    selectionType: SelectionType.range,
    keyboardEvents: true,
    commands: [
      {
        name: 'create',
        handlerFn: (id: string) => {
          const newTaskId = this.tasksGridActionsService.create(id, false);
          const newTaskGroup =
            (this.taskDataService.formArray.controls.find(
              (group) => group.value.id === newTaskId,
            ) as UntypedFormGroup) ?? null;
          this.gridService.selectGroup(newTaskGroup);
          this.setActiveNameCell(newTaskGroup);
        },
        allowedFn: () =>
          this.gridService.selectedGroupsValue?.length < 2 &&
          this.tasksGridActionsService.checkIsCreatingAllowed(),
        keyCode: 'Insert',
      },
      {
        name: 'edit',
        handlerFn: (id) => this.tasksGridActionsService.edit(id),
        allowedFn: (id) =>
          this.gridService.selectedGroupsValue.length === 1 && id !== null,
      },
      { name: 'setUserView', handlerFn: () => this.setUserView() },
      {
        name: 'toggle',
        handlerFn: (formGroup: UntypedFormGroup) =>
          this.tasksGridActionsService.toggleTask(formGroup),
      },
      {
        name: 'setLevel',
        handlerFn: (level: number) =>
          this.tasksGridActionsService.setLevel(level),
      },
      {
        name: 'increaseLevel',
        handlerFn: (id) => this.tasksGridActionsService.increaseTaskLevel(id),
        allowedFn: (id) =>
          this.gridService.selectedGroupsValue?.length < 2 &&
          this.tasksGridActionsService.checkIsIncreaseAllowed(id),
      },
      {
        name: 'decreaseLevel',
        handlerFn: (id) => this.tasksGridActionsService.decreaseTaskLevel(id),
        allowedFn: (id) =>
          this.gridService.selectedGroupsValue?.length < 2 &&
          this.tasksGridActionsService.checkIsDecreaseAllowed(id),
      },
      {
        name: 'up',
        handlerFn: (id) => this.tasksGridActionsService.upTaskPosition(id),
        allowedFn: (id) =>
          this.gridService.selectedGroupsValue?.length < 2 &&
          this.tasksGridActionsService.checkIsUpAllowed(id),
      },
      {
        name: 'down',
        handlerFn: (id) => this.tasksGridActionsService.downTaskPosition(id),
        allowedFn: (id) =>
          this.gridService.selectedGroupsValue?.length < 2 &&
          this.tasksGridActionsService.checkIsDownAllowed(id),
      },
      {
        name: 'delete',
        label: 'shared.actions.delete',
        handlerFn: () => {
          const taskIdsForDeleting = this.getTaskIdsForDeleting();
          this.tasksGridActionsService.delete(taskIdsForDeleting);

          const nextTaskGroup = this.getTaskFormGroupForSelect(
            this.taskDataService.formArray.controls as UntypedFormGroup[],
            taskIdsForDeleting,
          );

          this.gridService.selectGroup(nextTaskGroup);
          this.setActiveNameCell(nextTaskGroup);
        },
        allowedFn: () => {
          const selectedTaskIds = this.gridService.selectedGroups$
            .getValue()
            ?.map((taskGroup) => taskGroup.value.id);
          return (
            selectedTaskIds.length &&
            selectedTaskIds.every((taskId) =>
              this.tasksGridActionsService.checkIsDeletingAllowed(taskId),
            )
          );
        },
      },
      {
        name: 'downloadTaskReport',
        label: 'shared.pnlStatement.download',
        allowedFn: () => true,
        handlerFn: () => this.downloadTasksReport(),
      },
    ],
    rowContextMenu: [
      {
        name: 'create',
        label: 'shared.actions.create',
        handlerFn: (formGroup: UntypedFormGroup) => {
          const newTaskId = this.tasksGridActionsService.create(
            formGroup.value.id,
            false,
          );
          const newTaskGroup =
            (this.taskDataService.formArray.controls.find(
              (group) => group.value.id === newTaskId,
            ) as UntypedFormGroup) ?? null;
          this.gridService.selectGroup(newTaskGroup);
        },
        allowedFn: () =>
          this.gridService.selectedGroupsValue?.length === 1 &&
          this.tasksGridActionsService.checkIsCreatingAllowed(),
        iconClass: 'bi bi-plus-lg bi-15',
      },
      {
        name: 'createChild',
        label: 'components.tasksGridComponent.actions.createChild',
        handlerFn: (formGroup: UntypedFormGroup) =>
          this.tasksGridActionsService.create(formGroup.value.id, true),
        allowedFn: (formGroup: UntypedFormGroup) =>
          this.gridService.selectedGroupsValue?.length < 2 &&
          this.tasksGridActionsService.checkIsChildCreatingAllowed(
            formGroup.value.id,
          ),
      },
      {
        name: 'duplicate',
        label: 'shared.actions.duplicate',
        handlerFn: (formGroup: UntypedFormGroup) => {
          const newTaskIndex = this.tasksGridActionsService.duplicateTask(
            formGroup.value.id,
          );
          this.gridService.selectGroup(
            this.taskDataService.formArray.at(newTaskIndex) as UntypedFormGroup,
          );
          this.gridService.detectChanges();
        },

        allowedFn: (formGroup: UntypedFormGroup) =>
          this.gridService.selectedGroupsValue?.length < 2 &&
          this.taskDataService.getTask(formGroup.value.id)?.leadTaskId &&
          !this.taskDataService.readonly &&
          !this.taskDataService.isInherited,
      },
      {
        name: 'edit',
        label: 'shared.actions.edit',
        handlerFn: (formGroup: UntypedFormGroup) =>
          this.tasksGridActionsService.edit(formGroup.value.id),
        allowedFn: () => this.gridService.selectedGroupsValue?.length === 1,
      },
      {
        name: 'delete',
        label: 'shared.actions.delete',
        handlerFn: () => {
          const taskIdsForDeleting = this.getTaskIdsForDeleting();
          this.tasksGridActionsService.delete(taskIdsForDeleting);
          this.gridService.selectGroup(null);
        },
        allowedFn: () => {
          const selectedTaskIds = this.gridService.selectedGroups$
            .getValue()
            ?.map((taskGroup) => taskGroup.value.id);
          return selectedTaskIds.every((taskId) =>
            this.tasksGridActionsService.checkIsDeletingAllowed(taskId),
          );
        },
        iconClass: 'bi bi-trash3',
      },
      {
        name: 'clear',
        label: 'shared.actions.clear',
        handlerFn: () => {
          this.gridService.clearSelectedRangeCells();
        },
        allowedFn: () =>
          !this.taskDataService.readonly && !this.taskDataService.isInherited,
      },
      {
        name: 'increaseLevel',
        label: 'shared2.actions.increaseLevel',
        handlerFn: (formGroup: UntypedFormGroup) => {
          this.tasksGridActionsService.increaseTaskLevel(formGroup.value.id);
          this.gridService.detectChanges();
        },
        allowedFn: (formGroup: UntypedFormGroup) =>
          this.gridService.selectedGroupsValue?.length < 2 &&
          this.tasksGridActionsService.checkIsIncreaseAllowed(
            formGroup.value.id,
          ),
        iconClass: 'bi bi-arrow-bar-left',
      },
      {
        name: 'decreaseLevel',
        label: 'shared2.actions.decreaseLevel',
        handlerFn: (formGroup: UntypedFormGroup) => {
          this.tasksGridActionsService.decreaseTaskLevel(formGroup.value.id);
          this.gridService.detectChanges();
        },
        allowedFn: (formGroup: UntypedFormGroup) =>
          this.gridService.selectedGroupsValue?.length < 2 &&
          this.tasksGridActionsService.checkIsDecreaseAllowed(
            formGroup.value.id,
          ),
        iconClass: 'bi bi-arrow-bar-right',
      },
      {
        name: 'up',
        label: 'shared2.actions.moveUp',
        handlerFn: (formGroup: UntypedFormGroup) =>
          this.tasksGridActionsService.upTaskPosition(formGroup.value.id),
        allowedFn: (formGroup: UntypedFormGroup) =>
          this.gridService.selectedGroupsValue?.length < 2 &&
          this.tasksGridActionsService.checkIsUpAllowed(formGroup.value.id),
        iconClass: 'bi bi-arrow-bar-up',
      },
      {
        name: 'down',
        label: 'shared2.actions.moveDown',
        handlerFn: (formGroup: UntypedFormGroup) =>
          this.tasksGridActionsService.downTaskPosition(formGroup.value.id),
        allowedFn: (formGroup: UntypedFormGroup) =>
          this.gridService.selectedGroupsValue?.length < 2 &&
          this.tasksGridActionsService.checkIsDownAllowed(formGroup.value.id),
        iconClass: 'bi bi-arrow-bar-down',
      },
      {
        name: 'isActive',
        label: (formGroup: UntypedFormGroup) =>
          formGroup.controls.isActive.value
            ? 'shared2.actions.complete'
            : 'shared2.actions.open',
        handlerFn: (formGroup: UntypedFormGroup) =>
          this.tasksGridActionsService.switchTaskStatus(formGroup.value.id),
        allowedFn: () =>
          this.gridService.selectedGroupsValue?.length < 2 &&
          this.tasksGridActionsService.checkIsStatusChangingAllowed(),
      },
    ],
    copyPasteParsers: [
      {
        columnName: 'dependencies',
        parseTextToValue: PredecessorBoxComponent.parseTextToValue,
      },
      {
        columnName: 'projectTaskAssignments',
        parseTextToValue: MembersBoxComponent.parseTextToValue,
      },
    ],
    view: this.listService.getGridView(),
  };

  private project: Project;
  private destroyed$ = new Subject<void>();
  private projectSubscription: Subscription;

  constructor(
    @Inject(TASKS_VIEW) public tasksView: ProjectTaskView,
    private service: ProjectCardService,
    private versionCardService: ProjectVersionCardService,
    private listService: ListService,
    private gridService: GridService,
    private modal: NgbModal,
    private blockUI: BlockUIService,
    private message: MessageService,
    private httpClient: HttpClient,
    public taskDataService: ProjectTasksDataService,
    public tasksGridActionsService: TasksGridActionsService,
    private translateService: TranslateService,
    private elRef: ElementRef,
    private offCanvasService: OffCanvasService,
  ) {}

  public ngOnInit(): void {
    this.projectSubscription = this.service.project$.subscribe(
      (project: Project) => {
        this.project = project;
        if (this.grid) {
          this.grid.toolbarInputs = { project };
        }
        this.setColumnsCurrency();
        this.setColumnsOptions();
        this.gridService.detectChanges();
      },
    );

    /**
     * Subscription to loading indication.
     * */
    this.taskDataService.isLoading$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((isLoading: boolean) =>
        this.gridService.setLoadingState(isLoading),
      );

    /**
     * Subscription to changes.
     * */
    this.taskDataService.update$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.gridService.detectChanges());

    this.gridService.selectedGroup$
      .pipe(
        pairwise(),
        filter(
          ([lastGroup, newGroup]) =>
            this.offCanvasService.offCanvasEntry &&
            lastGroup.getRawValue().id !== newGroup.getRawValue().id,
        ),
        map(([_, newGroup]) => newGroup),
        takeUntil(this.destroyed$),
      )
      .subscribe((group: FormGroup) => {
        this.tasksGridActionsService.edit(group.getRawValue().id);
      });
  }

  ngAfterViewInit(): void {
    if (this.taskDataService.project) {
      this.grid.toolbarInputs = { project: this.taskDataService.project };
    }
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.projectSubscription.unsubscribe();
  }

  private setColumnsOptions() {
    const gridView = this.listService.getGridView();

    const taskTypeColumn = gridView.columns.find(
      (col) => col.name === 'type',
    ) as GridSelectControlColumn;

    if (taskTypeColumn) {
      const taskTypes = [];
      // TODO: remove filter after fixed units realize
      PROJECT_TASK_TYPES.forEach((taskType) =>
        taskTypes.push({
          id: taskType,
          name: this.translateService.instant(`enums.taskTypes.${taskType}`),
        }),
      );

      taskTypeColumn.values = taskTypes;
    }

    this.gridOptions.view = gridView;
  }

  /**
   * Opens view configuration dialog
   *
   * @private
   * */
  private setUserView() {
    this.listService.setUserView().then(
      () => {
        this.gridOptions.view = this.listService.getGridView();
        this.taskDataService.load();
        this.setColumnsCurrency();
        this.setColumnsOptions();
      },
      () => null,
    );
  }

  /**
   * Downloads task report
   *
   * @private
   */
  private downloadTasksReport() {
    const url = `${AppConfigService.config.api.url}/ProjectTasksReport/GetProjectTasksExcel`;

    const ref = this.modal.open(TasksReportModalComponent);

    ref.result.then(
      (settings) => {
        this.blockUI.start();

        const params = {
          projectId: this.taskDataService.project.id,
          versionId: !this.versionCardService.isWorkProjectVersion()
            ? this.versionCardService.projectVersion.id
            : null,
          kpiKinds: settings.kpiKinds,
          kpiTypes: settings.kpiTypes,
        };

        this.httpClient
          .post(url, params, {
            responseType: 'blob',
          })
          .subscribe({
            next: (data) => {
              saveAs(data, `Project tasks Report.xlsx`);
              this.blockUI.stop();
            },
            error: async (error) => {
              const isPromise = !!error && typeof error.then === 'function';

              if (isPromise) {
                const errorText = await error;
                const errorObj = JSON.parse(errorText);
                this.message.error(errorObj.error.message);
                this.blockUI.stop();
              } else {
                this.message.error(error.message);
                this.blockUI.stop();
              }
            },
          });
      },
      () => null,
    );
  }

  /**
   * Sets columns currency
   *
   * @private
   */
  private setColumnsCurrency() {
    const amountColumns = this.gridOptions.view.columns.filter(
      (column) =>
        column.name === 'actualCost' ||
        column.name === 'plannedCost' ||
        column.name === 'estimatedCost',
    );
    amountColumns.forEach((column) => {
      const financialColumn = column as GridCurrencyColumn;
      financialColumn.currencyCode = this.project.currency.alpha3Code;
    });
    this.gridService.detectChanges();
  }

  /**
   * Retrieves task ids for deleting. Lead task deletes with it children task.
   *
   * @returns An array of task IDs that for deleting.
   */
  private getTaskIdsForDeleting(): string[] {
    const selectedTasks = this.gridService.selectedGroups$
      .getValue()
      ?.map((taskGroup) => taskGroup.value);
    return selectedTasks
      .filter((task) => !selectedTasks.find((t) => t.id === task.leadTaskId))
      .map((t) => t.id);
  }

  /**
   * Get next task to be highlighted after deletion.
   *
   * @param tasks - project tasks form array.
   * @param deletedTaskIds - An array of task IDs that were deleted.
   * @returns The form group of the next task to be selected, or `null` if no tasks remain.
   */
  private getTaskFormGroupForSelect(
    tasks: UntypedFormGroup[],
    deletedTaskIds: string[],
  ): UntypedFormGroup | null {
    const remainingTasks = tasks.filter(
      (task) => !deletedTaskIds.includes(task.value.id),
    );

    if (!remainingTasks.length) {
      return null;
    }

    const minDeletedIndex = tasks.findIndex((task) =>
      deletedTaskIds.includes(task.value.id),
    );

    if (minDeletedIndex === -1 || minDeletedIndex >= remainingTasks.length) {
      return remainingTasks[remainingTasks.length - 1];
    }

    return remainingTasks[minDeletedIndex];
  }

  /**
   * Activates the name cell of form group and sets focus to the grid table.
   *
   * @param formGroup - The form group containing the `name` control to be activated.
   */
  private setActiveNameCell(formGroup: UntypedFormGroup): void {
    this.gridService.setActiveControl(
      formGroup.controls.name as UntypedFormControl,
    );

    this.elRef.nativeElement.querySelector('[name="left"]').firstChild.focus();
  }
}
