<wp-loading-indicator [loading]="isLoading$ | async"></wp-loading-indicator>
@if (!(isLoading$ | async)) {
  <form class="modal-form" novalidate [formGroup]="form" ngDraggable>
    <div class="modal-header">
      <h3 class="modal-title">{{ headerTitle }}</h3>
      <button
        [disabled]="isSaving$ | async"
        type="button"
        class="btn-close"
        data-dismiss="modal"
        aria-hidden="true"
        (click)="cancel()"
      ></button>
    </div>
    <div class="modal-body">
      <ul
        ngbNav
        #nav="ngbNav"
        class="nav-tabs"
        [hidden]="!grantsAreAllowed"
        [destroyOnHide]="false"
      >
        <li [ngbNavItem]>
          <a ngbNavLink>{{ 'analytics.dashboards.settings.tabs.properties' | translate }}</a>
          <ng-template ngbNavContent>
            <wp-dashboard-properties
              [navigationResponse]="navigationResponse"
              [form]="form"
              [navigations]="navigations"
            ></wp-dashboard-properties>
          </ng-template>
        </li>
        <li [ngbNavItem] [hidden]="!grantsAreAllowed">
          <a ngbNavLink>{{ 'analytics.dashboards.settings.tabs.access' | translate }}</a>
          <ng-template ngbNavContent>
            <tmt-performers
              class="p-0"
              [performerLines]="permissions"
              [readonly]="false"
              [predefinedRoles]="[{ id: 'All', name: 'shared2.props.roleAll' | translate }]"
              [gridHeader]="
                'components.boardCardPermissionsComponent.groups.gridHeader' | translate
              "
            />
          </ng-template>
        </li>
      </ul>

      <div [ngbNavOutlet]="nav"></div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-primary"
        (click)="ok()"
        wpButtonSpinner
        [isBusy]="isSaving$ | async"
      >
        {{ okTitle }}
      </button>
      <button
        type="button"
        class="btn btn-default"
        (click)="cancel()"
        [disabled]="isSaving$ | async"
      >
        {{ 'shared.actions.cancel' | translate }}
      </button>
    </div>
  </form>
}
