<tmt-form-header [name]="contactCardService.contactName()" />
<tmt-not-found [state]="contactCardService.state()" />
<wp-loading-indicator [state]="contactCardService.state()" />

@if (contactCardService.state() === 'Ready') {
  <div class="subheader">
    <div class="subheader__props">
      @if (contactCardService.contact().email) {
        <div class="subheader__prop">
          <span class="subheader__label">{{ 'shared2.props.email' | translate }}: </span>
          <div class="subheader__value" id="email">
            <p class="trim">
              <a
                title="{{ contactCardService.contact().email }}"
                href="mailto://{{ contactCardService.contact().email }}"
                >{{ contactCardService.contact().email }}
              </a>
            </p>
          </div>
        </div>
      }

      @if (contactCardService.contact().phone) {
        <div class="subheader__prop">
          <span class="subheader__label">{{ 'shared2.props.phone' | translate }}: </span>
          <div class="subheader__value" id="phone">
            <p class="trim">
              <a
                title="{{ contactCardService.contact().phone }}"
                href="tel:{{ contactCardService.contact().phone }}"
              >
                {{ contactCardService.contact().phone | tmtPhone }}
              </a>
            </p>
          </div>
        </div>
      }

      @if (contactCardService.contact().mobilePhone) {
        <div class="subheader__prop">
          <span class="subheader__label">{{ 'shared2.props.mobilePhone' | translate }}: </span>
          <div class="subheader__value" id="mobilePhone">
            <p class="trim">
              <a
                title="{{ contactCardService.contact().mobilePhone }}"
                href="tel:{{ contactCardService.contact().mobilePhone }}"
              >
                {{ contactCardService.contact().mobilePhone | tmtPhone }}
              </a>
            </p>
          </div>
        </div>
      }

      @if (contactCardService.contact().organization) {
        <div class="subheader__prop">
          <div class="subheader__label">{{ 'shared2.props.organization' | translate }}:</div>
          <div class="subheader__value">
            <a
              uiSref="client"
              [uiParams]="{ entityId: contactCardService.contact().organization.id }"
              >{{ contactCardService.contact().organization?.name }}</a
            >
          </div>
        </div>
      }
    </div>
  </div>

  <div>
    <ul class="nav nav-tabs">
      @for (tab of tabs; track tab) {
        <li class="nav-item">
          <a
            class="nav-link"
            [ngClass]="{ active: stateService?.current?.name === tab.state }"
            [uiSref]="tab.state"
            [uiParams]="{ routeMode: 'keep' }"
            >{{ tab.header | translate }}</a
          >
        </li>
      }
    </ul>
    <ui-view></ui-view>
  </div>
}
