import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { DataService, DataServiceHelper } from 'src/app/core/data.service';
import { Guid } from 'src/app/shared/helpers/guid';
import { HeaderParams } from 'src/app/shared/models/header-params.model';

class DataServiceHotHelper extends DataServiceHelper {
  constructor(
    public editingSessionId: string,
    public applicationId: string,
  ) {
    super();
  }

  public override getOptionsWithHeaders(
    headerParams: HeaderParams,
    existOptions?: Record<string, any>,
  ): Record<string, any> {
    const options = super.getOptionsWithHeaders(headerParams, existOptions);
    options.headers = options.headers.set(
      'X-Editing-Session-Id',
      this.editingSessionId,
    );
    options.headers = options.headers.set(
      'X-Application-Id',
      this.applicationId,
    );

    return options;
  }
}

@Injectable()
export class DataServiceHot extends DataService {
  public readonly applicationId = Guid.generate();

  protected override helper: DataServiceHelper = new DataServiceHotHelper(
    this.entityId,
    this.applicationId,
  );

  constructor(
    http: HttpClient,
    @Inject('entityId') protected entityId,
  ) {
    super(http);
  }
}
