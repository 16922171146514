<form [formGroup]="detailsForm" class="form">
  <div class="row">
    <div class="col-6">
      @if (viewName !== 'my') {
        <div class="form-group">
          <label>{{
            'components.dealListFilterComponent.filters.manager.label' | translate
          }}</label>
          <wp-user-box
            formControlName="manager"
            [allowInactive]="filterService.allowInactive$ | async"
            placeholder="{{ 'components.dealListFilterComponent.filters.manager.ph' | translate }}"
          />
        </div>
      }

      <div class="form-group">
        <label>{{ 'shared2.filters.period.value' | translate }}</label>
        <wp-date-period-box
          formControlName="period"
          placeholder="{{ 'shared2.filters.period.ph' | translate }}"
        />
      </div>

      <div class="form-group">
        <label>{{ 'components.dealListFilterComponent.filters.client.value' | translate }}</label>
        <wp-select-box
          formControlName="organization"
          collection="Organizations"
          [allowInactive]="filterService.allowInactive$ | async"
          placeholder="{{ 'components.dealListFilterComponent.filters.client.ph' | translate }}"
        />
      </div>

      <div class="form-group">
        <label>{{ 'components.dealListFilterComponent.filters.source.value' | translate }}</label>
        <wp-select-box
          formControlName="source"
          [directory]="{ entityName: 'Deal', propertyName: 'Source' }"
          [allowInactive]="filterService.allowInactive$ | async"
          placeholder="{{ 'components.dealListFilterComponent.filters.source.ph' | translate }}"
        />
      </div>

      <div class="form-group">
        <label>{{ 'shared2.filters.resolution.value' | translate }}</label>
        <wp-select-box
          formControlName="resolution"
          [directory]="{ entityName: 'Deal', propertyName: 'Resolution' }"
          [allowInactive]="filterService.allowInactive$ | async"
          placeholder="{{ 'shared2.filters.resolution.ph' | translate }}"
        />
      </div>
    </div>
    <div class="col-5">
      <tmt-state-select formControlName="states" collection="Deals" />
    </div>
  </div>
</form>
