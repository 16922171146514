import {
  DestroyRef,
  Inject,
  Injectable,
  computed,
  inject,
  signal,
} from '@angular/core';
import { Observable } from 'rxjs';
import _ from 'lodash';
import { Contact } from 'src/app/contacts/model/contact.model';
import { NotificationService } from 'src/app/core/notification.service';
import { Collection, DataService } from 'src/app/core/data.service';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { Exception } from 'src/app/shared/models/exception';
import { NavigationService } from 'src/app/core/navigation.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable()
export class ContactCardService {
  private readonly _state = signal<CardState>(CardState.Loading);
  public readonly state = computed(this._state);
  private readonly _contactName = signal<string>(null);
  public readonly contactName = computed(this._contactName);
  private readonly _contact = signal<Contact>(null);
  public readonly contact = computed(this._contact);

  public contactCollection: Collection;

  private readonly dataService = inject(DataService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly navigationService = inject(NavigationService);
  private readonly notificationService = inject(NotificationService);

  constructor(
    @Inject('entityId')
    public contactId: string,
  ) {
    this.contactCollection = this.dataService.collection('Contacts');
  }

  /** Loads contact data and processes it. */
  public load(): void {
    const query = this.buildQuery();

    this.loadContact(query)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (contact: Contact) => {
          this._contact.set(contact);
          this._contactName.set(contact.name);
          this._state.set(CardState.Ready);
          this.navigationService.addRouteSegment({
            id: this.contactId,
            title: contact.name,
          });
        },
        error: (error: Exception) => {
          if (error.code === Exception.BtEntityNotFoundException.code) {
            this._state.set(CardState.Error);
            return;
          }
          this.notificationService.error(error.message);
        },
      });
  }

  /**
   * Loads contact's data.
   *
   * @param query OData query for loading contact data.
   * @returns Contact entity.
   */
  public loadContact(query: any): Observable<Contact> {
    return this.contactCollection.entity(this.contactId).get<Contact>(query);
  }

  /** Updates current contact data. */
  public updateContact(contact: Partial<Contact>): void {
    const newContact: Partial<Contact> = {};

    Object.keys(this._contact()).forEach((key) => {
      if (contact[key] && !_.isEqual(contact[key], this._contact()[key])) {
        newContact[key] = contact[key];
      }
    });

    this._contactName.update(() => `${contact.firstName} ${contact.lastName}`);
    this._contact.update(() => Object.assign({}, this._contact(), newContact));
  }

  /**
   * Builds OData query for contact entity
   *
   * @returns OData query
   */
  private buildQuery(): any {
    return {
      select: ['id', 'name', 'email', 'mobilePhone', 'phone', 'editAllowed'],
      expand: [{ organization: { select: ['id', 'name'] } }],
    };
  }
}
