import {
  DestroyRef,
  inject,
  Inject,
  Injectable,
  OnDestroy,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import _ from 'lodash';
import { BehaviorSubject, delay, merge, tap } from 'rxjs';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ActionPanelService } from 'src/app/core/action-panel.service';
import { DataService } from 'src/app/core/data.service';
import { LifecycleService } from 'src/app/core/lifecycle.service';
import { MessageService } from 'src/app/core/message.service';
import { NavigationService } from 'src/app/core/navigation.service';
import { NotificationService } from 'src/app/core/notification.service';
import { OffCanvasService } from 'src/app/core/off-canvas.service';

import { Exception } from 'src/app/shared/models/exception';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { Constants } from 'src/app/shared/globals/constants';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';

import { Issue, IssueFollower } from 'src/app/issues/models/issue.model';
import { ChangingIssueTypeModalComponent } from 'src/app/issues/card/changing-issue-type-modal/changing-issue-type-modal.component';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';

@Injectable()
export class IssueCardService implements OnDestroy {
  public state$ = new BehaviorSubject<CardState>(null);
  public issue$ = new BehaviorSubject<Issue>(null);
  public isUpdateFollowers = false;
  public isEditorOpened = false;
  public isCommentOpened = false;
  public issueForm: FormGroup = this.fb.group({
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formTextMaxLength)],
    ],
    description: '',
    assigned: null,
    resolution: null,
    resolutionComment: '',
    modified: null,
    modifiedBy: null,
    priority: [null, Validators.required],
    initiator: [null, Validators.required],
    project: null,
    projectTask: null,
    dueDate: null,
    followers: null,
    isActive: false,
    mentionedUserIds: [],
  });
  public readonly actionPanelService: ActionPanelService;

  private query = {
    select: [
      'id',
      'code',
      'name',
      'created',
      'editAllowed',
      'typeId',
      'assignedId',
      'resolutionComment',
      'modified',
      'isActive',
      'description',
      'dueDate',
      'mentionedUserIds',
    ],
    expand: [
      { createdBy: { select: 'id, name' } },
      { assigned: { select: ['id', 'name'] } },
      { type: { select: ['id', 'name', 'iconClass'] } },
      { resolution: { select: 'id, name' } },
      { modifiedBy: { select: ['id', 'name'] } },
      { project: { select: 'id, name' } },
      { projectTask: { select: ['id', 'name'] } },
      { priority: { select: ['id', 'name'] } },
      { initiator: { select: ['id', 'name'] } },
      { state: { select: ['id', 'name'] } },
      {
        followers: {
          select: ['id'],
          expand: { follower: { select: ['id', 'name'] } },
        },
      },
    ],
  };
  private destroyRef = inject(DestroyRef);
  // NOTE: works only with Navigation properties.
  private readonly indicatorTrigger = ['assigned', 'initiator'];

  public get issue(): Issue {
    return this.issue$.getValue();
  }

  public get formValue(): any {
    return this.issueForm.getRawValue();
  }

  public get projectTaskControl(): AbstractControl {
    return this.issueForm.get('projectTask');
  }

  public get projectControl(): AbstractControl {
    return this.issueForm.get('project');
  }

  constructor(
    @Inject('entityId') public issueId: string,
    private fb: FormBuilder,
    private lifecycleService: LifecycleService,
    private dataService: DataService,
    private navigationService: NavigationService,
    private notificationService: NotificationService,
    private savingQueueService: SavingQueueService,
    private customFieldService: CustomFieldService,
    private offCanvasService: OffCanvasService,
    private messageService: MessageService,
    private modalService: NgbModal,
    rootActionPanelService: ActionPanelService,
  ) {
    this.actionPanelService = this.offCanvasService.offCanvasEntry
      ? offCanvasService.offCanvasActionPanelService
      : rootActionPanelService;

    this.actionPanelService.reload$.pipe(takeUntilDestroyed()).subscribe(() => {
      if (this.issueForm.dirty || this.isEditorOpened) {
        this.messageService.confirmLocal('shared.leavePageMessage').then(
          () => this.reload(),
          () => null,
        );
      } else {
        this.reload();
      }
    });

    merge(this.lifecycleService.reload$, this.savingQueueService.error$)
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.reload());

    this.customFieldService.enrichFormGroup(this.issueForm, 'Issue', true);

    this.issueForm.valueChanges
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.save());

    this.projectControl.valueChanges
      .pipe(
        delay(Constants.mainFormControlChangedDelayTime),
        takeUntilDestroyed(),
      )
      .subscribe((value) => {
        if (!value) {
          this.projectTaskControl.setValue(null, { emitEvent: false });
          this.projectTaskControl.disable({ emitEvent: false });
        } else {
          this.projectTaskControl.enable({ emitEvent: false });
        }
      });

    this.savingQueueService.save$.pipe(takeUntilDestroyed()).subscribe(() => {
      this.issueForm.markAsPristine();

      if (this.issue) {
        this.offCanvasService.onEntityUpdated({
          ...this.formValue,
          id: this.issueId,
        });
      }
    });

    this.lifecycleService.lifecycleInfo$
      .pipe(takeUntilDestroyed())
      .subscribe((info) => {
        if (this.issue) {
          this.offCanvasService.onEntityUpdated({
            ...this.formValue,
            id: this.issueId,
            changedStateId:
              info.currentState.id !== this.issue.state.id
                ? info.currentState.id
                : null,
          });
        }
      });

    if (this.offCanvasService.offCanvasEntry) {
      this.offCanvasService.offCanvasEntry.onClose = () => {
        if (this.isEditorOpened || this.isCommentOpened) {
          return this.messageService.confirmLocal('shared.leavePageMessage');
        } else {
          return Promise.resolve();
        }
      };
    }
  }

  public ngOnDestroy(): void {
    this.savingQueueService.save().then(() => {
      if (this.issue) {
        this.offCanvasService.onEntityUpdated({
          ...this.formValue,
          id: this.issueId,
        });
      }
    });
  }

  /** Loads issue. */
  public load(): void {
    this.state$.next(CardState.Loading);
    this.issueForm.markAsPristine();
    this.issueForm.markAsUntouched();
    this.actionPanelService.resetLifecycle();
    this.customFieldService.enrichQuery(this.query, 'Issue');

    this.dataService
      .collection('Issues')
      .entity(this.issueId)
      .get<Issue>(this.query)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (issue) => {
          this.issue$.next(issue);
          this.issueForm.patchValue(
            { ...issue, followers: issue.followers?.map((el) => el.follower) },
            { emitEvent: false },
          );

          if (issue.editAllowed) {
            this.issueForm.enable({ emitEvent: false });
            this.disableResolutionFields();
            this.toggleProjectFields();
          } else {
            this.issueForm.disable({ emitEvent: false });
          }

          if (!this.offCanvasService.offCanvasEntry) {
            this.navigationService.addRouteSegment({
              id: issue.id,
              title: issue.name,
            });
          }

          this.actionPanelService.setHasAutosave(true);
          this.actionPanelService.setAdditional([
            {
              title: 'components.issueCardComponent.actions.changeType',
              hint: 'components.issueCardComponent.actions.changeType',
              name: 'openChangeTypeModal',
              isBusy: false,
              isVisible: issue.editAllowed,
              handler: () => this.openChangeTypeModal(),
            },
          ]);

          this.issue$.next(issue);
          this.state$.next(CardState.Ready);
        },
        error: (error: Exception) => {
          this.state$.next(CardState.Error);
          if (error.code !== Exception.BtEntityNotFoundException.code) {
            this.notificationService.error(error.message);
          }
        },
      });
  }

  /** Saves issue. */
  public save(): void {
    if (this.issueForm.invalid) {
      this.notificationService.warningLocal(
        'shared2.messages.requiredFieldsError',
      );
      return;
    }

    const followers: Partial<IssueFollower>[] = [];
    this.formValue.followers?.forEach((follower: IssueFollower) => {
      followers.push({
        followerId: follower.id,
      });
    });
    const issue: Partial<Issue> = {
      description: this.formValue.description ?? '',
      assignedId: this.formValue.assigned?.id ?? null,
      projectId: this.formValue.project?.id ?? null,
      projectTaskId: this.formValue.project?.id
        ? (this.formValue.projectTask?.id ?? null)
        : null,
      priorityId: this.formValue.priority?.id ?? null,
      initiatorId: this.formValue.initiator?.id ?? null,
      dueDate: this.formValue.dueDate ?? null,
      followers,
      mentionedUserIds: this.formValue.mentionedUserIds,
    };
    this.customFieldService.assignValues(issue, this.formValue, 'Issue');

    this.savingQueueService.addToQueue(
      this.issueId,
      this.dataService
        .collection('Issues')
        .entity(this.issueId)
        .patch(issue)
        .pipe(
          tap(() => {
            for (const key of this.indicatorTrigger) {
              if (this.issue[key]?.id !== this.formValue[key]?.id) {
                this.navigationService.updateIndicators();
                break;
              }
            }

            if (this.isUpdateFollowers) {
              this.updateFollowers();
            }

            this.issueForm.markAsPristine();
            this.issue$.next(_.merge(this.issue, this.formValue));
          }),
        ),
    );
  }

  /** Reloads page. */
  public reload(): void {
    this.savingQueueService.save().then(
      () => {
        this.load();
        this.lifecycleService.reloadLifecycle();
      },
      () => null,
    );
  }

  private toggleProjectFields(): void {
    if (!this.issue.editAllowed) {
      this.projectControl.disable({ emitEvent: false });
      this.projectTaskControl.disable({ emitEvent: false });

      return;
    }

    if (this.projectControl.getRawValue()) {
      this.projectTaskControl.enable({ emitEvent: false });
    } else {
      this.projectTaskControl.disable({ emitEvent: false });
    }
  }

  private openChangeTypeModal(): void {
    const modalRef = this.modalService.open(ChangingIssueTypeModalComponent);
    modalRef.componentInstance.issue = this.issue;

    modalRef.result.then(
      () => {
        this.reload();
      },
      () => null,
    );
  }

  /** Disables resolution fields. */
  private disableResolutionFields(): void {
    this.issueForm.get('resolution').disable({ emitEvent: false });
    this.issueForm.get('resolutionComment').disable({ emitEvent: false });
  }

  /** Updates followers value. */
  private updateFollowers(): void {
    this.dataService
      .collection('Issues')
      .entity(this.issueId)
      .get<Issue>({
        select: ['id'],
        expand: [
          {
            followers: {
              select: ['id'],
              expand: { follower: { select: ['id', 'name'] } },
            },
          },
        ],
      })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (issue: Issue) => {
          const followers: Partial<IssueFollower>[] = [];
          issue.followers?.forEach((el) => followers.push(el.follower));
          this.issueForm
            .get('followers')
            .setValue(followers, { emitEvent: false });
          this.isUpdateFollowers = false;
        },
        error: (error: Exception) => {
          this.notificationService.error(error.message);
        },
      });
  }
}
