import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Timesheet } from 'src/app/shared/models/entities/base/timesheet.model';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { META_ENTITY_TYPE } from 'src/app/shared/tokens';
import { AppName } from 'src/app/shared/globals/app-name';
import { NavigationService } from 'src/app/core/navigation.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { MessageService } from 'src/app/core/message.service';
import { NotificationService } from 'src/app/core/notification.service';
import { Exception } from 'src/app/shared/models/exception';
import { TimesheetCardService } from './core/timesheet-card.service';
import { Subject } from 'rxjs';
import { Line } from './shared/models/line.model';
import { TranslateService } from '@ngx-translate/core';
import { WorkPipe } from 'src/app/shared/pipes/work.pipe';
import { PercentPipe } from '@angular/common';
import { AppService } from 'src/app/core/app.service';
import { StopwatchService } from 'src/app/core/stopwatch.service';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { UserInfoComponent } from 'src/app/shared/components/features/user-info/user-info.component';
import { HeaderIndicator } from 'src/app/shared/components/chrome/form-header2/header-indicator.model';
import { takeUntil } from 'rxjs/operators';
import { LifecycleService } from 'src/app/core/lifecycle.service';
import { CommentedEntityCollectionType } from 'src/app/shared/models/enums/commented-entity-collection-type.enum';
import { TimesheetLifecycleService } from './core/timesheet-lifecycle.service';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { TimeSheetCacheService } from 'src/app/timesheets/card/core/timesheet-cache.service';
import { WorkLogService } from 'src/app/work-log/work-log.service';
import { DataService } from 'src/app/core/data.service';
import { DataServiceHot } from 'src/app/core/hot-changes/data-hot.service';
import { WebSocketHotChangesService } from 'src/app/core/hot-changes';
import { DataNotSavedIndicatorService } from 'src/app/shared/components/features/data-not-saved-indicator/data-not-saved-indicator.service';

@Component({
  selector: 'tmt-timesheet-card',
  templateUrl: './timesheet-card.component.html',
  styleUrls: ['./timesheet-card.component.scss'],
  providers: [
    SavingQueueService,
    DataNotSavedIndicatorService,
    TimesheetCardService,
    TimeSheetCacheService,
    WorkLogService,
    { provide: META_ENTITY_TYPE, useValue: 'TimeSheet' },
    { provide: LifecycleService, useClass: TimesheetLifecycleService },
    { provide: DataService, useClass: DataServiceHot },
    WebSocketHotChangesService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class TimesheetCardComponent implements OnInit, OnDestroy {
  @Input() entityId: string;

  public activeTab: string;
  public readonly = false;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly CommentedEntityCollectionType =
    CommentedEntityCollectionType;

  private lines: Line[] = [];

  private labelHours = this.translate.instant(
    'timesheets.card.props.totals.hours',
  );
  private labelBillableHours = this.translate.instant(
    'timesheets.card.props.totals.billable',
  );

  private labelSchedule = this.translate.instant(
    'timesheets.card.props.totals.schedule',
  );

  private labelUtilization = this.translate.instant(
    'timesheets.card.props.totals.utilization',
  );

  private destroyed$ = new Subject<void>();

  constructor(
    public service: TimesheetCardService,
    public savingQueueService: SavingQueueService,
    public navigation: NavigationService,
    public blockUI: BlockUIService,
    public actionPanelService: ActionPanelService,
    private stopwatchService: StopwatchService,
    public app: AppService,
    private workPipe: WorkPipe,
    private percentPipe: PercentPipe,
    private translate: TranslateService,
    private messageService: MessageService,
    private notification: NotificationService,
    private infoPopupService: InfoPopupService,
    private injector: Injector,
    private lifecycleService: LifecycleService,
    private dataNotSavedIndicatorService: DataNotSavedIndicatorService,
  ) {
    this.savingQueueService.delayDuration = 0;
    this.stopwatchService.autosaveService = this.savingQueueService;
  }

  public ngOnInit(): void {
    this.actionPanelService.setHasAutosave(true);

    // Установка главного меню.
    this.actionPanelService.setAdditional([
      {
        title: 'shared.actions.delete',
        hint: 'timesheets.card.actions.delete.hint',
        name: 'delete',
        isBusy: false,
        isVisible: false,
        handler: () => this.delete(),
      },
      {
        title: 'finance.entries.actions.openEntries.title',
        hint: 'finance.entries.actions.openEntries.hint',
        name: 'openEntries',
        isBusy: false,
        isVisible: this.app.checkAppAccess(AppName.Finance),
        handler: () => this.service.goToAccountingEntry(),
      },
    ]);

    this.actionPanelService.reload$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        if (this.dataNotSavedIndicatorService.preventLeave) {
          this.messageService
            .confirmLocal('shared2.messages.leavePageMessage')
            .then(
              () => {
                this.service.load();
                this.lifecycleService.reloadLifecycle();
              },
              () => null,
            );
        } else {
          this.service.load();
          this.lifecycleService.reloadLifecycle();
        }
      });

    this.lifecycleService.reload$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.service.load();
      });

    this.service.timesheet$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((timesheet: Timesheet) => {
        this.readonly = !timesheet.editAllowed;

        this.calculateTotals();
        this.updateUIState();
      });

    this.service.linesUpdated$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.lines = this.service.dataLines.value;
        this.calculateTotals();
      });
  }

  ngOnDestroy(): void {
    this.stopwatchService.autosaveService = null;
    this.destroyed$.next();
  }

  /** Открыть сведения о пользователе. */
  public openUserInfo() {
    const userId = this.service.timesheet.user.id;
    const target = document.getElementById('timesheet-user');
    this.infoPopupService.open({
      target,
      data: {
        component: UserInfoComponent,
        params: {
          userId,
        },
        injector: this.injector,
      },
    });
  }

  /** Удаление таймшита. */
  public delete() {
    this.messageService
      .confirmLocal('timesheets.card.messages.deleteConfirmation')
      .then(
        () => {
          this.service.collection
            .entity(this.entityId)
            .delete()
            .subscribe({
              next: () => {
                this.notification.successLocal('shared.messages.deleted');
                this.navigation.goToSelectedNavItem(true);
              },
              error: (error: Exception) => {
                this.messageService
                  .error(error.message)
                  .then(this.service.load, this.service.load);
              },
            });
        },
        () => null,
      );
  }

  /** Рассчитать итоги по таймшиту. */
  private calculateTotals() {
    if (!this.service.timesheet) {
      return;
    }

    let hours = 0;
    let schedule = 0;
    let billableHours = 0;

    this.lines.forEach((line) =>
      line.allocations?.forEach((allocation) => {
        hours += allocation.hours || 0;

        if (allocation.isBillable) {
          billableHours += allocation.hours || 0;
        }
      }),
    );

    this.service.timesheet.timeOffRequests.forEach((request) =>
      request.timeAllocations.forEach((allocation) => {
        hours += allocation.hours || 0;

        if (allocation.isBillable) {
          billableHours += allocation.hours || 0;
        }
      }),
    );

    this.service.timesheet.issues.forEach((issue) =>
      issue.timeAllocations.forEach((allocation) => {
        hours += allocation.hours || 0;

        if (allocation.isBillable) {
          billableHours += allocation.hours || 0;
        }
      }),
    );

    this.service.timesheet.schedule.forEach((s) => (schedule += s.hours || 0));

    const indicators: HeaderIndicator[] = [
      {
        value: this.workPipe.transform(hours),
        description: this.labelHours,
      },
      {
        value: this.workPipe.transform(billableHours),
        description: this.labelBillableHours,
      },
      {
        value: `${this.workPipe.transform(schedule)}`,
        description: this.labelSchedule,
      },
      {
        value: this.percentPipe.transform(
          hours > 0 ? billableHours / hours : 0,
        ),
        description: this.labelUtilization,
      },
    ];

    this.service.updateIndicators(indicators);
  }

  private updateUIState() {
    this.actionPanelService.action('delete').isShown =
      this.service.timesheet.deleteAllowed;
  }
}
