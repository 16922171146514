<div class="h1">{{ entity.name }}</div>
<table class="table table-bordered mt-3">
  <colgroup>
    <col style="width: 300px" />
    <col />
  </colgroup>
  <tbody>
    <tr>
      <td>{{ 'components.metamodelListComponent.props.displayName' | translate }}</td>
      <td>
        {{ getFormattedLocalStrings(entity.displayNames) }}
      </td>
    </tr>
    <tr>
      <td>{{ 'components.metamodelListComponent.props.customizable' | translate }}</td>
      <td>{{ entity.customizable | wpBoolean }}</td>
    </tr>
    <tr>
      <td>{{ 'components.metamodelListComponent.props.usedInBoard' | translate }}</td>
      <td>{{ entity.usedInBoards | wpBoolean }}</td>
    </tr>
    <tr>
      <td>{{ 'components.metamodelListComponent.props.usedInLogging' | translate }}</td>
      <td>{{ entity.usedInLogging | wpBoolean }}</td>
    </tr>
    <tr>
      <td>{{ 'components.metamodelListComponent.props.hasLifecycle' | translate }}</td>
      <td>{{ entity.hasLifecycle | wpBoolean }}</td>
    </tr>

    @if (entity.hierarchyProperty) {
      <tr>
        <td>{{ 'components.metamodelListComponent.props.hierarchyProperty' | translate }}</td>
        <td>{{ entity.hierarchyProperty }}</td>
      </tr>
    }
  </tbody>
</table>

<span class="fw-semibold text-uppercase">{{
  'components.metamodelCardComponent.groups.primitiveProperties' | translate
}}</span>
<table class="table table-bordered">
  <thead>
    <tr>
      <th style="width: 300px">{{ 'shared2.props.name' | translate }}</th>
      <th>{{ 'shared2.props.type' | translate }}</th>
      <th>{{ 'components.metamodelListComponent.props.displayName' | translate }}</th>
      <th>{{ 'components.metamodelCardComponent.props.usedInLog' | translate }}</th>
    </tr>
  </thead>
  <tbody>
    @for (property of entity.primitiveProperties; track property.name) {
      <tr>
        <td>{{ property.name }}</td>
        <td>{{ property.type }}</td>
        <td>
          {{ getFormattedLocalStrings(property.displayNames) }}
        </td>
        <td>{{ property.usedInHistoryLog | wpBoolean }}</td>
      </tr>
    }
  </tbody>
</table>

@if (entity.navigationProperties?.length) {
  <span class="fw-semibold text-uppercase">{{
    'components.metamodelCardComponent.groups.navigationProperties' | translate
  }}</span>
  <table class="table table-bordered">
    <thead>
      <tr>
        <th style="width: 300px">{{ 'shared2.props.name' | translate }}</th>
        <th>{{ 'shared2.props.type' | translate }}</th>
        <th>{{ 'components.metamodelCardComponent.props.key' | translate }}</th>
        <th>{{ 'components.metamodelListComponent.props.displayName' | translate }}</th>
        <th>{{ 'components.metamodelCardComponent.props.usedInLog' | translate }}</th>
      </tr>
    </thead>
    <tbody>
      @for (property of entity.navigationProperties; track property.name) {
        <tr>
          <td>{{ property.name }}</td>
          <td>{{ property.clrType }}</td>
          <td>{{ property.keyProperty }}</td>
          <td>{{ getFormattedLocalStrings(property.displayNames) }}</td>
          <td>{{ property.usedInHistoryLog | wpBoolean }}</td>
        </tr>
      }
    </tbody>
  </table>
}

@if (entity.directoryProperties?.length) {
  <span class="fw-semibold text-uppercase">{{
    'components.metamodelCardComponent.groups.directoryProperties' | translate
  }}</span>
  <table class="table table-bordered">
    <thead>
      <tr>
        <th style="width: 300px">{{ 'shared2.props.name' | translate }}</th>
        <th>{{ 'components.metamodelCardComponent.props.directory' | translate }}</th>
        <th>{{ 'components.metamodelCardComponent.props.key' | translate }}</th>
        <th>{{ 'components.metamodelListComponent.props.displayName' | translate }}</th>
        <th>{{ 'components.metamodelCardComponent.props.usedInLog' | translate }}</th>
      </tr>
    </thead>
    <tbody>
      @for (property of entity.directoryProperties; track property.name) {
        <tr>
          <td>{{ property.name }}</td>
          <td>{{ property.directoryCode }}</td>
          <td>{{ property.keyProperty }}</td>
          <td>{{ getFormattedLocalStrings(property.displayNames) }}</td>
          <td>{{ property.usedInHistoryLog | wpBoolean }}</td>
        </tr>
      }
    </tbody>
  </table>
}

@if (entity.complexProperties?.length) {
  <span class="fw-semibold text-uppercase">{{
    'components.metamodelCardComponent.groups.complexProperties' | translate
  }}</span>
  <table class="table table-bordered">
    <thead>
      <tr>
        <th style="width: 300px">{{ 'shared2.props.name' | translate }}</th>
        <th>{{ 'shared2.props.type' | translate }}</th>
        <th>{{ 'components.metamodelListComponent.props.displayName' | translate }}</th>
        <th>{{ 'components.metamodelCardComponent.props.properties' | translate }}</th>
        <th>{{ 'components.metamodelCardComponent.props.usedInLog' | translate }}</th>
      </tr>
    </thead>
    <tbody>
      @for (property of entity.complexProperties; track property.name) {
        <tr>
          <td>{{ property.name }}</td>
          <td>{{ property.clrType }}</td>
          <td>{{ getFormattedLocalStrings(property.displayNames) }}</td>
          <td>{{ joinString(property.properties) }}</td>
          <td>{{ property.usedInHistoryLog | wpBoolean }}</td>
        </tr>
      }
    </tbody>
  </table>
}
