import { Component, ElementRef, Injector } from '@angular/core';
import { UserNotificationService } from 'src/app/core/user-notification.service';
import { WebSocketsService } from 'src/app/core/web-sockets.service';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { UserNotificationPopupComponent } from 'src/app/shared/components/features/user-notification-indicator/user-notification-popup/user-notification-popup.component';

/** User notification indicator. */
@Component({
  selector: 'wp-user-notification-indicator',
  templateUrl: './user-notification-indicator.component.html',
  styleUrls: ['./user-notification-indicator.component.scss'],
  standalone: false,
  providers: [WebSocketsService, UserNotificationService],
})
export class UserNotificationIndicatorComponent {
  private openedPopupId: string | null = null;
  constructor(
    public service: UserNotificationService,
    private popupService: InfoPopupService,
    private el: ElementRef,
    private injector: Injector,
  ) {}

  /** Toggles user notification popup. */
  public togglePopup(): void {
    if (this.openedPopupId) {
      this.popupService.close(this.openedPopupId);
      this.openedPopupId = null;
      return;
    }
    this.openedPopupId = this.popupService.open({
      target: this.el.nativeElement,
      data: {
        component: UserNotificationPopupComponent,
        componentParams: {
          injector: this.injector,
        },
      },
      containerStyles: { padding: '0' },
    });
  }
}
