import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  Injector,
  Input,
  OnInit,
  signal,
} from '@angular/core';
import { ClientCardService } from './client-card.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UIRouterGlobals } from '@uirouter/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash';
import {
  Organization,
  OrganizationTotal,
} from 'src/app/shared/models/entities/projects/organization.model';
import { NotificationService } from 'src/app/core/notification.service';
import { Exception } from 'src/app/shared/models/exception';
import { Feature } from 'src/app/shared/models/enums/feature.enum';
import { AppService } from 'src/app/core/app.service';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { UserInfoComponent } from 'src/app/shared/components/features/user-info/user-info.component';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { OverviewKpiService } from 'src/app/shared/components/features/overview/overview-kpi/core/overview-kpi.service';
import { ProjectSettingsComponent } from 'src/app/projects/settings/project-settings.component';
import { TabLink } from 'src/app/shared/models/navigation/navigation';

@Component({
  selector: 'wp-client-card',
  templateUrl: './client-card.component.html',
  styleUrls: ['./client-card.component.scss'],
  providers: [ClientCardService, OverviewKpiService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
  host: {
    class: 'data-not-saved-indicator-to-top',
  },
})
export class ClientCardComponent implements OnInit {
  @Input() entityId: string;

  public readonly client = signal<Organization>(null);
  public clientTotal: any;
  public tabs: TabLink[];
  public readonly defaultTabs: TabLink[] = [
    {
      header: 'projects.clients.card.tabs.overview',
      state: 'client.overview',
    },
    {
      header: 'projects.clients.card.tabs.settings',
      state: 'client.settings',
    },
    {
      header: 'projects.clients.card.tabs.projects',
      state: 'client.projects',
    },
  ];

  public readonly clientCardService = inject(ClientCardService);
  public readonly uiRouterGlobals = inject(UIRouterGlobals);

  private readonly actionPanelService = inject(ActionPanelService);
  private readonly appService = inject(AppService);
  private readonly infoPopupService = inject(InfoPopupService);
  private readonly notificationService = inject(NotificationService);
  private readonly elRef = inject(ElementRef);
  private readonly injector = inject(Injector);
  private readonly ngbModal = inject(NgbModal);
  private readonly destroyRef = inject(DestroyRef);

  public ngOnInit(): void {
    this.clientCardService.load(this.entityId);

    this.actionPanelService.setHasAutosave(true);
    this.actionPanelService.set([
      {
        title: 'projects.projects.list.actions.create',
        hint: 'projects.projects.list.actions.create',
        name: 'create',
        isDropDown: false,
        iconClass: 'bi bi-plus-lg bi-15',
        isBusy: false,
        isVisible: this.appService.checkEntityPermission(
          'Project',
          PermissionType.Modify,
        ),
        handler: () => this.createProject(),
      },
    ]);

    this.actionPanelService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.clientCardService.reloadTab());

    this.clientCardService.client$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((client: Organization) => {
        if (!_.isEqual(this.client(), client)) {
          this.client.set(client);
          this.tabs = [...this.defaultTabs];

          if (
            this.appService.checkFeature(Feature.clients) &&
            client.contactsViewAllowed
          ) {
            this.tabs.push({
              header: 'projects.clients.card.tabs.legal',
              state: 'client.legal',
            });

            this.tabs.push({
              header: 'shared2.groups.contacts',
              state: 'client.contacts',
            });

            this.tabs.push({
              header: 'shared2.groups.interactions',
              state: 'client.interactions',
            });
          }

          if (client.tariffsViewAllowed) {
            this.tabs.push({
              header: 'projects.clients.card.tabs.tariffs',
              state: 'client.tariffs',
            });
          }

          if (client.invoicesViewAllowed) {
            this.tabs.push({
              header: 'projects.clients.card.tabs.invoices',
              state: 'client.invoices',
            });
          }

          if (this.appService.checkFeature(Feature.finance)) {
            this.tabs.push({
              header: 'projects.clients.card.tabs.pnl',
              state: 'client.pnl',
            });
          }
        }
      });

    this.clientCardService.clientTotal$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((clientTotal: OrganizationTotal) => {
        this.clientTotal = clientTotal;
      });
  }

  /** Changes client state. */
  public selectState(isActive: boolean): void {
    this.clientCardService.clientCollection
      .entity(this.entityId)
      .patch({ isActive })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.clientCardService.currentClient = {
            ...this.clientCardService.currentClient,
            isActive,
          };
          this.client.update((client) => ({
            ...client,
            isActive,
          }));
        },
        error: (error: Exception) => {
          this.notificationService.error(error.message);
        },
      });
  }

  /** Opens user info popup. */
  public openUserInfo(): void {
    const userId = this.client().manager.id;
    const target = this.elRef.nativeElement.querySelector('#manager');
    this.infoPopupService.open({
      target,
      data: {
        component: UserInfoComponent,
        params: {
          userId,
        },
        injector: this.injector,
      },
    });
  }

  /** Opens modal window for project creating. */
  private createProject(): void {
    const ref = this.ngbModal.open(ProjectSettingsComponent, { size: 'lg' });
    (ref.componentInstance as ProjectSettingsComponent).client = this.client();
  }
}
