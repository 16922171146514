import { inject, Inject, Injectable, OnDestroy } from '@angular/core';

import { Subject } from 'rxjs';

import { DataService } from 'src/app/core/data.service';
import { DataServiceHot } from 'src/app/core/hot-changes/data-hot.service';
import { WebSocketsService } from 'src/app/core/web-sockets.service';

@Injectable()
export class WebSocketHotChangesService
  extends WebSocketsService
  implements OnDestroy
{
  private changesSubject = new Subject<unknown>();
  public changes$ = this.changesSubject.asObservable();

  private readonly dataServiceHot = inject(DataService) as DataServiceHot;

  constructor(@Inject('entityId') public entityId) {
    super();

    this.initSignalR('changes').then((connection) => {
      connection.send('SubscribeToSession', this.entityId);
      connection.on(
        'EntityChanged',
        (changes: unknown & { applicationId: string }) => {
          if (changes.applicationId !== this.dataServiceHot.applicationId) {
            this.changesSubject.next(changes);
          }
        },
      );
    });
  }

  public ngOnDestroy(): void {
    this.connection?.send('UnsubscribeFromSession', this.entityId);
    this.connection?.stop();
  }
}
