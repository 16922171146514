import { Component, Input, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TextBoxComponent } from 'src/app/shared/components/controls/text-box/text-box.component';
import { Wrapper } from './wrapper.interface';
import { PropagationMode } from 'src/app/shared/models/enums/control-propagation-mode.enum';
import { MetaEntityPropertyType } from 'src/app/shared/models/entities/settings/metamodel.model';

@Component({
  selector: 'wp-text-box-wrapper',
  template: `<wp-text-box
    [formControl]="control"
    placeholder="{{ placeholder | translate }}"
    [autofocus]="autofocus"
    [readonly]="readonly"
    [propagationMode]="propagationMode"
    [extendedType]="type.toLowerCase()"
    #component
  ></wp-text-box>`,
  standalone: false,
})
export class TextBoxWrapperComponent implements Wrapper {
  @Input() control: UntypedFormControl;
  @Input() type?: MetaEntityPropertyType;

  @Input() placeholder?: string;
  @Input() autofocus?: boolean;
  @Input() readonly?: boolean;

  @Input() propagationMode: PropagationMode = PropagationMode.onInput;

  @ViewChild('component') component: TextBoxComponent;
}
