<div class="action-panel d-flex align-items-center">
  @if (isShowBackButton() && navigationService.previousState$ | async) {
    <div class="back nav-item">
      <a
        class="nav-link"
        title="{{ 'shared.actionBack' | translate }}"
        [uiSref]="(navigationService.previousState$ | async).name"
        [uiParams]="(navigationService.previousState$ | async).params"
        data-test="back"
      >
        <i class="bi bi-arrow-left bi-15" aria-hidden="true"></i>
      </a>
    </div>
  }

  @if (actionPanelService.actionsVisibility$ | async) {
    <ul class="navbar-nav flex-row">
      @for (group of groups; track group; let lastGroup = $last) {
        <ng-container>
          @for (action of group.actions; track action; let lastAction = $last) {
            <li
              ngbDropdown
              class="nav-item"
              [ngClass]="{
                dropdown: action.isDropDown,
                'group-separator': lastAction && !lastGroup,
              }"
              [hidden]="!isVisibleElement(action)"
              [attr.data-test]="action.name"
              title="{{ action.hint | translate }}"
            >
              <div class="divider"></div>
              @if (action.isDropDown) {
                <a
                  class="nav-link d-flex align-items-center"
                  ngbDropdownToggle
                  (click)="click(action)"
                  [ngClass]="{ disabled: isDisabled }"
                >
                  <ng-container [ngTemplateOutlet]="icons" [ngTemplateOutletContext]="{ action }" />
                </a>
              } @else {
                <a
                  class="nav-link d-flex align-items-center"
                  ngbDropdownAnchor
                  (click)="click(action)"
                  [ngClass]="{ disabled: isDisabled }"
                >
                  <ng-container [ngTemplateOutlet]="icons" [ngTemplateOutletContext]="{ action }" />
                </a>
              }

              @if (action.isDropDown) {
                <div ngbDropdownMenu aria-labelledby="navbarDropdown" class="dropdown-menu">
                  @for (subAction of action.actions; track subAction.name) {
                    <ng-container>
                      @if (isNeedDivider($index, action.actions) && subAction.isVisible) {
                        <div class="dropdown-divider"></div>
                      }
                      <button
                        ngbDropdownItem
                        [hidden]="!subAction.isVisible"
                        (click)="click(subAction)"
                        title="{{ subAction.hint | translate }}"
                      >
                        {{ subAction.title | translate }}
                      </button>
                    </ng-container>
                  }
                </div>
              }
            </li>
          }
        </ng-container>
      }
    </ul>
  }
  <ul class="navbar-nav flex-row align-items-center ms-auto">
    @if (hasAutosave()) {
      <li
        class="nav-item"
        title="{{ 'shared.hintAutoSaveIndicator' | translate }}"
        data-test="autoSaveIndicator"
      >
        <a class="nav-link d-flex align-items-center autosave">
          <i class="bi bi-save" [ngClass]="{ saving: isSaving() }" aria-hidden="true"></i>
        </a>
      </li>
    }

    @if (hasReloader()) {
      <li
        class="nav-item"
        title="{{ 'shared.actions.updateData' | translate }}"
        data-test="updateData"
      >
        <a
          class="nav-link d-flex align-items-center"
          (click)="reload()"
          [ngClass]="{ disabled: isDisabled }"
        >
          <i class="bi bi-arrow-repeat" aria-hidden="true"></i>
        </a>
      </li>
    }

    @if (
      additionalActions.length &&
      isVisibleElement(additionalActions) &&
      (actionPanelService.actionsVisibility$ | async)
    ) {
      <li
        class="nav-item"
        title="{{ 'shared.actions.additional' | translate }}"
        data-test="additionalActions"
        ngbDropdown
      >
        <a ngbDropdownToggle class="nav-link d-flex align-items-center">
          <i class="bi bi-three-dots" aria-hidden="true"></i>
        </a>

        <div ngbDropdownMenu>
          @for (subAction of additionalActions; track subAction.name) {
            <ng-container>
              @if (isNeedDivider($index, additionalActions) && subAction.isVisible) {
                <div class="dropdown-divider"></div>
              }
              <button
                ngbDropdownItem
                [hidden]="!subAction.isVisible"
                (click)="click(subAction)"
                title="{{ subAction.hint | translate }}"
                [attr.data-test]="subAction.name"
              >
                {{ subAction.title | translate }}
              </button>
            </ng-container>
          }
        </div>
      </li>
    }
  </ul>
</div>

<ng-template #icons let-action="action">
  @if (action.iconClass || action.isBusy) {
    <div>
      <i [hidden]="action.isBusy" [ngClass]="action.iconClass"></i>
      @if (action.isBusy) {
        <i class="bi bi-arrow-clockwise rotate"></i>
      }
    </div>
  }
  <span
    class="trim"
    [ngClass]="{
      'has-icon': action.isBusy || action.iconClass,
      caret: action.isDropDown,
    }"
  >
    {{ action.title | translate }}
  </span>
</ng-template>
