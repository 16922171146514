import { Injectable, Type } from '@angular/core';
import { FilterDetails } from 'src/app/shared/components/features/filter/filter-details.interface';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { HistoryLogFilterComponent } from './history-log-filter.component';
import { Dictionary } from 'src/app/shared/models/dictionary';

@Injectable()
export class EventLogFilterService extends FilterService {
  public override component: Type<FilterDetails> = HistoryLogFilterComponent;
  public override hasDetails = true;
  public override hasViewSelector = false;
  public override placeholder = 'settings.historyLog.list.filter.placeholder';

  protected override getDefaultValues = (): any => ({
    text: '',
    period: null,
    user: null,
  });

  public override getODataFilter() {
    const result: any[] = [];

    if (this.values.user) {
      result.push({
        userId: {
          type: 'guid',
          value: this.values.user.id,
        },
      });
    }

    if (this.values.period) {
      const dates = this.dateService.getDatePairFromPeriodForFilter(
        this.values.period,
        true,
      );

      result.push({
        created: { le: { type: 'raw', value: dates.periodFinish } },
      });

      result.push({
        created: { ge: { type: 'raw', value: dates.periodStart } },
      });
    }

    return result;
  }

  public override getDatePeriodUrlParams(): Dictionary<string> {
    let text = this.values.text as string;

    if (text && text.length >= this.minStringLengthForFilter) {
      text = this.getClearText(text);

      return {
        term: text,
      };
    } else {
      return null;
    }
  }
}
