import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnInit,
} from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { ProjectCardService } from 'src/app/projects/card/core/project-card.service';
import { ProjectTasksDataService } from 'src/app/projects/card/project-tasks/core/project-tasks-data.service';
import { ProjectTaskAssignmentsView } from 'src/app/projects/card/project-tasks/shared/task-card-modal/task-assignments/model/task-assignments-view.enum';
import { PROJECT_TASK_ASSIGNMENTS_LIST } from 'src/app/projects/card/project-tasks/shared/task-card-modal/task-assignments/model/task-assignments.list';
import { AssignmentsToolbarComponent } from 'src/app/projects/card/project-tasks/shared/task-card-modal/task-assignments/assignments-table/assignments-toolbar/assignments-toolbar.component';
import { TaskAssignmentsService } from 'src/app/projects/card/project-tasks/shared/task-card-modal/task-assignments/task-assignments.service';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { GridSelectControlColumn } from 'src/app/shared-features/grid/models/grid-column.interface';
import {
  GridOptions,
  SelectionType,
} from 'src/app/shared-features/grid/models/grid-options.model';
import { naturalSort } from 'src/app/shared/helpers/natural-sort.helper';
import { StringHelper } from 'src/app/shared/helpers/string-helper';
import { ProjectTeamMember } from 'src/app/shared/models/entities/projects/project-team-member.model';
import { ProjectBillingType } from 'src/app/shared/models/enums/project-billing-type';
import { ResourceType } from 'src/app/shared/models/enums/resource-type.enum';
import { List } from 'src/app/shared/models/inner/list';
import { ListService } from 'src/app/shared/services/list.service';
import { LIST, VIEW_NAME } from 'src/app/shared/tokens';
import { OffCanvasService } from 'src/app/core/off-canvas.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export const resolveTaskAssignmentsView = (info: ProjectCardService) =>
  info.project.billingType.code === ProjectBillingType.nonBillable.code
    ? ProjectTaskAssignmentsView.nonBillable
    : ProjectTaskAssignmentsView.default;

@Component({
  selector: 'tmt-assignments-table',
  templateUrl: './assignments-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    GridService,
    ListService,
    { provide: LIST, useValue: PROJECT_TASK_ASSIGNMENTS_LIST },
    {
      provide: VIEW_NAME,
      useFactory: resolveTaskAssignmentsView,
      deps: [ProjectCardService],
    },
  ],
  standalone: false,
})
export class AssignmentTableComponent implements OnInit {
  @Input() assignmentRows: UntypedFormArray;

  public assignmentsGridOptions: GridOptions;

  constructor(
    @Inject(LIST) private list: List,
    public taskAssignmentsService: TaskAssignmentsService,
    public gridService: GridService,
    private projectTasksDataService: ProjectTasksDataService,
    private projectCardService: ProjectCardService,
    private listService: ListService,
    private offCanvasService: OffCanvasService,
  ) {
    this.offCanvasService.offCanvasWidth$
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.gridService.freezeTableService?.redraw();
      });
  }

  public ngOnInit(): void {
    if (this.taskAssignmentsService.readonly) {
      this.assignmentRows.disable({ emitEvent: false });
    } else {
      this.assignmentRows.enable({ emitEvent: false });
    }

    const memberColumn = this.list.columns.find(
      (c) => c.name === 'projectTeamMember',
    ) as GridSelectControlColumn;
    memberColumn.values = this.getAvailableMembers;

    this.assignmentsGridOptions = {
      selectionType: SelectionType.row,
      toolbar: AssignmentsToolbarComponent,
      resizableColumns: true,
      rowCommands: [
        {
          name: 'delete',
          label: 'shared.actions.delete',
          allowedFn: () => !this.taskAssignmentsService.readonly,
          handlerFn: (formGroup: UntypedFormGroup, index: number) => {
            this.assignmentRows.removeAt(index);
          },
        },
      ],
      commands: [
        {
          name: 'addAssignmentsLine',
          allowedFn: () => !this.taskAssignmentsService.readonly,
          handlerFn: () => this.addAssignmentsLine(),
        },
        {
          name: 'deleteAssignment',
          allowedFn: () => !this.taskAssignmentsService.readonly,
          handlerFn: () => this.deleteAssignment(),
        },
        { name: 'setUserView', handlerFn: () => this.setUserView() },
      ],
      view: this.listService.getGridView(),
    };
  }

  /** Adds new assignment line in the table. */
  public addAssignmentsLine(): void {
    const group = this.taskAssignmentsService.getAssignmentFormGroup();
    this.assignmentRows.insert(0, group);
    this.gridService.selectGroup(group);
  }

  /** Deletes assignment from array. */
  public deleteAssignment(): void {
    if (!this.gridService.selectedGroup$.getValue()) {
      return;
    }
    const index = this.assignmentRows.controls.findIndex(
      (control) => control.value.id === this.gridService.selectedGroupValue.id,
    );
    this.assignmentRows.removeAt(index);
    if (this.assignmentRows.controls.length) {
      this.gridService.selectGroup(
        this.assignmentRows.controls[0] as UntypedFormGroup,
      );
    }
  }

  /** Returns available project team members.
   *
   * @returns available members.
   */
  private getAvailableMembers = (): Partial<ProjectTeamMember>[] => {
    let members: Partial<ProjectTeamMember>[] =
      this.projectTasksDataService.members.sort(naturalSort('name'));

    if (this.projectCardService.project.isAutoPlanning) {
      members = members.filter(
        (member) => member?.resource?.resourceType !== ResourceType.department,
      );
    }

    members.forEach((member) => {
      if (!member.isActive) {
        member.name = StringHelper.getCrossedOutString(member.name);
      }
    });

    const assignedMemberIds = [];
    this.taskAssignmentsService.task.projectTaskAssignments.forEach(
      (assignments) => {
        if (assignments.projectTeamMember?.id) {
          assignedMemberIds.push(assignments.projectTeamMember.id);
        }
      },
    );
    return members.filter((member) => !assignedMemberIds.includes(member.id));
  };

  /**
   * Opens view configuration dialog
   *
   * @private
   * */
  private setUserView(): void {
    this.listService.setUserView().then(
      () => {
        this.assignmentsGridOptions.view = this.listService.getGridView();
      },
      () => null,
    );
  }
}
