/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable, Type } from '@angular/core';
import { CertificatesFilterComponent } from 'src/app/certificates/list/certificates-filter/certificates-filter.component';
import { FilterDetails } from 'src/app/shared/components/features/filter/filter-details.interface';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';

@Injectable()
export class CertificatesFilterService extends FilterService {
  public override component: Type<FilterDetails> = CertificatesFilterComponent;
  public override hasDetails = true;
  public override hasViewSelector = true;
  public hasActiveSelector = true;
  public override placeholder = 'team.certificates.list.filter.placeholder';
  public override views = [
    { code: 'all', local: 'shared.filter.allEntities' },
    { code: 'active', local: 'shared.filter.onlyActiveEntities' },
  ];
  public override customCriteriaCount: Record<string, () => number> = {
    text: () => 0,
    view: () => 0,
    states: () => (this.stateConditionExist() ? 1 : 0),
  };

  protected override getDefaultValues = (): any => ({
    text: '',
    view: this.views[0],
    owner: null,
    period: null,
    states: null,
  });

  public override getODataFilter(): any[] {
    const result: any[] = super.getODataFilter();

    let text = this.values.text;
    if (text && text.length >= this.minStringLengthForFilter) {
      text = this.getClearText(text);

      result.push({
        or: [
          { 'tolower(name)': { contains: text } },
          { owner: { 'tolower(name)': { contains: text } } },
        ],
      });
    }

    if (this.values.owner) {
      result.push({
        ownerId: { type: 'guid', value: this.values.owner.id },
      });
    }

    if (this.values.period) {
      const dates = this.dateService.getDatePairFromPeriodForFilter(
        this.values.period,
      );

      result.push({
        effectiveDate: { le: { type: 'raw', value: dates.periodStart } },
      });

      result.push({
        or: [
          { expiryDate: { ge: { type: 'raw', value: dates.periodFinish } } },
          { expiryDate: { eq: { type: 'raw', value: 'null' } } },
        ],
      });
    }

    if (this.values.view && this.values.view.code === 'active') {
      result.push({ isActive: true });
    }

    if (this.values.states) {
      const conditions = [];

      Object.keys(this.values.states).forEach((code) => {
        if (this.values.states[code].selected) {
          conditions.push({
            stateId: { type: 'guid', value: this.values.states[code].id },
          });
        }
      });

      result.push({ or: conditions });
    }

    return result;
  }

  public override getDatePeriodUrlParams(): Record<string, string> {
    return null;
  }
}
