import {
  DestroyRef,
  Inject,
  Injectable,
  inject,
  computed,
  signal,
} from '@angular/core';
import { Observable, Subject, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PercentPipe } from '@angular/common';
import { StateService } from '@uirouter/core';
import _ from 'lodash';
import { NotificationService } from 'src/app/core/notification.service';
import { Collection, DataService } from 'src/app/core/data.service';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { Exception } from 'src/app/shared/models/exception';
import { NavigationService } from 'src/app/core/navigation.service';
import { Deal, DealContact } from 'src/app/deals/model/deal.model';
import { AppService } from 'src/app/core/app.service';
import { LifecycleService } from 'src/app/core/lifecycle.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { MessageService } from 'src/app/core/message.service';
import { FormHeaderService } from 'src/app/shared/components/chrome/form-header2/form-header.service';
import { WpCurrencyPipe } from 'src/app/shared/pipes/currency.pipe';
import { HeaderIndicator } from 'src/app/shared/components/chrome/form-header2/header-indicator.model';

@Injectable()
export class DealCardService {
  private readonly _dealName = signal<string>(null);
  public readonly dealName = computed(this._dealName);
  private readonly _deal = signal<Deal>(null);
  public readonly deal = computed(this._deal);
  private readonly _state = signal<CardState>(CardState.Loading);
  public readonly state = computed(this._state);
  private readonly _reloadTabSubject = new Subject<void>();
  public readonly reloadTab$ = this._reloadTabSubject.asObservable();

  public readonly dealContacts = signal<DealContact[]>([]);
  public baseCurrencyCode: string;
  public dealCollection: Collection;

  private readonly actionPanelService = inject(ActionPanelService);
  private readonly appService = inject(AppService);
  private readonly dataService = inject(DataService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly formHeaderService = inject(FormHeaderService);
  private readonly lifecycleService = inject(LifecycleService);
  private readonly messageService = inject(MessageService);
  private readonly navigationService = inject(NavigationService);
  private readonly notificationService = inject(NotificationService);
  private readonly percentPipe = inject(PercentPipe);
  private readonly stateService = inject(StateService);
  private readonly wpCurrencyPipe = inject(WpCurrencyPipe);

  constructor(
    @Inject('entityId')
    public readonly dealId: string,
  ) {
    this.dealCollection = this.dataService.collection('Deals');

    this.actionPanelService.reset();
    this.actionPanelService.setHasAutosave(true);
    this.actionPanelService.setAdditional([
      {
        name: 'delete',
        title: 'components.dealCardComponent.actions.delete',
        hint: 'components.dealCardComponent.actions.delete',
        isBusy: false,
        isVisible: this.appService.checkEntityPermission(
          'Deal',
          PermissionType.Delete,
        ),
        handler: () => this.deleteDeal(),
      },
    ]);

    this.baseCurrencyCode =
      this.appService.session.configuration.baseCurrencyCode;
  }

  /** Reloads tab. */
  public reloadTab(): void {
    this.load();
    this.lifecycleService.reloadLifecycle();

    this._reloadTabSubject.next();
  }

  /** Saves deal's name. */
  public saveName = (name: string) =>
    this.dealCollection
      .entity(this.dealId)
      .patch({ name })
      .pipe(
        tap(() => {
          this._deal.update((deal) => ({ ...deal, name }));
        }),
      );

  /** Loads deal data and process it. */
  public load(): void {
    const query = this.buildQuery();

    this.loadDeal(query)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (deal: Deal) => {
          this._state.set(CardState.Ready);
          this.navigationService.addRouteSegment({
            id: this.dealId,
            title: deal.name,
          });
          this._deal.update(() => ({ ...deal }));

          this._dealName.set(deal.name);
          this.dealContacts.update(() => deal.dealContacts);
          this.updateIndicators(this.deal());
        },
        error: (error: Exception) => {
          this._state.set(CardState.Error);
          if (error.code === Exception.BtEntityNotFoundException.code) {
            return;
          }
          this.notificationService.error(error.message);
        },
      });
  }

  /**
   * Loads deal's data.
   *
   * @param query OData query for loading deal data.
   * @returns Deal entity.
   */
  public loadDeal(query: any): Observable<Deal | null> {
    return this.dealCollection.entity(this.dealId).get<Deal>(query);
  }

  /** Updates indicators. */
  public updateIndicators(deal: Partial<Deal>): void {
    const indicators: HeaderIndicator[] = [];
    if (deal.amount !== undefined && deal.amount !== null) {
      indicators.push({
        description: 'shared2.props.amount',
        value: this.wpCurrencyPipe.transform(
          deal.amount,
          this.baseCurrencyCode,
        ),
      });
    }
    if (deal.probability !== undefined && deal.probability !== null) {
      indicators.push({
        description: 'shared2.props.probability',
        value: this.percentPipe.transform(deal.probability, '1.0-2'),
      });
    }
    this.formHeaderService.updateIndicators(indicators);
  }

  /** Updates current deal data. */
  public updateDeal(deal: Partial<Deal>): void {
    const newDeal: Partial<Deal> = {};

    Object.keys(this._deal()).forEach((key) => {
      if (deal[key] && !_.isEqual(deal[key], this._deal()[key])) {
        newDeal[key] = deal[key];
      }
    });

    this._deal.update(() => Object.assign({}, this._deal(), newDeal));
  }

  /**
   * Builds OData query for deal entity
   *
   * @returns OData query
   */
  private buildQuery(): any {
    return {
      select: ['name', 'id', 'amount', 'probability', 'created', 'editAllowed'],
      expand: [
        {
          organization: {
            select: ['id', 'name'],
          },
        },
        { manager: { select: ['id', 'name'] } },
        {
          dealContacts: {
            select: ['contactId'],
          },
        },
      ],
    };
  }

  /** Deletes current deal. */
  private deleteDeal(): void {
    this.messageService
      .confirmLocal('shared2.messages.deleteConfirmation')
      .then(
        () => {
          this.dealCollection
            .entity(this.dealId)
            .delete()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
              next: () => {
                this.notificationService.successLocal(
                  'shared2.messages.deleteCompleted',
                );
                const selectedNavigationItem =
                  this.navigationService.selectedNavigationItem;
                this.stateService.go(
                  selectedNavigationItem?.state ?? 'deals',
                  selectedNavigationItem?.stateParams,
                );
              },
              error: (error: Exception) => {
                this.notificationService.error(error.message);
              },
            });
        },
        () => null,
      );
  }
}
