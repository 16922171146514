import { DestroyRef, inject, Inject, Injectable, signal } from '@angular/core';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { Exception } from 'src/app/shared/models/exception';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { NavigationService } from 'src/app/core/navigation.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Constants } from 'src/app/shared/globals/constants';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { MessageService } from 'src/app/core/message.service';
import { BillCode } from 'src/app/settings-app/bill-codes/model/bill-code.model';
import { AppService } from 'src/app/core/app.service';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable()
export class BillCodeCardService {
  public form = this.fb.group({
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    code: [
      '',
      [Validators.required, Validators.maxLength(Constants.formCodeMaxLength)],
    ],
    description: ['', Validators.maxLength(Constants.formTextMaxLength)],
    costAdjustment: 0,
    isActive: false,
    isDefault: false,
  });
  public readonly = signal<boolean>(
    !this.appService.checkEntityPermission('BillCode', PermissionType.Modify),
  );
  public state = signal<CardState>(CardState.Loading);
  public isSaving = signal<boolean>(false);
  public name = signal<string>('');
  public isDefault = signal<boolean>(false);

  private destroyRef = inject(DestroyRef);

  constructor(
    @Inject('entityId') public entityId: string,
    private dataService: DataService,
    private notificationService: NotificationService,
    private actionPanelService: ActionPanelService,
    private navigationService: NavigationService,
    private fb: UntypedFormBuilder,
    private blockUIService: BlockUIService,
    private messageService: MessageService,
    private appService: AppService,
  ) {
    this.load();

    this.form.controls.name.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        this.updateName(value);
      });

    this.actionPanelService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.reload());
  }

  /** Loads bill code. */
  public load(): void {
    this.state.set(CardState.Loading);

    const query = {
      select: [
        'id',
        'name',
        'code',
        'description',
        'costAdjustment',
        'isActive',
        'isDefault',
      ],
    };

    this.dataService
      .collection('BillCodes')
      .entity(this.entityId)
      .get<BillCode>(query)
      .subscribe({
        next: (billCode: BillCode) => {
          this.actionPanelService.action('save').isShown = !this.readonly();
          this.actionPanelService.action('setAsDefault').isShown =
            !this.readonly() && !billCode.isDefault;

          this.name.set(billCode.name);
          this.isDefault.set(billCode.isDefault);

          this.form.patchValue(billCode);
          if (this.readonly()) {
            this.form.disable();
          } else {
            this.form.enable();
          }

          this.navigationService.addRouteSegment({
            id: billCode.id,
            title: billCode.name,
          });
          this.state.set(CardState.Ready);
        },
        error: (error: Exception) => {
          this.state.set(CardState.Error);
          if (error.code === Exception.BtEntityNotFoundException.code) {
            return;
          }
          this.notificationService.error(error.message);
        },
      });
  }

  /** Updates bill code name. */
  public updateName(value: any): void {
    this.name.set(value);
  }

  /** Saves bill code. */
  public save(): void {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notificationService.warningLocal(
        'shared2.messages.requiredFieldsError',
      );
      return;
    }

    this.isSaving.set(true);
    this.blockUIService.start();
    this.actionPanelService.action('save').start();

    this.dataService
      .collection('BillCodes')
      .entity(this.entityId)
      .patch(this.form.value)
      .subscribe({
        next: () => {
          this.form.markAsPristine();
          this.isSaving.set(false);
          this.blockUIService.stop();
          this.actionPanelService.action('save').stop();
          this.notificationService.successLocal(
            'components.billCodeCardService.messages.saved',
          );
        },
        error: (error: Exception) => {
          this.isSaving.set(false);
          this.blockUIService.stop();
          this.actionPanelService.action('save').stop();
          this.notificationService.error(error.message);
        },
      });
  }

  /** Reloads data. */
  public reload(): void {
    if (!this.form.dirty) {
      this.load();
    } else {
      this.messageService
        .confirmLocal('shared2.messages.leavePageMessage')
        .then(
          () => {
            this.form.markAsPristine();
            this.load();
          },
          () => null,
        );
    }
  }

  /** Sets bill code as default. */
  public setAsDefault(): void {
    this.isSaving.set(true);
    this.actionPanelService.action('setAsDefault').start();

    this.dataService
      .collection('BillCodes')
      .entity(this.entityId)
      .action('SetAsDefault')
      .execute()
      .subscribe({
        next: () => {
          this.notificationService.successLocal(
            'components.billCodeCardService.messages.hasBeenSetByDefault',
          );
          this.actionPanelService.action('setAsDefault').stop();
          this.actionPanelService.action('setAsDefault').isShown = false;
          this.isSaving.set(false);
          this.isDefault.set(true);
        },
        error: (error: Exception) => {
          this.isSaving.set(false);
          this.actionPanelService.action('setAsDefault').stop();
          this.notificationService.error(error.message);
        },
      });
  }
}
