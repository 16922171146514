import { Inject, Injectable, NgZone } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { LocalStorageService } from 'ngx-webstorage';

import {
  ReplaySubject,
  Subject,
  Subscription,
  fromEvent,
  auditTime,
  throttleTime,
} from 'rxjs';

import { LogService } from 'src/app/core/log.service';
import { Constants } from 'src/app/shared/globals/constants';

/** Service for UI view management. */
@Injectable({
  providedIn: 'root',
})
export class ChromeService {
  /** Indicates whether the navigation panel is placed over the main area content or not. */
  public compactMode: boolean;
  /**  Indicates whether the navigation panel is permanently collapsed or not. */
  public permanentCompactMode: boolean;

  private scrollSubject = new Subject<Event | null>();
  public scroll$ = this.scrollSubject.asObservable().pipe(auditTime(10));

  public compactMode$ = new ReplaySubject<boolean>();
  public permanentCompactMode$ = new ReplaySubject<boolean>();

  private mainAreaSizeSubject = new Subject<void>();
  public mainAreaSize$ = this.mainAreaSizeSubject.asObservable();

  private minWidthForNonCompactMode = 1200; // Bootstrap xl breakpoint
  private storageName = 'compactMode';

  public get storedCompactMode(): boolean {
    return this.localStorageService.retrieve(this.storageName);
  }
  public set storedCompactMode(value: boolean) {
    this.localStorageService.store(this.storageName, value);
  }

  constructor(
    private localStorageService: LocalStorageService,
    private logService: LogService,
    private zone: NgZone,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.updateState();
  }

  /** Toggles compact mode. */
  public toggleCompactMode(): void {
    this.storedCompactMode = !this.storedCompactMode;
    this.updateState();
  }

  /** Updates state and emits main area size changes. */
  public setMainAreaDimension(): void {
    this.updateState();
    this.mainAreaSizeSubject.next();
  }

  /** Emits scroll changes. */
  public scrollMainArea(): void {
    this.scrollSubject.next(null);
  }

  /**
   * Runs callback on scroll event.
   *
   * @param loadPage callback. Usually it's a page-by-page loading.
   * @param lengthThreshold height to the end of view port at which the callback is run.
   * @param scrollContainer `HTMLElement` to observe.
   *
   * @returns `scroll$` subscription.
   */
  public setInfinityScroll(
    loadPage: () => void,
    lengthThreshold = 150,
    scrollContainer?: HTMLElement,
  ): Subscription {
    const container =
      scrollContainer ??
      this.document.getElementById(Constants.defaultRootContainerId);
    const scroll$ = scrollContainer
      ? fromEvent(scrollContainer, 'scroll')
      : this.scroll$;

    let subscription: Subscription;

    this.zone.runOutsideAngular(() => {
      subscription = scroll$
        .pipe(
          throttleTime(Constants.scrollThrottleTime, undefined, {
            leading: true,
            trailing: true,
          }),
        )
        .subscribe(() => {
          const remaining =
            container.scrollHeight -
            (container.clientHeight + container.scrollTop);

          if (remaining < lengthThreshold) {
            this.zone.run(() => {
              this.logService.debug(
                `View port has been scrolled to the end and a new page must be loaded.`,
              );
              loadPage();
            });
          }
        });
    });

    return subscription;
  }

  /** Sets scrollbar width of main-area container to root CSS variables. */
  public setMainAreaScrollWidth(): void {
    const element = this.document.querySelector<HTMLElement>(
      `#${Constants.defaultRootContainerId}`,
    );
    const width = (element?.offsetWidth ?? 0) - (element?.clientWidth ?? 0);

    this.document.documentElement.style.setProperty(
      '--main-scrollbar-width',
      `${width}px`,
    );
  }

  /**
   * Updates navigation panel state.
   * If user's view width is less then `minWidthForNonCompactMode`, compactMode is set to true.
   */
  private updateState(): void {
    this.permanentCompactMode =
      this.document.documentElement.clientWidth <
      this.minWidthForNonCompactMode;
    this.compactMode = this.permanentCompactMode
      ? true
      : this.storedCompactMode;
    this.compactMode$.next(this.compactMode);
    this.permanentCompactMode$.next(this.permanentCompactMode);
  }
}
