import {
  Component,
  Optional,
  signal,
  input,
  OnInit,
  inject,
  DestroyRef,
  ChangeDetectionStrategy,
  OnDestroy,
  SkipSelf,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl } from '@angular/forms';

import { merge, of } from 'rxjs';

import {
  initAndDestroy,
  initAndDestroyHeight,
} from 'src/app/shared/animations';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';

import { DataNotSavedIndicatorService } from './data-not-saved-indicator.service';

/** Unsaved form indicator. */
@Component({
  selector: 'wp-data-not-saved-indicator',
  templateUrl: './data-not-saved-indicator.component.html',
  styleUrls: ['./data-not-saved-indicator.component.scss'],
  standalone: false,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [initAndDestroy, initAndDestroyHeight],
  providers: [
    {
      provide: DataNotSavedIndicatorService,
      deps: [
        [new Optional(), new SkipSelf(), DataNotSavedIndicatorService],
        [new Optional(), SavingQueueService],
      ],
      useFactory: (
        parentService: DataNotSavedIndicatorService | null,
        savingQueueService: SavingQueueService | null,
      ) =>
        parentService || new DataNotSavedIndicatorService(savingQueueService),
    },
  ],
})
export class DataNotSavedIndicatorComponent implements OnInit, OnDestroy {
  public form = input.required<AbstractControl>();

  public dirtyMessageShown = signal<boolean>(false);
  public invalidMessageShown = signal<boolean>(false);

  private formIndex: number;
  private readonly destroyRef = inject(DestroyRef);

  constructor(
    public dataNotSavedIndicatorService: DataNotSavedIndicatorService,
    @Optional() public savingQueueService: SavingQueueService | null,
  ) {}

  public ngOnInit(): void {
    this.formIndex = this.dataNotSavedIndicatorService.registerForm(
      this.form(),
    );

    merge(this.form().events, this.savingQueueService?.save$ ?? of(null))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.dirtyMessageShown.set(this.form().dirty && this.form().touched);
        this.invalidMessageShown.set(
          this.form().invalid && this.form().touched,
        );

        if (
          this.form().invalid &&
          this.form().touched &&
          this.savingQueueService
        ) {
          this.form().markAllAsTouched();
        }
      });
  }

  public ngOnDestroy(): void {
    this.dataNotSavedIndicatorService.unregisterForm(this.formIndex);
  }
}
