import {
  computed,
  DestroyRef,
  effect,
  inject,
  Injectable,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { merge } from 'rxjs';
import { NotificationService } from 'src/app/core/notification.service';
import { DealCardService } from 'src/app/deals/card/deal-card.service';
import { Deal } from 'src/app/deals/model/deal.model';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';
import { Constants } from 'src/app/shared/globals/constants';
import { Exception } from 'src/app/shared/models/exception';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';

@Injectable()
export class DealProfileService {
  private readonly _isLoading = signal<boolean>(false);
  public readonly isLoading = computed(this._isLoading);

  public form: UntypedFormGroup;

  public get formRawValue(): Partial<Deal> {
    return this.form.getRawValue();
  }

  private readonly dealCardService = inject(DealCardService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly customFieldService = inject(CustomFieldService);
  private readonly savingQueueService = inject(SavingQueueService);
  private readonly notificationService = inject(NotificationService);
  private readonly fb = inject(UntypedFormBuilder);

  constructor() {
    effect(() => {
      if (this.dealCardService.dealContacts().length) {
        this.form.get('organizationId').disable({ emitEvent: false });
      } else {
        this.form.get('organizationId').enable({ emitEvent: false });
      }
    });

    this.form = this.fb.group({
      description: ['', [Validators.maxLength(Constants.formTextMaxLength)]],
      organizationId: null,
      manager: null,
      amount: [null, [Validators.min(0)]],
      probability: [null, [Validators.max(1), Validators.min(0)]],
      resolutionId: null,
      resolutionComment: [
        '',
        [Validators.maxLength(Constants.formTextMaxLength)],
      ],
      sourceId: null,
    });

    this.customFieldService.enrichFormGroup(this.form, 'Deal');

    this.savingQueueService.save$.pipe(takeUntilDestroyed()).subscribe(() => {
      this.dealCardService.updateIndicators(this.formRawValue);
      this.dealCardService.updateDeal(this.formRawValue);
      this.form.markAsPristine();
    });
    merge(this.savingQueueService.error$, this.dealCardService.reloadTab$)
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.load();
      });

    this.subscribeToFormChanges();
  }

  /** Loads deal's data. */
  public async load(): Promise<void> {
    await this.savingQueueService.save();

    this.form.markAsPristine();
    this.form.markAsUntouched();

    this._isLoading.set(true);

    const query = this.buildQuery();

    this.dealCardService
      .loadDeal(query)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (deal: Deal) => {
          this.form.patchValue(deal, { emitEvent: false });
          if (this.dealCardService.deal().editAllowed) {
            this.form.enable({ emitEvent: false });
          } else {
            this.form.disable({ emitEvent: false });
          }
          if (this.dealCardService.deal().dealContacts?.length) {
            this.form.get('organizationId').disable({ emitEvent: false });
          }
          this._isLoading.set(false);
        },
        error: (error: Exception) => {
          this._isLoading.set(false);
          this.notificationService.error(error.message);
        },
      });
  }

  /**
   * Builds OData query for deal entity
   *
   * @returns OData query
   */
  private buildQuery(): any {
    const query = {
      select: [
        'name',
        'description',
        'amount',
        'probability',
        'resolutionComment',
        'organizationId',
        'sourceId',
        'resolutionId',
      ],
      expand: [{ manager: { select: ['id', 'name'] } }],
    };

    this.customFieldService.enrichQuery(query, 'Deal');

    return query;
  }

  /** Subscribes to form changes and process form data. */
  private subscribeToFormChanges?(): void {
    this.form.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (this.form.invalid) {
          return;
        }

        const dealData: Partial<Deal> = Object.assign({}, this.formRawValue, {
          managerId: this.formRawValue['manager'].id || null,
        });
        delete dealData['manager'];

        this.savingQueueService.addToQueue(
          this.dealCardService.dealId,
          this.dealCardService.dealCollection
            .entity(this.dealCardService.dealId)
            .patch(dealData),
        );
      });
  }
}
