/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable, Type } from '@angular/core';
import { FilterDetails } from 'src/app/shared/components/features/filter/filter-details.interface';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { EmployeeFilterComponent } from './employee-filter.component';

@Injectable()
export class ProfileFilterService extends FilterService {
  public override component: Type<FilterDetails> = EmployeeFilterComponent;
  public override hasDetails = true;
  public override hasViewSelector = false;
  public override hasPeriodSelector = true;
  public override periodSelectorPlaceholder =
    'components.profileFilterService.filters.valueCalculationPeriod';
  public override placeholder =
    'components.profileFilterService.filters.textInput';

  protected override getDefaultValues(): any {
    return {
      text: '',
      view: null,
      department: null,
      resourcePool: null,
      role: null,
      legalEntity: null,
      competence: null,
      level: null,
      grade: null,
      location: null,
      resourceTypes: null,
      skills: null,
    };
  }

  public override getODataFilter(): any[] {
    const result: any[] = super.getODataFilter();
    result.push({ user: { isActive: true } });

    let text = this.values.text;
    if (text && text.length >= this.minStringLengthForFilter) {
      text = this.getClearText(text);

      result.push({
        user: {
          or: [
            { 'tolower(name)': { contains: text } },
            { 'tolower(code)': { contains: text } },
            { 'tolower(position)': { contains: text } },
            { 'tolower(email)': { contains: text } },
          ],
        },
      });
    }

    if (this.values.location) {
      result.push({
        user: {
          locationId: {
            type: 'guid',
            value: this.values.location.id,
          },
        },
      });
    }

    if (this.values.legalEntity) {
      result.push({
        user: {
          legalEntityId: {
            type: 'guid',
            value: this.values.legalEntity.id,
          },
        },
      });
    }

    if (this.values.role) {
      result.push({
        user: {
          roleId: {
            type: 'guid',
            value: this.values.role.id,
          },
        },
      });
    }

    if (this.values.competence) {
      result.push({
        user: {
          competenceId: {
            type: 'guid',
            value: this.values.competence.id,
          },
        },
      });
    }

    if (this.values.level) {
      result.push({
        user: {
          levelId: {
            type: 'guid',
            value: this.values.level.id,
          },
        },
      });
    }

    if (this.values.grade) {
      result.push({
        user: {
          gradeId: {
            type: 'guid',
            value: this.values.grade.id,
          },
        },
      });
    }

    if (this.values.resourcePool) {
      result.push({
        user: {
          resourcePoolId: {
            type: 'guid',
            value: this.values.resourcePool.id,
          },
        },
      });
    }

    if (this.values.department) {
      result.push({
        user: {
          departmentId: {
            type: 'guid',
            value: this.values.department.id,
          },
        },
      });
    }

    if (this.values.skills) {
      const conditions = [];
      this.values.skills.forEach((skill) => {
        conditions.push({
          user: {
            skills: {
              any: {
                skillId: { type: 'guid', value: skill.id },
              },
            },
          },
        });
      });

      result.push(conditions);
    }

    if (this.values.supervisor) {
      result.push({
        user: {
          supervisorId: {
            type: 'guid',
            value: this.values.supervisor.id,
          },
        },
      });
    }

    return result;
  }
}
