import {
  computed,
  DestroyRef,
  inject,
  Injectable,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ClientCardService } from 'src/app/clients/card/client-card.service';
import { NotificationService } from 'src/app/core/notification.service';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';
import { Constants } from 'src/app/shared/globals/constants';
import { Organization } from 'src/app/shared/models/entities/projects/organization.model';
import { Exception } from 'src/app/shared/models/exception';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { emailValidator } from 'src/app/shared/validators/email';
import { urlValidator } from 'src/app/shared/validators/url';

@Injectable()
export class ClientSettingsService {
  private readonly _isLoading = signal<boolean>(false);
  public readonly isLoading = computed(this._isLoading);

  public form: UntypedFormGroup;

  public get formRawValue(): Partial<Organization> {
    return this.form.getRawValue();
  }

  private readonly clientCardService = inject(ClientCardService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly customFieldService = inject(CustomFieldService);
  private readonly savingQueueService = inject(SavingQueueService);
  private readonly notificationService = inject(NotificationService);
  private readonly fb = inject(UntypedFormBuilder);

  constructor() {
    this.form = this.fb.group({
      name: [
        '',
        [
          Validators.required,
          Validators.maxLength(Constants.formNameMaxLength),
        ],
      ],
      code: ['', [Validators.maxLength(Constants.formCodeMaxLength)]],
      description: ['', [Validators.maxLength(Constants.formTextMaxLength)]],
      manager: [null, [Validators.required]],
      billingEqualPost: [false],
      billingAddress: this.fb.group({
        address: [null, [Validators.maxLength(Constants.formTextMaxLength)]],
        city: [null, [Validators.maxLength(Constants.formTextMaxLength)]],
        postIndex: [null, [Validators.maxLength(Constants.formTextMaxLength)]],
        state: [null, [Validators.maxLength(Constants.formTextMaxLength)]],
      }),
      postAddress: this.fb.group({
        address: [null, [Validators.maxLength(Constants.formTextMaxLength)]],
        city: [null, [Validators.maxLength(Constants.formTextMaxLength)]],
        postIndex: [null, [Validators.maxLength(Constants.formTextMaxLength)]],
        state: [null, [Validators.maxLength(Constants.formTextMaxLength)]],
      }),
      contact: ['', [Validators.maxLength(Constants.formTextMaxLength)]],
      contactEmail: ['', emailValidator()],
      phone: [
        '',
        [
          Validators.minLength(Constants.minPhoneLength),
          Validators.maxLength(Constants.maxPhoneLength),
        ],
      ],
      site: ['', urlValidator()],
    });

    this.customFieldService.enrichFormGroup(this.form, 'Organization');

    this.form
      .get('billingEqualPost')
      .valueChanges.pipe(takeUntilDestroyed())
      .subscribe(() => this.setAddressesControlsState());

    this.clientCardService.client$
      .pipe(takeUntilDestroyed())
      .subscribe((client: Organization) => {
        this.form.patchValue({ name: client.name }, { emitEvent: false });
      });

    this.savingQueueService.save$.pipe(takeUntilDestroyed()).subscribe(() => {
      this.clientCardService.updateClient(this.formRawValue);
      this.setAddressesControlsState();
      this.form.markAsPristine();
    });
    this.savingQueueService.error$.pipe(takeUntilDestroyed()).subscribe(() => {
      this.load();
    });

    this.subscribeToFormChanges();
  }

  /** Loads client's data. */
  public async load(): Promise<void> {
    await this.savingQueueService.save();

    this.form.markAsPristine();
    this.form.markAsUntouched();

    this._isLoading.set(true);

    const query = this.buildQuery();

    this.clientCardService
      .loadClient(query)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (client: Organization) => {
          this.form.patchValue(client, { emitEvent: false });
          if (!this.clientCardService.currentClient?.editAllowed) {
            this.form.disable({ emitEvent: false });
          } else {
            this.form.enable({ emitEvent: false });
          }
          this.setAddressesControlsState();
          this._isLoading.set(false);
        },
        error: (error: Exception) => {
          this._isLoading.set(false);
          this.notificationService.error(error.message);
        },
      });
  }

  /**
   * Builds OData query for organization entity
   *
   * @returns OData query
   */
  private buildQuery(): any {
    const query = {
      select: [
        'name',
        'phone',
        'site',
        'code',
        'contact',
        'contactEmail',
        'description',
        'postAddress',
        'billingAddress',
        'billingEqualPost',
      ],
      expand: [{ manager: { select: ['name', 'id'] } }],
    };

    this.customFieldService.enrichQuery(query, 'Organization');

    return query;
  }

  /** Disable or enable addresses controls depending on billingEqualPost value. */
  private setAddressesControlsState(): void {
    if (this.form.get('billingEqualPost').value) {
      this.form.get('billingAddress').disable({ emitEvent: false });

      this.form
        .get('billingAddress')
        .patchValue(this.form.get('postAddress').value, {
          emitEvent: false,
        });
    } else {
      this.form.get('billingAddress').enable({ emitEvent: false });
    }
  }

  /** Subscribes to form changes and process form data. */
  private subscribeToFormChanges?(): void {
    this.form.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (this.form.invalid) {
          return;
        }

        const clientData: Partial<Organization> = Object.assign(
          {},
          this.formRawValue,
          {
            managerId: this.formRawValue['manager'].id || null,
          },
        );
        delete clientData['manager'];

        this.savingQueueService.addToQueue(
          this.clientCardService.clientId,
          this.clientCardService.clientCollection
            .entity(this.clientCardService.clientId)
            .patch(clientData),
        );
      });
  }
}
