import { Injectable, Type } from '@angular/core';
import { FilterDetails } from 'src/app/shared/components/features/filter/filter-details.interface';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { ProgramFilterComponent } from './program-filter.component';

@Injectable()
export class ProgramFilterService extends FilterService {
  public override component: Type<FilterDetails> = ProgramFilterComponent;
  public override hasDetails = true;
  public override hasViewSelector = false;
  public override placeholder = 'projects.programs.list.filter.placeholder';

  protected override getDefaultValues = (): any => ({
    text: '',
    period: null,
    manager: null,
  });

  public override getODataFilter(): any[] {
    const result: any[] = super.getODataFilter();

    let text = this.values.text;
    if (text && text.length >= this.minStringLengthForFilter) {
      text = this.getClearText(text);

      result.push({
        program: {
          or: [
            { 'tolower(name)': { contains: text } },
            { 'tolower(code)': { contains: text } },
            { 'tolower(description)': { contains: text } },
          ],
        },
      });
    }

    if (this.values.manager) {
      result.push({
        program: {
          managerId: { eq: { type: 'guid', value: this.values.manager.id } },
        },
      });
    }

    return result;
  }
}
