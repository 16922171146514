import { Injectable, OnDestroy } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProjectTasksDataService } from 'src/app/projects/card/project-tasks/core/project-tasks-data.service';
import { Guid } from 'src/app/shared/helpers/guid';
import { StringHelper } from 'src/app/shared/helpers/string-helper';
import {
  ProjectTask,
  ProjectTaskAssignment,
} from 'src/app/shared/models/entities/projects/project-task.model';
import { ResourceType } from 'src/app/shared/models/enums/resource-type.enum';

@Injectable()
export class TaskAssignmentsService implements OnDestroy {
  public task: ProjectTask;
  public readonly: boolean;

  public allTeamCode = 'allTeam';

  private destroyed$ = new Subject<void>();

  constructor(
    private projectTasksDataService: ProjectTasksDataService,
    private fb: UntypedFormBuilder,
  ) {}

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  /** Returns assignment form group for table.
   *
   * @returns assignment form group
   */
  public getAssignmentFormGroup(
    assignment?: ProjectTaskAssignment,
  ): UntypedFormGroup {
    const formGroup = this.fb.group({
      id: Guid.generate(),
      isAllTeamRole: false,
      isUnassigned: false,
      projectTeamMember: [null, Validators.required],
      units: null,
      projectTariff: null,
    });

    if (assignment) {
      const projectTeamMember =
        assignment.projectTeamMember ??
        this.projectTasksDataService.members.find(
          (member) => member.id === assignment.projectTeamMemberId,
        );
      if (!projectTeamMember.isActive) {
        projectTeamMember.name = StringHelper.getCrossedOutString(
          projectTeamMember.name,
        );
      }

      const assignmentItem = {
        id: assignment.id,
        isAllTeamRole: false,
        isUnassigned: false,
        units: assignment.units,
        projectTariff: assignment.projectTariff,
        projectTeamMember,
      };
      formGroup.patchValue(assignmentItem, { emitEvent: false });

      if (
        projectTeamMember.resource?.resourceType === ResourceType.department
      ) {
        //TODO: remove setTimeout if possible
        setTimeout(() => {
          formGroup.controls.projectTariff.disable({ emitEvent: false });
          formGroup.controls.units.disable({ emitEvent: false });
        });
      }
    }

    formGroup.controls.projectTeamMember.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        formGroup.controls.projectTariff.setValue(null, {
          emitEvent: false,
        });
        if (value.resource?.resourceType === ResourceType.department) {
          formGroup.controls.units.setValue(null, { emitEvent: false });
          formGroup.controls.units.disable({ emitEvent: false });
          formGroup.controls.projectTariff.disable({ emitEvent: false });
        } else {
          if (
            formGroup.controls.units.getRawValue() === null ||
            this.task.effortDriven
          ) {
            formGroup.controls.units.setValue(1, { emitEvent: false });
          }
          formGroup.controls.units.enable({ emitEvent: false });
          formGroup.controls.projectTariff.enable({ emitEvent: false });
        }
        if (value.isActive === false) {
          value.name = StringHelper.getCrossedOutString(value.name);
        }
      });

    return formGroup;
  }
}
