import { Pipe, PipeTransform } from '@angular/core';

/** Cuts strings and adds ellipsis. */
@Pipe({
  name: 'truncate',
  standalone: false,
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, limit?: number, ellipsis = '...') {
    return value?.length > limit ? value.substr(0, limit) + ellipsis : value;
  }
}
