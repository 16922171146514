import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import { StateService } from '@uirouter/core';
import { TabLink } from 'src/app/shared/models/navigation/navigation';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { ContactCardService } from 'src/app/contacts/card/contact-card.service';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { AppService } from 'src/app/core/app.service';

@Component({
  selector: 'tmt-contact-card',
  templateUrl: './contact-card.component.html',
  providers: [ContactCardService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
  host: {
    class: 'data-not-saved-indicator-to-top',
  },
})
export class ContactCardComponent implements OnInit {
  @Input() entityId: string;

  public tabs: TabLink[] = [];

  public readonly stateService = inject(StateService);
  public readonly contactCardService = inject(ContactCardService);

  private readonly actionPanelService = inject(ActionPanelService);
  private readonly appService = inject(AppService);

  public ngOnInit(): void {
    this.contactCardService.load();
    this.actionPanelService.reset();
    this.actionPanelService.setHasAutosave(true);

    this.tabs.push({
      header: 'shared2.groups.profile',
      state: 'contact.profile',
    });

    if (
      this.appService.checkEntityPermission('Interaction', PermissionType.Read)
    ) {
      this.tabs.push({
        header: 'shared2.groups.interactions',
        state: 'contact.interactions',
      });
    }
  }
}
