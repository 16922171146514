<form [formGroup]="form" class="form">
  <div class="row">
    <div class="col-12">
      @if (mode === 'create' && boardService.columnMode() === 'states') {
        <div class="form-group" tmtIndicator>
          <label>{{ 'shared2.props.state' | translate }}</label>
          <wp-select-box
            formControlName="state"
            placeholder="{{ 'shared2.props.state' | translate }}"
            [values]="states"
          />
        </div>
      }

      <div class="form-group" tmtIndicator>
        <label>{{ 'shared2.props.name' | translate }}</label>
        <wp-text-box
          formControlName="name"
          placeholder="{{ 'shared2.props.name' | translate }}"
        />
      </div>
    </div>

    <div class="col-12 d-flex gap-2 justify-content-end">
      <button
        type="button"
        class="btn btn-sm btn-primary"
        (click)="save()"
        wpButtonSpinner
        [isBusy]="saving"
      >
        {{ (mode === 'create' ? 'shared2.actions.create' : 'shared2.actions.save') | translate }}
      </button>
      <button type="button" class="btn btn-sm btn-default" (click)="cancel()">
        {{ 'shared2.actions.cancel' | translate }}
      </button>
    </div>
  </div>
</form>
