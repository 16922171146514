import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { DatePeriodType } from '../shared/models/enums/date-period-type.enum';
import { DatePeriod } from '../shared/models/entities/date-period.model';

@Injectable({ providedIn: 'root' })
export class DateService {
  public getDatePair(periodType: DatePeriodType): { from: string; to: string } {
    let from: DateTime;
    let to: DateTime;

    if (periodType === DatePeriodType.ThisWeek) {
      from = DateTime.now().startOf('week');
      to = DateTime.now().endOf('week');
    }

    if (periodType === DatePeriodType.PrevWeek) {
      from = DateTime.now().startOf('week').minus({ days: 7 });
      to = DateTime.now().startOf('week').minus({ days: 1 });
    }

    if (periodType === DatePeriodType.ThisMonth) {
      from = DateTime.now().startOf('month');
      to = DateTime.now().endOf('month');
    }

    if (periodType === DatePeriodType.PrevMonth) {
      from = DateTime.now().startOf('month').minus({ months: 1 });
      to = DateTime.now().startOf('month').minus({ days: 1 });
    }

    if (periodType === DatePeriodType.ThisQuarter) {
      from = DateTime.now().startOf('quarter');
      to = DateTime.now().endOf('quarter');
    }

    if (periodType === DatePeriodType.PrevQuarter) {
      from = DateTime.now().startOf('quarter').minus({ months: 3 });
      to = DateTime.now().startOf('quarter').minus({ days: 1 });
    }
    if (periodType === DatePeriodType.ThisYear) {
      from = DateTime.now().startOf('year');
      to = DateTime.now().endOf('year');
    }
    if (periodType === DatePeriodType.PrevYear) {
      from = DateTime.now().startOf('year').minus({ years: 1 });
      to = DateTime.now().startOf('year').minus({ days: 1 });
    }

    if (periodType === DatePeriodType.NextYear) {
      from = DateTime.now().startOf('year').plus({ years: 1 });
      to = DateTime.now().endOf('year').plus({ years: 1 });
    }

    if (periodType === DatePeriodType.NextQuarter) {
      from = DateTime.now().startOf('quarter').plus({ quarter: 1 });
      to = DateTime.now().endOf('quarter').plus({ quarter: 1 });
    }

    if (periodType === DatePeriodType.NextMonth) {
      from = DateTime.now().startOf('month').plus({ month: 1 });
      to = DateTime.now().endOf('month').plus({ month: 1 });
    }

    if (periodType === DatePeriodType.NextWeek) {
      from = DateTime.now().startOf('week').plus({ days: 7 });
      to = DateTime.now().endOf('week').plus({ days: 7 });
    }

    if (periodType === DatePeriodType.Last7Days) {
      from = DateTime.now().minus({ days: 6 });
      to = DateTime.now();
    }

    if (periodType === DatePeriodType.Last30Days) {
      from = DateTime.now().minus({ days: 29 });
      to = DateTime.now();
    }

    if (periodType && from && to) {
      return { from: from.toISODate(), to: to.toISODate() };
    } else {
      return null;
    }
  }

  /**
   * Returns a date dictionary for a request to the API with a period.
   *
   * @param period value from control.
   * @param includeTimeZone Indicates whether to converts dates to UTC format.
   * @returns dictionary with dates.
   */
  public getDatePairFromPeriodForFilter(
    period: DatePeriod,
    includeTimeZone?: boolean,
  ): {
    periodStart: string;
    periodFinish: string;
  } | null {
    if (!period) {
      return null;
    }

    let result: { periodStart: string; periodFinish: string } = {
      periodStart: '',
      periodFinish: '',
    };

    if (period.periodType !== DatePeriodType.Custom) {
      const datePair = this.getDatePair(period.periodType);
      result = { periodStart: datePair.from, periodFinish: datePair.to };
    } else {
      result = { periodStart: period.from, periodFinish: period.to };
    }

    if (includeTimeZone) {
      result.periodStart = DateTime.fromISO(result.periodStart)
        .startOf('day')
        .toUTC()
        .toISO();
      result.periodFinish = DateTime.fromISO(result.periodFinish)
        .endOf('day')
        .toUTC()
        .toISO();
    }

    return result;
  }
}
