import {
  AfterViewInit,
  DestroyRef,
  Directive,
  inject,
  Injector,
  Input,
} from '@angular/core';
import { AbstractControl, FormControl, NgControl } from '@angular/forms';

import { PropagationMode } from 'src/app/shared/models/enums/control-propagation-mode.enum';

/** Abstract class for custom controls. */
@Directive({
  standalone: false,
})
export class AbstractBox implements AfterViewInit {
  /** Value change propagation mode. */
  @Input() public propagationMode: PropagationMode = PropagationMode.onInput;

  protected ngControl: AbstractControl | null;
  protected readonly injector = inject(Injector);
  protected readonly destroyRef = inject(DestroyRef);

  public ngAfterViewInit(): void {
    const ngControl: NgControl = this.injector.get(NgControl, null);

    if (ngControl) {
      this.ngControl = ngControl.control as FormControl;
    }
  }

  public toggleControlDirty(previousValue: string, newValue: string): void {
    if (!this.ngControl) {
      return;
    }

    if (previousValue !== newValue) {
      this.ngControl.markAsDirty();
    } else {
      this.ngControl.markAsPristine();
    }
  }
}
