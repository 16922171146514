import { Component, DestroyRef, Input, OnInit } from '@angular/core';
import { GridNavigationColumn } from 'src/app/shared-features/grid/models/grid-column.interface';
import { NavigationService } from 'src/app/core/navigation.service';
import { UIRouterGlobals } from '@uirouter/core';
import { RouteMode } from 'src/app/shared/models/inner/route-mode.enum';
import _ from 'lodash';
import { UntypedFormGroup } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { startWith } from 'rxjs';

@Component({
  selector: 'tmt-navigation-cell',
  templateUrl: './navigation-cell.component.html',
  styleUrls: ['./navigation-cell.component.scss'],
  standalone: false,
})
export class NavigationCellComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() column: GridNavigationColumn;

  public uiParams: any;
  public displayName: string;

  private get rawFormValue(): any {
    return this.form.getRawValue();
  }

  constructor(
    private navigationService: NavigationService,
    private uiRouterGlobals: UIRouterGlobals,
    private destroyRef: DestroyRef,
  ) {}

  public ngOnInit(): void {
    this.uiParams = {};
    if (this.column.stateParams) {
      Object.keys(this.column.stateParams).forEach((key) => {
        if (key !== 'fromRow') {
          this.uiParams[key] = this.column.stateParams[key];
        }
      });
      if (this.column.stateParams.fromRow) {
        const fromRow = this.column.stateParams.fromRow;
        Object.keys(fromRow).forEach((key) => {
          this.uiParams[key] = _.get(this.rawFormValue, fromRow[key]);
        });
      }
    } else {
      this.uiParams = {
        entityId: this.rawFormValue.id,
      };
    }

    this.uiParams['routeMode'] = RouteMode.continue;

    if (!this.uiParams.navigation) {
      this.uiParams.navigation = this.uiRouterGlobals.params?.navigation;
    }

    if (!this.uiParams.route && this.navigationService.storedRoute.length) {
      this.uiParams.route = _.cloneDeep(this.navigationService.storedRoute);
    }

    this.form.valueChanges
      .pipe(startWith(this.rawFormValue), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        const columnValue: any = this.rawFormValue[this.column.name];
        this.displayName = _.isObject(columnValue)
          ? (columnValue as any).name
          : columnValue;
      });
  }
}
