import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Transition, UIRouterModule } from '@uirouter/angular';
import { SharedModule } from 'src/app/shared/shared.module';
import { GridModule } from 'src/app/shared-features/grid/grid.module';
import { MetamodelListComponent } from 'src/app/settings-app/metamodel/list/metamodel-list.component';
import { MetamodelListFilterComponent } from 'src/app/settings-app/metamodel/list/filter/metamodel-list-filter.component';
import { MetamodelCardComponent } from 'src/app/settings-app/metamodel/card/metamodel-card.component';
import { StateHelper } from 'src/app/shared/helpers/state.helper';

@NgModule({
  declarations: [
    MetamodelListComponent,
    MetamodelListFilterComponent,
    MetamodelCardComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    GridModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'settings.metamodel',
          url: '/metamodel',
          component: MetamodelListComponent,
          params: {
            navigation: 'settings.metamodel',
          },
        },
        {
          name: 'settings.metamodelCard',
          params: {
            navigation: 'settings.metamodel',
          },
          url: '/metamodel/{entityId:string}',
          component: MetamodelCardComponent,
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
          ],
        },
      ],
    }),
  ],
})
export class MetamodelModule {}
