/**
 * Indirect changes.
 *
 * @example RequestBag<{TimesheetLine?: TimesheetLine, TimeAllocation?: TimeAllocation}>
 */
export interface RequestBag<T extends Record<string, unknown>> {
  added?: { [K in keyof T]: Array<Partial<T[K]>> };
  deleted?: { [K in keyof T]: string[] };
  updated?: { [K in keyof T]: Array<Partial<T[K]>> };
  applicationId?: string;
}
