import {
  GridColumnType,
  GridNavigationColumn,
} from 'src/app/shared-features/grid/models/grid-column.interface';
import { List } from 'src/app/shared/models/inner/list';
import { TotalType } from 'src/app/shared/models/inner/total-type';

export const METAENTITY_LIST: List = {
  version: 2,
  name: 'metamodel',
  views: [
    {
      name: 'default',
      columns: [
        {
          column: 'name',
          visibleByDefault: true,
          width: 2,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'displayName',
          visibleByDefault: true,
          width: 3,
        },
        {
          column: 'customizable',
          width: 1,
          visibleByDefault: true,
        },
        {
          column: 'usedInBoards',
          width: 1,
          visibleByDefault: true,
        },
        {
          column: 'usedInLogging',
          width: 1,
          visibleByDefault: true,
        },
        {
          column: 'hasLifecycle',
          width: 1,
          visibleByDefault: true,
        },
      ],
      order: { column: 'name', reverse: false },
    },
  ],
  columns: [
    <GridNavigationColumn>{
      name: 'name',
      type: GridColumnType.Navigation,
      state: 'settings.metamodelCard',
      header: 'shared2.props.name',
      hint: 'shared2.props.name',
      availableTotals: [TotalType.Count],
    },
    {
      name: 'displayName',
      header: 'components.metamodelListComponent.props.displayName',
      hint: 'components.metamodelListComponent.props.displayName',
      type: GridColumnType.String,
    },
    {
      name: 'customizable',
      header: 'components.metamodelListComponent.props.customizable',
      hint: 'components.metamodelListComponent.props.customizable',
      type: GridColumnType.Boolean,
    },
    {
      name: 'usedInBoards',
      header: 'components.metamodelListComponent.props.usedInBoard',
      hint: 'components.metamodelListComponent.props.usedInBoard',
      type: GridColumnType.Boolean,
    },
    {
      name: 'usedInLogging',
      header: 'components.metamodelListComponent.props.usedInLogging',
      hint: 'components.metamodelListComponent.props.usedInLogging',
      type: GridColumnType.Boolean,
    },
    {
      name: 'hasLifecycle',
      header: 'components.metamodelListComponent.props.hasLifecycle',
      hint: 'components.metamodelListComponent.props.hasLifecycle',
      type: GridColumnType.Boolean,
    },
    {
      name: 'hierarchyProperty',
      header: 'components.metamodelListComponent.props.hierarchyProperty',
      hint: 'components.metamodelListComponent.props.hierarchyProperty',
      type: GridColumnType.String,
    },
  ],
};
