<div
  #popper
  id="popper"
  role="tooltip"
  (clickOutside)="infoPopupService.close(popup)"
  [clickOutsideEnabled]="popup.clickOutsideEnabled"
  [delayClickOutsideInit]="true"
  [ngStyle]="popup.containerStyles"
  [class.default-theme]="popup.containerStyles !== null"
  [attr.data-show]="true"
  [attr.data-id]="popup.id"
>
  <div [hidden]="popup.isHideArrow" #arrow class="arrow" data-popper-arrow></div>

  @if (popup.data.component) {
    <ng-container
      *ngComponentOutlet="
        popup.data.component;
        inputs: popup.data.componentParams?.inputs;
        injector: popup.data.componentParams?.injector;
        content: popup.data.componentParams?.content
      "
    />
  } @else if (popup.data.templateRef) {
    <ng-container *ngTemplateOutlet="popup.data.templateRef; context: popup.data.templateParams" />
  }
</div>
