import { GridColumnType } from 'src/app/shared-features/grid/models/grid-column.interface';
import {
  DATE_TIME_COLUMN_WIDTH,
  getCodedEntityDataColumns,
  getCodedEntityGridColumns,
  IS_ACTIVE_COLUMN_WIDTH,
} from 'src/app/shared/helpers/list.helper';
import { List, LoadingStrategy } from 'src/app/shared/models/inner/list';
import { TotalType } from 'src/app/shared/models/inner/total-type';

export const BILL_CODE_LIST: List = {
  version: 1,
  name: 'billCodes',
  views: [
    {
      name: 'default',
      columns: [
        {
          column: 'name',
          visibleByDefault: true,
          width: 2,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'code',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'costAdjustment',
          visibleByDefault: false,
          width: 2,
        },
        {
          column: 'description',
          visibleByDefault: true,
          width: 3,
        },
        {
          column: 'isActive',
          width: IS_ACTIVE_COLUMN_WIDTH,
          visibleByDefault: true,
        },
        {
          column: 'isDefault',
          width: 1,
          visibleByDefault: true,
        },
        {
          column: 'created',
          width: DATE_TIME_COLUMN_WIDTH,
          visibleByDefault: false,
        },
        {
          column: 'modified',
          width: DATE_TIME_COLUMN_WIDTH,
          visibleByDefault: false,
        },
        {
          column: 'createdBy',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'modifiedBy',
          visibleByDefault: false,
          width: 1,
        },
      ],
      order: { column: 'name', reverse: false },
    },
  ],
  columns: [
    ...getCodedEntityGridColumns('settings.billCode'),
    {
      name: 'costAdjustment',
      header: 'components.billCodeCardComponent.props.costAdjustment',
      hint: 'components.billCodeCardComponent.props.costAdjustment',
      type: GridColumnType.Percent,
    },
    {
      name: 'description',
      header: 'shared2.props.description',
      hint: 'shared2.props.description',
      type: GridColumnType.String,
    },
    {
      name: 'isDefault',
      header: 'shared2.props.isDefault',
      hint: 'shared2.props.isDefault',
      type: GridColumnType.Boolean,
    },
  ],

  dataColumns: [
    ...getCodedEntityDataColumns(),
    {
      column: 'costAdjustment',
      field: 'costAdjustment',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'description',
      field: 'description',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'isDefault',
      field: 'isDefault',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
  ],

  dataCollection: 'BillCodes',
};
