import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  TimesheetError,
  TimesheetErrorGroup,
} from 'src/app/timesheets/card/shared/validation-modal/timesheet-errors.model';
import _ from 'lodash';
import { naturalSort } from 'src/app/shared/helpers/natural-sort.helper';

@Component({
  selector: 'wp-validation-modal',
  templateUrl: './validation-modal.component.html',
  styleUrls: ['./validation-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ValidationModalComponent implements OnInit {
  @Input() errors: TimesheetError[];

  public errorGroups: TimesheetErrorGroup[] = [];

  public get canOverride(): boolean {
    return this.errorGroups?.every((item) => item.canOverride);
  }

  constructor(private activeModal: NgbActiveModal) {}

  public ngOnInit(): void {
    // Natural sort for all messages
    this.errors.sort(naturalSort('message'));
    // Group messages by ruleName
    this.errors.forEach((error) => {
      const existGroup = this.errorGroups.find(
        (g) => g.ruleName === error.ruleName,
      );
      if (existGroup) {
        existGroup.messages.push(error.message);
      } else {
        this.errorGroups.push({
          ruleType: error.ruleType,
          ruleName: error.ruleName,
          customMessage: error.customMessage,
          canOverride: error.canOverride,
          messages: [error.message],
        });
      }
    });
    // Natural sort for groups by ruleName
    this.errorGroups.sort(naturalSort('ruleName'));
    // Move System group at the first position
    this.errorGroups.forEach((group, i) => {
      if (group.ruleType !== 'System') return;
      this.errorGroups.splice(i, 1);
      this.errorGroups.unshift(group);
    });
  }

  /** Ignore and submit the timesheet. */
  public ignoreAndSubmit = () => {
    this.activeModal.close();
  };

  /** Close the modal without changes. */
  public cancel = () => {
    this.activeModal.dismiss('cancel');
  };
}
