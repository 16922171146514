import { AssignmentTariffCellComponent } from 'src/app/projects/card/project-tasks/shared/task-card-modal/task-assignments/assignments-table/assignment-tariff-cell/assignment-tariff-cell.component';
import { AssignmentTariffReadonlyCellComponent } from 'src/app/projects/card/project-tasks/shared/task-card-modal/task-assignments/assignments-table/assignment-tariff-cell/assignment-tariff-readonly-cell.component';
import { ProjectTaskAssignmentsView } from 'src/app/projects/card/project-tasks/shared/task-card-modal/task-assignments/model/task-assignments-view.enum';
import {
  GridColumnType,
  GridComponentColumn,
  GridNumberControlColumn,
  GridSelectControlColumn,
} from 'src/app/shared-features/grid/models/grid-column.interface';
import { PropagationMode } from 'src/app/shared/models/enums/control-propagation-mode.enum';
import { List } from 'src/app/shared/models/inner/list';

export const PROJECT_TASK_ASSIGNMENTS_LIST: List = {
  name: 'projectTasksAssignments',
  version: 1,
  views: [
    {
      name: ProjectTaskAssignmentsView.default,
      resizableColumns: true,
      columns: [
        {
          column: 'projectTeamMember',
          visibleByDefault: true,
          width: 200,
        },
        {
          column: 'projectTariff',
          visibleByDefault: false,
          width: 180,
        },
        {
          column: 'units',
          visibleByDefault: true,
          width: 130,
        },
      ],
    },
    {
      name: ProjectTaskAssignmentsView.nonBillable,
      resizableColumns: true,
      columns: [
        {
          column: 'projectTeamMember',
          visibleByDefault: true,
          width: 200,
        },
        {
          column: 'units',
          visibleByDefault: true,
          width: 130,
        },
      ],
    },
  ],
  columns: [
    <GridSelectControlColumn>{
      name: 'projectTeamMember',
      header: 'shared2.props.resource',
      hint: 'shared2.props.resource',
      placeholder: 'shared2.props.resource',
      type: GridColumnType.SelectControl,
      fixedWidth: true,
      allowNull: false,
    },
    <GridComponentColumn>{
      name: 'projectTariff',
      header: 'components.assignmentTableComponent.groups.projectTariff',
      hint: 'components.assignmentTableComponent.groups.projectTariff',
      component: AssignmentTariffCellComponent,
      readonlyComponent: AssignmentTariffReadonlyCellComponent,
      type: GridColumnType.Component,
      width: '180px',
    },
    <GridNumberControlColumn>{
      name: 'units',
      header: 'components.assignmentTableComponent.groups.units',
      hint: 'components.assignmentTableComponent.groups.units',
      type: GridColumnType.NumberControl,
      controlType: 'percent',
      contentType: GridColumnType.Percent,
      propagationMode: PropagationMode.onExitFromEditing,
      min: 0,
      allowNull: false,
      width: '130px',
    },
  ],
  dataColumns: [],
};
