import { Injectable } from '@angular/core';
import { Constants } from 'src/app/shared/globals/constants';

@Injectable({
  providedIn: 'root',
})
export class PhoneService {
  public phoneTemplate = '+_ (___) ___-__-__';

  /** Formats a numeric string into the phone pattern. */
  public fillTemplate(value: string): string {
    if (!value) return '';
    this.chooseTemplate(value.length);
    let formatted = this.phoneTemplate;

    for (const char of value.replace(/\D/g, '')) {
      formatted = formatted.replace('_', char);
    }

    const firstUnderscore = formatted.indexOf('_');
    if (firstUnderscore !== -1) {
      formatted = formatted.substring(0, firstUnderscore);
    }

    // Delete all non-numeric characters at the end of the string
    formatted = formatted.replace(/[^\d]+$/g, '');

    return formatted;
  }

  /** Defines the type of template depending on the number of entered characters. */
  public chooseTemplate(numberCount: number): void {
    switch (numberCount) {
      case 12:
        this.phoneTemplate = '+__ (___) ___-__-__';
        break;
      case 13:
        this.phoneTemplate = '+___ (___) ___-__-__';
        break;
      default:
        this.phoneTemplate = '+_ (___) ___-__-__';
    }
  }

  /**
   * Formats a phone number based on a template.
   * @param replaceEightCode - Whether to replace '8' with '7' (default: true).
   * @param newValue - The new input value to format.
   * @param oldValue - The previous value (used to detect deletions).
   * @returns Formatted phone number as a string.
   */
  public getFormattedPhoneValue(
    replaceEightCode = true,
    newValue: string,
    oldValue?: string,
  ): string {
    const onlyNumbersValue = this.extractNumbers(newValue);
    const fixedNumbersLength =
      onlyNumbersValue.length > Constants.maxPhoneLength
        ? onlyNumbersValue.slice(0, Constants.maxPhoneLength)
        : onlyNumbersValue;

    const isDeleting = oldValue ? newValue.length < oldValue.length : false;
    const patternLength = this.phoneTemplate.split('_').length - 1;

    const maxLength = isDeleting
      ? Math.min(patternLength, fixedNumbersLength.length)
      : Math.max(patternLength, fixedNumbersLength.length);

    let phoneValue = fixedNumbersLength.slice(0, maxLength);
    if (replaceEightCode) phoneValue = phoneValue.replace(/^8/, '7');

    const formattedValue = isDeleting
      ? newValue
      : this.fillTemplate(phoneValue);

    return formattedValue;
  }

  /**
   * Extracts all digits from a string, removing any non-digit characters.
   * @param value - The input string containing digits and other characters.
   * @returns A new string containing only the digits from the input.
   */
  public extractNumbers(value: string): string {
    return value.replace(/\D/g, '');
  }
}
