import {
  ChangeDetectionStrategy,
  Component,
  effect,
  ElementRef,
  input,
  Signal,
  viewChild,
} from '@angular/core';
import { WidgetConfig } from 'src/app/analytics/dashboards/shared/widget-config.model';
import {
  ChartData,
  ChartDataset,
  SortProperty,
} from 'src/app/analytics/dashboards/dashboard/widget/models/widget-stacked-column.model';
import {
  Dataset,
  SortedData,
} from 'src/app/analytics/dashboards/dashboard/widget/models/widget-stacked-column.model';
import _ from 'lodash';
import { WidgetDataService } from 'src/app/analytics/dashboards/dashboard/widget/widget-data.service';
import { Constants } from 'src/app/shared/globals/constants';
import { TranslateService } from '@ngx-translate/core';
import { PivotRenderService } from 'src/app/analytics/shared/pivot-table/pivot-render.service';
import { PivotDataService } from 'src/app/analytics/shared/pivot-table/pivot-data.service';
import { ReportSourceDescription } from 'src/app/analytics/shared/models/source-description/report-source-description.model';
import * as echarts from 'echarts';
import { ECharts } from 'echarts';

@Component({
  selector: 'tmt-pie-chart',
  template: '<div #pieCanvas></div>',
  standalone: false,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [WidgetDataService, PivotRenderService, PivotDataService],
})
export class WidgetPieChartComponent {
  public pieCanvas = viewChild<ElementRef<HTMLElement>>('pieCanvas');
  public sourceData = input<Dataset>();
  public widget = input<WidgetConfig>();
  public sourceDescription = input<ReportSourceDescription>();

  public labels: string[] = [];
  public datasets: ChartDataset[] = [];
  private chartData: ChartData[] = [];
  private chart: ECharts;

  constructor(
    private widgetDataService: WidgetDataService,
    private translateService: TranslateService,
    private pivotRenderService: PivotRenderService,
  ) {
    effect(() => {
      if (!this.sourceData()?.length) return;
      this.processData(this.sourceData());
      this.pieChartRender();
    });
  }

  /**
   * Resizes the chart.
   *
   * @param width The new width of the chart.
   * @param height The new height of the chart.
   */
  public resize(width: number, height: number): void {
    this.chart?.resize({ width, height });
  }

  private pieChartRender(): void {
    if (this.chart) {
      echarts.dispose(this.chart);
    }

    this.chart = echarts.init(this.pieCanvas()?.nativeElement);

    this.chart.setOption({
      legend: {
        top: 'bottom',
        show: this.widget().properties.hasLegend,
      },
      tooltip: {
        position(point: number[]): (string | number)[] {
          return [point[0], point[1]];
        },
      },
      series: [
        {
          type: 'pie',
          label: {
            show: this.widget().properties.hasDataLabels,
            formatter: '{b}: {d}%',
          },
          data: this.chartData,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 10,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
      ],
    });
  }

  /**
   * Processes raw data to prepare labels and datasets for the chart.
   *
   * @param data Raw data to process.
   */
  private processData(data: Dataset): void {
    if (this.chart) {
      this.chartData.length = 0;
    }
    const sortedData = this.sortData(data, this.widget);
    this.datasets = this.widgetDataService.getChartDataset(
      sortedData as SortedData[],
    );
    this.labels = this.setLabels(sortedData);
    this.datasets[0].data.forEach((el, index) => {
      this.chartData.push({
        value: Math.round(el * 100) / 100,
        name: this.labels[index],
      });
    });
  }

  /**
   * Sorts the provided data based on the widget's configuration.
   *
   * @param data Data to sort.
   * @returns Sorted data.
   */
  private sortData(data: Dataset, widget: Signal<WidgetConfig>): SortedData[] {
    const sortProperty =
      widget().properties.sortBy === 'Value'
        ? SortProperty.value
        : SortProperty.category;

    let sortOrder: 'asc' | 'desc';
    if (widget().properties.sortReverse) {
      sortOrder = Constants.sortOrder.asc;
    } else {
      sortOrder = Constants.sortOrder.desc;
    }

    const hasLegend = widget().viewSettings.legendField != null;
    const map = new Map();

    data.forEach((arr) => {
      let category: string | number;
      let value: string | number;
      let legend: string | number;

      if (!hasLegend) {
        [category, value] = arr;
        legend = this.translateService.instant('shared2.props.value');
      } else {
        [category, legend, value] = arr;
      }

      if (!map.has(category)) {
        map.set(category, {
          category,
          value: 0,
          detailed: {},
        });
      }

      const item = map.get(category);
      item.value += value;
      item.detailed[legend] = value;
    });

    const aggregated = Array.from(map.values());
    let sorted = _.orderBy(aggregated, [sortProperty], sortOrder);

    if (widget().properties.showTop) {
      sorted = sorted.slice(0, widget().properties.showTop);
    }

    return sorted;
  }

  /**
   * Sets value of labels array.
   *
   * @param sortedData sorted chart data.
   * @returns Labels array.
   */
  private setLabels(sortedData: SortedData[]): string[] {
    return sortedData.map((item) =>
      typeof item.category === 'number'
        ? this.getLabel(item.category.toString())
        : this.getLabel(item.category),
    );
  }

  private getLabel(label: string): string {
    const sourceColumn = this.sourceDescription().allFields.find(
      (f) => f.name === this.widget().datasetFields[0].fieldName,
    );
    const formattedLabel = this.pivotRenderService.getFormattedValue(
      sourceColumn.type,
      label,
      this.widgetDataService.emptyValue,
    );
    if (!formattedLabel) {
      return this.widgetDataService.emptyValue;
    } else {
      return formattedLabel;
    }
  }
}
