import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnInit,
  ViewChild,
  ViewRef,
  OnChanges,
  SimpleChanges,
  inject,
  DestroyRef,
  TemplateRef,
  ContentChild,
  AfterContentInit,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
} from '@angular/forms';
import { DataService } from 'src/app/core/data.service';
import { firstValueFrom, isObservable, Observable } from 'rxjs';
import { auditTime, filter, map, take, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ValueIteratee, groupBy, isFunction } from 'lodash';
import { ScrollToService } from 'src/app/shared/services/scroll-to.service';
import { StandaloneFormControlDirective } from 'src/app/shared/directives/form-control.directive';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DirectoriesService } from 'src/app/shared/services/directories.service';
import { AppService } from 'src/app/core/app.service';

/** Контрол выбора значения из списка. */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'wp-select-box',
  templateUrl: './select-box.component.html',
  styleUrls: ['./select-box.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectBoxComponent),
      multi: true,
    },
  ],
  hostDirectives: [
    {
      directive: StandaloneFormControlDirective,
      inputs: ['formControl: control'],
    },
  ],
  standalone: false,
})
export class SelectBoxComponent
  implements
    OnInit,
    OnChanges,
    ControlValueAccessor,
    AfterViewInit,
    AfterContentInit
{
  @ViewChild('input') private inputEl: ElementRef;
  @ViewChild('expandingArea') private expandingArea: TemplateRef<HTMLElement>;
  @ViewChild('selectBoxContainer') private selectBoxContainer: ElementRef;
  @ContentChild('rowTemplate', { static: true })
  protected rowTemplate: TemplateRef<any>;
  @ContentChild('prefixTemplate', { static: true })
  protected prefixTemplate: TemplateRef<any>;

  /** Determines whether control works with id instead NamedEntity. */
  @Input() isIdMode = false;

  /** Максимальное число загружаемых строк (с сервера). */
  @Input() loadLimit = 50;

  /** Заменить пустого текста. */
  @Input() placeholder: string;

  /** Признак режима только-чтение. */
  @Input() readonly: boolean;

  /** Допустить очистку значения. */
  @Input() allowNull = true;

  /** Допустить выбор (с сервера) неактивных сущностей. */
  @Input() allowInactive = false;

  /** List of values, function or Observable. Highest priority. */
  @Input() values?: any = null;

  /** Имя коллекции на сервере. */
  @Input() collection?: string = null;

  /** Id of directory for getting directoryEntries as values. Priority higher that collection. */
  @Input() directory?: string | DirectoryParams = null;

  /** Признак необходимости фильтрации на сервере. */
  @Input() filterOnServer = true;

  /** Запрос (OData Query) к источнику (дополнительная фильтрация или запрос данных). */
  @Input() query: any = null;

  /** Признак, указывающий что выпадающая область будет иметь фиксированную позицию. */
  @Input() fixedPosition = false;

  /** Select input text and open expanding area after rendering. */
  @Input() autofocus?: boolean;

  /**
   * Group values by property, array of properties or callback.
   *
   * @example
   * groupHandler = "key";
   * groupHandler = "['key1', 'key2', ...]";
   * groupHandler = (item) => (item[prop] === 'example' ? 'Group1': 'Group2');
   */
  @Input() groupHandler: ValueIteratee<any>;

  /** Key used as text/name/title for a select-box option. */
  @Input() optionLabel = 'name';

  /** Initial value for input element after rendering. */
  @Input() initialValue?: unknown;

  /** Angular abstract control for binding to form outside of template. */
  @Input() control?: AbstractControl;

  public get directoryId(): string {
    if (!this.directory) {
      return null;
    }

    if (typeof this.directory === 'string') {
      return this.directory;
    }

    return this.app.getPropertyRelatedDictionaryId(
      this.directory.entityName,
      this.directory.propertyName,
    );
  }

  public rows: any[] = [];
  public listOpened = false;
  public isLoading = false;
  public loadedPartly = false;
  public selectedRow: any;
  public textControl = new UntypedFormControl('');
  public disabled = false;
  public paddingLeft = '0.75rem';

  /** Symbol property to show grouped items title, allow create “hidden” property of rows item */
  public readonly groupName = Symbol();

  private storedRows: any[] = null;
  private popupId: string;
  private destroyRef = inject(DestroyRef);

  private _value: any = null;
  public set value(obj: any) {
    this._value = obj;
    this.textControl.setValue(obj ? obj[this.optionLabel] : '', {
      emitEvent: false,
    });
    this.textControl.markAsPristine();
  }
  public get value(): any {
    return this._value;
  }

  public get allValues(): any[] {
    return this.storedRows?.slice(0);
  }

  public get controlName(): string {
    return (
      this.el.nativeElement.attributes.getNamedItem('formControlName')
        ?.textContent ??
      this.el.nativeElement.attributes.getNamedItem('ng-reflect-name')
        ?.textContent
    );
  }

  constructor(
    private app: AppService,
    private scrollToService: ScrollToService,
    private translate: TranslateService,
    private data: DataService,
    private ref: ChangeDetectorRef,
    private infoPopupService: InfoPopupService,
    private el: ElementRef<HTMLElement>,
    private directoriesService: DirectoriesService,
  ) {}

  public ngOnInit(): void {
    if (this.values) {
      this.filterOnServer = false;
    }

    this.textControl.valueChanges
      .pipe(
        tap((v) => {
          if (!v?.length && this.allowNull) {
            this.changeValue(null);
          }
        }),
        auditTime(this.filterOnServer ? 500 : 0),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        if (!this.listOpened) {
          this.openList();
        }

        if (this.filterOnServer) {
          this.storedRows = [];
        }

        this.refreshRows();
      });
  }

  public ngAfterViewInit(): void {
    if (this.inputEl && this.autofocus) {
      this.openList();
    }

    this.applyInitialValue();
  }

  public ngAfterContentInit(): void {
    this.updatePadding();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['readonly']) {
      this.disabled = this.readonly;
      this.ref.markForCheck();
    }
  }

  public writeValue(value: any): void {
    if (this.isIdMode && value) {
      const existEntity = this.rows.find((row) => row.id === value);
      if (existEntity) {
        this.value = existEntity;
        return;
      }
      if (this.values) {
        if (isObservable(this.values)) {
          this.values.pipe(take(1)).subscribe((val: NamedEntity[]) => {
            this.value = val.find((v) => v.id === value);
          });
          return;
        } else {
          const valueById = this.values.find((v) => v.id === value);
          this.value = valueById;
          return;
        }
      }
      if (this.directoryId || this.collection) {
        this.getEntityById(value)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe((entity) => {
            this.value = entity;
            this.ref.markForCheck();
          });
      }
      return;
    }
    this.value = value;

    if (!(this.ref as ViewRef).destroyed) {
      this.ref.detectChanges();
    }
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    if (!this.readonly) {
      this.disabled = isDisabled;
    }

    if (!(this.ref as ViewRef).destroyed) {
      this.ref.detectChanges();
    }
  }

  /** Listens event OnBlur. */
  public onBlur(): void {
    this.propagateTouch();
  }

  /**
   * Selects row.
   *
   * @param row row
   */
  public selectRow(row: any): void {
    this.selectedRow = row;
  }

  /**
   * Listens event mousedownOnRow.
   *
   * @param event event.
   */
  public mousedownOnRow(event): void {
    event.preventDefault();
  }

  /**
   * Chooses value.
   *
   * @param row row.
   */
  public clickRow(row: any): void {
    this.changeValue(row);
    this.closeList();
    this.ref.markForCheck();
  }

  /**
   * Listens event onKeyDown.
   *
   * @param event KeyboardEvent.
   */
  public onKeyDown(event: KeyboardEvent): void {
    const escCode = 27;
    const enterCode = 13;
    const downCode = 40;
    const upCode = 38;

    if (event.keyCode === escCode) {
      this.cancel();
      event.preventDefault();
      event.stopPropagation();
    }

    if (event.keyCode === enterCode) {
      // Очистили текст, нажали Enter (но нет активной записи) = убрали выбор.
      if (!this.textControl.value && !this.selectedRow) {
        if (this.allowNull) {
          this.changeValue(null);
        }
        this.cancel();
        return;
      }

      if (this.selectedRow) {
        this.clickRow(this.selectedRow);
      }
    }

    if (event.keyCode === downCode) {
      this.selectNext();
      event.preventDefault();
      event.stopPropagation();
    }

    if (event.keyCode === upCode) {
      this.selectPrevious();
      event.preventDefault();
      event.stopPropagation();
    }

    this.ref.detectChanges();
  }

  /** Listens event onClick. */
  public onInputClick(): void {
    if (!this.listOpened) {
      this.openList();
    }
  }

  /** CLears value. */
  public clear(): void {
    this.changeValue(null);
    this.closeList();
  }

  /** Closes list. */
  public closeList(): void {
    this.listOpened = false;
    this.selectedRow = null;
    this.infoPopupService.close(this.popupId);
    this.ref.detectChanges();
  }

  /** Opens list. */
  public openList(): void {
    if (this.listOpened) {
      this.cancel();
      return;
    }

    this.selectedRow = null;
    this.inputEl.nativeElement.select();
    this.listOpened = true;

    this.popupId = this.infoPopupService.open({
      target: this.selectBoxContainer.nativeElement,
      data: {
        templateRef: this.expandingArea,
      },
      containerStyles: null,
      isHideArrow: true,
      popperModifiers: this.infoPopupService.controlPopperModifiers,
      onDestroy: () => {
        this.onCancel();
        this.listOpened = false;
        this.selectedRow = null;
      },
    });

    this.refreshRows();
  }

  /** Cancels changes. */
  public cancel(): void {
    this.closeList();
    this.onCancel();
  }

  /**
   * Gets readonly display text.
   *
   * @returns text as string.
   */
  public getReadOnlyDisplayText(): string {
    if (this.textControl.value) {
      return this.textControl.value;
    }

    return this.translate.instant('shared.valueNotSelected');
  }

  public changeFilter(filterPart: any): void {
    if (!this.query) {
      this.query = {};
    }

    this.query.filter = filterPart;
  }

  private updatePadding(): void {
    if (this.prefixTemplate) {
      setTimeout(() => {
        this.paddingLeft =
          this.el.nativeElement.querySelector<HTMLElement>('.prefix')
            .offsetWidth + 'px';
        this.ref.markForCheck();
      });
    }
  }

  private getEntityById(id: string): Observable<NamedEntity> {
    if (this.directoryId) {
      return this.directoriesService.getDirectoryEntries(this.directoryId).pipe(
        take(1),
        map(
          (entries: NamedEntity[]) =>
            entries.find((entry) => entry.id === id) ?? null,
        ),
      );
    }
    return this.data
      .collection(this.collection)
      .entity(id)
      .get({ select: ['id', 'name'] });
  }

  private applyInitialValue(): void {
    if (this.initialValue === undefined) {
      return;
    }
    if (this.inputEl) {
      const event = new Event('input');
      if (typeof this.initialValue === 'string') {
        this.inputEl.nativeElement.value = this.initialValue;
        this.inputEl.nativeElement.dispatchEvent(event);
      } else if (this.initialValue === null) {
        this.inputEl.nativeElement.value = '';
        this.inputEl.nativeElement.dispatchEvent(event);
      }
    }
    this.initialValue = undefined;
  }

  private loadStoredRows(): Observable<any[]> {
    return new Observable<any[]>((subscribe) => {
      // Используем локальные значения, загрузка не нужна.
      if (this.values && !isObservable(this.values)) {
        const values = isFunction(this.values) ? this.values() : this.values;
        subscribe.next(values);
        return;
      }

      if (this.values && isObservable(this.values)) {
        this.isLoading = true;
        this.ref.detectChanges();
        (this.values as Observable<any>).subscribe((data) => {
          this.isLoading = false;
          this.loadedPartly = false;
          subscribe.next(data);
        });
        return;
      }

      // Есть значения и загрузка не нужна.
      if (
        (this.collection || this.directoryId) &&
        !this.filterOnServer &&
        this.storedRows
      ) {
        subscribe.next(this.storedRows);
        return;
      }

      if (!this.directoryId && !this.collection) return;

      this.isLoading = true;
      this.ref.detectChanges();

      const dataParams: any = {
        top: this.loadLimit,
        select: ['id', 'name'],
        filter: [],
        orderBy: 'name',
      };

      if (!this.allowInactive) {
        dataParams.filter.push({ isActive: { eq: true } });
      }

      if (this.query) {
        if (this.query.filter) {
          dataParams.filter = dataParams.filter.concat(this.query.filter);
        }

        if (this.query.select) {
          dataParams.select = this.query.select;
        }

        if (this.query.expand) {
          dataParams.expand = this.query.expand;
        }
      }

      if (
        this.textControl.value.trim() &&
        this.filterOnServer &&
        this.textControl.dirty
      ) {
        dataParams.filter.push({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'tolower(name)': {
            contains: this.textControl.value.trim().toLowerCase(),
          },
        });
      }

      let requestCollection: string;
      if (this.directoryId) {
        dataParams.filter.push({
          directoryId: { type: 'guid', value: this.directoryId },
        });
        requestCollection = 'DirectoryEntries';
      } else {
        requestCollection = this.collection;
      }

      this.data
        .collection(requestCollection)
        .query<object[]>(dataParams)
        .subscribe((data) => {
          this.isLoading = false;
          this.loadedPartly =
            this.loadLimit &&
            data.length === this.loadLimit &&
            data.length !== 0;
          subscribe.next(data);
        });
    });
  }

  private changeValue(value: any): void {
    this.updatePadding();
    this.propagateTouch();

    if (
      !(
        this._value === value ||
        (this._value && value && this._value.id === value.id)
      )
    ) {
      this.value = value;
      this.propagateChange(this.isIdMode ? (value?.id ?? null) : value);
      return;
    }

    this.value = value;
  }

  public refreshRows(): Promise<void> {
    return new Promise((resolve) => {
      this.loadStoredRows().subscribe((data: any[]) => {
        this.storedRows = data;
        if (
          !this.filterOnServer &&
          this.textControl.dirty &&
          this.textControl.value.trim()
        ) {
          const filteringValue = this.textControl.value.toLowerCase().trim();
          this.rows = this.storedRows.filter(
            (row: any) =>
              row[this.optionLabel].toLowerCase().indexOf(filteringValue) !==
              -1,
          );
        } else {
          this.rows = Object.assign([], this.storedRows);
        }

        if (this.value) {
          this.selectRow(this.rows.find((row) => row.id === this.value.id));
        }

        if (this.groupHandler) {
          this.groupRows();
        }

        this.ref.detectChanges();

        this.infoPopupService.update(this.popupId);
        firstValueFrom(
          this.infoPopupService.event$.pipe(filter((e) => e.name === 'update')),
        ).then(() => {
          this.scrollToSelectRow();
        });

        resolve();
      });
    });
  }

  private groupRows(): void {
    let groupedRows = null;
    switch (typeof this.groupHandler) {
      case 'string':
        groupedRows = groupBy(
          this.rows,
          (item) => `${item[this.groupHandler as string]}`,
        );
        break;
      case 'object':
        groupedRows = groupBy(this.rows, (item) =>
          (this.groupHandler as string[]).reduce(
            (accumulator, currentValue) =>
              accumulator + item[currentValue] + ' ',
            ``,
          ),
        );
        break;
      case 'function':
        groupedRows = groupBy(this.rows, this.groupHandler);
        break;
      default:
        return;
    }
    this.rows = [];
    for (const group of Object.keys(groupedRows)) {
      for (const row of groupedRows[group]) {
        row[this.groupName] =
          group === 'null'
            ? this.translate.instant('shared.withoutGroup')
            : group;
        this.rows.push(row);
      }
    }
  }

  private selectNext(): void {
    if (!this.listOpened) {
      this.openList();
    }
    if (this.rows.length === 0) {
      return;
    }

    if (!this.selectedRow) {
      this.selectedRow = this.rows[0];
    } else {
      const index = this.rows.indexOf(this.selectedRow);
      if (index < this.rows.length - 1) {
        this.selectedRow = this.rows[index + 1];
        this.scrollToSelectRow();
      }
    }
  }

  private selectPrevious(): void {
    if (!this.listOpened) {
      this.openList();
    }
    if (this.rows.length === 0) {
      return;
    }

    if (!this.selectedRow) {
      this.selectedRow = this.rows[0];
    } else {
      const index = this.rows.indexOf(this.selectedRow);
      if (index > 0) {
        this.selectedRow = this.rows[index - 1];
        this.scrollToSelectRow();
      }
    }
  }

  private scrollToSelectRow(): void {
    if (this.selectedRow && this.listOpened) {
      this.scrollToService.scrollTo(this.selectedRow.id, 'selecting-list');
    }
  }

  private onCancel(): void {
    this.textControl.markAsPristine();

    if (this.textControl.value && this.value) {
      this.textControl.setValue(this.value[this.optionLabel], {
        emitEvent: false,
      });
    } else {
      if (this.allowNull) {
        this.changeValue(null);
      } else {
        // Если запрещено "не выбрать", но выбора еще не было - то ничего не делаем.
        if (this.value) {
          this.textControl.setValue(this.value[this.optionLabel], {
            emitEvent: false,
          });
        }
      }
    }
  }

  private propagateChange = (_: any) => null;
  private propagateTouch = () => null;
}

export interface DirectoryParams {
  entityName: string;
  propertyName: string;
}
