import { Pipe, PipeTransform } from '@angular/core';
import { PhoneService } from 'src/app/shared/services/phone.service';

@Pipe({
  name: 'tmtPhone',
  standalone: true,
})
export class PhonePipe implements PipeTransform {
  constructor(private phoneService: PhoneService) {}

  transform(value: string): any {
    return this.phoneService.fillTemplate(value);
  }
}
