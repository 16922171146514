import { Injectable, Type } from '@angular/core';
import { InteractionListFilterComponent } from 'src/app/interactions/list/interaction-list-filter/interaction-list-filter.component';
import { FilterDetails } from 'src/app/shared/components/features/filter/filter-details.interface';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';

@Injectable()
export class InteractionListFilterService extends FilterService {
  public override component: Type<FilterDetails> =
    InteractionListFilterComponent;
  public override hasDetails = true;
  public override hasViewSelector = this.viewName === 'all';
  public override placeholder =
    'components.interactionsListFilterService.filter.placeholder';
  public override views = [
    { code: 'all', local: 'shared.filter.allEntities' },
    {
      code: 'onlyPlanned',
      local: 'components.interactionsListFilterService.filter.onlyPlanned',
    },
  ];
  public override customCriteriaCount: Record<string, () => number> = {
    text: () => 0,
    view: () => 0,
  };

  public override getODataFilter(): any[] {
    const result: any[] = super.getODataFilter();

    let text = this.values.text;
    if (text && text.length >= this.minStringLengthForFilter) {
      text = this.getClearText(text);

      result.push(
        this.getTextFilter(
          ['deal/name', 'organization/name'],
          this.getClearText(text),
        ),
      );

      result[0].or.push({
        interactionContacts: {
          any: {
            contact: { 'tolower(name)': { contains: text } },
          },
        },
      });
    }

    if (this.values.performer) {
      result.push({
        performerId: { type: 'guid', value: this.values.performer.id },
      });
    }

    if (this.values.interactionContact) {
      result.push({
        interactionContacts: {
          any: {
            contactId: {
              type: 'guid',
              value: this.values.interactionContact.id,
            },
          },
        },
      });
    }

    if (this.values.organization) {
      result.push({
        organizationId: {
          type: 'guid',
          value: this.values.organization.id,
        },
      });
    }

    if (this.values.deal) {
      result.push({
        dealId: {
          type: 'guid',
          value: this.values.deal.id,
        },
      });
    }

    if (this.values.period) {
      const dates = this.dateService.getDatePairFromPeriodForFilter(
        this.values.period,
        true,
      );

      result.push({
        date: { ge: { type: 'raw', value: dates.periodStart } },
      });

      result.push({
        date: { le: { type: 'raw', value: dates.periodFinish } },
      });
    }

    if (
      this.values.view &&
      this.values.view.code === 'onlyPlanned' &&
      this.hasViewSelector
    ) {
      result.push({ isPlanned: true });
    }

    return result;
  }

  protected override getDefaultValues(): any {
    return {
      text: '',
      period: null,
      performer: null,
      organization: null,
      deal: null,
      interactionContact: null,
      view: this.views[0],
    };
  }
}
