<tmt-form-header [name]="billCodeCardService.name()" />
<tmt-not-found [state]="billCodeCardService.state()" />
<wp-loading-indicator [state]="billCodeCardService.state()" />
<wp-data-not-saved-indicator [form]="billCodeCardService.form" />

<form
  [formGroup]="billCodeCardService.form"
  [hidden]="billCodeCardService.state() !== 'Ready'"
  class="form mt-3"
  style="width: 600px"
>
  @if (billCodeCardService.isDefault()) {
    <p class="fw-semibold">
      <i class="bi bi-check-circle" aria-hidden="true"></i> &nbsp;{{
        'components.billCodeCardComponent.props.usingByDefault' | translate
      }}
    </p>
  }
  <div class="form-group" tmtIndicator>
    <label>{{ 'shared2.props.name' | translate }}</label>
    <wp-text-box formControlName="name" placeholder="{{ 'shared2.props.name' | translate }}" />
  </div>

  <div class="form-group w-150" tmtIndicator>
    <label>{{ 'shared2.props.code' | translate }}</label>
    <wp-text-box formControlName="code" placeholder="{{ 'shared2.props.code' | translate }}" />
  </div>

  <div class="form-group">
    <label>
      {{ 'components.billCodeCardComponent.props.costAdjustment' | translate }}
    </label>
    <wp-number-box class="w-150" formControlName="costAdjustment" type="percent" />
  </div>

  <div class="form-group">
    <label>{{ 'shared2.props.description' | translate }}</label>
    <wp-multiline-text-box
      formControlName="description"
      placeholder="{{ 'shared2.props.description' | translate }}"
    />
  </div>

  <div class="form-check" title="{{ 'shared2.props.isActive.hint' | translate }}">
    <input formControlName="isActive" type="checkbox" class="form-check-input" id="isActive" />
    <label class="form-check-label" for="isActive">
      {{ 'shared2.props.isActive.value' | translate }}
    </label>
  </div>

  @if (!billCodeCardService.readonly()) {
    <div class="actions">
      <button
        type="button"
        class="btn btn-primary"
        wpButtonSpinner
        [isBusy]="billCodeCardService.isSaving()"
        (click)="billCodeCardService.save()"
      >
        {{ 'shared2.actions.save' | translate }}
      </button>
    </div>
  }
</form>
