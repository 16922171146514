import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  signal,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { StateService } from '@uirouter/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { Constants } from 'src/app/shared/globals/constants';

@Component({
  selector: 'tmt-bill-code-creation',
  templateUrl: './bill-code-creation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class BillCodeCreationComponent {
  public form: UntypedFormGroup = this.fb.group({
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    code: [
      '',
      [Validators.required, Validators.maxLength(Constants.formCodeMaxLength)],
    ],
  });
  public isSaving = signal<boolean>(false);

  private destroyRef = inject(DestroyRef);

  constructor(
    private fb: UntypedFormBuilder,
    private notificationService: NotificationService,
    private dataService: DataService,
    private stateService: StateService,
    private activeModal: NgbActiveModal,
  ) {}

  /* Creates bill code. */
  public create(): void {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notificationService.warningLocal(
        'shared.messages.requiredFieldsError',
      );
      return;
    }

    this.isSaving.set(true);

    this.dataService
      .collection('BillCodes')
      .insert(this.form.value)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response) => {
          this.notificationService.successLocal(
            'components.billCodeCreationComponent.messages.created',
          );
          this.stateService.go('settings.billCode', {
            entityId: response.id,
          });
          this.isSaving.set(false);
          this.activeModal.close();
        },
        error: (error) => {
          this.notificationService.error(error.message);
          this.isSaving.set(false);
        },
      });
  }

  /* Closes bill code creation modal. */
  public cancel(): void {
    this.activeModal.dismiss('cancel');
  }
}
