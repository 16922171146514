import { Component, OnInit, DestroyRef } from '@angular/core';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { EntityListService } from 'src/app/shared/components/entity-list/entity-list.service';
import { ListService } from 'src/app/shared/services/list.service';
import { StateService } from '@uirouter/core';
import { AppService } from 'src/app/core/app.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { ClientFilterService } from './client-filter/client-filter.service';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { ClientSettingsModalComponent } from 'src/app/clients/card/client-settings/client-settings-modal/client-settings-modal.component';
import { Organization } from 'src/app/shared/models/entities/projects/organization.model';
import { RouteMode } from 'src/app/shared/models/inner/route-mode.enum';
import { NavigationService } from 'src/app/core/navigation.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'wp-client-list',
  template: '<wp-entity-list/> ',
  providers: [
    { provide: FilterService, useClass: ClientFilterService },
    EntityListService,
    ListService,
    GridService,
  ],
  standalone: false,
})
export class ClientListComponent implements OnInit {
  constructor(
    private entityListService: EntityListService,
    private gridService: GridService,
    private stateService: StateService,
    private destroyRef: DestroyRef,
    private navigationService: NavigationService,
    private appService: AppService,
    private actionPanelService: ActionPanelService,
    private ngbModal: NgbModal,
  ) {}

  public ngOnInit(): void {
    this.actionPanelService.set([
      this.actionPanelService.getDefaultAction('create', {
        title: 'projects.clients.list.actions.create',
        isVisible: this.appService.checkEntityPermission(
          'Organization',
          PermissionType.Modify,
        ),
        handler: this.create,
      }),
      this.actionPanelService.getDefaultAction('card', {
        handler: () =>
          this.stateService.go('client', {
            entityId: this.gridService.selectedGroupValue.id,
          }),
      }),
      this.actionPanelService.getDefaultAction('delete', {
        handler: this.entityListService.delete,
      }),
    ]);

    this.gridService.selectedGroup$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((row: any) => {
        this.actionPanelService.action('card').isShown = !!row;
        this.actionPanelService.action('delete').isShown =
          row &&
          this.appService.checkEntityPermission(
            'Organization',
            PermissionType.Modify,
          );
      });
  }

  private create = () => {
    this.ngbModal
      .open(ClientSettingsModalComponent, { size: 'lg' })
      .result.then(
        (client: Organization) => {
          this.stateService.go('client', {
            entityId: client.id,
            routeMode: RouteMode.continue,
            navigation:
              this.navigationService.selectedNavigationItem?.name ??
              'projects.allClients',
          });
        },
        () => null,
      );
  };
}
