import { View } from './view';
import { GridColumn } from 'src/app/shared-features/grid/models/grid-column.interface';

/** Definition of entity list. */
export interface List {
  /** Version. Used to flush cached local settings on list definition breaking change. */
  version: number;

  /** Unique name. */
  name: string;

  /** Views. */
  views: View[];

  /** Grid columns. */
  columns: GridColumn[];

  /** Data source columns. Used to build select query. */
  dataColumns?: DataColumn[];

  /** Data source collection. */
  dataCollection?: string;

  /** Data source query function. */
  queryFunction?: string;

  /** Type of customizable entity. Used to enrich list with custom fields. */
  customFieldEntityType?: string;

  /** Determines whether custom field columns are editable or not. */
  editableCustomFields?: boolean;

  /** Custom field prefix. Used when customizable entity is not the root, e.g. when loading ProjectTotals. */
  customFieldPrefixForDataField?: string;

  /** Determines if view settings can be customized by user. */
  customizable?: boolean;
}

/** Definition of data column. */
export interface DataColumn {
  /** Column name. */
  column: string;

  /** Loading strategy. */
  loadingStrategy: LoadingStrategy;

  /** Entity field in API.
   * For navigations one should use composite name such as "project.manager.name".
   * Array should be used for loading fields of one entity, e.g. ["project.manager.name", "project.manager.id"].
   * With array, first its element will be used for sorting, e.g. by name in example above.
   */
  field: string | string[];
}

/** Loading strategy. */
export enum LoadingStrategy {
  /** Always load field. */
  Always,

  /** Load only when configured to be displayed. */
  WhenShowing,
}
