<div class="chart" [hidden]="!chart" [attr.id]="chartId"></div>

@if (widget().type === widgetType.Funnel) {
  <tmt-funnel-chart #funnelChart [sourceData]="sourceData?.data" />
}

@if (widget().type === widgetType.Speedometer) {
  <tmt-gauge
    class="position-absolute h-100"
    [sourceData]="sourceData"
    [sourceDescription]="sourceDescription"
    [widget]="widget()"
    #gauge
  />
}

@if (
  widget().type === widgetType.StackedColumns ||
  widget().type === widgetType.Column ||
  widget().type === widgetType.StackedPercentageColumns
) {
  <tmt-histogram-chart
    #histogramChart
    [hidden]="isLoading || hasMessage"
    [sourceData]="sourceData"
    [sourceDescription]="sourceDescription"
    [widget]="widget()"
  />
}

@if (widget().type === 'Pie') {
  <tmt-pie-chart
    class="position-absolute h-100"
    #pie
    [sourceData]="sourceData?.data"
    [sourceDescription]="sourceDescription"
    [widget]="widget()"
  />
}

<div
  *ngIf="widget().type === widgetType.Value"
  [hidden]="isLoading || hasMessage"
  class="single-value"
>
  <div class="value trim" title="{{ value }}">
    <div class="value-number">{{ value }}</div>
    <div class="value-abbreviation">{{ abbreviation }}</div>
  </div>
  <div class="title trim" title="trim">{{ fieldTitle }}</div>
</div>

<div
  *ngIf="widget().type === widgetType.DoubleValue"
  [hidden]="isLoading || hasMessage"
  class="double-value"
>
  <div class="row">
    <div class="col-6">
      <div class="value trim" [title]="valueFirst + ' ' + abbreviation">
        <div class="value-number">{{ valueFirst }}</div>
        <div class="value-abbreviation">{{ abbreviation }}</div>
      </div>
      <div class="title trim">{{ fieldTitleFirst }}</div>
    </div>
    <div class="col-6">
      <div class="value trim" [title]="valueSecond + ' ' + abbreviation">
        <div class="value-number">{{ valueSecond }}</div>
        <div class="value-abbreviation">{{ abbreviation }}</div>
      </div>
      <div class="title trim">{{ fieldTitleSecond }}</div>
    </div>
  </div>

  <ngb-progressbar
    type="info"
    class="mt-3"
    [value]="proportion"
    max="1"
    *ngIf="widget().properties.showProportion"
  >
    {{ proportion | percent: '1.0-2' }}
  </ngb-progressbar>
</div>
