import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  DestroyRef,
  inject,
} from '@angular/core';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { AppService } from 'src/app/core/app.service';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { DefaultFilterService } from '../../shared/default-filter/default-filter.service';
import { EntityListService } from 'src/app/shared/components/entity-list/entity-list.service';
import { ListService } from 'src/app/shared/services/list.service';
import { StateService } from '@uirouter/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { BillCodeCreationComponent } from 'src/app/settings-app/bill-codes/creation/bill-code-creation.component';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { Exception } from 'src/app/shared/models/exception';

@Component({
  selector: 'tmt-bill-codes-list',
  template: '<wp-entity-list />',
  providers: [
    { provide: FilterService, useClass: DefaultFilterService },
    EntityListService,
    ListService,
    GridService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class BillCodesListComponent implements OnInit {
  private destroyRef = inject(DestroyRef);

  constructor(
    private gridService: GridService,
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private actionPanelService: ActionPanelService,
    private appService: AppService,
    private entityListService: EntityListService,
    private stateService: StateService,
    private dataService: DataService,
  ) {}

  public ngOnInit(): void {
    this.actionPanelService.set([
      this.actionPanelService.getDefaultAction('create', {
        title: 'components.billCodesListComponent.actions.create',
        isVisible: this.appService.checkEntityPermission(
          'BillCode',
          PermissionType.Modify,
        ),
        handler: () => this.create(),
      }),
      this.actionPanelService.getDefaultAction('card', {
        handler: () =>
          this.stateService.go('settings.billCode', {
            entityId: this.gridService.selectedGroupValue.id,
          }),
      }),
      this.actionPanelService.getDefaultAction('delete', {
        handler: () => this.entityListService.delete(),
      }),
      this.actionPanelService.getDefaultAction('setAsDefault', {
        handler: () => this.setAsDefault(),
      }),
    ]);

    this.gridService.selectedGroup$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((row: any) => {
        this.actionPanelService.action('card').isShown = !!row;
        this.actionPanelService.action('delete').isShown =
          row &&
          this.appService.checkEntityPermission(
            'BillCode',
            PermissionType.Modify,
          );
        this.actionPanelService.action('setAsDefault').isShown =
          row &&
          this.appService.checkEntityPermission(
            'BillCode',
            PermissionType.Modify,
          ) &&
          !row.getRawValue().isDefault;
      });
  }

  private setAsDefault(): void {
    this.dataService
      .collection('BillCodes')
      .entity(this.gridService.selectedGroupValue.id)
      .action('SetAsDefault')
      .execute()
      .subscribe({
        next: () => {
          this.notificationService.successLocal(
            'components.billCodeCardService.messages.hasBeenSetByDefault',
          );
          this.entityListService.reload();
        },
        error: (error: Exception) => {
          this.notificationService.error(error.message);
        },
      });
  }

  private create(): void {
    this.modalService.open(BillCodeCreationComponent);
  }
}
