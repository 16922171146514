import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import { SortableEvent, SortableOptions } from 'sortablejs';
import _ from 'lodash';

import { AppService } from 'src/app/core/app.service';
import { NotificationService } from 'src/app/core/notification.service';

import { naturalSort } from 'src/app/shared/helpers/natural-sort.helper';
import {
  LocalString,
  LocalStringHelper,
} from 'src/app/shared/models/enums/language.enum';
import {
  MetaEntity,
  MetaEntityBaseProperty,
} from 'src/app/shared/models/entities/settings/metamodel.model';
import { BoardCardViewProperties } from 'src/app/settings-app/boards/model/board.model';

import { REQUIRED_PROPERTIES } from 'src/app/boards/models/board.config';

import { MetaEntityBasePropertyItem } from './board-mini-card-builder.interface';

@Component({
  selector: 'tmt-board-mini-card-builder',
  templateUrl: './board-mini-card-builder.component.html',
  styleUrl: './board-mini-card-builder.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class BoardMiniCardBuilderComponent implements OnInit {
  @Input({ required: true }) public initialValue: BoardCardViewProperties[];
  @Input({ required: true }) public entityType: string;

  @Output() public result$ = new EventEmitter<BoardCardViewProperties[]>();

  public entity: MetaEntity;
  public properties: MetaEntityBasePropertyItem[] = [];
  public selectedProperties: MetaEntityBasePropertyItem[] = [];
  public propertiesSortableOptions: SortableOptions = {
    group: {
      name: 'properties',
      pull: 'clone',
      put: false,
    },
    sort: false,
    handle: '.handle',
    draggable: '.draggable',
    onStart: () => {
      this.cdr.markForCheck();
    },
    onEnd: () => {
      this.cdr.markForCheck();
    },
  };
  public selectedPropertiesSortableOptions: SortableOptions = {
    group: {
      name: 'selectedProperties',
      pull: true,
      put: (from, to, dragEl) =>
        !this.selectedProperties.some((c) => c.name === dragEl.dataset.key),
    },
    handle: '.handle',
    chosenClass: 'chosen-selected',
    ghostClass: 'ghost-selected',
    dragClass: 'drag-selected',
    draggable: '.draggable',
    onStart: (event: SortableEvent) => {
      this.cdr.markForCheck();
      const key = event.item.dataset.key;

      this.dropEventHandler = () => {
        if (REQUIRED_PROPERTIES.includes(key)) {
          this.notificationService.warningLocal(
            'components.boardMiniCardBuilderComponent.messages.propertyRequired',
          );
        } else {
          this.removeItem(
            this.selectedProperties.findIndex((c) => c.name === key),
          );
        }
      };

      this.el.nativeElement
        .querySelector(`#${this.availableListId}`)
        .addEventListener('drop', this.dropEventHandler, { once: true });
    },
    onEnd: () => {
      this.cdr.markForCheck();
      this.el.nativeElement
        .querySelector(`#${this.availableListId}`)
        .removeEventListener('drop', this.dropEventHandler);
    },
    onSort: () => {
      this.sendResult();
    },
  };
  public availableListId = 'available-list';

  private dropEventHandler: () => void;
  private readonly restrictedTypes = ['text'];

  constructor(
    private appService: AppService,
    private notificationService: NotificationService,
    private cdr: ChangeDetectorRef,
    private el: ElementRef<HTMLElement>,
  ) {}

  public async ngOnInit(): Promise<void> {
    await this.init();
  }

  /**
   * Removes item.
   *
   * @param index.
   */
  public removeItem(index: number): void {
    const removedProperty = this.selectedProperties.splice(index, 1).pop();
    const property = this.properties.find(
      (p) => p.name === removedProperty.name,
    );
    property.selected = false;

    this.sendResult();
  }

  /**
   * Handler for `Clone` event of sortablejs.
   *
   * @param property MetaEntityBasePropertyItem.
   * @returns updated MetaEntityBasePropertyItem.
   */
  public cloneFieldHandler(
    property: MetaEntityBasePropertyItem,
  ): MetaEntityBasePropertyItem {
    property.selected = true;
    return property;
  }

  /** Checkbox input handler. */
  public onSelected(property: MetaEntityBasePropertyItem): void {
    if (property.selected) {
      this.selectedProperties.push(property);
    } else {
      this.selectedProperties.splice(
        this.selectedProperties.findIndex(
          (selected) => selected.name === property.name,
        ),
        1,
      );
    }

    this.sendResult();
  }

  private sendResult(): void {
    this.result$.emit(
      this.selectedProperties.map((value) => ({
        name: value.name,
        clrType: value.clrType,
        type: value.type,
        kind: value.kind,
        displayName: this.getTranslate(value.displayNames),
      })),
    );
  }

  private getTranslate(translates: LocalString[]): string | undefined {
    return LocalStringHelper.getTranslate(
      translates,
      this.appService.session.language,
    );
  }

  private async init(): Promise<void> {
    try {
      this.entity = this.appService.getMetaEntity(this.entityType);

      (this.entity.primitiveProperties as MetaEntityBaseProperty[])
        .concat(this.entity.navigationProperties)
        .concat(this.entity.directoryProperties)
        // .concat(this.entity.complexProperties) // TODO: not supported yet.
        .filter((p) => !this.restrictedTypes.includes(p.type.toLowerCase()))
        .forEach((property) => {
          const initialValueItemIndex = this.initialValue?.findIndex(
            (v) => v.name.toLowerCase() === property.name.toLowerCase(),
          );
          const isRequired = REQUIRED_PROPERTIES.includes(property.name);
          const item: MetaEntityBasePropertyItem = {
            ...property,
            title: this.appService.getTranslate(property.displayNames),
            hint: null,
            selected: isRequired || initialValueItemIndex > -1,
            required: isRequired,
            index: Math.max(0, initialValueItemIndex),
          };

          this.properties.push(item);

          if (item.selected) {
            this.selectedProperties.push(item);
          }
        });

      this.properties.sort(naturalSort('title'));
      this.selectedProperties = _.sortBy(this.selectedProperties, 'index');
      this.cdr.markForCheck();
    } catch (error) {
      this.entity = null;
      this.cdr.markForCheck();
      this.notificationService.error(error.message);
    }
  }
}
