import {
  Component,
  ChangeDetectionStrategy,
  Input,
  effect,
  OnInit,
  inject,
  DestroyRef,
  input,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { filter, forkJoin, switchMap } from 'rxjs';

import { NotificationService } from 'src/app/core/notification.service';

import { GridColumn } from 'src/app/shared-features/grid/models/grid-column.interface';
import { GridComponentCell } from 'src/app/shared-features/grid/models/grid-component-cell.interface';

import { FileBoxComponent } from '../file-box.component';
import { FilesService } from '../files.service';

@Component({
  selector: 'tmt-file-box-cell',
  styleUrl: './file-box-cell.component.scss',
  templateUrl: './file-box-cell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FileBoxComponent],
  providers: [FilesService],
})
export class FileBoxCellComponent implements OnInit, GridComponentCell {
  public entityType = input.required<string>();
  @Input() public column: GridColumn;
  @Input() public formGroup: UntypedFormGroup;
  @Input() public initialValue: unknown;

  private readonly filesService = inject(FilesService);
  private readonly notificationService = inject(NotificationService);
  private readonly destroyRef = inject(DestroyRef);

  public get control(): UntypedFormControl {
    return this.formGroup?.controls[this.column.name] as UntypedFormControl;
  }

  public get entityId(): string {
    return this.formGroup.controls['id'].getRawValue();
  }

  constructor() {
    effect(() => {
      const files = this.filesService.files();
      this.control?.setValue(files.length ? files : null, { emitEvent: false });

      files
        .filter((file) => file.dataReadyStatus === 'fail')
        .forEach((file) => {
          this.notificationService.error(file.message);
        });

      if (files.some((file) => file.dataReadyStatus === 'fail')) {
        this.filesService.setInitialFiles(null);
        this.filesService.reload();
      }
    });
  }

  public ngOnInit(): void {
    this.control.valueChanges
      .pipe(
        filter((value) => value === null),
        switchMap(() =>
          forkJoin(
            this.filesService
              .files()
              .map((file) => this.filesService.deleteAttachment(file.id)),
          ),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.filesService.clearFiles();
      });

    this.control.valueChanges
      .pipe(
        filter((value) => Array.isArray(value)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((value) => {
        this.filesService.setInitialFiles(value);
        this.filesService.reload();
      });
  }
}
