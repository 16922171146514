<div id="wp-time-entry-info" name="area">
  <wp-data-not-saved-indicator [form]="form" />
  <ng-container [formGroup]="form">
    <div class="position-relative mb-2">
      <wp-multiline-text-box
        [rows]="4"
        placeholder="{{ 'timesheets.card.entryInfo.comment' | translate }}"
        formControlName="description"
        [propagationMode]="propagationMode()"
      />
    </div>

    @if (!readonly()) {
      <div class="btn-group btn-group-sm btn-group-justified" role="group">
        @for (buttonHint of buttonHints; track $index) {
          <div class="btn-group btn-group-sm" role="group">
            <button
              type="button"
              class="btn btn-default"
              title="{{ buttonHints[$index] }}"
              (click)="setHours($index + 1)"
            >
              {{ buttonTitles[$index] }}
            </button>
          </div>
        }

        @if (showStartButton) {
          <div class="btn-group btn-group-sm" role="group">
            <button
              type="button"
              [disabled]="swExecuted"
              class="btn btn-default"
              title="{{ 'stopwatch.actions.start.hint' | translate }}"
              (click)="start()"
              data-test="startStopwatch"
            >
              <span class="bi bi-play-fill"></span>
            </button>
          </div>
        }

        @if (showStopButton) {
          <div class="btn-group btn-group-sm" role="group">
            <button
              type="button"
              id="action-stop-stopwatch"
              [disabled]="swExecuted"
              class="btn btn-default"
              title="{{ 'stopwatch.actions.stop.hint' | translate }}"
              (click)="stop('Increment')"
            >
              <span class="bi bi-stop-fill" [ngClass]="{ gray: isPause, red: !isPause }"></span>
            </button>
          </div>
        }
      </div>
    }

    <wp-custom-fields
      [formGroup]="form"
      [fields]="timesheetCardService.allocationCustomFields"
      columnClasses="col-12"
      [propagationMode]="propagationMode()"
    />
  </ng-container>
</div>
