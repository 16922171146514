import {
  Injectable,
  NgModule,
  Pipe,
  PipeTransform,
  ModuleWithProviders,
} from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

const translations: any = {};

class FakeLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return of(translations);
  }
}

@Pipe({
  name: 'translate',
  standalone: false,
})
export class TranslatePipeMock implements PipeTransform {
  public name = 'translate';

  public transform(query: string, ...args: any[]): any {
    return query;
  }
}

@Injectable()
export class TranslateServiceStub {
  public get<T>(key: T): Observable<T> {
    return of(key);
  }

  public instant(key: string): string {
    return key;
  }
}

@NgModule({
  declarations: [TranslatePipeMock],
  providers: [],
  imports: [
    TranslateModule.forRoot({
      loader: { provide: TranslateLoader, useClass: FakeLoader },
    }),
  ],
  exports: [TranslatePipeMock, TranslateModule],
})
export class TranslateStubModule {
  static forRoot(): ModuleWithProviders<TranslateStubModule> {
    return {
      ngModule: TranslateStubModule,
      providers: [TranslateServiceStub, TranslatePipeMock],
    };
  }
}
