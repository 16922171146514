import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ProjectCardService } from 'src/app/projects/card/core/project-card.service';
import { ProjectVersionCardService } from 'src/app/projects/card/core/project-version-card.service';
import { ProjectVersionUtil } from 'src/app/projects/project-versions/project-version-util';
import { GridColumn } from 'src/app/shared-features/grid/models/grid-column.interface';
import { ResourceType } from 'src/app/shared/models/enums/resource-type.enum';
import { SharedModule } from 'src/app/shared/shared.module';

@Component({
  selector: 'tmt-assignment-primary-tariff',
  imports: [SharedModule],
  template: `<wp-select-box
    [formControl]="control"
    collection="ProjectTariffs"
    [query]="getProjectTariffQuery()"
    [placeholder]="'shared2.props.projectTariff' | translate"
  />`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssignmentTariffCellComponent implements OnInit {
  @Input() column: GridColumn;
  @Input() formGroup: UntypedFormGroup;
  @Input() initialValue: unknown;

  public resourceType: ResourceType;

  public get control(): UntypedFormControl {
    return this.formGroup.controls[this.column.name] as UntypedFormControl;
  }

  private destroyRef = inject(DestroyRef);

  constructor(
    private projectVersionCardService: ProjectVersionCardService,
    public projectCardService: ProjectCardService,
    private cdr: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.formGroup.controls.projectTeamMember.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.cdr.detectChanges());
  }

  /**
   * Generates a query object for fetching project tariffs based on the current team member and project context.
   *
   * @returns An object representing the query for fetching project tariffs.
   */
  public getProjectTariffQuery(): object {
    const teamMember = this.formGroup.controls.projectTeamMember.value;
    const query = {
      select: ['id', 'name'],
      filter: [],
    };

    if (teamMember) {
      query.filter.push({
        assignments: {
          any: {
            or: [
              {
                projectTeamMemberId: {
                  type: 'guid',
                  value: teamMember.id,
                },
              },
              {
                isAllTeamRole: true,
              },
            ],
          },
        },
      });
    }

    ProjectVersionUtil.addProjectEntityIdFilter(
      query,
      this.projectVersionCardService.projectVersion,
      this.projectCardService.project.id,
    );

    return query;
  }
}
