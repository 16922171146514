import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnInit,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { AppService } from 'src/app/core/app.service';
import { NavigationService } from 'src/app/core/navigation.service';
import { MetaEntity } from 'src/app/shared/models/entities/settings/metamodel.model';
import { LocalString } from 'src/app/shared/models/enums/language.enum';

@Component({
  selector: 'tmt-metamodel-card',
  templateUrl: './metamodel-card.component.html',
  styleUrl: './metamodel-card.component.scss',
  standalone: false,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetamodelCardComponent implements OnInit {
  @Input() entityId: string;
  public entity: MetaEntity;

  constructor(
    private appService: AppService,
    private navigationService: NavigationService,
    private title: Title,
    private el: ElementRef<HTMLElement>,
    private actionPanelService: ActionPanelService,
  ) {}

  public ngOnInit(): void {
    this.actionPanelService.hideReloader();
    this.el.nativeElement.scrollIntoView();
    this.entity = this.appService.session.configuration.metamodel.entities.find(
      (entity) => entity.name === this.entityId,
    );
    this.title.setTitle(this.entity.name);
    this.navigationService.addRouteSegment({
      id: this.entity.name,
      title: this.entity.name,
    });
  }

  /**
   * Joins array of strings into string separated by ', '
   *
   * @param strings array of strings.
   * @returns joined string.
   */
  public joinString(strings: string[]): string {
    return strings.join(', ');
  }

  /**
   * Gets formatted local string.
   *
   * @param localStrings array of LocalString objects.
   * @returns formatted string value.
   */
  public getFormattedLocalStrings(localStrings: LocalString[]): string {
    return localStrings.map((x) => `${x.language}: ${x.value}`).join(', ');
  }
}
