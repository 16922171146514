export const FILE_EXTENSION: ReadonlyMap<string, string> = new Map([
  ['png', 'bi bi-filetype-png'],
  ['jpg', 'bi bi-filetype-jpg'],
  ['jpeg', 'bi bi-filetype-jpg'],
  ['svg', 'bi bi-filetype-svg'],
  ['css', 'bi bi-filetype-css'],
  ['csv', 'bi bi-filetype-csv'],
  ['doc', 'bi bi-filetype-docx'],
  ['docx', 'bi bi-filetype-docx'],
  ['otf', 'bi bi-filetype-otf'],
  ['exe', 'bi bi-filetype-exe'],
  ['gif', 'bi bi-filetype-gif'],
  ['html', 'bi bi-filetype-html'],
  ['js', 'bi bi-filetype-js'],
  ['json', 'bi bi-filetype-json'],
  ['mov', 'bi bi-filetype-mov'],
  ['mp3', 'bi bi-filetype-mp3'],
  ['mp4', 'bi bi-filetype-mp4'],
  ['pdf', 'bi bi-filetype-pdf'],
  ['ppt', 'bi bi-filetype-ppt'],
  ['pptx', 'bi bi-filetype-pptx'],
  ['psd', 'bi bi-filetype-psd'],
  ['tsx', 'bi bi-filetype-tsx'],
  ['ttf', 'bi bi-filetype-ttf'],
  ['txt', 'bi bi-filetype-txt'],
  ['xls', 'bi bi-filetype-xls'],
  ['xlsx', 'bi bi-filetype-xlsx'],
  ['xml', 'bi bi-filetype-xml'],
  ['webm', 'bi bi-file-earmark-play'],
  ['ogg', 'bi bi-file-earmark-play'],
  ['7z', 'bi bi-file-earmark-zip'],
  ['rar', 'bi bi-file-earmark-zip'],
  ['zip', 'bi bi-file-earmark-zip'],
  ['default', 'bi bi-file-earmark'],
  ['webp', 'bi bi-file-image'],
]);
