import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { GridColumn } from 'src/app/shared-features/grid/models/grid-column.interface';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ProjectTeamService } from 'src/app/shared/services/project-team.service';
import { Subject } from 'rxjs';
import { GridComponentCell } from 'src/app/shared-features/grid/models/grid-component-cell.interface';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'wp-members-readonly-cell',
  template: `<div class="trim" [title]="fullNames">
    {{ shortNames }}
  </div>`,
  styles: [
    `
      div {
        padding-top: 6px;
        padding-bottom: 6px;
        padding-left: 12px;
        padding-right: 12px;
      }
    `,
  ],
  standalone: false,
})
export class MembersReadonlyCellComponent implements GridComponentCell, OnInit {
  @Input() column: GridColumn;
  @Input() formGroup: UntypedFormGroup;
  @Input() initialValue: unknown;

  public fullNames = '';
  public shortNames = '';

  public get control(): UntypedFormControl {
    return <UntypedFormControl>this.formGroup.controls[this.column.name];
  }

  constructor(
    private projectTeamService: ProjectTeamService,
    public gridService: GridService,
    public cdr: ChangeDetectorRef,
  ) {
    this.gridService.detectChanges$.pipe(takeUntilDestroyed()).subscribe(() => {
      this.calculateViewNames();
    });
  }

  public ngOnInit(): void {
    this.calculateViewNames();
  }

  private calculateViewNames(): void {
    this.fullNames = this.projectTeamService.getAssignmentsString(
      this.control.value,
    );
    this.shortNames = this.projectTeamService.getAssignmentsString(
      this.control.value,
      null,
      this.projectTeamService.maxAssignmentShortNameLength,
    );

    this.cdr.markForCheck();
  }
}
