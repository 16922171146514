import {
  Component,
  forwardRef,
  ChangeDetectionStrategy,
  input,
  inject,
  DestroyRef,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Observable } from 'rxjs';

import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[tmtTimesheetAnalyticBox]',
  templateUrl: './timesheet-analytic-box.component.html',
  styleUrls: ['./timesheet-analytic-box.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimesheetAnalyticBoxComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class TimesheetAnalyticBoxComponent implements ControlValueAccessor {
  public values = input.required<Observable<NamedEntity[]>>();
  public allowed = input<boolean>(true);
  public placeholder = input<string>();

  public row = signal<NamedEntity>(null);
  public rows = signal<readonly NamedEntity[]>([]);
  public areaExpanded = signal<boolean>(false);
  public loading = signal<boolean>(false);
  public readonly: boolean;
  public propagateChange = (_: any) => null;
  public registerOnTouched = (fn: any) => null;

  private readonly destroyRef = inject(DestroyRef);

  public writeValue(value: NamedEntity): void {
    this.row.set(value);
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.readonly = isDisabled;
  }

  /** Opens select area. */
  public openArea(): void {
    if (this.readonly) {
      return;
    }

    this.areaExpanded.update((v) => !v);
    this.loading.set(true);

    this.values()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((activities) => {
        this.rows.set(activities);
        this.loading.set(false);
      });
  }

  /** Close area. */
  public cancel(): void {
    this.areaExpanded.set(false);
  }

  /**
   * Selects row.
   *
   * @param value selected value.
   */
  public clickRow(value: NamedEntity): void {
    this.row.set(value);
    this.propagateChange(value);
    this.cancel();
  }
}
