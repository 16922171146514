import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
  inject,
} from '@angular/core';
import _ from 'lodash';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { MetaEntity } from 'src/app/shared/models/entities/settings/metamodel.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AppService } from 'src/app/core/app.service';
import {
  GridOptions,
  SelectionType,
} from 'src/app/shared-features/grid/models/grid-options.model';
import { LIST, VIEW_NAME } from 'src/app/shared/tokens';
import { METAENTITY_LIST } from 'src/app/shared/lists/metamodel.list';
import { ListService } from 'src/app/shared/services/list.service';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { FormArray, FormBuilder } from '@angular/forms';
import { MetamodelListFilterService } from 'src/app/settings-app/metamodel/list/filter/metamodel-list-filter.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { StateService } from '@uirouter/core';
import { LocalString } from 'src/app/shared/models/enums/language.enum';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Order } from 'src/app/shared/models/inner/order';
import { Constants } from 'src/app/shared/globals/constants';

@Component({
  selector: 'tmt-metamodel-list',
  templateUrl: './metamodel-list.component.html',
  styleUrl: './metamodel-list.component.scss',
  providers: [
    { provide: FilterService, useClass: MetamodelListFilterService },
    { provide: VIEW_NAME, useValue: 'default' },
    { provide: LIST, useValue: METAENTITY_LIST },
    GridService,
    ListService,
  ],
  standalone: false,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetamodelListComponent implements OnInit {
  public entitiesLines: FormArray = this.fb.array([]);
  public gridOptions: GridOptions = {
    css: 'root',
    clientTotals: true,
    sorting: true,
    selectionType: SelectionType.row,
    view: this.listService.getGridView(),
    commands: [
      {
        name: 'enter',
        handlerFn: () => {
          if (this.actionPanelService.action('card')?.isShown) {
            this.actionPanelService.action('card').execute();
          }
        },
        keyCode: ['Enter', 'NumpadEnter'],
      },
    ],
  };

  private destroyRef = inject(DestroyRef);
  metaEntities: MetaEntity[];

  constructor(
    private filterService: FilterService,
    private appService: AppService,
    private listService: ListService,
    private fb: FormBuilder,
    private actionPanelService: ActionPanelService,
    private stateService: StateService,
    private gridService: GridService,
    private title: Title,
    private translateService: TranslateService,
  ) {
    this.gridService.setOrder(this.listService.getGridView().order);
    this.gridService.order$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((o: Order) => this.orderChanged(o));
  }

  public ngOnInit(): void {
    this.metaEntities = _.sortBy(
      this.appService.session.configuration.metamodel.entities,
      (x) => x.name,
    );

    this.actionPanelService.hideReloader();
    this.load(this.gridService.order);
    this.title.setTitle(
      this.translateService.instant(
        'components.metamodelListComponent.groups.title',
      ),
    );

    this.actionPanelService.set([
      this.actionPanelService.getDefaultAction('card', {
        handler: () =>
          this.stateService.go('settings.metamodelCard', {
            entityId: this.gridService.selectedGroupValue.id,
          }),
      }),
    ]);

    this.gridService.selectedGroup$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((row: any) => {
        this.actionPanelService.action('card').isShown = !!row;
      });

    this.filterService.values$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.load(this.gridService.order));
  }

  public getFormattedLocalStrings(localStrings: LocalString[]): string {
    return localStrings.map((x) => `${x.language}: ${x.value}`).join(', ');
  }

  /**
   * Loads data.
   *
   * @param order grid order by name.
   */
  private load(order: Order): void {
    const term = this.filterService.values.text?.toLowerCase();

    if (term) {
      const entities = this.metaEntities.filter(
        (x) =>
          x.name.toLowerCase().indexOf(term) > -1 ||
          x.displayNames.some((x) => x.value.toLowerCase().indexOf(term) > -1),
      );
      this.initEntitiesFormArray(entities, order);
      return;
    }

    this.initEntitiesFormArray(this.metaEntities, order);
  }

  /**
   * Inits grid form array.
   *
   * @param entities meta entities list.
   * @param order grid order by name.
   */
  private initEntitiesFormArray(entities: MetaEntity[], order: Order): void {
    this.entitiesLines.clear();
    entities.forEach((entity) => {
      this.entitiesLines.push(
        this.fb.group({
          id: entity.name,
          name: entity.name,
          displayName: this.getFormattedLocalStrings(entity.displayNames),
          customizable: entity.customizable,
          usedInBoards: entity.usedInBoards,
          usedInLogging: entity.usedInLogging,
          hasLifecycle: entity.hasLifecycle,
          hierarchyProperty: entity.hierarchyProperty || '',
        }),
      );
    });
    this.entitiesLines.patchValue(
      _.orderBy(
        this.entitiesLines.getRawValue(),
        order.column,
        order.reverse ? Constants.sortOrder.desc : Constants.sortOrder.asc,
      ),
    );
  }

  /**
   * Updates the order of the user view and reloads the list.
   *
   * @param order The new order to be applied.
   */
  public orderChanged(order: Order): void {
    const userView = this.listService.getUserView();
    userView.order = order;
    this.listService.saveUserView(userView);
    this.load(order);
  }
}
