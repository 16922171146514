import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
  signal,
  inject,
  model,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NgTemplateOutlet } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { UIRouterModule } from '@uirouter/angular';
import { TranslateModule } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  catchError,
  firstValueFrom,
  Observable,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { DateTime } from 'luxon';

import { AppService } from 'src/app/core/app.service';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { NavigationService } from 'src/app/core/navigation.service';

import { RouteMode } from 'src/app/shared/models/inner/route-mode.enum';
import { Guid } from 'src/app/shared/helpers/guid';
import { User } from 'src/app/shared/models/entities/settings/user.model';
import {
  KeysWithName,
  NamedEntity,
} from 'src/app/shared/models/entities/named-entity.model';
import { Exception } from 'src/app/shared/models/exception';
import { TimeAllocation } from 'src/app/shared/models/entities/base/timesheet.model';
import { SharedModule } from 'src/app/shared/shared.module';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';
import { MetaEntityBaseProperty } from 'src/app/shared/models/entities/settings/metamodel.model';

import { WorkLogService } from '../work-log.service';

@Component({
  imports: [NgTemplateOutlet, TranslateModule, SharedModule, UIRouterModule],
  selector: 'tmt-work-log-card',
  styleUrl: './work-log-card.component.scss',
  templateUrl: './work-log-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkLogCardComponent implements OnInit {
  public timeEntry = model<Partial<TimeAllocation> | null>(null);
  public readonly = model<boolean>(false);
  public issueNameIsLink = model<boolean>(false);
  public issue = model<NamedEntity>();

  public templateId: string;
  public isSaving = signal<boolean>(false);
  public isLoading = signal<boolean>(true);
  public availableFields = signal<KeysWithName<TimeAllocation>[]>([]);
  public availableCustomFields = signal<MetaEntityBaseProperty[]>([]);
  public form: UntypedFormGroup = this.fb.group({
    id: Guid.generate(),
    user: [null, Validators.required],
    date: DateTime.now().toISODate(),
    hours: [null, [Validators.required, Validators.min(0.01)]],
    project: null,
    projectTask: null,
    projectTariff: null,
    projectCostCenter: null,
    activity: null,
    role: null,
    billCode: null,
    description: null,
    issueId: null,
  });
  public readonly routeMode = RouteMode;

  private readonly destroyRef = inject(DestroyRef);

  public get formValues(): any {
    return this.form.getRawValue();
  }

  constructor(
    public workLogService: WorkLogService,
    public navigationService: NavigationService,
    private appService: AppService,
    private dataService: DataService,
    private customFieldService: CustomFieldService,
    private notificationService: NotificationService,
    private activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
  ) {}

  public async ngOnInit(): Promise<void> {
    this.customFieldService.enrichFormGroup(this.form, 'TimeAllocation');
    const userControl = this.form.get('user');

    if (this.timeEntry()) {
      this.templateId = this.timeEntry().template?.id;
      this.issue.set(this.timeEntry().issue);
      this.form.patchValue({
        issueId: this.timeEntry().issueId,
      });

      const result = await firstValueFrom(
        this.workLogService.timeEntryCollection
          .entity(this.timeEntry().id)
          .get<TimeAllocation>(this.workLogService.timeEntryQuery)
          .pipe(
            catchError((error: Exception) => {
              this.notificationService.error(error.message);
              return of(null);
            }),
          ),
      );

      const [availableFields, availableCustomFields] =
        this.workLogService.getAvailableFields(result.template);
      this.availableFields.set(availableFields);
      this.availableCustomFields.set(availableCustomFields);

      if (result) {
        this.templateId = result.template?.id;
        this.form.patchValue(result);
      } else {
        this.form.disable();
      }
    } else {
      this.form.patchValue({
        user: this.appService.session.user,
        project: this.workLogService.issueProject,
        projectTask: this.workLogService.issueProjectTask,
      });

      const user = await firstValueFrom(
        this.getUser(this.appService.session.user.id),
      );

      this.templateId = user.timeSheetTemplate?.id;

      await this.workLogService.setDefaultValues(
        this.appService.session.user.id,
        this.formValues.project?.id,
        this.form,
        this.availableFields(),
      );
    }

    if (this.readonly() || this.workLogService.readonly) {
      this.form.disable();
    }

    userControl.disable();
    userControl.valueChanges
      .pipe(
        tap(() => {
          this.availableFields.set([]);
          this.availableCustomFields.set([]);
        }),
        switchMap((value) => this.getUser(value.id)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((user) => {
        this.workLogService.dependOnUserFields.forEach((key) => {
          this.form.get(key).setValue(null, { emitEvent: null });
        });
        this.workLogService.setDefaultValues(
          user.id,
          this.formValues.project?.id,
          this.form,
          this.availableFields(),
        );
      });

    this.toggleProjectTaskControl(this.formValues['project']);
    this.form
      .get('project')
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        this.workLogService.dependOnProjectFields.forEach((key) => {
          this.form.get(key).setValue(null, { emitEvent: null });
        });

        this.workLogService.setDefaultValues(
          this.formValues.user?.id,
          value?.id,
          this.form,
          this.availableFields(),
        );

        this.toggleProjectTaskControl(value);
      });

    this.isLoading.set(false);
  }

  /** Accepts of modal window.*/
  public ok(): void {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notificationService.warningLocal(
        'shared.messages.requiredFieldsError',
      );

      return;
    }

    this.isSaving.set(true);

    if (this.timeEntry()) {
      this.updateTimeEntry();
    } else {
      this.createTimeEntry();
    }
  }

  /** Closes modal window without save. */
  public cancel(): void {
    this.activeModal.dismiss();
  }

  /** Deletes the time entry, then closes modal. */
  public delete(): void {
    this.workLogService.deleteTimeEntry(this.timeEntry(), () =>
      this.activeModal.close(null),
    );
  }

  private toggleProjectTaskControl(value: any | null): void {
    if (!value || this.readonly() || this.workLogService.readonly) {
      this.form.get('projectTask').disable({ emitEvent: null });
      return;
    }

    this.form.get('projectTask').enable({ emitEvent: null });
  }

  private updateTimeEntry(): void {
    const preparedEntry = this.workLogService.prepareData(this.formValues);

    this.workLogService.timeEntryCollection
      .entity(this.form.value.id)
      .patch(preparedEntry)
      .subscribe({
        next: () => {
          this.notificationService.successLocal(
            'components.workLogCardComponent.messages.updated',
          );
          this.activeModal.close(Object.assign(preparedEntry, this.formValues));
        },
        error: (error: Exception) => {
          this.notificationService.error(error.message);
          this.isSaving.set(false);
        },
      });
  }

  private createTimeEntry(): void {
    this.workLogService.timeEntryCollection
      .insert(this.workLogService.prepareData(this.formValues))
      .subscribe({
        next: (timeEntry) => {
          this.notificationService.successLocal(
            'components.workLogCardComponent.messages.created',
          );
          this.activeModal.close(timeEntry);
        },
        error: (error: Exception) => {
          this.notificationService.error(error.message);
          this.isSaving.set(false);
        },
      });
  }

  private getUser(userId: string): Observable<Partial<User>> {
    return this.dataService
      .collection('Users')
      .entity(userId)
      .get<Partial<User>>({
        select: ['id', 'name'],
        expand: {
          timeSheetTemplate: {
            select: ['*'],
            expand: {
              customFields: {
                select: ['*'],
              },
            },
          },
        },
      })
      .pipe(
        tap((value) => {
          const [availableFields, availableCustomFields] =
            this.workLogService.getAvailableFields(value.timeSheetTemplate);
          this.availableFields.set(availableFields);
          this.availableCustomFields.set(availableCustomFields);
        }),
      );
  }
}
