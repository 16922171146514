import {
  Component,
  ChangeDetectionStrategy,
  ElementRef,
  viewChild,
  effect,
  input,
} from '@angular/core';
import { naturalSort } from 'src/app/shared/helpers/natural-sort.helper';
import * as echarts from 'echarts';
import { ECharts } from 'echarts';
import { ChartData } from 'src/app/analytics/dashboards/dashboard/widget/models/widget-stacked-column.model';

@Component({
  selector: 'tmt-funnel-chart',
  template: '<div #funnelCanvas></div>',
  standalone: false,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetFunnelChartComponent {
  public funnelCanvas = viewChild<ElementRef>('funnelCanvas');
  public sourceData = input<(string & number)[][]>();

  private labels: string[] = [];
  private datasets: number[] = [];
  private chartData: ChartData[] = [];
  private chart: ECharts;

  constructor() {
    effect(() => {
      if (!this.sourceData()?.length) return;
      this.processData(this.sourceData());
      this.createFunnelChart();
    });
  }

  /**
   * Resizes the chart.
   *
   * @param width The new width of the chart.
   * @param height The new height of the chart.
   */
  public resize(width: number, height: number): void {
    this.chart?.resize({ width, height });
  }

  private createFunnelChart(): void {
    if (this.chart) {
      echarts.dispose(this.chart);
    }

    this.chart = echarts.init(this.funnelCanvas()?.nativeElement);

    this.chart.setOption({
      tooltip: {
        trigger: 'item',
      },
      series: [
        {
          type: 'funnel',
          data: this.chartData,
          label: {
            position: 'inside',
            formatter: '{b}: {d}%',
          },
          emphasis: {
            label: {
              fontSize: 20,
            },
          },
        },
      ],
    });
  }

  /**
   * Processes source data to prepare labels and datasets for the chart.
   *
   * @param sourceData Source data to process.
   */
  private processData(sourceData: (string & number)[][]): void {
    this.labels = [];
    this.datasets = [];
    let totalLength = 0;

    // Checks if there is a second array in sourceData to display empty chart.
    if (!sourceData[1]?.length) {
      return;
    }

    const modifiedData = sourceData.map((elem) => ({
      label: elem[0],
      value: elem[1],
    }));
    const sortedData = modifiedData.sort(naturalSort('label'));

    sourceData.forEach((data) => {
      totalLength += data[1];
    });

    sortedData.forEach((data) => {
      if (data.value === 0 || data.label === null) {
        return;
      }
      this.labels.push(data.label);
      this.datasets.push(Math.round((data.value / totalLength) * 100) / 100);
    });

    this.labels = this.labels.sort(naturalSort(this.labels[0]));

    if (this.chart) {
      this.chartData.length = 0;
    }

    this.datasets.forEach((el, index) => {
      this.chartData.push({
        value: Math.round(el * 100) / 100,
        name: this.labels[index],
      });
    });
  }
}
