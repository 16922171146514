import {
  ChangeDetectionStrategy,
  Component,
  effect,
  ElementRef,
  input,
  viewChild,
} from '@angular/core';
// eslint-disable-next-line @typescript-eslint/naming-convention
import Chart from 'chart.js/auto';
// eslint-disable-next-line @typescript-eslint/naming-convention
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { WidgetConfig } from 'src/app/analytics/dashboards/shared/widget-config.model';
import {
  ChartDataset,
  StackedColumnNormalData,
} from 'src/app/analytics/dashboards/dashboard/widget/models/widget-stacked-column.model';
import {
  SortedData,
  SortProperty,
} from 'src/app/analytics/dashboards/dashboard/widget/models/widget-stacked-column.model';
import { ReportFieldType } from 'src/app/analytics/shared/models/report-field-type.enum';
import { Constants } from 'src/app/shared/globals/constants';
import { WidgetDataService } from 'src/app/analytics/dashboards/dashboard/widget/widget-data.service';
import { ViewValueField } from 'src/app/analytics/shared/models/view-settings/view-value-field.model';
import { SourceData } from 'src/app/analytics/shared/models/source-data.model';
import { ReportSourceDescription } from 'src/app/analytics/shared/models/source-description/report-source-description.model';
import { PivotRenderService } from 'src/app/analytics/shared/pivot-table/pivot-render.service';
import { PivotDataService } from 'src/app/analytics/shared/pivot-table/pivot-data.service';
import _ from 'lodash';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'tmt-histogram-chart',
  templateUrl: './widget-histogram-chart.component.html',
  styleUrls: ['./widget-histogram-chart.component.scss'],
  standalone: false,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [WidgetDataService, PivotRenderService, PivotDataService],
})
export class HistogramChartComponent {
  public widget = input<WidgetConfig>();
  public sourceData = input<SourceData>();
  public sourceDescription = input<ReportSourceDescription>();
  public barCanvas = viewChild<ElementRef>('barCanvas');
  public labels: string[] = [];
  public datasets: ChartDataset[] = [];
  public fieldNames: string[];

  private chart: Chart;

  constructor(
    private widgetDataService: WidgetDataService,
    private pivotRenderService: PivotRenderService,
  ) {
    effect(() => {
      if (!this.sourceData()?.data.length) return;
      const data = this.sourceData();
      this.processData(data);
      this.barChartMethod();
    });
  }

  /**
   * Resizes the chart.
   *
   * @param width The new width of the chart.
   * @param height The new height of the chart.
   */
  public resize(width: number, height: number): void {
    if (this.chart) this.chart.resize(width, height);
  }

  /** Creates and configures the bar chart instance. */
  private barChartMethod(): void {
    this.chart?.destroy();
    if (environment.production) return;

    this.chart = new Chart(this.barCanvas()?.nativeElement, {
      plugins: [ChartDataLabels],
      type: 'bar',
      data: {
        labels: this.labels,
        datasets: this.datasets,
      },
      options: {
        plugins: {
          tooltip: {
            enabled: true,
          },
          legend: {
            display: this.widget().properties.hasLegend,
            position: 'bottom',
          },
          datalabels: {
            display: this.widget().properties.hasDataLabels,
            padding: 4,
          },
        },
        animation: {
          duration: 500,
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
            beginAtZero: true,
          },
        },
      },
    });
  }

  /**
   * Processes raw data to prepare labels and datasets for the chart.
   *
   * @param data Raw data to process.
   */
  private processData(dataset: SourceData): void {
    const { data, fieldNames } = dataset;
    this.fieldNames = fieldNames;

    // Prepare data .
    const valueFields = this.getValueFields();

    // Normalized data.
    const normalData = [];
    const hasLegend = this.widget().viewSettings.legendField != null;
    const hasCategory = this.widget().viewSettings.categoryField != null;
    const hasValues = valueFields && valueFields.length;

    if (data.length === 0 || (!hasLegend && !hasValues)) {
      return;
    }

    const legendFieldIndex = hasLegend
      ? this.widgetDataService.getFieldIndex(
          this.widget().viewSettings.legendField.name,
          this.fieldNames,
        )
      : null;
    const valueFieldIndex = hasValues
      ? this.widgetDataService.getFieldIndex(
          valueFields[0].name,
          this.fieldNames,
        )
      : null;
    const categoryFieldIndex = hasCategory
      ? this.widgetDataService.getFieldIndex(
          this.widget().viewSettings.categoryField.name,
          this.fieldNames,
        )
      : null;

    // Transformation into a normalized array of objects.
    data.forEach((row) => {
      const addNormRow = (
        legendName: string | number,
        value: any,
        valueField: ViewValueField,
      ) => {
        const normRow: {
          category?: string | number;
          legend?: string | number;
          value?: number;
        } = {};

        if (!hasCategory) {
          normRow.category = null;
        } else {
          normRow.category = row[categoryFieldIndex];
        }

        normRow.legend = this.getSeriesName(legendName, hasLegend);
        if (valueField) {
          const valueFieldType = this.sourceDescription().allFields.find(
            (f) => f.name === valueField.name,
          ).type;
          if (valueFieldType === ReportFieldType.Percent) {
            value *= 100;
          }
        }

        normRow.value = value;
        normalData.push(normRow);
      };

      // Multiple values (meaning there’s no legend).
      if (valueFields && valueFields.length > 1) {
        valueFields.forEach((valueField) => {
          const valueIndex = this.widgetDataService.getFieldIndex(
            valueField.name,
            this.fieldNames,
          );
          addNormRow(valueField.name, row[valueIndex], valueField);
        });

        return;
      }

      // There is a legend (meaning there is only one value).
      if (hasLegend) {
        const value = hasValues ? row[valueFieldIndex] : 0;
        addNormRow(row[legendFieldIndex], value, valueFields[0]);
        return;
      }

      // And an option where there is no legend, but according to the earlier condition, there is a value.
      addNormRow(valueFields[0].name, row[valueFieldIndex], valueFields[0]);
    });

    const sortedData = this.sortData(normalData);

    this.datasets = this.widgetDataService.getChartDataset(
      sortedData as SortedData[],
    );
  }

  /**
   * Sorts the provided data based on the widget's configuration.
   *
   * @param data Data to sort.
   * @returns Sorted data.
   */
  private sortData(normalData: StackedColumnNormalData[]) {
    const sortProperty =
      this.widget().properties.sortBy === 'Value'
        ? SortProperty.value
        : SortProperty.category;
    const sortOrder: 'asc' | 'desc' = this.widget().properties.sortReverse
      ? Constants.sortOrder.asc
      : Constants.sortOrder.desc;
    const map = new Map<string | number, SortedData>();

    normalData.forEach(({ category, legend, value }) => {
      if (!map.has(category)) {
        map.set(category, { category, value: 0, detailed: {} });
      }
      const item = map.get(category);
      item.value += value;
      item.detailed[legend] = value;
    });

    let sorted = _.orderBy([...map.values()], [sortProperty], [sortOrder]);
    this.labels = sorted.map(
      (item) => item.category?.toString() || this.widgetDataService.emptyValue,
    );

    if (this.widget().properties.showTop) {
      sorted = sorted.slice(0, this.widget().properties.showTop);
    }

    return sorted;
  }

  /**
   * Retrieves the value fields from the widget's view settings.
   *
   * @returns An array of value fields based on the widget's view settings.
   */
  private getValueFields(): ViewValueField[] {
    if (
      this.widget().viewSettings.valueFields &&
      this.widget().viewSettings.valueFields.length
    ) {
      return _.cloneDeep(this.widget().viewSettings.valueFields);
    }

    if (this.widget().viewSettings.valueField) {
      return [this.widget().viewSettings.valueField];
    }
    return [];
  }

  /**
   * Retrieves the title from the set of value fields or source description.
   *
   * @param name Name or identifier of the field to retrieve the title for.
   * @returns Title of the specified field.
   */
  private getValueFieldTitle(name: string | number): string {
    const valueFields = this.getValueFields();
    const field = valueFields.find((f) => f.name === name);
    return field.customTitle
      ? field.customTitle
      : this.sourceDescription().allFields.find((f) => f.name === name).title;
  }

  /**
   * Constructs the series name based on the legend configuration.
   *
   * @param seriesName Name or identifier of the series.
   * @param hasLegend Determines if legend is displayed.
   * @returns Formatted series name.
   */
  private getSeriesName(
    seriesName: string | number,
    hasLegend: boolean,
  ): string {
    if (!seriesName) {
      return this.widgetDataService.emptyValue;
    }

    if (!hasLegend) {
      return this.getValueFieldTitle(seriesName);
    }

    const sourceColumn = this.sourceDescription().allFields.find(
      (f) => f.name === this.widget().viewSettings.legendField.name,
    );
    return this.pivotRenderService.getFormattedValue(
      sourceColumn.type,
      seriesName,
      this.widgetDataService.emptyValue,
    );
  }
}
