import {
  GridColumnType,
  GridCurrencyColumn,
} from 'src/app/shared-features/grid/models/grid-column.interface';
import { List, LoadingStrategy } from '../models/inner/list';
import { TotalType } from '../models/inner/total-type';

import {
  getFilterByActiveWorkflowInstances,
  getFilterByActiveWorkflowTasks,
} from 'src/app/shared/lists/navigation.helpers';
import { ViewColumn } from 'src/app/shared/models/inner/view';
import {
  DATE_COLUMN_WIDTH,
  DATE_TIME_COLUMN_WIDTH,
  getCodedEntityDataColumns,
  getCodedEntityGridColumns,
} from 'src/app/shared/helpers/list.helper';

const getViewColumns = (isMonitoring = false): ViewColumn[] => [
  {
    column: 'name',
    visibleByDefault: true,
    width: 3,
    totalByDefault: TotalType.Count,
  },
  {
    column: 'code',
    visibleByDefault: true,
    width: 1,
  },
  {
    column: 'manager',
    visibleByDefault: !isMonitoring,
    width: 1,
  },
  {
    column: 'description',
    visibleByDefault: false,
    width: 3,
  },
  {
    column: 'organization',
    visibleByDefault: !isMonitoring,
    width: 2,
  },
  {
    column: 'legalEntity',
    visibleByDefault: false,
    width: 2,
  },
  {
    column: 'program',
    visibleByDefault: false,
    width: 2,
  },
  {
    column: 'sourceProject',
    visibleByDefault: false,
    width: 2,
  },
  {
    column: 'state',
    visibleByDefault: true,
    width: 1,
  },
  {
    column: 'startDate',
    visibleByDefault: !isMonitoring,
    width: DATE_COLUMN_WIDTH,
  },
  {
    column: 'endDate',
    visibleByDefault: !isMonitoring,
    width: DATE_COLUMN_WIDTH,
  },
  {
    column: 'billingType',
    visibleByDefault: false,
    width: 1,
  },
  {
    column: 'created',
    visibleByDefault: false,
    width: DATE_TIME_COLUMN_WIDTH,
  },
  {
    column: 'createdBy',
    visibleByDefault: false,
    width: 1,
  },
  {
    column: 'modified',
    visibleByDefault: false,
    width: DATE_TIME_COLUMN_WIDTH,
  },
  {
    column: 'modifiedBy',
    visibleByDefault: false,
    width: 1,
  },
  {
    column: 'externalUrl',
    visibleByDefault: false,
    width: 1,
  },
  {
    column: 'currency',
    visibleByDefault: false,
    width: 1,
  },
  {
    column: 'estimatedHours',
    visibleByDefault: false,
    width: 1,
    totalByDefault: TotalType.Sum,
  },
  {
    column: 'estimatedHoursOverPeriod',
    visibleByDefault: isMonitoring,
    width: 1,
    totalByDefault: TotalType.Sum,
  },
  {
    column: 'plannedHours',
    visibleByDefault: false,
    width: 1,
    totalByDefault: TotalType.Sum,
  },
  {
    column: 'plannedHoursOverPeriod',
    visibleByDefault: isMonitoring,
    width: 1,
    totalByDefault: TotalType.Sum,
  },
  {
    column: 'actualHours',
    visibleByDefault: false,
    width: 1,
    totalByDefault: TotalType.Sum,
  },
  {
    column: 'actualHoursOverPeriod',
    visibleByDefault: isMonitoring,
    width: 1,
    totalByDefault: TotalType.Sum,
  },

  {
    column: 'plannedRevenue',
    visibleByDefault: false,
    width: 1,
  },
  {
    column: 'plannedRevenueBC',
    visibleByDefault: false,
    width: 1,
    totalByDefault: TotalType.Sum,
  },
  {
    column: 'plannedRevenueOverPeriod',
    visibleByDefault: false,
    width: 1,
  },
  {
    column: 'plannedRevenueOverPeriodBC',
    visibleByDefault: false,
    totalByDefault: TotalType.Sum,
    width: 1,
  },
  {
    column: 'actualRevenue',
    visibleByDefault: false,
    width: 1,
  },
  {
    column: 'actualRevenueBC',
    visibleByDefault: false,
    width: 1,
    totalByDefault: TotalType.Sum,
  },
  {
    column: 'actualRevenueOverPeriod',
    visibleByDefault: false,
    width: 1,
  },
  {
    column: 'actualRevenueOverPeriodBC',
    visibleByDefault: false,
    width: 1,
    totalByDefault: TotalType.Sum,
  },
  {
    column: 'plannedExpenses',
    visibleByDefault: false,
    width: 1,
  },
  {
    column: 'plannedExpensesBC',
    visibleByDefault: false,
    width: 1,
    totalByDefault: TotalType.Sum,
  },
  {
    column: 'plannedExpensesOverPeriod',
    visibleByDefault: false,
    width: 1,
  },
  {
    column: 'plannedExpensesOverPeriodBC',
    visibleByDefault: false,
    width: 1,
    totalByDefault: TotalType.Sum,
  },
  {
    column: 'actualExpenses',
    visibleByDefault: false,
    width: 1,
  },
  {
    column: 'actualExpensesBC',
    visibleByDefault: false,
    totalByDefault: TotalType.Sum,
    width: 1,
  },
  {
    column: 'actualExpensesOverPeriod',
    visibleByDefault: false,
    width: 1,
  },
  {
    column: 'actualExpensesOverPeriodBC',
    visibleByDefault: false,
    width: 1,
    totalByDefault: TotalType.Sum,
  },

  {
    column: 'actualCost',
    visibleByDefault: false,
    width: 1,
  },
  {
    column: 'actualCostBC',
    visibleByDefault: false,
    totalByDefault: TotalType.Sum,
    width: 1,
  },
  {
    column: 'actualCostOverPeriod',
    visibleByDefault: false,
    width: 1,
  },
  {
    column: 'actualCostOverPeriodBC',
    visibleByDefault: false,
    width: 1,
    totalByDefault: TotalType.Sum,
  },

  {
    column: 'plannedProfit',
    visibleByDefault: false,
    width: 1,
  },
  {
    column: 'plannedProfitBC',
    visibleByDefault: false,
    width: 1,
    totalByDefault: TotalType.Sum,
  },
  {
    column: 'plannedProfitOverPeriod',
    visibleByDefault: false,
    width: 1,
  },
  {
    column: 'plannedProfitOverPeriodBC',
    visibleByDefault: false,
    width: 1,
    totalByDefault: TotalType.Sum,
  },
  {
    column: 'actualProfit',
    visibleByDefault: false,
    width: 1,
  },
  {
    column: 'actualProfitBC',
    visibleByDefault: false,
    width: 1,
    totalByDefault: TotalType.Sum,
  },
  {
    column: 'actualProfitOverPeriod',
    visibleByDefault: false,
    width: 1,
  },
  {
    column: 'actualProfitOverPeriodBC',
    visibleByDefault: false,
    width: 1,
    totalByDefault: TotalType.Sum,
  },

  {
    column: 'plannedProfitability',
    visibleByDefault: false,
    width: 1,
  },
  {
    column: 'plannedProfitabilityBC',
    visibleByDefault: false,
    width: 1,
  },
  {
    column: 'plannedProfitabilityOverPeriod',
    visibleByDefault: false,
    width: 1,
  },
  {
    column: 'plannedProfitabilityOverPeriodBC',
    visibleByDefault: false,
    width: 1,
  },
  {
    column: 'actualProfitability',
    visibleByDefault: false,
    width: 1,
  },
  {
    column: 'actualProfitabilityBC',
    visibleByDefault: false,
    width: 1,
  },
  {
    column: 'actualProfitabilityOverPeriod',
    visibleByDefault: false,
    width: 1,
  },
  {
    column: 'actualProfitabilityOverPeriodBC',
    visibleByDefault: false,
    width: 1,
  },
  {
    column: 'isAutoPlanning',
    visibleByDefault: false,
    width: 1,
  },
];

export const PROJECT_LIST: List = {
  version: 8,
  name: 'projects',
  dataCollection: 'ExtendedProjects',
  customFieldEntityType: 'Project',
  customFieldPrefixForDataField: 'project.',
  views: [
    {
      name: 'my',
      columns: getViewColumns().filter((x) => x.column !== 'manager'),
      contextFilter: [
        {
          project: {
            or: [
              {
                managerId: { type: 'guid', value: '#user' },
              },
              {
                coManagers: {
                  any: {
                    userId: { type: 'guid', value: '#user' },
                  },
                },
              },
            ],
          },
        },
      ],
      order: {
        column: 'name',
        reverse: false,
      },
    },
    {
      name: 'active',
      columns: getViewColumns(),
      order: {
        column: 'name',
        reverse: false,
      },
      contextFilter: [
        {
          project: {
            isActive: true,
          },
        },
      ],
    },
    {
      name: 'monitoring',
      columns: getViewColumns(true),
      order: { column: 'name', reverse: false },
      contextFilter: [
        {
          project: {
            isActive: true,
          },
        },
      ],
    },
    {
      name: 'all',
      columns: getViewColumns(),
      order: {
        column: 'name',
        reverse: false,
      },
    },
    {
      name: 'program',
      columns: getViewColumns(),
      order: {
        column: 'name',
        reverse: false,
      },
    },
    {
      name: 'client',
      columns: getViewColumns(),
      order: {
        column: 'name',
        reverse: false,
      },
    },

    {
      name: 'with-my-workflow',
      order: {
        column: 'name',
        reverse: false,
      },
      contextFilter: [{ project: getFilterByActiveWorkflowInstances() }],
      columns: getViewColumns(),
    },

    {
      name: 'with-my-task',
      order: {
        column: 'name',
        reverse: false,
      },
      contextFilter: [{ project: getFilterByActiveWorkflowTasks() }],
      columns: getViewColumns(),
    },
  ],
  columns: [
    ...getCodedEntityGridColumns('project'),

    {
      name: 'description',
      type: GridColumnType.String,
      header: 'projects.projects.list.columns.description.header',
      hint: 'projects.projects.list.columns.description.hint',
    },
    {
      name: 'manager',
      type: GridColumnType.String,
      header: 'projects.projects.list.columns.manager.header',
      hint: 'projects.projects.list.columns.manager.hint',
    },
    {
      name: 'program',
      type: GridColumnType.String,
      header: 'projects.projects.list.columns.program.header',
      hint: 'projects.projects.list.columns.program.hint',
    },
    {
      name: 'sourceProject',
      type: GridColumnType.String,
      header: 'projects.projects.list.columns.sourceProject.header',
      hint: 'projects.projects.list.columns.sourceProject.hint',
    },
    {
      name: 'organization',
      type: GridColumnType.String,
      header: 'projects.projects.list.columns.client.header',
      hint: 'projects.projects.list.columns.client.hint',
    },
    {
      name: 'state',
      type: GridColumnType.State,
      header: 'projects.projects.list.columns.state.header',
      hint: 'projects.projects.list.columns.state.hint',
    },
    {
      name: 'startDate',
      type: GridColumnType.Date,
      fixedWidth: true,
      header: 'projects.projects.list.columns.startDate.header',
      hint: 'projects.projects.list.columns.startDate.hint',
    },
    {
      name: 'endDate',
      type: GridColumnType.Date,
      fixedWidth: true,
      header: 'projects.projects.list.columns.endDate.header',
      hint: 'projects.projects.list.columns.endDate.hint',
    },
    {
      name: 'billingType',
      type: GridColumnType.String,
      header: 'projects.projects.list.columns.billingType.header',
      hint: 'projects.projects.list.columns.billingType.hint',
    },
    {
      name: 'externalUrl',
      type: GridColumnType.String,
      header: 'projects.projects.list.columns.externalUrl.header',
      hint: 'projects.projects.list.columns.externalUrl.hint',
    },
    {
      name: 'currency',
      type: GridColumnType.Entity,
      header: 'projects.projects.list.columns.currency.header',
      hint: 'projects.projects.list.columns.currency.hint',
    },

    <GridCurrencyColumn>{
      name: 'plannedExpenses',
      header: 'projects.projects.list.columns.plannedExpenses.header',
      hint: 'projects.projects.list.columns.plannedExpenses.hint',
      type: GridColumnType.Currency,
      currencyCodeProperty: 'Currency.alpha3Code',
    },
    <GridCurrencyColumn>{
      name: 'plannedExpensesBC',
      header: 'projects.projects.list.columns.plannedExpensesBC.header',
      hint: 'projects.projects.list.columns.plannedExpensesBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },
    <GridCurrencyColumn>{
      name: 'plannedExpensesOverPeriod',
      header: 'projects.projects.list.columns.plannedExpensesOverPeriod.header',
      hint: 'projects.projects.list.columns.plannedExpensesOverPeriod.hint',
      type: GridColumnType.Currency,
      currencyCodeProperty: 'Currency.alpha3Code',
    },
    <GridCurrencyColumn>{
      name: 'plannedExpensesOverPeriodBC',
      header:
        'projects.projects.list.columns.plannedExpensesOverPeriodBC.header',
      hint: 'projects.projects.list.columns.plannedExpensesOverPeriodBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },
    <GridCurrencyColumn>{
      name: 'actualExpenses',
      header: 'projects.projects.list.columns.actualExpenses.header',
      hint: 'projects.projects.list.columns.actualExpenses.hint',
      type: GridColumnType.Currency,
      currencyCodeProperty: 'Currency.alpha3Code',
    },
    <GridCurrencyColumn>{
      name: 'actualExpensesBC',
      header: 'projects.projects.list.columns.actualExpensesBC.header',
      hint: 'projects.projects.list.columns.actualExpensesBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },
    <GridCurrencyColumn>{
      name: 'actualExpensesOverPeriod',
      header: 'projects.projects.list.columns.actualExpensesOverPeriod.header',
      hint: 'projects.projects.list.columns.actualExpensesOverPeriod.hint',
      type: GridColumnType.Currency,
      currencyCodeProperty: 'Currency.alpha3Code',
    },
    <GridCurrencyColumn>{
      name: 'actualExpensesOverPeriodBC',
      header:
        'projects.projects.list.columns.actualExpensesOverPeriodBC.header',
      hint: 'projects.projects.list.columns.actualExpensesOverPeriodBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },

    <GridCurrencyColumn>{
      name: 'actualCost',
      header: 'projects.projects.list.columns.actualCost.header',
      hint: 'projects.projects.list.columns.actualCost.hint',
      type: GridColumnType.Currency,
      currencyCodeProperty: 'Currency.alpha3Code',
    },
    <GridCurrencyColumn>{
      name: 'actualCostBC',
      header: 'projects.projects.list.columns.actualCostBC.header',
      hint: 'projects.projects.list.columns.actualCostBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },
    <GridCurrencyColumn>{
      name: 'actualCostOverPeriod',
      header: 'projects.projects.list.columns.actualCostOverPeriod.header',
      hint: 'projects.projects.list.columns.actualCostOverPeriod.hint',
      type: GridColumnType.Currency,
      currencyCodeProperty: 'Currency.alpha3Code',
    },
    <GridCurrencyColumn>{
      name: 'actualCostOverPeriodBC',
      header: 'projects.projects.list.columns.actualCostOverPeriodBC.header',
      hint: 'projects.projects.list.columns.actualCostOverPeriodBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },

    <GridCurrencyColumn>{
      name: 'plannedRevenue',
      header: 'projects.projects.list.columns.plannedRevenue.header',
      hint: 'projects.projects.list.columns.plannedRevenue.hint',
      type: GridColumnType.Currency,
      currencyCodeProperty: 'Currency.alpha3Code',
    },
    <GridCurrencyColumn>{
      name: 'plannedRevenueBC',
      header: 'projects.projects.list.columns.plannedRevenueBC.header',
      hint: 'projects.projects.list.columns.plannedRevenueBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },
    <GridCurrencyColumn>{
      name: 'plannedRevenueOverPeriod',
      header: 'projects.projects.list.columns.plannedRevenueOverPeriod.header',
      hint: 'projects.projects.list.columns.plannedRevenueOverPeriod.hint',
      type: GridColumnType.Currency,
      currencyCodeProperty: 'Currency.alpha3Code',
    },
    <GridCurrencyColumn>{
      name: 'plannedRevenueOverPeriodBC',
      header:
        'projects.projects.list.columns.plannedRevenueOverPeriodBC.header',
      hint: 'projects.projects.list.columns.plannedRevenueOverPeriodBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },
    <GridCurrencyColumn>{
      name: 'actualRevenue',
      header: 'projects.projects.list.columns.actualRevenue.header',
      hint: 'projects.projects.list.columns.actualRevenue.hint',
      type: GridColumnType.Currency,
      currencyCodeProperty: 'Currency.alpha3Code',
    },
    <GridCurrencyColumn>{
      name: 'actualRevenueBC',
      header: 'projects.projects.list.columns.actualRevenueBC.header',
      hint: 'projects.projects.list.columns.actualRevenueBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },
    <GridCurrencyColumn>{
      name: 'actualRevenueOverPeriod',
      header: 'projects.projects.list.columns.actualRevenueOverPeriod.header',
      hint: 'projects.projects.list.columns.actualRevenueOverPeriod.hint',
      type: GridColumnType.Currency,
      currencyCodeProperty: 'Currency.alpha3Code',
    },
    <GridCurrencyColumn>{
      name: 'actualRevenueOverPeriodBC',
      header: 'projects.projects.list.columns.actualRevenueOverPeriodBC.header',
      hint: 'projects.projects.list.columns.actualRevenueOverPeriodBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },
    <GridCurrencyColumn>{
      name: 'plannedProfit',
      header: 'projects.projects.list.columns.plannedProfit.header',
      hint: 'projects.projects.list.columns.plannedProfit.hint',
      type: GridColumnType.Currency,
      currencyCodeProperty: 'Currency.alpha3Code',
    },
    <GridCurrencyColumn>{
      name: 'plannedProfitBC',
      header: 'projects.projects.list.columns.plannedProfitBC.header',
      hint: 'projects.projects.list.columns.plannedProfitBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },
    <GridCurrencyColumn>{
      name: 'plannedProfitOverPeriod',
      header: 'projects.projects.list.columns.plannedProfitOverPeriod.header',
      hint: 'projects.projects.list.columns.plannedProfitOverPeriod.hint',
      type: GridColumnType.Currency,
      currencyCodeProperty: 'Currency.alpha3Code',
    },
    <GridCurrencyColumn>{
      name: 'plannedProfitOverPeriodBC',
      header: 'projects.projects.list.columns.plannedProfitOverPeriodBC.header',
      hint: 'projects.projects.list.columns.plannedProfitOverPeriodBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },
    <GridCurrencyColumn>{
      name: 'actualProfit',
      header: 'projects.projects.list.columns.actualProfit.header',
      hint: 'projects.projects.list.columns.actualProfit.hint',
      type: GridColumnType.Currency,
      currencyCodeProperty: 'Currency.alpha3Code',
    },
    <GridCurrencyColumn>{
      name: 'actualProfitBC',
      header: 'projects.projects.list.columns.actualProfitBC.header',
      hint: 'projects.projects.list.columns.actualProfitBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },
    <GridCurrencyColumn>{
      name: 'actualProfitOverPeriod',
      header: 'projects.projects.list.columns.actualProfitOverPeriod.header',
      hint: 'projects.projects.list.columns.actualProfitOverPeriod.hint',
      type: GridColumnType.Currency,
      currencyCodeProperty: 'Currency.alpha3Code',
    },
    <GridCurrencyColumn>{
      name: 'actualProfitOverPeriodBC',
      header: 'projects.projects.list.columns.actualProfitOverPeriodBC.header',
      hint: 'projects.projects.list.columns.actualProfitOverPeriodBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },
    <GridCurrencyColumn>{
      name: 'plannedProfitability',
      header: 'projects.projects.list.columns.plannedProfitability.header',
      hint: 'projects.projects.list.columns.plannedProfitability.hint',
      type: GridColumnType.Percent,
    },
    <GridCurrencyColumn>{
      name: 'plannedProfitabilityBC',
      header: 'projects.projects.list.columns.plannedProfitabilityBC.header',
      hint: 'projects.projects.list.columns.plannedProfitabilityBC.hint',
      type: GridColumnType.Percent,
    },
    <GridCurrencyColumn>{
      name: 'plannedProfitabilityOverPeriod',
      header:
        'projects.projects.list.columns.plannedProfitabilityOverPeriod.header',
      hint: 'projects.projects.list.columns.plannedProfitabilityOverPeriod.hint',
      type: GridColumnType.Percent,
    },
    <GridCurrencyColumn>{
      name: 'plannedProfitabilityOverPeriodBC',
      header:
        'projects.projects.list.columns.plannedProfitabilityOverPeriodBC.header',
      hint: 'projects.projects.list.columns.plannedProfitabilityOverPeriodBC.hint',
      type: GridColumnType.Percent,
    },
    <GridCurrencyColumn>{
      name: 'actualProfitability',
      header: 'projects.projects.list.columns.actualProfitability.header',
      hint: 'projects.projects.list.columns.actualProfitability.hint',
      type: GridColumnType.Percent,
    },
    <GridCurrencyColumn>{
      name: 'actualProfitabilityBC',
      header: 'projects.projects.list.columns.actualProfitabilityBC.header',
      hint: 'projects.projects.list.columns.actualProfitabilityBC.hint',
      type: GridColumnType.Percent,
    },
    <GridCurrencyColumn>{
      name: 'actualProfitabilityOverPeriod',
      header:
        'projects.projects.list.columns.actualProfitabilityOverPeriod.header',
      hint: 'projects.projects.list.columns.actualProfitabilityOverPeriod.hint',
      type: GridColumnType.Percent,
    },
    <GridCurrencyColumn>{
      name: 'actualProfitabilityOverPeriodBC',
      header:
        'projects.projects.list.columns.actualProfitabilityOverPeriodBC.header',
      hint: 'projects.projects.list.columns.actualProfitabilityOverPeriodBC.hint',
      type: GridColumnType.Percent,
    },
    {
      name: 'estimatedHours',
      header: 'projects.projects.list.columns.estimatedHours.header',
      hint: 'projects.projects.list.columns.estimatedHours.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Work,
    },
    {
      name: 'estimatedHoursOverPeriod',
      header: 'projects.projects.list.columns.estimatedHoursOverPeriod.header',
      hint: 'projects.projects.list.columns.estimatedHoursOverPeriod.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Work,
    },
    {
      name: 'actualHours',
      header: 'projects.projects.list.columns.actualHours.header',
      hint: 'projects.projects.list.columns.actualHours.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Work,
    },
    {
      name: 'actualHoursOverPeriod',
      header: 'projects.projects.list.columns.actualHoursOverPeriod.header',
      hint: 'projects.projects.list.columns.actualHoursOverPeriod.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Work,
    },
    {
      name: 'plannedHours',
      header: 'projects.projects.list.columns.plannedHours.header',
      hint: 'projects.projects.list.columns.plannedHours.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Work,
    },
    {
      name: 'plannedHoursOverPeriod',
      header: 'projects.projects.list.columns.plannedHoursOverPeriod.header',
      hint: 'projects.projects.list.columns.plannedHoursOverPeriod.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Work,
    },
    {
      name: 'isAutoPlanning',
      type: GridColumnType.Boolean,
      header: 'projects.projects.list.columns.isAutoPlanning.header',
      hint: 'projects.projects.list.columns.isAutoPlanning.hint',
    },
    {
      name: 'legalEntity',
      type: GridColumnType.String,
      header: 'projects.projects.list.columns.legalEntity.header',
      hint: 'projects.projects.list.columns.legalEntity.hint',
    },
  ],

  dataColumns: [
    ...getCodedEntityDataColumns('project'),

    {
      column: 'description',
      field: 'project.description',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'program',
      field: 'project.program.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'manager',
      field: 'project.manager.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'organization',
      field: 'project.organization.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'state',
      field: [
        'project.state.name',
        'project.state.code',
        'project.state.style',
        'project.state.isEntityProtected',
      ],
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'sourceProject',
      field: 'project.sourceProject.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'endDate',
      field: 'project.endDate',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'startDate',
      field: 'project.startDate',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'billingType',
      field: 'project.billingType.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'externalUrl',
      field: 'project.externalUrl',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },

    {
      column: 'currency',
      field: ['project.currency.alpha3Code', 'project.currency.name'],
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'isAutoPlanning',
      field: 'project.isAutoPlanning',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'legalEntity',
      field: 'project.legalEntity.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },

    // TOTAL COLUMNS.

    {
      column: 'plannedExpenses',
      field: 'extended.plannedExpenses',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'plannedExpensesBC',
      field: 'extended.plannedExpensesBC',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'plannedExpensesOverPeriod',
      field: 'extended.plannedExpensesOverPeriod',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'plannedExpensesOverPeriodBC',
      field: 'extended.plannedExpensesOverPeriodBC',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualExpenses',
      field: 'extended.actualExpenses',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualExpensesBC',
      field: 'extended.actualExpensesBC',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualExpensesOverPeriod',
      field: 'extended.actualExpensesOverPeriod',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualExpensesOverPeriodBC',
      field: 'extended.actualExpensesOverPeriodBC',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'plannedRevenue',
      field: 'extended.plannedRevenue',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'plannedRevenueBC',
      field: 'extended.plannedRevenueBC',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'plannedRevenueOverPeriod',
      field: 'extended.plannedRevenueOverPeriod',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'plannedRevenueOverPeriodBC',
      field: 'extended.plannedRevenueOverPeriodBC',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualRevenue',
      field: 'extended.actualRevenue',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualRevenueBC',
      field: 'extended.actualRevenueBC',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualRevenueOverPeriod',
      field: 'extended.actualRevenueOverPeriod',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualRevenueOverPeriodBC',
      field: 'extended.actualRevenueOverPeriodBC',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'plannedProfit',
      field: 'extended.plannedProfit',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'plannedProfitBC',
      field: 'extended.plannedProfitBC',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'plannedProfitOverPeriod',
      field: 'extended.plannedProfitOverPeriod',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'plannedProfitOverPeriodBC',
      field: 'extended.plannedProfitOverPeriodBC',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualProfit',
      field: 'extended.actualProfit',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualProfitBC',
      field: 'extended.actualProfitBC',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualProfitOverPeriod',
      field: 'extended.actualProfitOverPeriod',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualProfitOverPeriodBC',
      field: 'extended.actualProfitOverPeriodBC',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'plannedProfitability',
      field: 'extended.plannedProfitability',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'plannedProfitabilityBC',
      field: 'extended.plannedProfitabilityBC',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'plannedProfitabilityOverPeriod',
      field: 'extended.plannedProfitabilityOverPeriod',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'plannedProfitabilityOverPeriodBC',
      field: 'extended.plannedProfitabilityOverPeriodBC',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualProfitability',
      field: 'extended.actualProfitability',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualProfitabilityBC',
      field: 'extended.actualProfitabilityBC',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualProfitabilityOverPeriod',
      field: 'extended.actualProfitabilityOverPeriod',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualProfitabilityOverPeriodBC',
      field: 'extended.actualProfitabilityOverPeriodBC',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'estimatedHours',
      field: 'extended.estimatedHours',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'estimatedHoursOverPeriod',
      field: 'extended.estimatedHoursOverPeriod',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualHours',
      field: 'extended.actualHours',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualCost',
      field: 'extended.actualCost',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualCostBC',
      field: 'extended.actualCostBC',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualCostOverPeriod',
      field: 'extended.actualCostOverPeriod',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualCostOverPeriodBC',
      field: 'extended.actualCostOverPeriodBC',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualHoursOverPeriod',
      field: 'extended.actualHoursOverPeriod',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'plannedHours',
      field: 'extended.plannedHours',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'plannedHoursOverPeriod',
      field: 'extended.plannedHoursOverPeriod',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
  ],
};
