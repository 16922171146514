@if (allowed()) {
  <div (clickOutside)="cancel()" [attachOutsideOnClick]="true">
    <div class="face" [class.readonly]="readonly" title="{{ row()?.name }}" (click)="openArea()">
      @if (row()) {
        <div>
          {{ row()?.name }}
        </div>
      } @else {
        <div class="trim placeholder">
          {{ placeholder() | translate }}
        </div>
      }
      <i class="bi bi-chevron-down expander" aria-hidden="true"></i>
    </div>

    @if (areaExpanded()) {
      <div class="expanding-area expanding-area-vertical-scroll">
        @if (loading()) {
          <wp-loading-indicator [loading]="true" />
        }

        @if (!rows().length && !loading()) {
          <div class="center-block" style="text-align: center; padding: 3px">
            <p class="text-body-secondary m-0">
              {{ 'shared.noOptions' | translate }}
            </p>
          </div>
        }

        @if (rows().length && !loading()) {
          <table class="selecting-list">
            <tbody>
              @for (item of rows(); track item.id) {
                <tr
                  id="{{ item.id }}"
                  title="{{ item.name }}"
                  class="select-tem"
                  [class.selected-row]="item.id == row()?.id"
                  (click)="clickRow(item)"
                >
                  <td class="trim">{{ item.name }}</td>
                </tr>
              }
            </tbody>
          </table>
        }
      </div>
    }
  </div>
}
