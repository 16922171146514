import {
  computed,
  DestroyRef,
  inject,
  Injectable,
  signal,
} from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import _ from 'lodash';
import { Collection, DataService } from 'src/app/core/data.service';
import { Exception } from 'src/app/shared/models/exception';
import {
  Organization,
  OrganizationTotal,
} from 'src/app/shared/models/entities/projects/organization.model';
import { NotificationService } from 'src/app/core/notification.service';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { NavigationService } from 'src/app/core/navigation.service';

@Injectable()
export class ClientCardService {
  private readonly _state = signal<CardState>(CardState.Loading);
  public readonly state = computed(this._state);
  private readonly reloadTabSubject = new Subject<void>();
  public readonly reloadTab$ = this.reloadTabSubject.asObservable();
  private readonly clientTotalSubject = new BehaviorSubject<OrganizationTotal>(
    null,
  );
  public readonly clientTotal$ = this.clientTotalSubject
    .asObservable()
    .pipe(filter((p) => !!p));
  private readonly clientSubject = new BehaviorSubject<Organization>(null);
  public readonly client$ = this.clientSubject
    .asObservable()
    .pipe(filter((p) => !!p));

  public clientId: string;
  public clientCollection: Collection;

  public get currentClient(): Organization {
    return this.clientSubject.getValue();
  }
  public set currentClient(client: Organization) {
    this.clientSubject.next(client);
  }

  private readonly dataService = inject(DataService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly navigationService = inject(NavigationService);
  private readonly notificationService = inject(NotificationService);

  constructor() {
    this.clientCollection = this.dataService.collection('Organizations');
  }

  /** Saves client name. */
  public saveName = (name: string) =>
    this.clientCollection
      .entity(this.clientId)
      .patch({ name })
      .pipe(
        tap(() => {
          this.currentClient = {
            ...this.currentClient,
            name,
          };
        }),
      );

  /** Reloads tab. */
  public reloadTab() {
    this.reloadTabSubject.next();
  }

  /**
   * Loads all client's data.
   *
   * @param clientId Id of client to load.
   */
  public load(clientId: string): void {
    this.clientId = clientId;
    this._state.set(CardState.Loading);

    const query = this.buildQuery();

    this.loadClient(query)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (client: Organization) => {
          this.navigationService.addRouteSegment({
            id: client.id,
            title: client.name,
          });
          this.clientSubject.next(client);
          this._state.set(CardState.Ready);
        },
        error: (error: Exception) => {
          this._state.set(CardState.Error);
          if (error.code !== Exception.BtEntityNotFoundException.code) {
            this.notificationService.error(error.message);
          }
        },
      });
    this.loadClientTotal();
  }

  /** Loads client's totals. */
  private loadClientTotal(): void {
    const query = {
      select: ['actualHours'],
    };

    this.dataService
      .collection('OrganizationTotals')
      .entity(this.clientId)
      .get(query)
      .subscribe({
        next: (clientTotal: OrganizationTotal) => {
          this.clientTotalSubject.next(clientTotal);
        },
      });
  }

  /**
   * Loads client's data.
   *
   * @param query OData query for loading client data.
   * @returns Client entity.
   */
  public loadClient(query: any): Observable<Organization> {
    return this.clientCollection.entity(this.clientId).get<Organization>(query);
  }

  /** Updates current client data. */
  public updateClient(client: Partial<Organization>): void {
    const newClient: Partial<Organization> = {};

    Object.keys(this.currentClient).forEach((key) => {
      if (client[key] && !_.isEqual(client[key], this.currentClient[key])) {
        newClient[key] = client[key];
      }
    });

    this.currentClient = Object.assign({}, this.currentClient, newClient);
  }

  /**
   * Builds OData query for organization entity
   *
   * @returns OData query
   */
  private buildQuery(): any {
    return {
      select: [
        'id',
        'name',
        'isActive',
        'site',
        'editAllowed',
        'tariffsEditAllowed',
        'tariffsViewAllowed',
        'contactsViewAllowed',
      ],
      expand: [{ manager: { select: ['name', 'id'] } }],
    };
  }
}
