<form class="form-container" [formGroup]="form" novalidate autocomplete="off">
  <div ngbAccordion class="accordion--custom">
    <div ngbAccordionItem [tmtAccordionState]="'project-task-main-properties'">
      <h2 ngbAccordionHeader>
        <button class="group pt-0" ngbAccordionButton>
          {{ 'shared2.groups.mainProperties' | translate }}
        </button>
      </h2>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <ng-template>
            <div class="form-group" tmtIndicator>
              <label>{{ 'shared2.props.name' | translate }}</label>
              <wp-text-box
                formControlName="name"
                [maxLength]="constants.formNameMaxLength"
                placeholder="{{ 'shared2.props.taskName' | translate }}"
                [propagationMode]="propagationMode.onExitFromEditing"
              />
            </div>
            <div class="form-grid">
              <div class="form-group" tmtIndicator>
                <label>{{ 'shared2.props.startDate' | translate }}</label>
                <wp-date-box
                  formControlName="startDate"
                  [checkIsHighlighted]="scheduleService.checkNonWorkingDay"
                  [allowNull]="false"
                />
              </div>
              <div class="form-group" tmtIndicator>
                <label>{{ 'shared2.props.endDate' | translate }}</label>
                <wp-date-box
                  formControlName="endDate"
                  [checkIsHighlighted]="scheduleService.checkNonWorkingDay"
                  [readonly]="isSummaryTask"
                  [allowNull]="false"
                />
              </div>

              <div
                class="form-group"
                title="{{ 'shared2.props.projectTaskDuration.hint' | translate }}"
              >
                <label>{{ 'shared2.props.projectTaskDuration.value' | translate }}</label>
                <tmt-duration-box
                  class="w-100"
                  formControlName="duration"
                  [allowNull]="false"
                  [readonly]="isSummaryTask"
                />
              </div>
            </div>

            <div class="form-group">
              <label>{{ 'shared2.props.estimatedHours' | translate }}</label>
              <wp-number-box
                formControlName="estimatedHours"
                [propagationMode]="propagationMode.onExitFromEditing"
                [type]="'work'"
                [precision]="1"
              />
            </div>

            <div class="form-group">
              <label>{{ 'shared2.props.description' | translate }}</label>
              <wp-multiline-text-box
                formControlName="description"
                placeholder="{{ 'shared2.props.description' | translate }}"
                rows="2"
                [propagationMode]="propagationMode.onExitFromEditing"
              />
            </div>

            <div
              class="form-check"
              title="{{ 'components.taskCardModalComponent.props.trackingAllowed' | translate }}"
            >
              <input
                formControlName="allowTimeEntry"
                type="checkbox"
                class="form-check-input"
                id="allowTimeEntry"
              />
              <label class="form-check-label" for="allowTimeEntry">
                {{ 'components.taskCardModalComponent.props.trackingAllowed' | translate }}
              </label>
            </div>

            @if (task.leadTaskId) {
              <div title="{{ 'shared2.props.completed' | translate }}">
                <tmt-checkbox
                  formControlName="isActive"
                  id="isActive"
                  label="{{ 'shared2.props.completed' | translate }}"
                  [reversed]="true"
                />
              </div>
            }

            <div class="form-check" title="{{ 'shared2.props.completed.milestone' | translate }}">
              <input
                formControlName="isMilestone"
                type="checkbox"
                class="form-check-input"
                id="isMilestone"
              />
              <label class="form-check-label" for="isMilestone">
                {{ 'shared2.props.milestone' | translate }}
              </label>
            </div>
          </ng-template>
        </div>
      </div>
    </div>

    <div ngbAccordionItem class="grid" [tmtAccordionState]="'project-task-dependencies'">
      <h2 ngbAccordionHeader>
        <button class="group" ngbAccordionButton>
          {{ 'shared2.props.dependencies' | translate }}
        </button>
      </h2>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <tmt-grid [formArray]="dependencies" [options]="gridOptions" [readonly]="readonly" />
        </div>
      </div>
    </div>

    <div ngbAccordionItem class="grid" [tmtAccordionState]="'project-task-assignments'">
      <h2 ngbAccordionHeader>
        <button class="group" ngbAccordionButton>
          {{ 'shared2.props.assignments' | translate }}
        </button>
      </h2>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <tmt-task-assignments
            [task]="task"
            [assignmentsFormGroup]="form?.controls.projectTaskAssignments"
            [readonly]="readonly"
          />
        </div>
      </div>
    </div>

    @if (this.app.getCustomFields('ProjectTask').length || autoPlanning) {
      <div ngbAccordionItem [tmtAccordionState]="'project-task-advanced'">
        <h2 ngbAccordionHeader>
          <button class="group" ngbAccordionButton>
            {{ 'shared2.groups.advanced' | translate }}
          </button>
        </h2>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ng-template>
              @if (autoPlanning) {
                <div class="form-group">
                  <label class="control-label">
                    {{ 'shared2.props.type' | translate }}
                  </label>
                  <wp-select-box
                    formControlName="type"
                    [values]="taskTypes"
                    [allowNull]="false"
                    placeholder="{{ 'shared2.props.type' | translate }}"
                  />
                </div>
                <div class="form-check" title="{{ 'shared2.props.effortDriven' | translate }}">
                  <input
                    formControlName="effortDriven"
                    type="checkbox"
                    class="form-check-input"
                    id="effortDriven"
                  />
                  <label class="form-check-label" for="effortDriven">
                    {{ 'shared2.props.effortDriven' | translate }}
                  </label>
                </div>
              }

              <div class="form-group">
                <wp-custom-fields
                  columnClasses="col-12"
                  [isVisibleHr]="autoPlanning"
                  [formGroup]="form"
                  [entityType]="'ProjectTask'"
                  [propagationMode]="propagationMode.onExitFromEditing"
                />
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    }
  </div>
</form>
