import {
  GridColumnType,
  GridNavigationColumn,
} from 'src/app/shared-features/grid/models/grid-column.interface';
import { List, LoadingStrategy } from '../models/inner/list';
import { TotalType } from '../models/inner/total-type';
import { getFilterByActiveWorkflowTasks2 } from 'src/app/shared/lists/navigation.helpers';

export const WORKFLOW_TASK_TIMESHEET_LIST: List = {
  name: 'workflow-task-timesheets',
  version: 3,
  dataCollection: 'Timesheets',
  queryFunction: 'GetWorkflowTotals',
  views: [
    {
      name: 'with-my-task',
      columns: [
        {
          column: 'taskName',
          visibleByDefault: true,
          totalByDefault: TotalType.Count,
          width: 3,
        },
        {
          column: 'name',
          visibleByDefault: true,
          width: 3,
        },
        {
          column: 'state',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'user',
          visibleByDefault: true,
          width: 2,
        },
        {
          column: 'totalDuration',
          visibleByDefault: true,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'billableDuration',
          visibleByDefault: true,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'nonBillableDuration',
          visibleByDefault: true,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'dateFrom',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'dateTo',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'created',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'assigned',
          visibleByDefault: false,
          width: 2,
        },
      ],
      order: { column: 'name', reverse: false },
      contextFilter: [getFilterByActiveWorkflowTasks2()],
    },
  ],
  columns: [
    {
      name: 'taskName',
      header: 'timesheets.list.columns.workflowTask.header',
      hint: 'timesheets.list.columns.workflowTask.hint',
      availableTotals: [TotalType.Count],
      type: GridColumnType.String,
    },
    <GridNavigationColumn>{
      name: 'name',
      type: GridColumnType.Navigation,
      header: 'timesheets.list.columns.name.header',
      hint: 'timesheets.list.columns.name.hint',
      state: 'timesheet',
    },
    {
      name: 'state',
      header: 'shared.columns.state',
      hint: 'shared.columns.state',
      type: GridColumnType.State,
    },
    {
      name: 'dateFrom',
      header: 'timesheets.list.columns.dateFrom.header',
      hint: 'timesheets.list.columns.dateFrom.hint',
      type: GridColumnType.Date,
    },
    {
      name: 'dateTo',
      header: 'timesheets.list.columns.dateTo.header',
      hint: 'timesheets.list.columns.dateTo.hint',
      type: GridColumnType.Date,
    },
    {
      name: 'dueDate',
      header: 'timesheets.list.columns.dueDate.header',
      hint: 'timesheets.list.columns.dueDate.hint',
      type: GridColumnType.Date,
    },
    {
      name: 'totalDuration',
      header: 'timesheets.list.columns.totalDuration.header',
      hint: 'timesheets.list.columns.totalDuration.hint',
      availableTotals: [TotalType.Sum],
      type: GridColumnType.Work,
    },
    {
      name: 'billableDuration',
      header: 'timesheets.list.columns.billableDuration.header',
      hint: 'timesheets.list.columns.billableDuration.hint',
      availableTotals: [TotalType.Sum],
      type: GridColumnType.Work,
    },
    {
      name: 'nonBillableDuration',
      header: 'timesheets.list.columns.nonBillableDuration.header',
      hint: 'timesheets.list.columns.nonBillableDuration.hint',
      availableTotals: [TotalType.Sum],
      type: GridColumnType.Work,
    },
    {
      name: 'created',
      type: GridColumnType.DateTime,
      header: 'shared.columns.created.title',
      hint: 'shared.columns.created.hint',
    },
    {
      name: 'assigned',
      header: 'base.columns.assigned.header',
      hint: 'base.columns.assigned.hint',
      type: GridColumnType.String,
    },
    {
      name: 'user',
      header: 'timesheets.list.columns.user.header',
      hint: 'timesheets.list.columns.user.hint',
      type: GridColumnType.String,
    },
    {
      name: 'department',
      header: 'timesheets.list.columns.department.header',
      hint: 'timesheets.list.columns.department.hint',
      type: GridColumnType.String,
    },
  ],
  dataColumns: [
    {
      column: 'taskName',
      field: 'workflowTask.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'name',
      field: 'workflowEntity.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'department',
      field: 'workflowEntity.department.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'user',
      field: 'workflowEntity.user.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'state',
      field: [
        'workflowEntity.state.name',
        'workflowEntity.state.code',
        'workflowEntity.state.style',
        'workflowEntity.state.isEntityProtected',
      ],
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'dateFrom',
      field: 'workflowEntity.dateFrom',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'dateTo',
      field: 'workflowEntity.dateTo',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'dueDate',
      field: 'workflowEntity.dueDate',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'created',
      field: 'workflowEntity.created',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'assigned',
      field: 'extended.currentAssigned',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'nonBillableDuration',
      field: 'extended.nonBillableDuration',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'billableDuration',
      field: 'extended.billableDuration',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'totalDuration',
      field: 'extended.totalDuration',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
  ],
};
