/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable, Type } from '@angular/core';
import { FilterDetails } from 'src/app/shared/components/features/filter/filter-details.interface';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { InvoiceFilterComponent } from './invoice-filter.component';

@Injectable()
export class InvoiceFilterService extends FilterService {
  public override component: Type<FilterDetails> = InvoiceFilterComponent;
  public override hasDetails = true;
  public override hasViewSelector = false;
  public override placeholder = 'billing.invoices.list.filter.placeholder';

  protected override getDefaultValues = (): any => ({
    text: '',
    period: null,
    project: null,
    client: null,
    states: null,
  });

  public override getODataFilter() {
    const result: any[] = [];

    let text = this.values.text;
    if (text && text.length >= this.minStringLengthForFilter) {
      text = this.getClearText(text);

      result.push({
        or: [
          { 'tolower(number)': { contains: text } },
          { 'tolower(description)': { contains: text } },
          { organization: { 'tolower(name)': { contains: text } } },
          { project: { 'tolower(name)': { contains: text } } },
        ],
      });
    }

    if (this.values.client) {
      result.push({
        organizationId: { type: 'guid', value: this.values.client.id },
      });
    }

    if (this.values.project) {
      result.push({
        projectId: { type: 'guid', value: this.values.project.id },
      });
    }

    if (this.values.states) {
      const conditions = [];

      Object.keys(this.values.states).forEach((code) => {
        if (this.values.states[code].selected) {
          conditions.push({
            stateId: { type: 'guid', value: this.values.states[code].id },
          });
        }
      });

      result.push({ or: conditions });
    }

    if (this.values.period) {
      const dates = this.dateService.getDatePairFromPeriodForFilter(
        this.values.period,
      );

      result.push({
        issueDate: { ge: { type: 'raw', value: dates.periodStart } },
      });

      result.push({
        issueDate: { le: { type: 'raw', value: dates.periodFinish } },
      });
    }

    return result;
  }
}
