import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { ProjectTask } from 'src/app/shared/models/entities/projects/project-task.model';
import _ from 'lodash';
import { filter, map, takeUntil } from 'rxjs/operators';
import { merge, Subject } from 'rxjs';
import { TaskAssignmentsService } from 'src/app/projects/card/project-tasks/shared/task-card-modal/task-assignments/task-assignments.service';
import { Guid } from 'src/app/shared/helpers/guid';
import { naturalSort } from 'src/app/shared/helpers/natural-sort.helper';
import { ProjectCardService } from 'src/app/projects/card/core/project-card.service';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';

@Component({
  selector: 'tmt-task-assignments',
  templateUrl: './task-assignments.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TaskAssignmentsService],
  standalone: false,
})
export class TaskAssignmentsComponent implements OnInit, OnDestroy {
  @Input() task: ProjectTask;
  @Input() readonly: boolean;
  @Input() assignmentsFormGroup: UntypedFormGroup;

  public assignmentFormGroup: UntypedFormGroup = this.fb.group({
    allTeamAssignment: false,
    assignmentRows: this.fb.array([]),
  });

  public get assignmentRows(): UntypedFormArray {
    return this.assignmentFormGroup.controls.assignmentRows as UntypedFormArray;
  }

  private assignmentsChangingFromModal = false;

  private destroyed$ = new Subject<void>();
  private stopSubscriptionsSubject$ = new Subject<void>();

  constructor(
    public gridService: GridService,
    public projectCardService: ProjectCardService,
    private fb: UntypedFormBuilder,
    private taskAssignmentsService: TaskAssignmentsService,
  ) {}

  ngOnInit(): void {
    this.prepareAssignments();

    if (this.assignmentsFormGroup.disabled) {
      this.readonly = true;
    }
    if (this.readonly) {
      this.assignmentFormGroup.disable({ emitEvent: false });
    }
    this.taskAssignmentsService.readonly = this.readonly;
    this.taskAssignmentsService.task = this.task;

    if (!this.readonly) {
      this.initCardGroupChangesSubscription();
      this.initExternalChangesSubscription();
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  private initCardGroupChangesSubscription(): void {
    this.assignmentFormGroup.valueChanges
      .pipe(
        map((formResult) => {
          if (formResult.allTeamAssignment) {
            return [
              {
                id: Guid.generate(),
                isAllTeamRole: true,
                isUnassigned: false,
                projectTeamMemberId: null,
                units: null,
                projectTariff: null,
              },
            ];
          }
          return formResult.assignmentRows
            .filter((row) => row.projectTeamMember)
            .map((assignmentRow) => ({
              id: assignmentRow.id,
              isAllTeamRole: false,
              isUnassigned: false,
              projectTeamMember: assignmentRow.projectTeamMember,
              projectTeamMemberId:
                assignmentRow.projectTeamMember.resource?.id ?? null,
              units: assignmentRow.units ?? null,
              projectTariff: assignmentRow.projectTariff,
              projectTariffId: assignmentRow.projectTariff?.id ?? null,
            }));
        }),
        filter(
          (newAssignments) =>
            !_.isEqual(
              this.task.projectTaskAssignments
                .map(
                  (assignment) =>
                    assignment.id +
                    (assignment.projectTeamMemberId ?? '') +
                    (assignment.units ?? '') +
                    (assignment.projectTariffId ?? ''),
                )
                .sort(),
              newAssignments
                .map(
                  (newAssignment) =>
                    newAssignment.id +
                    (newAssignment.projectTeamMemberId ?? '') +
                    (newAssignment.units ?? '') +
                    (newAssignment.projectTariffId ?? ''),
                )
                .sort(),
            ),
        ),
        takeUntil(merge(this.destroyed$, this.stopSubscriptionsSubject$)),
      )
      .subscribe((newAssignments) => {
        this.assignmentsChangingFromModal = true;
        this.assignmentsFormGroup.setValue(newAssignments);
      });
  }

  /** Prepares assignments form group for assignment tab. */
  private prepareAssignments(): void {
    this.task.projectTaskAssignments.sort(
      naturalSort('projectTeamMember.name'),
    );

    if (this.task.projectTaskAssignments.some((ass) => ass.isUnassigned)) {
      return;
    }

    if (this.task.projectTaskAssignments.some((ass) => ass.isAllTeamRole)) {
      this.assignmentFormGroup.controls.allTeamAssignment.setValue(true, {
        emitEvent: false,
      });
      return;
    }

    this.task.projectTaskAssignments.forEach((assignment) => {
      const assignmentGroup =
        this.taskAssignmentsService.getAssignmentFormGroup(assignment);

      this.assignmentRows.push(assignmentGroup, { emitEvent: false });
    });
  }

  /** Inits listener of main formArray assignments changing. */
  private initExternalChangesSubscription(): void {
    this.assignmentsFormGroup.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (this.assignmentsChangingFromModal) {
          this.assignmentsChangingFromModal = false;
        } else {
          const newAssignmentsIds = value.map((assignment) => assignment.id);
          const existAssignmentsIds = this.assignmentRows.value
            .filter((row) => row.projectTeamMember)
            .map((assignment) => assignment.id);
          const isEqualRows = _.isEqual(
            newAssignmentsIds.sort(),
            existAssignmentsIds.sort(),
          );

          if (!isEqualRows) {
            this.rebuildAssignments();
          }
        }
      });
  }

  /** Rebuilds dependencies table. */
  private rebuildAssignments() {
    this.stopSubscriptionsSubject$.next();
    this.assignmentRows.clear();
    this.prepareAssignments();
    this.initCardGroupChangesSubscription();
  }
}
