import {
  Component,
  OnInit,
  forwardRef,
  Input,
  ViewChild,
  ElementRef,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
} from '@angular/forms';
import { fromEvent, Subscription } from 'rxjs';
import { AbstractBox } from 'src/app/shared/components/controls/abstract-box/abstract-box';
import { PropagationMode } from 'src/app/shared/models/enums/control-propagation-mode.enum';

/** Контрол ввода многострочного текста. */
@Component({
  selector: 'wp-multiline-text-box',
  templateUrl: './multiline-text-box.component.html',
  styleUrls: ['./multiline-text-box.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultilineTextBoxComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class MultilineTextBoxComponent
  extends AbstractBox
  implements ControlValueAccessor, OnInit, OnDestroy
{
  @Input() placeholder: string;
  @Input() maxLength = 10240;
  @Input() readonly: boolean;
  @Input() spellcheck = true;
  @Input() rows = 3;

  @ViewChild('editor') editor: ElementRef;

  public formControl = new UntypedFormControl('');

  private _value = '';
  private keyboardSubscription: Subscription;

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.formControl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        value &&= value.trim();

        if (this.propagationMode === PropagationMode.onInput) {
          this._value = value;
          this.propagateChange(this._value);
        }

        if (this.propagationMode === PropagationMode.onExitFromEditing) {
          this.toggleControlDirty(this._value, value);
        }
      });
  }

  ngOnDestroy(): void {
    if (this.propagationMode === PropagationMode.onExitFromEditing) {
      this.onBlur();
    }
    this.keyboardSubscription?.unsubscribe();
  }

  propagateChange = (_: any) => null;
  propagateTouch = () => null;

  writeValue(value: any): void {
    // Do not patch value if control in editing process.
    if (
      this.propagationMode === PropagationMode.onExitFromEditing &&
      this.formControl.value !== this._value
    ) {
      return;
    }
    this._value = value ? value : '';
    this.formControl.setValue(this._value, { emitEvent: false });
    this.cdr.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  /** Sets disable state.
   *
   * @param isDisabled disable state flag.
   */
  public setDisabledState?(isDisabled: boolean): void {
    this.readonly = isDisabled;
    this.cdr.detectChanges();
  }

  /** Input onFocus logic.*/
  public onFocus(): void {
    if (this.propagationMode === PropagationMode.onExitFromEditing) {
      this.initKeysSubscribes();
    }
  }

  /** Input onBlur logic.*/
  public onBlur(): void {
    this.propagateTouch();
    if (this.propagationMode === PropagationMode.onExitFromEditing) {
      const value = this.formControl.value.trim();
      if (this._value !== value) {
        this._value = value;
        this.propagateChange(value);
      }
      this.keyboardSubscription?.unsubscribe();
    }
  }

  /** Sets focus. */
  public focus() {
    (this.editor.nativeElement as HTMLElement).focus();
  }

  /** Initializes keyboard listener. */
  private initKeysSubscribes() {
    if (this.keyboardSubscription) {
      this.keyboardSubscription.unsubscribe();
    }
    this.keyboardSubscription = fromEvent(window, 'keydown').subscribe(
      (event: KeyboardEvent) => {
        if (!event.repeat) {
          switch (event.code) {
            case 'Escape':
              this.formControl.setValue(this._value, { emitEvent: false });
              break;
          }
        }
        return;
      },
    );
  }
}
