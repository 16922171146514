import { inject, Injectable } from '@angular/core';

import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from '@microsoft/signalr';
import { BehaviorSubject, first, firstValueFrom, map } from 'rxjs';

import { AppConfigService } from 'src/app/core/app-config.service';
import { AuthService } from 'src/app/core/auth.service';

@Injectable()
export class WebSocketsService {
  private connectionSubject = new BehaviorSubject<HubConnection | null>(null);
  public connection$ = this.connectionSubject
    .asObservable()
    .pipe(first((v) => !!v));

  protected authService = inject(AuthService);

  public get connection(): HubConnection | null {
    return this.connectionSubject.getValue();
  }

  /**
   * Connects to websocket.
   *
   * @param hubName websocket hub name.
   * @returns connection.
   */
  public async initSignalR(hubName: string): Promise<HubConnection> {
    if (!AppConfigService.config.websocket.url) {
      return;
    }

    const connection = new HubConnectionBuilder()
      .withUrl(
        `${AppConfigService.config.websocket.url}/${hubName}` +
          (this.authService.substitutedEmail
            ? `?login_as=${this.authService.substitutedEmail}`
            : ''),
        {
          accessTokenFactory: () => this.getToken(),
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets,
        },
      )
      .withAutomaticReconnect(new Array(10).fill(5000))
      .configureLogging(LogLevel.Information)
      .build();

    // Start the connection.
    await connection.start();

    this.connectionSubject.next(connection);

    return connection;
  }

  private getToken(): Promise<string> {
    return firstValueFrom(
      this.authService
        .getAuthorizationHeaderValue()
        .pipe(map((token) => token.replace('Bearer ', ''))),
    );
  }
}
