import { inject, Injectable, NgZone, OnDestroy, Optional } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { TransitionService, StateService, Transition } from '@uirouter/core';

import { MessageService } from 'src/app/core/message.service';

import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';

@Injectable()
export class DataNotSavedIndicatorService implements OnDestroy {
  private forms: AbstractControl[] = [];
  private disableLeaveControl = false;
  private messageIsOpened = false;
  private transitionOnStartListener: ReturnType<TransitionService['onStart']>;
  private readonly beforeUnloadHandler = (event: BeforeUnloadEvent) => {
    if (this.preventLeave) {
      const dialogText = this.translate.instant(
        'shared2.messages.leavePageMessage',
      );
      event.returnValue = dialogText;
      return dialogText;
    }
  };

  private readonly zone = inject(NgZone);
  private readonly messageService = inject(MessageService);
  private readonly translate = inject(TranslateService);
  private readonly transitionService = inject(TransitionService);
  private readonly state = inject(StateService);

  public get preventLeave(): boolean {
    return (
      this.forms.some((form) => form.dirty) || this.savingQueueService?.isSaving
    );
  }

  constructor(
    @Optional() public savingQueueService: SavingQueueService | null,
  ) {
    window.addEventListener('beforeunload', this.beforeUnloadHandler);

    this.transitionOnStartListener = this.transitionService.onStart(
      null,
      (trans: Transition) => {
        if (this.preventLeave && !this.disableLeaveControl) {
          if (this.messageIsOpened) {
            return false;
          }

          this.messageIsOpened = true;
          this.messageService
            .confirmLocal('shared2.messages.leavePageMessage')
            .then(
              () => {
                this.disableLeaveControl = true;
                const toState = trans.to();

                this.zone.run(() => {
                  this.state.go(toState.name, trans.params());
                });

                this.messageIsOpened = false;
              },
              () => {
                this.messageIsOpened = false;
              },
            );

          return false;
        }
      },
    );
  }

  public ngOnDestroy(): void {
    this.transitionOnStartListener();
    window.removeEventListener('beforeunload', this.beforeUnloadHandler);
  }

  /**
   * Registers forms to the service.
   *
   * @param form any form.
   * @returns index of form in the collection.
   */
  public registerForm(form: AbstractControl): number {
    return this.forms.push(form) - 1;
  }

  /**
   * Removes form from the service.
   *
   * @param index form index.
   */
  public unregisterForm(index: number): void {
    this.forms.splice(index, 1);
  }
}
