import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy,
  Injector,
  signal,
  input,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { ActionPanelService } from 'src/app/core/action-panel.service';
import { NavigationService } from 'src/app/core/navigation.service';

import _ from 'lodash';

import {
  MenuAction,
  MenuActionsGroup,
  SubAction,
} from 'src/app/shared/models/inner/menu-action';
import { AutosaveStateService } from 'src/app/shared/services/autosave-state.service';
import { OffCanvasActionPanelService } from 'src/app/shared/components/features/off-canvas/off-canvas.component';

/** Панель действий. */
@Component({
  selector: 'tmt-action-panel',
  templateUrl: './action-panel.component.html',
  styleUrls: ['./action-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ActionPanelComponent implements OnDestroy {
  public isShowBackButton = input<boolean>(true);
  public groups: MenuActionsGroup[] = [];
  public additionalActions: SubAction[] = [];
  public isDisabled = false;
  public isSaving = signal<boolean>(false);
  public hasAutosave = signal<boolean>(false);
  public hasReloader = signal<boolean>(true);

  protected actionPanelService: ActionPanelService;

  constructor(
    public navigationService: NavigationService,
    public autosaveStateService: AutosaveStateService,
    private cdr: ChangeDetectorRef,
    private injector: Injector,
    rootActionPanelService: ActionPanelService,
  ) {
    this.actionPanelService = this.injector.get(
      OffCanvasActionPanelService,
      rootActionPanelService,
      {
        optional: true,
      },
    );

    this.actionPanelService.updated$
      .pipe(takeUntilDestroyed())
      .subscribe((groups: MenuActionsGroup[]) => {
        this.isDisabled = groups
          .flatMap((g) => g.actions)
          .some((a) => a.isBusy);
        this.groups = groups.filter((g) => g.actions.length);
        this.cdr.markForCheck();
      });

    this.actionPanelService.additionalUpdated$
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.additionalActions = this.actionPanelService.additionalActions;
        this.cdr.markForCheck();
      });

    this.actionPanelService.hasAutosave$
      .pipe(takeUntilDestroyed())
      .subscribe((hasAutosave) => {
        this.hasAutosave.set(hasAutosave);
      });

    this.actionPanelService.hasReloader$
      .pipe(takeUntilDestroyed())
      .subscribe((hasReloader) => {
        this.hasReloader.set(hasReloader);
      });

    this.autosaveStateService.isSaving$
      .pipe(takeUntilDestroyed())
      .subscribe((isSaving) => {
        this.isSaving.set(isSaving);
      });
  }

  public ngOnDestroy(): void {
    this.actionPanelService.setHasAutosave(false);
  }

  /** Returns `true` if action or sub-actions is visible. */
  public isVisibleElement(value: MenuAction | SubAction[]): boolean {
    if (Array.isArray(value)) {
      return !!value.find((item) => item.isVisible);
    }

    if (!value.isDropDown) {
      return value.isVisible;
    }

    if (value.isVisible) {
      return !!value.actions.find(
        (subAction) => _.isObject(subAction) && subAction.isVisible,
      );
    }

    return false;
  }

  /** Run action event on click. */
  public click(action: MenuAction): void {
    if (this.isDisabled || action.isDropDown) {
      return;
    }

    this.actionPanelService.runAction(action);
  }

  /** Send command to reload for `form-header`. */
  public reload(): void {
    this.actionPanelService.reload();
  }

  public isNeedDivider(index: number, actions: SubAction[]): boolean {
    return !!actions
      .slice(0, index)
      .reverse()
      .find((a) => a.isVisible && a.group !== actions[index].group);
  }
}
