import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { Transition } from '@uirouter/core';
import { VIEW_NAME, LIST } from 'src/app/shared/tokens';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';
import { BillCodesListComponent } from 'src/app/settings-app/bill-codes/list/bill-codes-list.component';
import { BillCodeCardComponent } from 'src/app/settings-app/bill-codes/card/bill-code-card.component';
import { BillCodeCreationComponent } from 'src/app/settings-app/bill-codes/creation/bill-code-creation.component';
import { BILL_CODE_LIST } from 'src/app/settings-app/bill-codes/model/bill-code.list';

@NgModule({
  declarations: [
    BillCodesListComponent,
    BillCodeCardComponent,
    BillCodeCreationComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    EntityListComponent,
    UIRouterModule.forChild({
      states: [
        {
          name: 'settings.billCodes',
          url: '/bill-codes',
          component: BillCodesListComponent,
          params: {
            navigation: 'settings.billCodes',
          },
          resolve: [
            { provide: VIEW_NAME, useValue: 'default' },
            { provide: LIST, useValue: BILL_CODE_LIST },
          ],
        },

        {
          name: 'settings.billCode',
          params: {
            navigation: 'settings.billCodes',
          },
          url: '/bill-codes/{entityId:guid}',
          component: BillCodeCardComponent,
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
          ],
        },
      ],
    }),
  ],
})
export class BillCodesModule {}
