import {
  Component,
  OnInit,
  inject,
  DestroyRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { StateService } from '@uirouter/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from 'src/app/core/app.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { ListService } from 'src/app/shared/services/list.service';
import { EntityListService } from 'src/app/shared/components/entity-list/entity-list.service';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { UntypedFormGroup } from '@angular/forms';
import { DealCreateModalComponent } from 'src/app/deals/creation/deal-create-modal.component';
import { DealListFilterService } from 'src/app/deals/list/deal-list-filter/deal-list-filter.service';
import { RouteMode } from 'src/app/shared/models/inner/route-mode.enum';
import { NavigationService } from 'src/app/core/navigation.service';
import { Deal } from 'src/app/deals/model/deal.model';

@Component({
  selector: 'tmt-deal-list',
  template: '<wp-entity-list />',
  providers: [
    { provide: FilterService, useClass: DealListFilterService },
    EntityListService,
    ListService,
    GridService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class DealListComponent implements OnInit {
  private readonly actionPanelService = inject(ActionPanelService);
  private readonly appService = inject(AppService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly entityListService = inject(EntityListService);
  private readonly gridService = inject(GridService);
  private readonly modalService = inject(NgbModal);
  private readonly navigationService = inject(NavigationService);
  private readonly stateService = inject(StateService);

  public ngOnInit(): void {
    this.actionPanelService.set([
      this.actionPanelService.getDefaultAction('create', {
        title: 'components.dealsListComponent.actions.create',
        isVisible: this.appService.checkEntityPermission(
          'Deal',
          PermissionType.Modify,
        ),
        handler: () => this.create(),
      }),
      this.actionPanelService.getDefaultAction('card', {
        handler: () =>
          this.stateService.go('deal', {
            entityId: this.gridService.selectedGroupValue.id,
          }),
      }),
      this.actionPanelService.getDefaultAction('delete', {
        handler: this.entityListService.delete,
      }),
    ]);

    this.gridService.selectedGroup$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((row: UntypedFormGroup) => {
        this.actionPanelService.action('card').isShown = !!row;
        this.actionPanelService.action('delete').isShown = row && true;
        this.appService.checkEntityPermission('Deal', PermissionType.Delete);
      });
  }

  private create(): void {
    this.modalService.open(DealCreateModalComponent).result.then(
      (entity: Deal) => {
        this.stateService.go('deal', {
          entityId: entity.id,
          routeMode: RouteMode.continue,
          navigation:
            this.navigationService.selectedNavigationItem?.name ??
            'clients.allDeals',
        });
      },
      () => null,
    );
  }
}
