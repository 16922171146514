import { NgModule } from '@angular/core';
import { TimesheetFilterComponent } from './list/filter/timesheet-filter.component';
import { TimesheetCardComponent } from './card/timesheet-card.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { TimesheetListComponent } from './list/timesheet-list.component';
import { TimesheetTaskComponent } from './card/shared/timesheet-task/timesheet-task.component';
import { TimesheetHeaderComponent } from './card/timesheet-header/timesheet-header.component';
import { Transition, UIRouterModule } from '@uirouter/angular';
import { TimesheetDetailsComponent } from './card/table-view/timesheet-details/timesheet-details.component';
import { TableViewComponent } from './card/table-view/table-view.component';
import { TimesheetCellComponent } from './card/shared/timesheet-cell/timesheet-cell.component';
import { AllocationInfoComponent } from './card/table-view/allocation-info/allocation-info.component';
import { ValidationModalComponent } from './card/shared/validation-modal/validation-modal.component';
import { ROUTE_DEFAULT_PARAMS } from 'src/app/app.states';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import {
  LIST,
  MASS_OPERATION_PARAMETERS,
  META_ENTITY_TYPE,
  MULTI_SELECT_LIST,
  VIEW_NAME,
} from 'src/app/shared/tokens';
import { TIMESHEET_LIST } from 'src/app/shared/lists/timesheet.list';
import { DataService } from 'src/app/core/data.service';
import { AppService } from 'src/app/core/app.service';
import { CommentsModule } from 'src/app/shared-features/comments/comments.module';
import { BaseSharedModule } from 'src/app/shared-features/shared/base-shared.module';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { NotificationService } from 'src/app/core/notification.service';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';
import { MassOperationParameters } from 'src/app/shared/components/mass-operation/model/mass-operation-parameters.model';
import { WORKFLOW_TASK_TIMESHEET_LIST } from 'src/app/shared/lists/workflow-task-timesheet.list';
import { AllocationIssueInfoComponent } from 'src/app/timesheets/card/table-view/allocation-issue-info/allocation-issue-info.component';
import { TimesheetAnalyticBoxComponent } from 'src/app/timesheets/card/shared/timesheet-analytic-box/timesheet-analytic-box.component';

/** Модуль реализует функциональность таймшитов, заявок на отсутствия и заявок на затраты,
 * используемую приложениями MyApp и TeamApp.
 */
@NgModule({
  declarations: [
    TimesheetListComponent,
    TimesheetFilterComponent,
    TimesheetCardComponent,
    TableViewComponent,
    TimesheetAnalyticBoxComponent,
    TimesheetTaskComponent,
    TimesheetHeaderComponent,
    TimesheetDetailsComponent,
    TimesheetCellComponent,
    AllocationInfoComponent,
    AllocationIssueInfoComponent,
    ValidationModalComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    BaseSharedModule,
    EntityListComponent,
    UIRouterModule.forChild({
      states: [
        {
          name: 'currentTimesheet',
          url: '/timesheets/current?{navigation}&{route}',
          params: {
            routeMode: null,
            entityId: null,
            navigation: 'my.currentTimesheet',
            route: ROUTE_DEFAULT_PARAMS,
          },
          component: TimesheetCardComponent,
          resolve: [
            {
              provide: 'entityId',
              deps: [
                DataService,
                AppService,
                BlockUIService,
                NotificationService,
              ],
              useFactory: StateHelper.resolveCurrentId,
            },
          ],
        },
        {
          name: 'timesheet',
          url: '/timesheets/{entityId:guid}?{navigation}&{route}',
          params: {
            routeMode: null,
            route: ROUTE_DEFAULT_PARAMS,
            navigation: 'my.timesheetsAll',
          },
          component: TimesheetCardComponent,
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
          ],
        },

        {
          name: 'timesheetsWithMyTasks',
          url: '/timesheets/with-my-task?{navigation}&{route}',
          resolve: [
            { provide: LIST, useValue: WORKFLOW_TASK_TIMESHEET_LIST },
            { provide: VIEW_NAME, useValue: 'with-my-task' },
            { provide: META_ENTITY_TYPE, useValue: 'TimeSheet' },
            {
              provide: MASS_OPERATION_PARAMETERS,
              useValue: {
                state: 'timesheet',
                entityPropertyName: 'timesheet',
              } as MassOperationParameters,
            },
            { provide: MULTI_SELECT_LIST, useValue: true },
          ],
          params: {
            route: ROUTE_DEFAULT_PARAMS,
            navigation: 'my.timesheetsAll',
            routeMode: null,
            view: null,
          },
          component: TimesheetListComponent,
        },

        {
          name: 'timesheets',
          url: '/timesheets/{view:viewName}?{navigation}&{route}',
          resolve: [
            {
              provide: VIEW_NAME,
              useFactory: StateHelper.resolveView,
              deps: [Transition],
            },
            { provide: LIST, useValue: TIMESHEET_LIST },
            { provide: META_ENTITY_TYPE, useValue: 'TimeSheet' },
            {
              provide: MASS_OPERATION_PARAMETERS,
              useValue: {
                state: 'timesheet',
                entityPropertyName: 'timesheet',
              } as MassOperationParameters,
            },
            { provide: MULTI_SELECT_LIST, useValue: true },
          ],
          params: {
            route: ROUTE_DEFAULT_PARAMS,
            navigation: 'my.timesheetsAll',
            routeMode: null,
            view: null,
          },
          component: TimesheetListComponent,
        },
      ],
    }),
    CommentsModule,
  ],
})
export class TimesheetsModule {}
