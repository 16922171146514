import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Injector,
  inject,
} from '@angular/core';
import { StateService } from '@uirouter/core';
import { merge } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TabLink } from 'src/app/shared/models/navigation/navigation';
import { DealCardService } from './deal-card.service';
import { AppService } from 'src/app/core/app.service';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { UserInfoComponent } from 'src/app/shared/components/features/user-info';
import { LifecycleService } from 'src/app/core/lifecycle.service';
import { META_ENTITY_TYPE } from 'src/app/shared/tokens';
import { FormHeaderService } from 'src/app/shared/components/chrome/form-header2/form-header.service';
import { DealContactsService } from 'src/app/deals/card/deal-contacts/core/deal-contacts.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { DealProfileService } from 'src/app/deals/card/deal-profile/deal-profile.service';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { MessageService } from 'src/app/core/message.service';

@Component({
  selector: 'tmt-deal-card',
  templateUrl: './deal-card.component.html',
  providers: [
    DealCardService,
    DealContactsService,
    DealProfileService,
    LifecycleService,
    SavingQueueService,
    FormHeaderService,
    { provide: META_ENTITY_TYPE, useValue: 'Deal' },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
  host: {
    class: 'data-not-saved-indicator-to-top',
  },
})
export class DealCardComponent {
  public tabs: TabLink[] = [];
  public readonly dealCardService = inject(DealCardService);
  public readonly stateService = inject(StateService);
  public readonly appService = inject(AppService);

  private readonly actionPanelService = inject(ActionPanelService);
  private readonly dealProfileService = inject(DealProfileService);
  private readonly elementRef = inject(ElementRef<HTMLElement>);
  private readonly lifecycleService = inject(LifecycleService);
  private readonly messageService = inject(MessageService);
  private readonly injector = inject(Injector);
  private readonly infoPopupService = inject(InfoPopupService);

  constructor() {
    this.dealCardService.load();

    this.tabs.push({
      header: 'shared2.groups.main',
      state: 'deal.profile',
    });

    if (
      this.appService.checkEntityPermission('Interaction', PermissionType.Read)
    ) {
      this.tabs.push({
        header: 'shared2.groups.interactions',
        state: 'deal.interactions',
      });
    }

    merge(this.lifecycleService.reload$, this.actionPanelService.reload$)
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        if (this.dealProfileService.form.dirty) {
          this.messageService
            .confirmLocal('shared2.messages.leavePageMessage')
            .then(
              () => this.dealCardService.reloadTab(),
              () => null,
            );
        } else {
          this.dealCardService.reloadTab();
        }
      });
  }

  /** Open user info. */
  public openUserInfo(userId: string): void {
    const target = this.elementRef.nativeElement.querySelector('#manager');
    this.infoPopupService.open({
      target,
      data: {
        component: UserInfoComponent,
        params: {
          userId,
        },
        injector: this.injector,
      },
    });
  }
}
