import {
  Component,
  ChangeDetectionStrategy,
  inject,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ClientCardService } from 'src/app/clients/card/client-card.service';
import { ClientSettingsService } from 'src/app/clients/card/client-settings/client-settings.service';
import { AppService } from 'src/app/core/app.service';
import { MessageService } from 'src/app/core/message.service';
import { TextBoxComponent } from 'src/app/shared/components/controls/text-box/text-box.component';
import { PropagationMode } from 'src/app/shared/models/enums/control-propagation-mode.enum';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';

@Component({
  selector: 'tmt-client-settings',
  templateUrl: './client-settings.component.html',
  styleUrl: './client-settings.component.scss',
  providers: [SavingQueueService, ClientSettingsService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ClientSettingsComponent {
  public phone = viewChild<TextBoxComponent>('phone');

  public readonly propagationMode = PropagationMode;

  public readonly clientSettingsService = inject(ClientSettingsService);
  public readonly appService = inject(AppService);

  private readonly clientCardService = inject(ClientCardService);
  private readonly messageService = inject(MessageService);

  constructor() {
    this.clientSettingsService.load();

    this.clientCardService.reloadTab$
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        if (this.clientSettingsService.form.dirty) {
          this.messageService
            .confirmLocal('shared2.messages.leavePageMessage')
            .then(
              () => this.clientSettingsService.load(),
              () => null,
            );
        } else {
          this.clientSettingsService.load();
        }
      });
  }
}
