import {
  Component,
  ChangeDetectionStrategy,
  inject,
  signal,
  DestroyRef,
  viewChild,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter } from 'rxjs';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Exception } from 'src/app/shared/models/exception';
import { Organization } from 'src/app/shared/models/entities/projects/organization.model';
import { LogService } from 'src/app/core/log.service';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';
import { NotificationService } from 'src/app/core/notification.service';
import { PropagationMode } from 'src/app/shared/models/enums/control-propagation-mode.enum';
import { ClientCardService } from 'src/app/clients/card/client-card.service';
import { urlValidator } from 'src/app/shared/validators/url';
import { Constants } from 'src/app/shared/globals/constants';
import { emailValidator } from 'src/app/shared/validators/email';
import { TextBoxComponent } from 'src/app/shared/components/controls/text-box/text-box.component';

@Component({
  selector: 'tmt-client-settings-modal',
  templateUrl: './client-settings-modal.component.html',
  providers: [ClientCardService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ClientSettingsModalComponent {
  public phone = viewChild<TextBoxComponent>('phone');

  public readonly isSaving = signal<boolean>(false);

  public form: UntypedFormGroup;
  public readonly propagationMode = PropagationMode;

  private get formRawValue(): Partial<Organization> {
    return this.form.getRawValue();
  }

  public readonly clientCardService = inject(ClientCardService);

  private readonly logService = inject(LogService);
  private readonly ngbActiveModal = inject(NgbActiveModal);
  private readonly destroyRef = inject(DestroyRef);
  private readonly customFieldService = inject(CustomFieldService);
  private readonly fb = inject(UntypedFormBuilder);
  private readonly notificationService = inject(NotificationService);

  constructor() {
    this.form = this.fb.group({
      name: [
        '',
        [
          Validators.required,
          Validators.maxLength(Constants.formNameMaxLength),
        ],
      ],
      code: ['', [Validators.maxLength(Constants.formCodeMaxLength)]],
      description: ['', [Validators.maxLength(Constants.formTextMaxLength)]],
      manager: [null, [Validators.required]],
      billingEqualPost: [false],
      billingAddress: this.fb.group({
        address: [null, [Validators.maxLength(Constants.formTextMaxLength)]],
        city: [null, [Validators.maxLength(Constants.formTextMaxLength)]],
        postIndex: [null, [Validators.maxLength(Constants.formTextMaxLength)]],
        state: [null, [Validators.maxLength(Constants.formTextMaxLength)]],
      }),
      postAddress: this.fb.group({
        address: [null, [Validators.maxLength(Constants.formTextMaxLength)]],
        city: [null, [Validators.maxLength(Constants.formTextMaxLength)]],
        postIndex: [null, [Validators.maxLength(Constants.formTextMaxLength)]],
        state: [null, [Validators.maxLength(Constants.formTextMaxLength)]],
      }),
      contact: ['', [Validators.maxLength(Constants.formTextMaxLength)]],
      contactEmail: ['', emailValidator()],
      phone: [
        '',
        [
          Validators.minLength(Constants.minPhoneLength),
          Validators.maxLength(Constants.maxPhoneLength),
        ],
      ],
      site: ['', urlValidator()],
    });

    this.customFieldService.enrichFormGroup(this.form, 'Organization');

    this.customFieldService.enrichFormGroupWithDefaultValues(
      this.form,
      'Organization',
    );

    this.form
      .get('postAddress')
      .valueChanges.pipe(
        filter(() => this.form.get('billingEqualPost').value),
        takeUntilDestroyed(),
      )
      .subscribe(() => {
        this.form
          .get('billingAddress')
          .patchValue(this.form.get('postAddress').value, {
            emitEvent: false,
          });
      });

    this.form
      .get('billingEqualPost')
      .valueChanges.pipe(takeUntilDestroyed())
      .subscribe(() => this.setAddressesControlsState());
  }

  /** Creates new client. */
  public create(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      this.notificationService.warningLocal(
        'shared.messages.requiredFieldsError',
      );
      return;
    }

    this.isSaving.set(true);
    this.logService.debug('Saving client entity...');

    const clientData: Partial<Organization> = Object.assign(
      {},
      this.formRawValue,
      {
        managerId: this.formRawValue['manager'].id || null,
      },
    );
    delete clientData['manager'];

    this.clientCardService.clientCollection
      .insert(clientData)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: Organization) => {
          this.notificationService.successLocal(
            'projects.clients.settings.messages.created',
          );
          this.isSaving.set(false);
          this.ngbActiveModal.close(response);
        },
        error: (error: Exception) => {
          this.isSaving.set(false);
          this.notificationService.error(error.message);
        },
      });
  }

  /** Closes modal window. */
  public cancel(): void {
    this.ngbActiveModal.dismiss('cancel');
  }

  /** Disable or enable addresses controls depending on billingEqualPost value. */
  private setAddressesControlsState(): void {
    if (this.form.get('billingEqualPost').value) {
      this.form.get('billingAddress').disable({ emitEvent: false });

      this.form
        .get('billingAddress')
        .patchValue(this.form.get('postAddress').value, {
          emitEvent: false,
        });
    } else {
      this.form.get('billingAddress').enable({ emitEvent: false });
    }
  }
}
