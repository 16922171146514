import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { AbstractToolbar } from 'src/app/shared-features/grid/abstract-toolbar.directive';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { TranslateStubModule } from 'src/app/testing/translate-stub.module';

@Component({
  selector: 'tmt-task-dependencies-toolbar',
  imports: [TranslateStubModule, AsyncPipe],
  templateUrl: `./assignments-toolbar.component.html`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssignmentsToolbarComponent extends AbstractToolbar {
  constructor(gridService: GridService, cdr: ChangeDetectorRef) {
    super(gridService, cdr);
  }
}
