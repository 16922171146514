import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  ChartDataset,
  SortedData,
} from 'src/app/analytics/dashboards/dashboard/widget/models/widget-stacked-column.model';

@Injectable()
export class WidgetDataService {
  public emptyValue = this.translate.instant('shared2.props.empty');
  public yesLabel = this.translate.instant('shared2.props.yes');
  public noLabel = this.translate.instant('shared2.props.no');

  constructor(private translate: TranslateService) {}

  /**
   * Gets chart data set.
   *
   * @param data Sorted chart data.
   * @returns Chart data set.
   */
  public getChartDataset(data: SortedData[]): ChartDataset[] {
    const legendsSet = new Set();

    data.forEach((item: SortedData) => {
      Object.keys(item.detailed).forEach((legend: string) => {
        legendsSet.add(legend);
      });
    });

    const legends = Array.from(legendsSet);
    const datasets = legends.map((legend: string) => ({
      label: legend,
      data: [],
    }));

    data.forEach((item: SortedData) => {
      datasets.forEach((dataset: ChartDataset) => {
        if (item.detailed[dataset.label] === 0) return;
        dataset.data.push(+item.detailed[dataset.label] || 0);
      });
    });

    return datasets;
  }

  /**
   * Returns the field index by its name.
   *
   * @param name - Name of the field to find the index.
   * @param fieldNames - An array of field names to search.
   * @returns The index of the field.
   */
  public getFieldIndex(name: string, fieldNames: string[]): number {
    return fieldNames.indexOf(name);
  }
}
