@if (!gridService.readonly) {
  <div class="toolbar-container pt-0">
    <div class="group">
      <button
        type="button"
        class="btn btn-default"
        title="{{ 'shared2.actions.addLine' | translate }}"
        [disabled]="
          !this.gridService.canBeExecuted('create', gridService.selectedGroup$.getValue())
        "
        (click)="gridService.execute('create')"
        data-test="addLine"
      >
        <i class="bi bi-plus-lg bi-15" aria-hidden="true"></i>
        {{ 'shared2.actions.addLine' | translate }}
      </button>
      <button
        type="button"
        class="btn btn-default"
        (click)="gridService.execute('delete', gridService.selectedGroup$.getValue())"
        [disabled]="!gridService.canBeExecuted('delete', gridService.selectedGroup$.getValue())"
        data-test="delete"
      >
        <span class="bi bi-trash3"></span>
      </button>
    </div>
  </div>
}
