import { Inject, Injectable } from '@angular/core';
import {
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { BehaviorSubject, forkJoin, Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';
import { Dictionary } from 'src/app/shared/models/dictionary';
import {
  ProjectTask,
  ProjectTaskAssignment,
  ProjectTaskDependency,
  ProjectTaskType,
} from 'src/app/shared/models/entities/projects/project-task.model';
import { Project } from 'src/app/shared/models/entities/projects/project.model';
import { Exception } from 'src/app/shared/models/exception';
import { ProjectTeamService } from 'src/app/shared/services/project-team.service';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { ProjectVersionDataService } from '../../../project-versions/project-version-data.service';
import { ProjectVersionUtil } from '../../../project-versions/project-version-util';
import { ProjectVersionCardService } from '../../core/project-version-card.service';
import { ScheduleService } from 'src/app/core/schedule.service';
import { ProjectCardService } from 'src/app/projects/card/core/project-card.service';
import { ProjectTeamMember } from 'src/app/shared/models/entities/projects/project-team-member.model';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { ProjectTasksCommandsService } from 'src/app/projects/card/project-tasks/shared/tasks-grid/project-tasks-commands.service';
import { ProjectTaskView } from 'src/app/projects/card/project-tasks/shared/models/project-task-view.enum';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { Guid } from 'src/app/shared/helpers/guid';

@Injectable()
export class ProjectTasksDataService {
  private destroyed$ = new Subject<void>();

  public undoRedoSessionId: string;

  /** Reload data subject */
  private reloadSubject = new Subject<void>();
  public reload$ = this.reloadSubject.asObservable();

  /** Loading data subject */
  private isLoadingSubject = new Subject<boolean>();
  public isLoading$ = this.isLoadingSubject.asObservable();

  /** Update data subject */
  private updateSubject = new Subject<void>();
  public update$ = this.updateSubject.asObservable();

  /** Sync dependencies subject. */
  private syncDependenciesSubject = new Subject<void>();
  public syncDependencies$ = this.syncDependenciesSubject.asObservable();

  public readonly = false;
  public isInherited = false;

  public formArray: UntypedFormArray = this.fb.array([]);
  public totals: Dictionary<number> = {};

  public readonly projectTaskView = ProjectTaskView;

  // Represents the dates changed in the timeline. If true, no need add a duration for task moving.
  public timelineDateChanges: { startDate: boolean; endDate: boolean } = {
    startDate: false,
    endDate: false,
  };

  public project: Project;

  public getTasksCollection = () => this.data.collection('ProjectTasks');

  public summaryFields = [
    'plannedHours',
    'plannedCost',
    'estimatedHours',
    'estimatedCost',
    'actualHours',
    'actualCost',
    'percentComplete',
  ];

  public summaryEditableFields = ['estimatedHours'];

  /** Loaded task list. Includes all tasks of the project. */
  public tasks: ProjectTask[];
  public members: ProjectTeamMember[];

  public deletingTaskIds: string[] = [];
  public creatingTaskIds: string[] = [];

  public isShowMainTask$ = new BehaviorSubject<boolean>(true);
  public isShowPlannedTasks$ = new BehaviorSubject<boolean>(false);
  public isShowCriticalPath$ = new BehaviorSubject<boolean>(false);

  public criticalDependencies$ = new BehaviorSubject<
    {
      taskId: string;
      dependency: ProjectTaskDependency;
    }[]
  >([]);
  public criticalTaskIds$ = new BehaviorSubject<string[]>([]);

  private loadingSubscription: Subscription;
  private blockFormGroupChangeListener = false;

  constructor(
    @Inject('entityId') public entityId: string,
    public scheduleService: ScheduleService,
    private commands: ProjectTasksCommandsService,
    private projectTeamService: ProjectTeamService,
    private autosave: SavingQueueService,
    private versionCardService: ProjectVersionCardService,
    private versionDataService: ProjectVersionDataService,
    private data: DataService,
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private customFieldService: CustomFieldService,
    private projectCardService: ProjectCardService,
    private translateService: TranslateService,
    private blockUI: BlockUIService,
  ) {}

  /** Returns actual task row version.
   *
   * @param taskId id of the target task
   * @returns row version of the target task
   */
  public getRowVersion = (taskId: string): string =>
    this.getTask(taskId).rowVersion;

  /**
   * Reloads data
   */
  public reload() {
    this.reloadSubject.next();
  }

  /**
   * Loads project data with optional loading bar display.
   *
   * @param isShowLoadingBar Flag to show loading bar (default: true)
   */
  public load(isShowLoadingBar = true) {
    this.projectTeamService.reset(this.versionCardService.projectVersion);
    this.autosave.save().then(
      () => {
        if (isShowLoadingBar) {
          this.formArray.clear();
          this.totals = null;
          this.tasks = [];
        }

        if (this.loadingSubscription) {
          this.loadingSubscription.unsubscribe();
        }

        if (isShowLoadingBar) {
          this.setIsLoading(true);
        } else {
          this.blockUI.start();
        }

        /** Generate a request. */
        const query = this.getTasksQuery();
        this.loadingSubscription = forkJoin({
          members: this.projectTeamService.getTeamMembers(
            this.versionCardService.projectVersion,
          ),
          totalTasks: this.versionDataService
            .projectCollectionEntity(
              this.versionCardService.projectVersion,
              this.entityId,
            )
            .function('GetTasks')
            .query(null, query),
        }).subscribe({
          next: (result) => {
            this.members = result.members;
            this.tasks = result.totalTasks as ProjectTask[];
            // Prepares tasks for view.
            this.tasks.forEach((totalTask) => {
              this.prepareTaskPropertyForView(totalTask);
              // Set task percent complete
              totalTask.percentComplete =
                totalTask.estimatedHours && totalTask.actualHours
                  ? totalTask.actualHours / totalTask.estimatedHours
                  : 0;
              totalTask.estimatedHours ??= null;
              totalTask.actualHours ??= null;
              totalTask.plannedStartDate ??= null;
              totalTask.plannedEndDate ??= null;
            });

            // Expands main task.
            const mainTask = this.tasks.find((task) => !task.leadTaskId);
            this.addExpanded(mainTask?.id);

            this.updateSummaryValues();
            this.updateView();
            this.syncDependenciesSubject.next();

            if (isShowLoadingBar) {
              this.setIsLoading(false);
            } else {
              this.blockUI.stop();
            }
          },
          error: (error: Exception) => {
            this.notification.error(error.message);
            if (isShowLoadingBar) {
              this.setIsLoading(false);
            } else {
              this.blockUI.stop();
            }
          },
        });
      },
      () => null,
    );
  }

  /** Change some properties in the task for work with them in the UI.
   *
   * @param task task for modify.
   */
  public prepareTaskPropertyForView(task: Partial<ProjectTask>): void {
    if (task.updatedAssignments) {
      task.projectTaskAssignments = task.updatedAssignments;
    }
    Object.keys(task).forEach((key) => {
      switch (key) {
        case 'projectTaskAssignments':
          task.projectTaskAssignments.forEach((ass) => {
            if (ass.projectTeamMemberId) {
              ass.projectTeamMember = this.members.find(
                (m) => m.id === ass.projectTeamMemberId,
              );
            }
          });
          break;
      }
    });
  }

  /** Determines if the task is leading.
   *
   * @param taskId - id of the looking task
   * @param targetTasks - project tasks array for search (default uses array from this service)
   */
  public checkIsLeadTask(taskId: string, targetTasks?: ProjectTask[]): boolean {
    const tasks = targetTasks ? targetTasks : this.tasks;
    if (tasks.length === 1) {
      return false;
    }
    return taskId ? !!tasks.find((task) => task.leadTaskId === taskId) : false;
  }

  /**
   * Returns tasks query for request and enriches query with custom fields.
   *
   * @returns tasks query for request
   */
  private getTasksQuery() {
    const query: any = {
      select: [
        'id',
        'name',
        'description',
        'number',
        'indent',
        'leadTaskId',
        'allowTimeEntry',
        'startDate',
        'endDate',
        'estimatedCost',
        'estimatedHours',
        'plannedCost',
        'plannedHours',
        'actualCost',
        'actualHours',
        'isActive',
        'rowVersion',
        'dependencies',
        'structNumber',
        'type',
        'effortDriven',
        'duration',
        'isMilestone',
        'plannedStartDate',
        'plannedEndDate',
      ],
      expand: [
        {
          projectTaskAssignments: {
            select: [
              'id',
              'isAllTeamRole',
              'isUnassigned',
              'projectTariffId',
              'projectTeamMemberId',
              'units',
            ],
            expand: [
              {
                projectTariff: {
                  select: ['id', 'name'],
                },
              },
            ],
          },
        },
      ],
    };

    ProjectVersionUtil.addProjectSelectFields(
      this.versionCardService.projectVersion,
      query.select,
    );
    this.customFieldService.enrichQuery(query, 'ProjectTask');

    return query;
  }

  /** Adds task to expanded tasks list. */
  public addExpanded(id: string) {
    if (!this.projectCardService.expandedTasks.includes(id)) {
      this.projectCardService.expandedTasks.push(id);
    }
  }

  /** Updates summary values for collapsible cells. */
  public updateSummaryValues(): void {
    if (!this.tasks.length) {
      return;
    }

    this.tasks.forEach((task) => {
      this.summaryFields.forEach((f) => {
        task[`${f}Sum`] = 0;
      });
    });

    let maxIndent = _.maxBy(this.tasks, (t) => t.indent).indent;
    while (maxIndent !== -1) {
      const tasksInIndent = this.tasks.filter((t) => t.indent === maxIndent);

      tasksInIndent.forEach((task) => {
        this.summaryFields.forEach((f) => {
          task[`${f}Sum`] += task[f] ?? 0;
        });

        // Add a value to the main task.
        if (task.leadTaskId) {
          const leadTask = this.tasks.find((t) => t.id === task.leadTaskId);
          this.summaryFields.forEach((f) => {
            leadTask[`${f}Sum`] += task[`${f}Sum`] ?? 0;
          });
        }
      });
      maxIndent--;
    }

    this.tasks.forEach((task) => {
      this.summaryFields.forEach((f) => {
        task[`${f}Sum`] = task[`${f}Sum`] === 0 ? null : task[`${f}Sum`];
      });
    });

    this.updatePercentCompleteSums();
  }

  /**
   * Updates the formArray based on tasks.
   */
  public updateView() {
    // Построить список задач, который должен быть отображен.
    const visibleTasks = this.getVisibleTasks();

    // Обновить структурные номера.
    this.commands.updateStructNumbers(
      this.tasks.filter((task) => !task.leadTaskId),
      this.tasks,
    );

    // Обновить суммарные значения.
    this.updateSummaryValues();

    // Обновить итоги.
    this.updateTotals();

    // Скорректировать структуру формы грида.
    for (let index = 0; index < visibleTasks.length; index++) {
      const task = visibleTasks[index];

      // Для задачи в нужной позиции есть группа.
      if (task.id === this.formArray.at(index)?.value.id) {
        continue;
      }

      const groupIndex = _.findIndex(
        this.formArray.value,
        (r: any) => r.id === task.id,
        index + 1,
      );

      // Группы нет в списке вообще.
      if (groupIndex === -1) {
        this.formArray.insert(index, this.getFormGroup());
        continue;
      }

      // Группа есть, но дальше.
      const group = this.formArray.at(groupIndex);
      this.formArray.removeAt(groupIndex);
      this.formArray.insert(index, group);
    }

    // Убрать "лишние" группы.
    while (visibleTasks.length !== this.formArray.controls.length) {
      this.formArray.removeAt(this.formArray.controls.length - 1);
    }

    // Загрузить данные в форму.
    this.blockFormGroupChangeListener = true;
    this.formArray.patchValue(visibleTasks);
    this.blockFormGroupChangeListener = false;

    this.updatePlanningFields();

    // Disable name and description for main task.
    const rootTaskIndex = 0;
    const rootTaskFormGroup = this.formArray.at(
      rootTaskIndex,
    ) as UntypedFormGroup;
    if (rootTaskFormGroup) {
      if (this.isShowMainTask$.getValue()) {
        rootTaskFormGroup.controls.name.disable({
          emitEvent: false,
        });
        rootTaskFormGroup.controls.description.disable({
          emitEvent: false,
        });
      } else {
        rootTaskFormGroup.controls.name.enable({
          emitEvent: false,
        });
        rootTaskFormGroup.controls.description.enable({
          emitEvent: false,
        });
      }
    }

    // Отработать изменение в гриде.
    this.detectChanges();
  }

  /** Returns are there expanded tasks. */
  public isExpanded(id: string): boolean {
    return this.projectCardService.expandedTasks.includes(id);
  }

  /** Patches task by saving queue.
   *
   * @param taskId task id.
   * @param taskToPatch task patch DTO.
   */
  public addSavingQueuePatch(
    taskId: string,
    taskToPatch: Partial<ProjectTask>,
  ) {
    this.autosave.addToQueuePatch(
      taskId,
      () => this.getRowVersion(taskId),
      (taskDTO: Partial<ProjectTask>) =>
        this.data.collection('ProjectTasks').entity(taskId).patch(taskDTO, {
          withResponse: true,
          undoRedoSessionId: this.undoRedoSessionId,
        }),
      taskToPatch,
      !taskToPatch.startDate &&
        !taskToPatch.endDate &&
        !taskToPatch.duration &&
        !taskToPatch.projectTaskAssignments,
    );
  }

  /** Updates the totals line. */
  private updateTotals() {
    this.totals = {};
    this.summaryFields.forEach((field) => (this.totals[field] = 0));
    this.totals['name'] = 0;

    const currencyFields = ['plannedCost', 'estimatedCost', 'actualCost'];
    const projectCurrencyCode =
      this.projectCardService.project.currency.alpha3Code;
    currencyFields.forEach((field) => {
      (this.totals[field] as any) = {
        value: 0,
        currencyCode: projectCurrencyCode,
      };
    });

    this.tasks.forEach((task) => {
      this.summaryFields.forEach((field) => {
        if (_.isObject(this.totals[field])) {
          (this.totals[field] as any).value += task[field] ?? 0;
        } else {
          this.totals[field] += task[field] ?? 0;
        }
      });
      this.totals['name']++;
    });
  }

  private getFormGroup() {
    const group = this.fb.group({
      id: null,
      isExpanded: null,
      leadTaskId: null,
      indent: null,
      description: null,
      structNumber: null,
      name: new UntypedFormControl({
        value: '',
        disabled: this.isInherited,
      }),
      duration: null,
      startDate: null,
      endDate: null,
      plannedHours: null,
      plannedCost: null,
      estimatedHours: null,
      estimatedCost: null,
      actualHours: null,
      actualCost: null,
      percentComplete: null,
      allowTimeEntry: null,
      isActive: new UntypedFormControl({
        value: false,
        disabled: this.isInherited,
      }),
      projectTaskAssignments: null,
      dependencies: null,
      effortDriven: null,
      type: null,
      isMilestone: null,
      plannedStartDate: null,
      plannedEndDate: null,
    });

    this.summaryFields.forEach((f) => {
      const control = new UntypedFormControl({ value: null });

      group.addControl(`${f}Sum`, control);
    });

    this.customFieldService.enrichFormGroup(group, 'ProjectTask');

    this.summaryEditableFields.forEach((f) => {
      group.controls[f].valueChanges
        .pipe(
          filter(() => !this.blockFormGroupChangeListener),
          takeUntil(this.destroyed$),
        )
        .subscribe(() => {
          setTimeout(() => {
            this.updateSummaryValues();
            this.applySummaryValues();
            this.updateTotals();
          });
        });
    });

    group.controls['type'].valueChanges
      .pipe(
        filter(() => !this.blockFormGroupChangeListener),
        takeUntil(this.destroyed$),
      )
      .subscribe((value) => {
        if (value.id === ProjectTaskType.fixedWork) {
          group.controls.effortDriven.setValue(true, { emitEvent: false });
          group.controls.effortDriven.disable({ emitEvent: false });
        } else {
          group.controls.effortDriven.enable({ emitEvent: false });
        }
      });

    group.valueChanges
      .pipe(
        filter(() => !this.blockFormGroupChangeListener),
        takeUntil(this.destroyed$),
      )
      .subscribe((groupValue) => {
        const task = this.getTask(groupValue.id);
        const oldTask = _.cloneDeep(task);
        const groupRawValue = group.getRawValue();
        Object.assign(task, groupRawValue);

        // Repair task field values to initial type.
        // Form array works with named entity, but data service has string.
        task.type = groupRawValue.type.id;

        const taskPartsToPatch = this.getTaskPartsToPatch(oldTask, task);

        taskPartsToPatch.forEach((part) => {
          this.addSavingQueuePatch(task.id, part);
        });
      });

    return group;
  }

  /** Returns all visible tasks in hierarchy */
  public getVisibleTasks(): ProjectTask[] {
    const visibleTasks: ProjectTask[] = [];
    const traverse = (renderTasks: ProjectTask[]) => {
      const sortedTasks = _.sortBy(renderTasks, ['number']);
      sortedTasks.forEach((task) => {
        const childTasks = this.tasks.filter((t) => t.leadTaskId === task.id);
        if (childTasks.length > 0) {
          task.isExpanded = this.isExpanded(task.id);
        } else {
          task.isExpanded = null;
        }

        visibleTasks.push(task);
        if (task.isExpanded) {
          traverse(childTasks);
        }
      });
    };
    const tasks = this.tasks.filter((task) => !task.leadTaskId);
    traverse(tasks);
    return this.isShowMainTask$.getValue()
      ? visibleTasks
      : visibleTasks.filter((task) => task.leadTaskId);
  }

  /** Gets a task to save. */
  public getTaskToSave(taskId: string): any {
    const task = this.getTask(taskId);
    const taskForSaving: any = _.pick(task, this.getUpdateFields());

    ProjectVersionUtil.setEntityRootPropertyId(
      this.versionCardService.projectVersion,
      taskForSaving,
      this.entityId,
    );

    this.customFieldService.assignValues(taskForSaving, task, 'ProjectTask');

    if (task.projectTaskAssignments) {
      taskForSaving.projectTaskAssignments = this.getAssignmentsDTO(
        task.projectTaskAssignments,
      );
    }

    return taskForSaving;
  }

  private getUpdateFields(): string[] {
    const updateFields = [
      'id',
      'name',
      'description',
      'number',
      'allowTimeEntry',
      'leadTaskId',
      'rowVersion',
      'isActive',
      'endDate',
      'startDate',
      'duration',
      'projectTaskAssignments',
      'dependencies',
      'effortDriven',
      'type',
      'estimatedHours',
      'isMilestone',
    ];

    ProjectVersionUtil.addProjectSelectFields(
      this.versionCardService.projectVersion,
      updateFields,
    );

    return updateFields;
  }

  /**
   * Updates type and planning fields for task form.
   */
  private updatePlanningFields() {
    this.formArray.controls.forEach((fg: FormGroup) => {
      const taskValue = fg.getRawValue();
      fg.controls.type.setValue(
        {
          id: taskValue.type as ProjectTaskType,
          name: this.translateService.instant(
            `enums.taskTypes.${taskValue.type}`,
          ),
        },
        { emitEvent: false },
      );

      if (this.project.isAutoPlanning) {
        if (this.isSummaryTask(taskValue)) {
          fg.controls.estimatedHours.disable({ emitEvent: false });
        } else {
          fg.controls.estimatedHours.enable({ emitEvent: false });
        }
        fg.controls.type.enable({ emitEvent: false });
      } else {
        fg.controls.estimatedHours.disable({ emitEvent: false });
        fg.controls.type.disable({ emitEvent: false });
        fg.controls.effortDriven.disable({ emitEvent: false });
      }

      if (this.project.isAutoPlanning && this.isSummaryTask(taskValue)) {
        fg.controls.projectTaskAssignments.disable({ emitEvent: false });
      } else {
        fg.controls.projectTaskAssignments.enable({ emitEvent: false });
      }

      if (taskValue.type === ProjectTaskType.fixedWork) {
        fg.controls.effortDriven.setValue(true, { emitEvent: false });
        fg.controls.effortDriven.disable({ emitEvent: false });
      } else {
        fg.controls.effortDriven.enable({ emitEvent: false });
      }
    });
  }

  /** Обновляет значения для "схопываемых" ячеек.
   * Дублирует updateView но затрагивает меньшее число ячеек для обновления.
   */
  private applySummaryValues() {
    const controls = this.formArray.controls;
    controls.forEach((formGroup: UntypedFormGroup) => {
      const task = this.tasks.find((t) => t.id === formGroup.value.id);
      this.summaryFields.forEach((field) => {
        const name = `${field}Sum`;
        if (task[name] !== formGroup.value[name]) {
          formGroup.controls[name].setValue(task[name], { emitEvent: false });
        }
      });
    });
  }

  /** Returns task by id. */
  public getTask(id: string): ProjectTask {
    return this.tasks.find((task) => task.id === id);
  }

  /** Sets the download status. */
  public setIsLoading(isLoading: boolean) {
    this.isLoadingSubject.next(isLoading);
  }

  /** Runs a changes detection */
  public detectChanges() {
    this.updateSubject.next();
  }

  /** Unsubscribes from all possible subscriptions of the service. */
  public dispose() {
    this.loadingSubscription?.unsubscribe();
    this.destroyed$.next();
  }

  /**
   * Prepares task parts for patching based on the differences between the old and new task.
   *
   * @param oldTask The old task to compare.
   * @param newTask The new task to compare.
   * @returns An array of partial ProjectTask objects representing the parts to patch.
   */
  public getTaskPartsToPatch(
    oldTask: ProjectTask,
    newTask: ProjectTask,
  ): Partial<ProjectTask>[] {
    const changedFields = this.getChangedFields(oldTask, newTask);

    const taskToSaving = this.getTaskToSave(newTask.id);

    this.enrichListWithRequiredFields(changedFields, newTask.type);
    const taskForPatching: Partial<ProjectTask> = _.pick(
      taskToSaving,
      changedFields,
    );

    const oldTaskAssignmentsDTO = this.getAssignmentsDTO(
      oldTask.projectTaskAssignments,
    );
    if (!taskForPatching.projectTaskAssignments) {
      return [taskForPatching];
    }

    // Handle projectTaskAssignments
    if (
      taskForPatching.projectTaskAssignments &&
      !_.isEqual(oldTaskAssignmentsDTO, taskForPatching.projectTaskAssignments)
    ) {
      const newAssignmentsParts = this.getAssignmentsParts(
        oldTaskAssignmentsDTO,
        taskForPatching.projectTaskAssignments,
      );
      delete taskForPatching.projectTaskAssignments;
      const partsToPatch = [];
      if (Object.keys(taskForPatching).length) {
        partsToPatch.push(taskForPatching);
      }

      newAssignmentsParts.forEach((assignments) =>
        partsToPatch.push({
          projectTaskAssignments: assignments,
        }),
      );

      return partsToPatch;
    } else {
      return [];
    }
  }

  /**
   * Converts project task assignments to DTO format.
   *
   * @param projectTaskAssignments The project task assignments to convert.
   * @returns An array of ProjectTaskAssignment objects in DTO format.
   */
  private getAssignmentsDTO(
    projectTaskAssignments: ProjectTaskAssignment[],
  ): ProjectTaskAssignment[] {
    const assignmentsDTO = [];
    projectTaskAssignments.forEach((assignment: ProjectTaskAssignment) => {
      assignmentsDTO.push({
        id: assignment.id,
        projectTeamMemberId: assignment.projectTeamMember
          ? assignment.projectTeamMember.id
          : null,
        isAllTeamRole: assignment.isAllTeamRole,
        isUnassigned: assignment.isUnassigned,
        units: assignment.units,
        projectTariffId: assignment.projectTariffId,
      });
    });

    if (!projectTaskAssignments.length && this.project.isAutoPlanning) {
      assignmentsDTO.push({
        id: Guid.generate(),
        projectTeamMemberId: null,
        isAllTeamRole: false,
        isUnassigned: true,
        units: null,
        projectTariffId: null,
      });
    }
    return assignmentsDTO;
  }

  /** Updates summary percent complete of project tasks. */
  private updatePercentCompleteSums() {
    this.tasks.forEach((task) => {
      task['percentCompleteSum'] =
        task['estimatedHoursSum'] && task['actualHoursSum']
          ? task['actualHoursSum'] / task['estimatedHoursSum']
          : 0;
    });
  }

  /**
   * Identifies and separates project task assignments into parts based on changes.
   *
   * This method takes two arrays of project task assignments, old and new, and identifies
   * the changes between them. It then separates these changes into distinct parts based
   * on the type of change: deletions, changes to member ID, additions and changes to units. Each part is
   * an array of project task assignments that have undergone a specific type of change.
   *
   * @param oldAssignments The original project task assignments.
   * @param newAssignments The updated project task assignments.
   * @returns An array of arrays, where each inner array represents a part of changes.
   */
  private getAssignmentsParts(
    oldAssignments: ProjectTaskAssignment[],
    newAssignments: ProjectTaskAssignment[],
  ): ProjectTaskAssignment[][] {
    // Sort arrays for correct isEqual comparing
    oldAssignments = _.sortBy(oldAssignments, 'id');
    newAssignments = _.sortBy(newAssignments, 'id');

    const parts: ProjectTaskAssignment[][] = [];

    // Processing deletions
    const deleteAssignmentsPart = [];
    newAssignments.forEach((newAss) => {
      const preservedAss = oldAssignments.find((ass) => ass.id === newAss.id);
      if (preservedAss) {
        deleteAssignmentsPart.push(preservedAss);
      }
    });
    if (!_.isEqual(deleteAssignmentsPart, oldAssignments)) {
      parts.push(deleteAssignmentsPart);
    }

    // Processing changes to member ID or changes to tariff
    const changedMemberIdPart = structuredClone(deleteAssignmentsPart);
    newAssignments.forEach((newAss) => {
      const newMemberPreservedAss = changedMemberIdPart.find(
        (ass) =>
          ass.id === newAss.id &&
          (ass.projectTeamMemberId !== newAss.projectTeamMemberId ||
            ass.projectTariffId !== newAss.projectTariffId),
      );
      if (newMemberPreservedAss) {
        newMemberPreservedAss.projectTeamMemberId = newAss.projectTeamMemberId;
        newMemberPreservedAss.projectTariffId = newAss.projectTariffId;
      }
    });
    if (!_.isEqual(changedMemberIdPart, deleteAssignmentsPart)) {
      parts.push(changedMemberIdPart);
    }

    // Processing additions
    const addAssignmentsPart = structuredClone(changedMemberIdPart);
    newAssignments.forEach((newAss) => {
      const preservedAss = oldAssignments.find((ass) => ass.id === newAss.id);
      if (!preservedAss) {
        addAssignmentsPart.push(newAss);
      }
    });
    if (!_.isEqual(addAssignmentsPart, changedMemberIdPart)) {
      parts.push(addAssignmentsPart);
    }

    // Processing changes to units
    const changedUnitsPart = structuredClone(addAssignmentsPart);
    newAssignments.forEach((newAss) => {
      const newMemberPreservedAss = changedUnitsPart.find(
        (ass) => ass.id === newAss.id && ass.units !== newAss.units,
      );
      if (newMemberPreservedAss) {
        newMemberPreservedAss.units = newAss.units;
      }
    });
    if (!_.isEqual(changedUnitsPart, addAssignmentsPart)) {
      parts.push(changedUnitsPart);
    }

    return parts;
  }

  /** Returns list of the keys with not equal task and new task values.
   *
   * @param task old task.
   * @param newTask new task.
   * @returns list of the keys.
   */
  private getChangedFields(task: ProjectTask, newTask: ProjectTask): string[] {
    const keysToSave: string[] = [];
    Object.keys(task).forEach((key) => {
      if (!_.isEqual(task[key], newTask[key])) {
        keysToSave.push(key);
      }
    });
    return keysToSave;
  }

  /** Enriches task fields with request required fields.
   *
   * @param fields initial fields
   * @param type updated task type
   */
  private enrichListWithRequiredFields(
    fields: string[],
    type: ProjectTaskType,
  ): void {
    if (fields.includes('startDate') && !fields.includes('duration')) {
      if (this.timelineDateChanges.startDate) {
        this.timelineDateChanges.startDate = false;
      } else {
        fields.push('duration');
      }
    }

    if (
      this.project.isAutoPlanning &&
      type === ProjectTaskType.fixedDuration &&
      fields.includes('endDate') &&
      !fields.includes('duration')
    ) {
      if (this.timelineDateChanges.endDate) {
        this.timelineDateChanges.endDate = false;
      } else {
        fields.push('duration');
      }
    }
  }

  /**
   *  Returns is target task summary.
   *
   * @param task target task.
   * @returns is task summary.
   */
  private isSummaryTask(task: ProjectTask): boolean {
    return !!this.tasks.find((t) => t.leadTaskId === task.id);
  }
}
