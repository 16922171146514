import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { VIEW_NAME, LIST } from 'src/app/shared/tokens';
import { Transition } from '@uirouter/core';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { ROUTE_DEFAULT_PARAMS } from 'src/app/app.states';
import { DashboardListComponent } from './list/dashboard-list.component';
import { DashboardList } from 'src/app/shared/lists/dashboard.list';
import { DashboardSettingsComponent } from './settings/dashboard-settings.component';
import { GridsterModule } from 'angular2gridster';
import { DashboardPropertiesComponent } from './settings/dashboard-properties/dashboard-properties.component';
import { AnalyticsSharedModule } from '../shared/analytics-shared.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { WidgetComponent } from './dashboard/widget/widget.component';
import { WidgetSettingsComponent } from './dashboard/widget/widget-settings/widget-settings.component';
import { WidgetPropertiesComponent } from './dashboard/widget/widget-settings/widget-properties/widget-properties.component';
import { WidgetChartComponent } from './dashboard/widget/widget-chart/widget-chart.component';
import { WidgetDoubleValuePropertiesComponent } from './dashboard/widget/widget-settings/widget-properties/widget-double-value-properties/widget-double-value-properties.component';
import { WidgetPiePropertiesComponent } from './dashboard/widget/widget-settings/widget-properties/widget-pie-properties/widget-pie-properties.component';
import { WidgetColumnsPropertiesComponent } from './dashboard/widget/widget-settings/widget-properties/widget-columns-properties/widget-columns-properties.component';
import { WidgetLinePropertiesComponent } from './dashboard/widget/widget-settings/widget-properties/widget-line-properties/widget-line-properties.component';
import { WidgetSpeedometerPropertiesComponent } from './dashboard/widget/widget-settings/widget-properties/widget-speedometer-properties/widget-speedometer-properties.component';
import { WidgetFramePropertiesComponent } from './dashboard/widget/widget-settings/widget-properties/widget-frame-properties/widget-frame-properties.component';
import { WidgetFrameComponent } from './dashboard/widget/widget-frame/widget-frame.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { WidgetTableComponent } from './dashboard/widget/widget-table/widget-table.component';
import { WidgetValuePropertiesComponent } from './dashboard/widget/widget-settings/widget-properties/widget-value-properties/widget-value-properties.component';
import { CopyDashboardModalComponent } from './dashboard/copy-dashboard-modal/copy-dashboard-modal.component';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';
import { PerformersModule } from 'src/app/settings-app/shared/performers/performers.module';
import { NavigationItemsComponent } from 'src/app/shared/components/navigations/navigation-items.component';
import { GaugeComponent } from 'src/app/analytics/dashboards/dashboard/widget/widget-gauge/widget-gauge.component';
import { HistogramChartComponent } from 'src/app/analytics/dashboards/dashboard/widget/widget-histogram/widget-histogram-chart.component';
import { WidgetFunnelChartComponent } from 'src/app/analytics/dashboards/dashboard/widget/funnel/widget-funnel-chart.component';
import { WidgetPieChartComponent } from 'src/app/analytics/dashboards/dashboard/widget/pie/widget-pie-chart.component';

@NgModule({
  declarations: [
    DashboardComponent,
    DashboardListComponent,
    DashboardSettingsComponent,
    DashboardPropertiesComponent,
    WidgetComponent,
    WidgetSettingsComponent,
    WidgetPropertiesComponent,
    WidgetChartComponent,
    WidgetDoubleValuePropertiesComponent,
    WidgetValuePropertiesComponent,
    WidgetPiePropertiesComponent,
    WidgetColumnsPropertiesComponent,
    WidgetLinePropertiesComponent,
    WidgetSpeedometerPropertiesComponent,
    WidgetFramePropertiesComponent,
    WidgetFrameComponent,
    WidgetTableComponent,
    CopyDashboardModalComponent,
    GaugeComponent,
    HistogramChartComponent,
    WidgetFunnelChartComponent,
    WidgetPieChartComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ColorPickerModule,
    AnalyticsSharedModule,
    EntityListComponent,
    GridsterModule.forRoot(),
    UIRouterModule.forChild({
      states: [
        {
          name: 'dashboards',
          url: '/dashboards?{navigation}&{route}',
          component: DashboardListComponent,
          resolve: [
            { provide: VIEW_NAME, useValue: 'all' },
            { provide: LIST, useValue: DashboardList },
          ],
          params: {
            route: ROUTE_DEFAULT_PARAMS,
            navigation: 'analytics.dashboards',
            routeMode: null,
            view: null,
          },
        },

        {
          name: 'dashboard',
          params: {
            navigation: 'analytics.dashboards',
          },
          url: '/dashboards/{entityId:guid}?{navigation}&{route}',
          component: DashboardComponent,
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
          ],
        },
      ],
    }),
    PerformersModule,
    NavigationItemsComponent,
  ],
})
export class DashboardsModule {}
