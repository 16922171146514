import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Guid } from 'src/app/shared/helpers/guid';
import { ProjectTeamService } from 'src/app/shared/services/project-team.service';
import { ProjectVersion } from 'src/app/shared/models/entities/projects/project-version.model';
import { bottom, createPopper, Instance } from '@popperjs/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import _ from 'lodash';
import { naturalSort } from 'src/app/shared/helpers/natural-sort.helper';
import { defaultResourceTypes } from 'src/app/shared/models/enums/resource-type.enum';
import { TextToControlValueParserHelper } from 'src/app/shared-features/grid/core/text-to-control-value-parser.helper';
import { ProjectTaskAssignment } from 'src/app/shared/models/entities/projects/project-task.model';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'wp-members-box',
  templateUrl: './members-box.component.html',
  styleUrls: ['./members-box.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MembersBoxComponent),
      multi: true,
    },
  ],
  standalone: false,
})
export class MembersBoxComponent
  implements ControlValueAccessor, OnInit, AfterViewInit, OnDestroy
{
  @Input() placeholder: string;
  @Input() projectVersion: ProjectVersion;
  @Input() autofocus?: boolean;
  /** Clear control if initial value === null. */
  @Input() initialValue?: unknown;
  @Input() availableResourceTypes = defaultResourceTypes;
  @Input() isAllTeamAvailable = true;

  @ViewChild('input') inputEl: ElementRef;
  @ViewChild('expandingArea') expandingArea: ElementRef;
  @ViewChild('membersBoxContainer') membersBoxContainer: ElementRef;
  @ViewChild('inputSearch') inputSearch: ElementRef;

  public memberRows: any[] = [];
  public filteredMemberRows: any[] = [];

  public listOpened = false;
  public assignments: ProjectTaskAssignment[];
  public isLoading: boolean;
  public readonly: boolean;
  public searchControl = new UntypedFormControl('');
  public fullNames = '';
  public shortNames = '';

  public allTeamCode = 'allTeam';

  private popperInstance: Instance;
  private destroyed$ = new Subject<void>();

  propagateChange = (list: any[]) => null;
  propagateTouch = () => null;

  constructor(
    private projectTeamService: ProjectTeamService,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.searchControl.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        this.filteredMemberRows = value
          ? this.memberRows.filter((row) =>
              row.name.toLowerCase().includes(value.toLowerCase()),
            )
          : this.memberRows;
      });
  }

  ngAfterViewInit(): void {
    if (this.autofocus && this.inputEl) {
      this.onInputClick();
    }
    if (!this.readonly && this.initialValue === null) {
      this.clear();
      this.onInputClick();
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  writeValue(assignments: any[]): void {
    if (assignments === null) {
      assignments = [];
    }
    if (_.isEqual(assignments, this.assignments)) {
      return;
    }

    this.assignments = [];

    if (assignments) {
      assignments.forEach((assignment) => {
        this.assignments.push(assignment);
      });
    }
    this.refreshRows();
    this.calculateViewNames();
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.readonly = isDisabled;

    this.cdr.detectChanges();
  }

  /** Input onBlur logic. */
  public onBlur() {
    this.propagateTouch();
  }

  /** Input onClick logic. */
  public onInputClick() {
    if (!this.listOpened) {
      this.openList();
    }
  }

  /** Clears project task assignments. */
  public clear() {
    this.memberRows.forEach((row) => {
      row.use = false;
    });

    this.updateAssignments();
    this.propagateChange(this.assignments);
    this.calculateViewNames();
    this.cancel();
  }

  /** Closes members list. */
  public cancel() {
    this.listOpened = false;
  }

  /** Opens members list. */
  public openList() {
    if (this.listOpened) {
      this.cancel();
      return;
    }

    setTimeout(() => {
      this.inputSearch?.nativeElement.focus();
    });
    this.propagateTouch();
    this.listOpened = true;
    this.refreshRows();
    this.popperInstance = createPopper(
      this.membersBoxContainer.nativeElement,
      this.expandingArea.nativeElement,
      {
        strategy: 'fixed',
        placement: bottom,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 2],
            },
          },
          {
            name: 'sameWidth',
            enabled: true,
            phase: 'beforeWrite',
            requires: ['computeStyles'],
            fn: ({ state }) => {
              state.styles.popper.width = `${state.rects.reference.width}px`;
            },
            effect: ({ state }) => {
              state.elements.popper.style.width = `${
                state.elements.reference.getBoundingClientRect().width
              }px`;
            },
          },
        ],
      },
    );

    this.show();
  }

  /** Drop search string in the dropdown. */
  public clearSearch(): void {
    this.searchControl.setValue('');
  }

  /** Adds member to project task assignments. */
  public clickItem(row: any) {
    row.use = !row.use;
    if (row.use) {
      if (row.id === this.allTeamCode) {
        this.memberRows
          .filter((r) => r.id !== this.allTeamCode)
          .forEach((memberRow) => {
            memberRow.use = false;
          });
      } else {
        const allTeam = this.memberRows.find((r) => r.id === this.allTeamCode);
        if (allTeam) {
          allTeam.use = false;
        }
      }
    }

    this.updateAssignments();
    this.propagateChange(this.assignments);
    this.calculateViewNames();
  }

  /**
   * Parses a JSON string to an array of assignment objects with new GUIDs.
   *
   * @param {string} textData - The JSON string to parse.
   * @returns {Array<Object>|undefined} An array of assignment objects with new GUIDs, or undefined if invalid.
   */
  public static parseTextToValue(textData: string) {
    if (textData === '') return [];
    if (
      !TextToControlValueParserHelper.isValidJSONStructure(
        textData,
        ['id', 'isAllTeamRole', 'isUnassigned', 'units'],
        true,
      )
    ) {
      return undefined;
    }

    return JSON.parse(textData).map((assignment) => ({
      ...assignment,
      id: Guid.generate(),
    }));
  }

  /** Shows expanding area in the popper and update its position. */
  private show() {
    this.renderer.setAttribute(
      this.expandingArea.nativeElement,
      'data-show',
      '',
    );

    this.popperInstance.update();
    this.cdr.detectChanges();
  }

  /** Updates project task assignments. */
  private updateAssignments(): void {
    const newAssignments = [];

    this.memberRows.forEach((row) => {
      if (row.use === true) {
        let assignment = this.getAssignment(row.id);

        if (!assignment) {
          assignment = {
            id: Guid.generate(),
            projectTeamMemberId: row.id === this.allTeamCode ? null : row.id,
            projectTeamMember: row.id === this.allTeamCode ? null : row,
            projectTariffId: null,
            isAllTeamRole: row.id === this.allTeamCode,
            isUnassigned: false,
            units: 1,
          };
        }
        newAssignments.push(assignment);
      }
    });
    this.assignments = newAssignments;
  }

  /** Forms rows for expanding area view. */
  private refreshRows() {
    this.isLoading = true;
    this.projectTeamService
      .getTeamMembers(this.projectVersion)
      .subscribe((members) => {
        this.memberRows = [];

        members.forEach((member) => {
          this.memberRows.push(member);
        });

        this.memberRows = this.memberRows.sort(naturalSort('name'));

        this.memberRows = this.memberRows.filter((member) =>
          this.availableResourceTypes.some(
            (type) =>
              !member.isUnassigned && type === member?.resource?.resourceType,
          ),
        );

        if (this.isAllTeamAvailable) {
          this.memberRows.unshift({
            id: this.allTeamCode,
            name: this.translate.instant('projects.allTeamRole'),
          });
        }

        this.memberRows.forEach((row) => {
          row.use = this.getAssignment(row.id) !== null;
        });

        this.memberRows = _.sortBy(this.memberRows, (el) => !el.use);

        this.filteredMemberRows = this.memberRows;

        this.isLoading = false;
        this.cdr.detectChanges();
        this.popperInstance?.update();
      });
  }

  /** Returns assignment.
   *
   * @param memberId member id.
   * @returns
   */
  private getAssignment(memberId: string): ProjectTaskAssignment | null {
    return (
      this.assignments.find(
        (assignment) =>
          (memberId === this.allTeamCode && assignment.isAllTeamRole) ||
          (!assignment.isAllTeamRole &&
            !assignment.isUnassigned &&
            memberId === assignment.projectTeamMember.id),
      ) ?? null
    );
  }

  private calculateViewNames(): void {
    this.fullNames = this.projectTeamService.getAssignmentsString(
      this.assignments,
      null,
    );
    this.shortNames = this.projectTeamService.getAssignmentsString(
      this.assignments,
      null,
      this.projectTeamService.maxAssignmentShortNameLength,
    );
  }
}
