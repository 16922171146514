import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnDestroy,
} from '@angular/core';
import { DealCardService } from 'src/app/deals/card/deal-card.service';
import { DealProfileService } from 'src/app/deals/card/deal-profile/deal-profile.service';
import { PropagationMode } from 'src/app/shared/models/enums/control-propagation-mode.enum';
import { CommentedEntityCollectionType } from 'src/app/shared/models/enums/commented-entity-collection-type.enum';
import { AppService } from 'src/app/core/app.service';

@Component({
  selector: 'tmt-deal-profile',
  templateUrl: './deal-profile.component.html',
  styleUrl: './deal-profile.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: 'organizationId',
      useFactory: (dealCardService: DealCardService) =>
        dealCardService.deal().organization?.id,
      deps: [DealCardService],
    },
  ],
  standalone: false,
})
export class DealProfileComponent implements OnDestroy {
  public activeTab: string;
  public readonly commentedEntityCollectionType = CommentedEntityCollectionType;
  public readonly propagationMode = PropagationMode;

  public readonly appService = inject(AppService);
  public readonly dealCardService = inject(DealCardService);
  public readonly dealProfileService = inject(DealProfileService);

  constructor() {
    this.dealProfileService.load();
  }

  public ngOnDestroy(): void {
    this.dealProfileService.form.markAsPristine();
    this.dealProfileService.form.markAsUntouched();
  }
}
