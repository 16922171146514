<tmt-form-header
  [name]="dealCardService.dealName()"
  [isEditable]="dealCardService.deal()?.editAllowed"
  [saveFn]="dealCardService.saveName"
/>

<tmt-not-found [state]="dealCardService.state()" />
<wp-loading-indicator [state]="dealCardService.state()" />

@if (dealCardService.state() === 'Ready') {
  <div class="subheader">
    <div class="subheader__props">
      <div class="subheader__prop">
        <wp-state-indicator />
      </div>
      @if (dealCardService.deal().organization) {
        <div class="subheader__prop">
          <div class="subheader__label">{{ 'shared2.props.client' | translate }}:</div>
          <div class="subheader__value">
            <a uiSref="client" [uiParams]="{ entityId: dealCardService.deal().organization.id }">{{
              dealCardService.deal().organization.name
            }}</a>
          </div>
        </div>
      }
      @if (dealCardService.deal().manager) {
        <div class="subheader__prop">
          <div class="subheader__label">{{ 'shared2.props.manager' | translate }}:</div>
          <div class="subheader__value" id="manager">
            <button
              class="btn btn-link btn-link-content"
              type="button"
              (click)="openUserInfo(dealCardService.deal().manager.id)"
            >
              {{ dealCardService.deal().manager.name }}
            </button>
          </div>
        </div>
      }
      <div class="subheader__prop">
        <div class="subheader__label">{{ 'shared2.props.created.value' | translate }}:</div>
        <div class="subheader__value">
          {{
            dealCardService.deal().created | date: 'shortDate' : appService.session.timeZoneOffset
          }}
        </div>
      </div>
    </div>
  </div>

  <div>
    <ul class="nav nav-tabs">
      @for (tab of tabs; track tab) {
        <li class="nav-item">
          <a
            class="nav-link"
            [ngClass]="{ active: stateService?.current?.name === tab.state }"
            [uiSref]="tab.state"
            [uiParams]="{ routeMode: 'keep' }"
            >{{ tab.header | translate }}</a
          >
        </li>
      }
    </ul>
    <ui-view></ui-view>
  </div>
}
