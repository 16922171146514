import {
  ChangeDetectionStrategy,
  Component,
  inject,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ContactCardService } from 'src/app/contacts/card/contact-card.service';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { ContactProfileService } from 'src/app/contacts/card/contact-profile/contact-profile.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { PropagationMode } from 'src/app/shared/models/enums/control-propagation-mode.enum';
import { MessageService } from 'src/app/core/message.service';
import { TextBoxComponent } from 'src/app/shared/components/controls/text-box/text-box.component';
import { AppService } from 'src/app/core/app.service';

@Component({
  selector: 'tmt-contact-profile',
  templateUrl: './contact-profile.component.html',
  styleUrls: ['./contact-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SavingQueueService, ContactProfileService],
  standalone: false,
})
export class ContactProfileComponent {
  public phone = viewChild<TextBoxComponent>('phone');
  public mobilePhone = viewChild<TextBoxComponent>('mobilePhone');

  public readonly propagationMode = PropagationMode;

  public readonly appService = inject(AppService);
  public readonly contactCardService = inject(ContactCardService);
  public readonly contactProfileService = inject(ContactProfileService);

  private readonly actionPanelService = inject(ActionPanelService);
  private readonly messageService = inject(MessageService);

  constructor() {
    this.contactProfileService.load();

    this.actionPanelService.reload$.pipe(takeUntilDestroyed()).subscribe(() => {
      if (this.contactProfileService.form.dirty) {
        this.messageService
          .confirmLocal('shared2.messages.leavePageMessage')
          .then(
            () => this.contactProfileService.load(),
            () => null,
          );
      } else {
        this.contactProfileService.load();
      }
    });
  }
}
