import { Component } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { Constants } from 'src/app/shared/globals/constants';
import { PropagationMode } from 'src/app/shared/models/enums/control-propagation-mode.enum';
import { emailValidator } from 'src/app/shared/validators/email';

@Component({
  selector: 'wp-text-box-test',
  templateUrl: './text-box-test.component.html',
  standalone: false,
})
export class TextBoxTestComponent {
  public readonly propagationMode = PropagationMode;
  public form = this.fb.group({
    text: ['', Validators.required],
    url: ['', Validators.required],
    phone: [
      '',
      [
        Validators.required,
        Validators.minLength(Constants.minPhoneLength),
        Validators.maxLength(Constants.maxPhoneLength),
      ],
    ],
    phoneOnExit: [
      '',
      [
        Validators.required,
        Validators.minLength(Constants.minPhoneLength),
        Validators.maxLength(Constants.maxPhoneLength),
      ],
    ],
    email: ['', [Validators.required, emailValidator()]],
  });

  public formInputParams = this.fb.group({
    readonly: [false],
    onExitEditing: [false],
  });

  public get isExitEditing() {
    return this.formInputParams.controls['onExitEditing'].value;
  }

  public get isReadonly() {
    return this.formInputParams.controls['readonly'].value;
  }

  constructor(private fb: UntypedFormBuilder) {}

  setValue() {
    this.form.patchValue({
      text: 'Test 1',
      url: 'https://timetta.com',
      phone: '79999999999',
      phoneOnExit: '78888888888',
      email: 'test@test.com',
    });
  }
}
