import { Injectable, Type } from '@angular/core';
import { MetamodelListFilterComponent } from 'src/app/settings-app/metamodel/list/filter/metamodel-list-filter.component';
import { FilterDetails } from 'src/app/shared/components/features/filter/filter-details.interface';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';

@Injectable()
export class MetamodelListFilterService extends FilterService {
  public override component: Type<FilterDetails> = MetamodelListFilterComponent;
  public override hasDetails = false;
  public override hasViewSelector = false;

  public override placeholder = 'settings.metamodel.filter.placeholder';

  protected override getDefaultValues = (): any => {
    const value = {
      text: '',
    };

    return value;
  };
}
