@if (!readonly) {
  <div
    class="input-group"
    (clickOutside)="cancel()"
    [excludeBeforeClick]="true"
    [attachOutsideOnClick]="true"
    wpIntersectable
    (onIntersect)="cancel()"
    #membersBoxContainer
  >
    <input
      #input
      class="form-control input-table"
      [value]="shortNames"
      [title]="fullNames"
      type="text"
      readonly
      placeholder="{{ placeholder }}"
      (click)="onInputClick()"
    />

    @if (assignments.length) {
      <span
        class="input-clear"
        title="{{ 'shared.hintClearControlButton' | translate }}"
        (click)="clear()"
        data-test="clearInput"
        >&times;</span
      >
    }

    <button type="button" class="btn btn-default input-group-text" (click)="openList()">
      <i class="bi bi-chevron-down" aria-hidden="true"></i>
    </button>

    <div class="select-expand-container" #expandingArea [hidden]="!listOpened">
      @if (listOpened) {
        <div class="fixed-expanding-area">
          <div class="p-2 position-relative" class="search">
            <input
              #inputSearch
              class="form-control ellipsis"
              type="text"
              maxlength="255"
              placeholder="{{ 'shared.filter.defaultPlaceholder' | translate }}"
              [formControl]="searchControl"
            />
            <span
              class="input-clear"
              (click)="clearSearch()"
              [hidden]="!searchControl.value"
              data-test="clearSearch"
            >
              &times;
            </span>
          </div>
          <wp-loading-indicator [loading]="isLoading"></wp-loading-indicator>
          <div class="task__container expanding-area-vertical-scroll">
            @if (!filteredMemberRows.length && !isLoading) {
              <div class="text-center">
                <p class="text-body-secondary m-0 p-2">{{ 'shared.noOptions' | translate }}</p>
              </div>
            }
            @if (filteredMemberRows.length) {
              <table class="selecting-list selecting-list-hover">
                <tbody>
                  @for (row of filteredMemberRows; track row.id) {
                    <tr
                      (click)="clickItem(row)"
                      id="{{ row.id }}"
                      title="{{ row.name }}"
                      [ngClass]="{
                        'text-decoration-line-through': !row.isActive && row.id !== allTeamCode,
                      }"
                    >
                      <td class="checkbox-cell">
                        <input type="checkbox" [(ngModel)]="row.use" class="form-check-input" />
                      </td>
                      <td class="trim">{{ row.name }}</td>
                    </tr>
                  }
                </tbody>
              </table>
            }
          </div>
        </div>
      }
    </div>
  </div>
}
@if (readonly && assignments.length) {
  <p class="trim" [title]="fullNames">
    {{ shortNames }}
  </p>
}
@if (readonly && !assignments.length) {
  <p class="trim text-body-secondary">
    {{ 'shared.valueNotSelected' | translate }}
  </p>
}
