import {
  Inject,
  Injectable,
  Injector,
  NgZone,
  OnDestroy,
  Optional,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { DateTime, DurationLike, Interval } from 'luxon';
import {
  BehaviorSubject,
  Subject,
  Subscription,
  firstValueFrom,
  debounceTime,
  filter,
  switchMap,
  takeUntil,
} from 'rxjs';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { DataService } from 'src/app/core/data.service';
import { LocalConfigService } from 'src/app/core/local-config.service';
import { LogService } from 'src/app/core/log.service';
import { MessageService } from 'src/app/core/message.service';
import { NotificationService } from 'src/app/core/notification.service';
import { ChromeService } from 'src/app/core/chrome.service';
import { ValueMode } from 'src/app/shared-features/planner/models/value-mode.enum';
import { ScheduleNavigationService } from 'src/app/shared-features/schedule-navigation/core/schedule-navigation.service';
import {
  Slot,
  SlotGroup,
} from 'src/app/shared-features/schedule-navigation/models/slot.model';
import { FreezeTableService } from 'src/app/shared/directives/freeze-table/freeze-table.service';
import {
  BookingEntry,
  BookingEntryReqBody,
  ProjectRow,
} from 'src/app/shared/models/entities/resources/booking-entry.model';
import { BookingType } from 'src/app/shared/models/enums/booking-type.enum';
import { PlanningScale } from 'src/app/shared/models/enums/planning-scale.enum';
import { Exception } from 'src/app/shared/models/exception';
import { BookingSettings } from '../models/booking.settings';
import { BookingEntryFormComponent } from '../shared/booking-entry-form/booking-entry-form.component';
import { BookingNotifyComponent } from '../shared/booking-notify/booking-notify.component';
import { BookingDataService } from './booking-data.service';
import { BookingManageService } from './booking-manage.service';
import { BookingRenderingService } from './booking-rendering.service';
import { ScheduleNavigationContext } from 'src/app/shared-features/schedule-navigation/models/schedule-navigation-context.enum';
import { Guid } from 'src/app/shared/helpers/guid';
import { BookingDetailEntry } from 'src/app/shared/models/entities/resources/booking-detail-entry.model';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { AppService } from 'src/app/core/app.service';
import { BookingMode } from 'src/app/shared/models/enums/booking-mode.enum';
import { DateHours } from 'src/app/shared/models/entities/date-hours.model';
import {
  RecalculateState,
  ToggleState,
} from 'src/app/booking/booking/models/state.interface';
import { ResourceType } from 'src/app/shared/models/enums/resource-type.enum';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { BookingViewSettingsService } from 'src/app/booking/booking/shared/booking-view-settings/booking-view-settings.service';
import { Constants } from 'src/app/shared/globals/constants';
import { FILTER } from 'src/app/shared/tokens';
import { EntityFilter } from 'src/app/core/navigation.service';

@Injectable()
export class BookingService implements OnDestroy {
  public loading$ = new BehaviorSubject<boolean>(true);
  public get loading(): boolean {
    return this._loading;
  }
  public set loading(value: boolean) {
    this._loading = value;
    this.loading$.next(value);
  }
  private _loading: boolean;

  private detectChangesSubject = new Subject<string>();
  public detectChanges$ = this.detectChangesSubject.asObservable();

  private recalculateGroupSubject = new Subject<RecalculateState>();
  public recalculateGroup$ = this.recalculateGroupSubject.asObservable();

  private toggleGroupSubject = new BehaviorSubject<ToggleState>(null);
  public toggleGroup$ = this.toggleGroupSubject.asObservable();

  private toggleRowSubject = new BehaviorSubject<ToggleState>(null);
  public toggleRow$ = this.toggleRowSubject.asObservable();

  private frameLoadingSubject = new BehaviorSubject<boolean>(false);
  public frameLoading$ = this.frameLoadingSubject.asObservable();

  /** Emits event on any change. */
  public changes$ = new Subject<void>();
  public updateSubscription: Subscription;
  public page: number;
  public interval: Interval;
  public interval$ = new Subject<Interval>();
  public slots: Slot[];
  public slotGroups: SlotGroup[];
  public settings: BookingSettings;
  public resourceRequestInterval: Interval;
  public bookingMode: BookingMode;
  public isAssistantBottomMode: boolean;
  public isAssistantTopMode: boolean;

  private expandedResourceIds: Set<string> = new Set();
  private expandedProjectIds: Map<string, Set<string>> = new Map();
  private pageSize = 30;
  private allAreLoaded: boolean;
  private scrollSubscription: Subscription | null;
  private entriesQueueId = Guid.generate();
  private entriesToSave: BookingEntryReqBody[] = [];
  private readonly massToggleOperationDelay = 100;
  private readonly destroyed$ = new Subject<void>();

  public get isAllExpanded(): boolean {
    return this.expandedResourceIds.size === this.dataService.resources.length;
  }

  public get isResourceLineHidden(): boolean {
    return this.isAssistantTopMode && this.bookingMode === BookingMode.Detailed;
  }

  public get isActualizationResourceRequest(): boolean {
    return (
      this.dataService.resourceRequest?.teamMember?.resource?.resourceType ===
      ResourceType.user
    );
  }

  public get isChangeEntriesHidden(): boolean {
    return (
      this.isAssistantTopMode ||
      this.isAssistantBottomMode ||
      this.resourceRequestId ||
      !this.expandedResourceIds.size ||
      !this.bookingViewSettingsService.settings.resourceRequestShown
    );
  }

  constructor(
    @Optional() @Inject('assistantRequestId') public resourceRequestId,
    @Optional() private bookingRenderingService: BookingRenderingService | null,
    private freezeTableService: FreezeTableService,
    private modal: NgbModal,
    private activeModal: NgbActiveModal,
    private bookingFilterService: FilterService,
    private dataService: BookingDataService,
    private data: DataService,
    private scheduleNavigationService: ScheduleNavigationService,
    private localConfigService: LocalConfigService,
    private blockUI: BlockUIService,
    private log: LogService,
    private injector: Injector,
    private notification: NotificationService,
    private bookingManageService: BookingManageService,
    private bookingViewSettingsService: BookingViewSettingsService,
    private translate: TranslateService,
    private messageService: MessageService,
    private savingQueueService: SavingQueueService,
    private chromeService: ChromeService,
    private zone: NgZone,
    private translateService: TranslateService,
    private notificationService: NotificationService,
    @Inject(DOCUMENT) private document: Document,
    @Optional() @Inject(FILTER) private entityFilter: EntityFilter,
    appService: AppService,
  ) {
    this.bookingMode = appService.session.configuration.bookingMode;

    this.initSubscribers();
  }

  public ngOnDestroy(): void {
    this.savingQueueService.save();
    this.scrollSubscription?.unsubscribe();
    this.destroyed$.next();
  }

  // TODO remove duplicate after bookingRenderingService's refactoring
  public getDataTableWidth(): number {
    if (!this.slots) {
      return null;
    }
    return (
      this.getSlotWidth(this.settings.planningScale) * this.slots.length + 1
    );
  }

  // TODO remove duplicate after bookingRenderingService's refactoring
  public getSlotWidth(scale: PlanningScale): number {
    switch (scale) {
      case PlanningScale.Day:
        return 45;
      case PlanningScale.Week:
        return 55;
      case PlanningScale.Month:
        return 65;
    }
  }

  /**
   * Emits detect changes in booking components with resources.
   *
   * @param resourceId Resource ID.
   *
   * */
  public emitComponentChanges(resourceId: string | null): void {
    if (resourceId) {
      this.detectChangesSubject.next(resourceId);
      this.recalculateGroupSubject.next({ id: resourceId });
      this.bookingRenderingService?.redrawGroup(resourceId);
    } else {
      this.dataService.resources.forEach((resource) => {
        this.detectChangesSubject.next(resource.id);
        this.recalculateGroupSubject.next({ id: resource.id });
        this.bookingRenderingService?.redrawGroup(resource.id);
      });
    }
  }

  /**
   * Emits DetectChangesSubject.
   *
   * @param resourceId Resource ID.
   *
   * */
  public emitDetectChanges(resourceId: string | null): void {
    this.detectChangesSubject.next(resourceId);
  }

  /**
   * Add new `BookingEntry` in the current booking entries array.
   *
   * @param bookingEntry `BookingEntry`.
   *
   * */
  public addNewBookingEntry(bookingEntry: BookingEntry): void {
    this.dataService.bookings.push(bookingEntry);
    this.detectChangesSubject.next(bookingEntry.resourceId);
  }

  /**
   * Gets project list with `detailEntries` from current bookings sorted by `id`.
   *
   * @param resourceId `resourceId`.
   *
   * @return Project's rows.
   * */
  public getsProjectRows(resourceId: string): ProjectRow[] {
    const projectRows: ProjectRow[] = [];

    for (const entry of this.dataService.getResourceBookings(resourceId)) {
      let projectName: string;
      // TODO not correct, fix it after loadFrame refactoring
      if (entry.isTimeOff) {
        const timeOffRow = projectRows.find((el) => el.isTimeOff);
        projectName = 'resources.booking.common.timeOff';

        if (timeOffRow) {
          timeOffRow.detailEntries = timeOffRow.detailEntries.concat(
            entry.detailEntries,
          );

          continue;
        }
      }

      // TODO not correct, fix it after loadFrame refactoring
      if (entry.isOther) {
        const otherProjectRow = projectRows.find((el) => !el.id && el.name);
        projectName = 'resources.booking.common.otherProjects';

        if (otherProjectRow) {
          otherProjectRow.detailEntries = otherProjectRow.detailEntries.concat(
            entry.detailEntries,
          );

          continue;
        }
      }

      projectRows.push({
        id: entry.isTimeOff || entry.isOther ? null : entry.project.id,
        name: projectName
          ? this.translate.instant(projectName)
          : entry.project.name,
        bookingEntryId: entry.id,
        bookingEntryEditAllowed: entry.editAllowed,
        requestBookings: [],
        detailEntries: entry.detailEntries,
        isTimeOff: entry.isTimeOff,
        isExpanded: this.expandedProjectIds
          ?.get(resourceId)
          ?.has(entry.project?.id),
      });
    }

    projectRows
      .filter((row) => row.id)
      .forEach((row) => {
        row.requestBookings = this.dataService.bookingsChangeEntries.filter(
          (booking) =>
            booking.projectId === row.id && booking.resourceId === resourceId,
        );
      });

    return _.sortBy(projectRows, ['id']);
  }

  /**
   * Add entry to saving queue.
   *
   * @param bookingEntryId BookingEntry ID.
   * @param entry BookingDetailEntry.
   * @param resourceId resourceId.
   *
   * */
  public addEntryToQueue(
    bookingEntryId: string,
    entry: Partial<BookingDetailEntry>,
    resourceId: string,
  ): void {
    const queueEntry: BookingEntryReqBody = {
      date: entry.date,
      hours: entry.hours ?? 0,
      bookingId: bookingEntryId,
    };

    const queueEntryIndex = this.entriesToSave.findIndex(
      (el) =>
        el.date === queueEntry.date && el.bookingId === queueEntry.bookingId,
    );

    if (queueEntryIndex > -1) {
      this.entriesToSave[queueEntryIndex].hours = queueEntry.hours;
    } else {
      this.entriesToSave.push(queueEntry);
    }

    this.updateBookingDetailEntries(
      bookingEntryId,
      resourceId,
      this.entriesToSave.filter((el) => el.bookingId === bookingEntryId),
    );

    const planningScale = _.clone(this.settings.planningScale);

    this.savingQueueService.addToQueue(this.entriesQueueId, () =>
      this.dataService.updateDetails(this.entriesToSave, planningScale),
    );
  }

  /** Expands all resources or collapses if they are all open */
  public toggleAllResources(): void {
    const state = !this.isAllExpanded;

    this.dataService.resources.forEach((item) => {
      if (state && !this.expandedResourceIds.has(item.id)) {
        this.toggleGroup(item.id, state);
      }

      if (!state && this.expandedResourceIds.has(item.id)) {
        this.toggleGroup(item.id, state);
      }
    });
  }

  private loadFrame(direction: 'left' | 'right' | null) {
    this.frameLoadingSubject.next(true);
    this.blockUI.start();

    let loadingInterval: Interval;

    if (direction) {
      let shift: DurationLike;

      switch (this.settings.planningScale) {
        case PlanningScale.Day:
          shift = { weeks: 2 };
          break;
        case PlanningScale.Week:
          shift = { weeks: 10 };
          break;
        case PlanningScale.Month:
          shift = { month: 5 };
          break;
      }

      loadingInterval =
        direction === 'left'
          ? Interval.fromDateTimes(
              this.interval.start.minus(shift),
              this.interval.start.minus({ days: 1 }),
            )
          : Interval.fromDateTimes(
              this.interval.end.plus({ days: 1 }),
              this.interval.end.plus(shift),
            );

      this.interval =
        direction === 'left'
          ? this.interval.set({
              start: this.interval.start.minus(shift),
            })
          : this.interval.set({
              end: this.interval.end.plus(shift),
            });

      if (
        this.settings.planningScale === PlanningScale.Month &&
        direction === 'right'
      ) {
        loadingInterval = loadingInterval.set({
          end: loadingInterval.end.endOf('month'),
        });
        this.interval = this.interval.set({
          end: this.interval.end.endOf('month'),
        });
      }
    } else {
      loadingInterval = this.interval;
    }

    this.log.debug(`Load new interval: ${loadingInterval.toISODate()}`);

    this.dataService
      .loadFrame(
        this.dataService.resources.map((r) => r.id),
        loadingInterval,
        this.settings.planningScale,
      )
      .subscribe({
        next: () => {
          this.updateDates();
          this.blockUI.stop();
          this.changes$.next(null);
          this.detectChangesSubject.next(null);

          // NOTE: Handles by `booking.assistant` in this mode
          if (this.isAssistantTopMode || this.isAssistantBottomMode) {
            return;
          }

          setTimeout(() => {
            this.freezeTableService.disableMutationObserver();

            if (direction === 'left') {
              this.freezeTableService.scrollToLeft();
            } else if (direction === 'right') {
              this.freezeTableService.scrollToRight();
            }

            setTimeout(() => {
              this.freezeTableService.enableMutationObserver();
            }, 500);
          }, 10);
        },
        error: (error: Exception) => {
          this.blockUI.stop();
          this.loading = false;
          this.notification.error(error.message);
        },
        complete: () => {
          this.frameLoadingSubject.next(false);
        },
      });

    if (!this.isChangeEntriesHidden) {
      this.dataService
        .loadChangeEntries(
          this.getResourceIdsForLoadChanges(),
          this.interval,
          this.settings.planningScale,
        )
        .subscribe((bookings) => {
          bookings.forEach((booking) => {
            this.detectChangesSubject.next(booking.resourceId);
          });
        });
    }
  }

  /** Запустить создание нового бронирования. */
  public openBookingForm(booking: BookingEntry, mode: 'create' | 'edit') {
    const ref = this.modal.open(BookingEntryFormComponent, {
      size: 'xl',
      injector: this.injector,
    });
    const instance = ref.componentInstance as BookingEntryFormComponent;
    instance.mode = mode;
    instance.readonly = !booking.editAllowed;
    instance.bookingEntry = _.cloneDeep(booking);
    instance.resource = this.dataService.resources.find(
      (r) => r.id === booking.resourceId,
    );

    ref.result.then(
      (updateBooking: BookingEntry) => {
        _.merge(booking, updateBooking);
        booking.detailEntries = updateBooking.detailEntries;
        booking.fromLx = DateTime.fromISO(booking.from);
        booking.toLx = DateTime.fromISO(booking.to);
        booking.rowVersion = updateBooking.rowVersion;

        this.bookingRenderingService?.redrawGroup(booking.resourceId);
        this.recalculateGroup(booking.resourceId);
        this.detectChangesSubject.next(booking.resourceId);
        this.changes$.next();
      },
      (result) => {
        if (mode === 'create') {
          _.remove(this.dataService.bookings, (e) => e.id === booking.id);
          this.bookingRenderingService?.redrawGroup(booking.resourceId);
          this.detectChangesSubject.next(booking.resourceId);
        }

        if (result === 'error') {
          this.reloadBookings();
        }
      },
    );
  }

  /**
   * Update details of booking entry in the current bookings.
   *
   * @param bookingEntryId bookingEntryId.
   * @param resourceId resourceId.
   * @param details details.
   *
   * */
  public updateBookingDetailEntries(
    bookingEntryId: string,
    resourceId: string,
    details: Partial<BookingDetailEntry>[],
  ): void {
    const bookingEntry = this.dataService.bookings.find(
      (entry) => entry.id === bookingEntryId,
    );

    if (!bookingEntry) {
      return;
    }

    details.forEach((item) => {
      const detailIndex = bookingEntry.detailEntries.findIndex(
        (el) => el.date === item.date,
      );

      if (detailIndex > -1) {
        bookingEntry.bookedHours = Math.max(
          0,
          bookingEntry.bookedHours -
            bookingEntry.detailEntries[detailIndex].hours +
            item.hours,
        );

        bookingEntry.detailEntries[detailIndex].hours = item.hours;
      } else {
        bookingEntry.bookedHours += item.hours;

        bookingEntry.detailEntries.push({
          hours: item.hours,
          date: item.date,
          id: null,
        });
      }
    });

    this.recalculateGroup(resourceId);
  }

  /**
   * Clear details of booking entry.
   *
   * @param bookingEntry BookingEntry.
   *
   * */
  public clearBooking(bookingEntry: BookingEntry): void {
    const booking = this.dataService.bookings.find(
      (el) => el.id === bookingEntry.id,
    );

    booking.detailEntries.length = 0;
    booking.bookedHours = 0;

    this.detectChangesSubject.next(bookingEntry.resourceId);
    this.recalculateGroup(bookingEntry.resourceId);
    this.dataService.clear(bookingEntry.id).subscribe({
      next: () => {
        this.changes$.next();
      },
      error: (error) => {
        this.notification.error(error.message);
        this.reloadBookings();
      },
    });
  }

  /**
   * Delete BookingEntry .
   *
   * @param bookingEntry BookingEntry.
   *
   * */
  public deleteBooking(booking: BookingEntry): void {
    _.remove(this.dataService.bookings, (e) => e.id === booking.id);
    this.bookingRenderingService?.redrawGroup(booking.resourceId);
    this.detectChangesSubject.next(booking.resourceId);
    this.recalculateGroup(booking.resourceId);

    this.dataService.delete(booking.id).subscribe({
      next: () => {
        this.changes$.next();
      },
      error: (error) => {
        this.notification.error(error.message);
        this.reloadBookings();
      },
    });
  }

  public replaceBookingEntry(
    bookingEntryId: string,
    resourceId: string,
    requestDateHours: DateHours[],
  ): void {
    const bookingEntry: BookingEntry = this.dataService.bookings.find(
      (el) => el.id === bookingEntryId,
    );
    bookingEntry.bookedHours = 0;
    bookingEntry.detailEntries.forEach((el) => {
      el.hours = 0;
      this.addEntryToQueue(bookingEntryId, el, resourceId);
    });

    requestDateHours.forEach((entry) => {
      this.addEntryToQueue(bookingEntryId, { ...entry }, resourceId);
    });

    this.detectChangesSubject.next(resourceId);
    this.recalculateGroup(resourceId);
  }

  /**
   * Automatically enrich result before request for the bookingEntry when `BookingMode` is detailed.
   *
   * @param bookingEntryId BookingEntry ID.
   * @param resourceId Resource ID.
   * @param requestDateHours Requested hours by date.
   */
  public fillBookingEntry(
    bookingEntryId: string,
    resourceId: string,
    requestDateHours: DateHours[],
  ): void {
    let updatingBookingEntry: BookingEntry;

    requestDateHours.forEach((entry) => {
      let deviation = -entry.hours;

      this.dataService.bookings.forEach((bookingEntry) => {
        deviation +=
          bookingEntry.detailEntries.find((el) => el.date === entry.date)
            ?.hours ?? 0;

        if (bookingEntry.id === bookingEntryId) {
          updatingBookingEntry = bookingEntry;
        }
      });

      if (deviation < 0) {
        const detailEntry = updatingBookingEntry.detailEntries.find(
          (el) => el.date === entry.date,
        );
        const newDetailEntry: DateHours = {
          date: entry.date,
          hours: detailEntry ? detailEntry.hours + -deviation : -deviation,
        };

        this.addEntryToQueue(bookingEntryId, newDetailEntry, resourceId);
      }
    });

    this.detectChangesSubject.next(resourceId);
    this.recalculateGroup(resourceId);
  }

  /** Изменить тип. */
  setType(originalBooking: BookingEntry) {
    this.blockUI.start();

    const actionName =
      originalBooking.type === BookingType.Hard
        ? 'SwitchToSoft'
        : 'SwitchToHard';

    this.data
      .collection('BookingEntries')
      .entity(originalBooking.id)
      .action(actionName)
      .execute()
      .subscribe({
        next: (updatedBooking: BookingEntry) => {
          const booking = this.dataService.bookings.find(
            (b) => b.id === updatedBooking.id,
          );
          booking.type = updatedBooking.type;
          booking.editAllowed = updatedBooking.editAllowed;
          booking.rowVersion = updatedBooking.rowVersion;

          this.recalculateGroup(booking.resourceId);
          this.bookingRenderingService?.redrawGroup(booking.resourceId);

          this.changes$.next();
          this.blockUI.stop();
        },
        error: (error: Exception) => {
          this.blockUI.stop();
          this.notification.error(error.message);
        },
      });
  }

  /** Обновляет даты начала и окончания выбранного периода, слоты и набор периодов в быстром выборе. */
  private updateDates() {
    const slotInfo = this.scheduleNavigationService.getSlots(
      this.interval,
      this.settings.planningScale,
    );

    this.slots = slotInfo.slots;
    this.slotGroups = slotInfo.groups;

    this.interval$.next(this.interval);

    if (this.bookingRenderingService) {
      this.bookingRenderingService.interval = this.interval;
      this.bookingRenderingService.slots = this.slots;
    }

    this.dataService.resources.forEach((r) => {
      this.recalculateGroup(r.id, true);
      this.bookingRenderingService?.redrawGroup(r.id);
    });
  }

  /** Изменение единицы планирования. */
  public setValueMode(valueMode: ValueMode) {
    this.settings.valueMode = valueMode;
    this.dataService.valueMode = this.settings.valueMode;
    this.loadFrame(null);
  }

  /** Изменить масштаб планирования. */
  public setPlanningScale(planningScale: PlanningScale) {
    this.settings.planningScale = planningScale;

    this.interval = this.scheduleNavigationService.getInterval(
      this.settings.planningScale,
    );

    if (this.bookingRenderingService) {
      this.bookingRenderingService.planningScale = this.settings.planningScale;
      this.bookingRenderingService.interval = this.interval;
    }

    this.updateDates();
    this.reload();
  }

  /** Loads resources by page. */
  public loadResourcesPage(): void {
    this.loading = true;
    this.detectChangesSubject.next(null);

    if (this.entityFilter) {
      this.bookingFilterService.values.project = {
        id: this.entityFilter.filter.find((v) => v.projectId).projectId.value,
        name: this.entityFilter.name,
      };
    }

    this.dataService
      .loadResourcesPage(
        this.page,
        this.pageSize,
        this.bookingFilterService.values,
        this.interval,
        this.settings.planningScale,
      )
      .pipe(
        switchMap((resources) => {
          this.allAreLoaded = resources.length < this.pageSize;
          this.blockUI.start();

          Array.from(this.expandedResourceIds).forEach((id) => {
            if (resources.findIndex((el) => el.id === id) === -1) {
              this.expandedResourceIds.delete(id);
              this.expandedProjectIds.delete(id);
            }
          });

          this.detectChangesSubject.next(null);

          return this.dataService.loadBookings(
            resources.map((r) => r.id),
            this.interval,
            this.settings.planningScale,
          );
        }),
      )
      .subscribe({
        next: () => {
          this.loading = false;

          this.dataService.resources.forEach((r) => {
            this.recalculateGroup(r.id, true);
          });
          this.dataService.resources.forEach((r) => {
            this.bookingRenderingService?.redrawGroup(r.id);
          });

          this.expandedResourceIds.forEach((resourceId) =>
            this.toggleGroup(resourceId, true),
          );

          this.blockUI.stop();

          this.detectChangesSubject.next(null);
        },
        error: (error: Exception) => {
          this.blockUI.stop();
          this.loading = false;
          this.notification.error(error.message);
        },
      });

    this.detectChangesSubject.next(null);
  }

  /** Перезагрузить данные. */
  public reload(toDate?: DateTime) {
    this.loading = true;

    this.dataService.resources = [];
    this.dataService.bookings = [];
    this.dataService.bookingsChangeEntries = [];
    this.dataService.bookingsResourceRequest = [];
    this.dataService.fte = {};
    this.dataService.schedules = {};

    this.interval =
      this.resourceRequestInterval ??
      this.scheduleNavigationService.getInterval(
        this.settings.planningScale,
        toDate,
      );

    this.updateDates();
    this.allAreLoaded = false;
    this.page = 0;
    this.loadResourcesPage();
  }

  /** Updates bookings (for loaded resource and current interval). */
  public async reloadBookings(): Promise<void> {
    try {
      this.blockUI.start();

      await firstValueFrom(
        this.dataService.loadBookings(
          this.dataService.resources.map((r) => r.id),
          this.interval,
          this.settings.planningScale,
          true,
        ),
      );

      this.dataService.resources.forEach((r) => {
        this.detectChangesSubject.next(r.id);
        this.recalculateGroup(r.id);
        this.bookingRenderingService?.redrawGroup(r.id);
      });

      this.blockUI.stop();

      if (!this.isChangeEntriesHidden) {
        this.dataService
          .loadChangeEntries(
            this.getResourceIdsForLoadChanges(),
            this.interval,
            this.settings.planningScale,
            true,
          )
          .subscribe((bookings) => {
            bookings.forEach((booking) => {
              this.detectChangesSubject.next(booking.resourceId);
            });
          });
      }
    } catch (error) {
      this.blockUI.stop();
      this.notification.error(error.message);
    }
  }

  public recalculateGroup(resourceId: string, rebuild = false) {
    this.recalculateGroupSubject.next({ id: resourceId, rebuild });
  }

  /**
   * Toggles resource line.
   *
   * @param resourceId Resource id.
   * @param state To open or to close.
   */
  public toggleGroup(resourceId: string, state: boolean) {
    if (state) {
      this.expandedResourceIds.add(resourceId);
      this.bookingRenderingService?.updateLineIndexes(resourceId);
    } else {
      this.expandedResourceIds.delete(resourceId);
    }

    this.toggleGroupSubject.next({
      id: resourceId,
      state,
    });
    this.bookingRenderingService?.propagateGroupBoardHeight(resourceId);
  }

  /**
   * Toggles project line of resource line.
   *
   * @param resourceId Resource id.
   * @param resourceId Project id.
   * @param state To open or to close.
   */
  public toggleRow(
    resourceId: string,
    projectId: string,
    state: boolean,
  ): void {
    if (!this.expandedProjectIds.has(resourceId)) {
      this.expandedProjectIds.set(resourceId, new Set());
    }

    if (state) {
      this.expandedProjectIds.get(resourceId).add(projectId);
    } else {
      this.expandedProjectIds.get(resourceId).delete(projectId);
    }

    this.toggleRowSubject.next({
      id: resourceId,
      state,
      projectIds: this.expandedProjectIds.get(resourceId),
    });
  }

  /**
   * Handles errors after saving or creating booking entry.
   *
   * @param error Error object.
   * @param resource Resource.
   * @param project Project.
   * @param onOkCallback Callback for `ok` action of modal that opens if request's response has `teamMemberNotFound` error.
   *
   * */
  public errorHandlerOnSave(
    error: Exception,
    resource: NamedEntity,
    project: NamedEntity,
    onOkCallback?: () => void,
  ): void {
    if (error.code === Exception.BtConcurrencyException.code) {
      this.messageService
        .message(
          this.translateService.instant(
            Exception.BtConcurrencyException.message,
          ),
          '',
          [],
          this.translateService.instant('shared.actions.reload'),
        )
        .then(
          () => {
            this.modal.dismissAll();
            this.reloadBookings();
          },
          () => this.activeModal.dismiss('error'),
        );

      return;
    }

    if (error.code === Exception.TeamMember_IsNotTeamMemberOfProject.code) {
      this.messageService
        .message(
          this.translateService.instant(
            'resources.booking.teamMemberNotFound.message',
            {
              resource: resource.name,
              project: project?.name,
            },
          ),
          '',
          [],
          this.translateService.instant(
            'resources.booking.teamMemberNotFound.includeToTeam',
          ),
          this.translateService.instant('shared.actions.cancel'),
        )
        .then(
          () => {
            this.data
              .collection('ProjectTeamMembers')
              .insert({
                projectId: project?.id,
                resourceId: resource.id,
              })
              .subscribe({
                next: () => {
                  if (onOkCallback) {
                    onOkCallback();
                  }
                },
                error: (ex: Exception) => {
                  this.notificationService.error(ex.message);
                },
              });
          },
          () => null,
        );

      return;
    }

    this.notificationService.error(error.message);
  }

  /** Включение бесконечной прокрутки. */
  private enableLoadingOnScroll() {
    const container = this.document.getElementById(
      this.isAssistantBottomMode
        ? 'resources-container'
        : Constants.defaultRootContainerId,
    );

    if (!container) {
      return;
    }

    this.scrollSubscription = this.chromeService.setInfinityScroll(
      () => {
        if (this.allAreLoaded || this.loading) {
          return;
        }

        this.page++;
        this.loadResourcesPage();
      },
      150,
      container,
    );
  }

  private updateEntry(event: {
    booking: BookingEntry;
    originalBooking: BookingEntry;
  }) {
    this.blockUI.start();

    this.updateSubscription?.unsubscribe();
    this.updateSubscription = this.dataService
      .update(event.booking, this.settings.planningScale)
      .subscribe({
        next: (updatedBooking: BookingEntry) => {
          const booking = this.dataService.bookings.find(
            (b) => b.id === updatedBooking.id,
          );
          booking.from = updatedBooking.from;
          booking.to = updatedBooking.to;
          booking.fromLx = DateTime.fromISO(booking.from);
          booking.toLx = DateTime.fromISO(booking.to);
          booking.bookedHours = updatedBooking.bookedHours;
          booking.detailEntries = updatedBooking.detailEntries;
          booking.resourceId = updatedBooking.resourceId;
          booking.rowVersion = updatedBooking.rowVersion;

          this.recalculateGroup(booking.resourceId);
          this.recalculateGroup(event.originalBooking.resourceId);

          this.bookingRenderingService?.redrawGroup(booking.resourceId);

          this.changes$.next();

          this.blockUI.stop();
        },
        error: (error: Exception) => {
          this.blockUI.stop();

          const cancel = () => {
            const resourceId = event.booking.resourceId;
            _.merge(event.booking, event.originalBooking);

            this.bookingRenderingService?.redrawGroup(event.booking.resourceId);
            this.bookingRenderingService?.redrawGroup(resourceId);

            this.detectChangesSubject.next(resourceId);
            this.detectChangesSubject.next(event.booking.resourceId);
          };

          if (error.code === Exception.BtConcurrencyException.code) {
            this.messageService
              .message(
                this.translate.instant(
                  Exception.BtConcurrencyException.message,
                ),
                '',
                [],
                this.translate.instant('shared.actions.reload'),
              )
              .then(
                () => this.reloadBookings(),
                () => this.reloadBookings(),
              );

            return;
          }

          if (
            error.code === Exception.TeamMember_IsNotTeamMemberOfProject.code
          ) {
            this.messageService
              .message(
                this.translate.instant(
                  'resources.booking.teamMemberNotFound.message',
                  {
                    resource: this.dataService.resources.find(
                      (r) => r.id === event.booking.resourceId,
                    )?.name,
                    project: event.booking.project?.name,
                  },
                ),
                '',
                [],
                this.translate.instant(
                  'resources.booking.teamMemberNotFound.includeToTeam',
                ),
                this.translate.instant('shared.actions.cancel'),
              )
              .then(
                () => {
                  this.data
                    .collection('ProjectTeamMembers')
                    .insert({
                      projectId: event.booking.project.id,
                      resourceId: event.booking.resourceId,
                    })
                    .subscribe({
                      next: () => {
                        this.updateEntry(event);
                      },
                      error: (ex: Exception) => {
                        this.notification.error(ex.message);
                        cancel();
                      },
                    });
                },
                () => cancel(),
              );

            return;
          }

          cancel();
          this.notification.error(error.message);
        },
      });
  }

  notify(booking: BookingEntry) {
    const ref = this.modal.open(BookingNotifyComponent, {});
    (ref.componentInstance as BookingNotifyComponent).bookingId = booking.id;
  }

  public init(): void {
    this.scheduleNavigationService.init(ScheduleNavigationContext.Booking);
    this.settings = this.localConfigService.getConfig(BookingSettings);
    this.settings.planningScale = this.scheduleNavigationService.planningScale;
    this.dataService.valueMode = this.settings.valueMode;

    if (this.bookingRenderingService) {
      this.bookingRenderingService.planningScale = this.settings.planningScale;
    }

    this.zone.runOutsideAngular(() => {
      this.enableLoadingOnScroll();
    });

    this.reload();
  }

  private getResourceIdsForLoadChanges(): string[] {
    return this.dataService.resources
      .filter((r) => this.expandedResourceIds.has(r.id))
      .map((r) => r.id);
  }

  private initSubscribers(): void {
    if (this.bookingRenderingService) {
      this.bookingRenderingService.stopChange$
        .pipe(takeUntil(this.destroyed$))
        .subscribe((event) => {
          this.updateEntry(event);
        });
      this.bookingRenderingService.stopCreate$
        .pipe(takeUntil(this.destroyed$))
        .subscribe((event) => {
          this.openBookingForm(event.booking, 'create');
        });
    }

    this.toggleGroup$
      .pipe(
        debounceTime(this.massToggleOperationDelay),
        filter(() => !this.isChangeEntriesHidden),
        switchMap(() =>
          this.dataService.loadChangeEntries(
            this.getResourceIdsForLoadChanges(),
            this.interval,
            this.settings.planningScale,
          ),
        ),
        takeUntil(this.destroyed$),
      )
      .subscribe((bookings) => {
        bookings.forEach((booking) => {
          this.detectChangesSubject.next(booking.resourceId);
        });
      });

    this.bookingManageService.reload$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.savingQueueService.save();
        this.reloadBookings();
      });

    /** Событие изменения фильтра. */
    this.bookingFilterService.values$
      .pipe(
        filter(() => !!this.settings),
        debounceTime(Constants.textInputClientDebounce),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        this.reload();
      });

    this.bookingViewSettingsService.settings$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((settings) => {
        if (!settings.resourceRequestShown) {
          this.dataService.bookingsChangeEntries.length = 0;
        }

        this.reload();
      });

    this.scheduleNavigationService.previous$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.loadFrame('left');
      });

    this.scheduleNavigationService.next$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.loadFrame('right');
      });

    this.scheduleNavigationService.jump$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((date) => {
        this.reload(date);
      });

    this.scheduleNavigationService.planningScale$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((planningScale) => {
        this.savingQueueService.save();
        this.setPlanningScale(planningScale);
      });

    this.scheduleNavigationService.valueMode$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((valueMode) => {
        this.setValueMode(valueMode);
      });

    this.savingQueueService.error$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.reload();
      });
  }
}
