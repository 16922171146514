import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Injector,
  OnInit,
} from '@angular/core';

import { InfoPopupService } from './info-popup.service';

@Component({
  selector: 'tmt-popup-container',
  templateUrl: './info-popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class InfoPopupComponent implements OnInit {
  constructor(
    public infoPopupService: InfoPopupService,
    public injector: Injector,
    private cdr: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.infoPopupService.event$.subscribe((event) => {
      switch (event.name) {
        case 'create':
        case 'destroy':
          this.cdr.markForCheck();
          break;
      }
    });
  }
}
