<wp-data-not-saved-indicator [form]="contactProfileService.form" />
<wp-loading-indicator [loading]="contactProfileService.isLoading()" class="mt-3 d-block" />

<form
  class="form-container"
  [hidden]="contactProfileService.isLoading()"
  [formGroup]="contactProfileService.form"
  novalidate
  autocomplete="off"
>
  <div ngbAccordion class="accordion--custom">
    <div ngbAccordionItem [tmtAccordionState]="'contact-profile'">
      <h2 ngbAccordionHeader>
        <button class="group" ngbAccordionButton>
          {{ 'shared2.groups.mainProperties' | translate }}
        </button>
      </h2>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <div class="form-grid">
            <div class="form-group" tmtIndicator>
              <label>{{ 'shared2.props.firstName' | translate }}</label>
              <wp-text-box
                formControlName="firstName"
                placeholder="{{ 'shared2.props.firstName' | translate }}"
                [propagationMode]="propagationMode.onExitFromEditing"
              />
            </div>

            <div class="form-group" tmtIndicator>
              <label>{{ 'shared2.props.organization' | translate }}</label>
              <wp-select-box
                formControlName="organizationId"
                [isIdMode]="true"
                collection="Organizations"
                placeholder="{{ 'shared2.props.organization' | translate }}"
              />
            </div>

            <div class="form-group">
              <label>{{ 'shared2.props.email' | translate }}</label>
              <wp-text-box
                formControlName="email"
                placeholder="{{ 'shared2.props.email' | translate }}"
                extendedType="email"
                [propagationMode]="propagationMode.onExitFromEditing"
              />
            </div>

            <div class="form-group">
              <label>{{ 'shared2.props.lastName' | translate }}</label>
              <wp-text-box
                formControlName="lastName"
                placeholder="{{ 'shared2.props.lastName' | translate }}"
                [propagationMode]="propagationMode.onExitFromEditing"
              />
            </div>

            <div class="form-group">
              <label>{{ 'shared2.props.role' | translate }}</label>
              <wp-select-box
                formControlName="roleId"
                [isIdMode]="true"
                [directory]="{ entityName: 'Contact', propertyName: 'Role' }"
                placeholder="{{ 'shared2.props.role' | translate }}"
              />
            </div>

            <div class="form-group">
              <label>{{ 'shared2.props.phone' | translate }}</label>
              <wp-text-box
                formControlName="phone"
                placeholder="{{ 'shared2.props.phone' | translate }}"
                extendedType="phone"
                [propagationMode]="propagationMode.onExitFromEditing"
                #phone
              />
            </div>

            <div class="form-group">
              <label>{{ 'shared2.props.patronymic' | translate }}</label>
              <wp-text-box
                formControlName="patronymic"
                placeholder="{{ 'shared2.props.patronymic' | translate }}"
                [propagationMode]="propagationMode.onExitFromEditing"
              />
            </div>

            <div class="form-group">
              <label>{{ 'shared2.props.position' | translate }}</label>
              <wp-text-box
                formControlName="position"
                placeholder="{{ 'shared2.props.position' | translate }}"
                [propagationMode]="propagationMode.onExitFromEditing"
              />
            </div>

            <div class="form-group">
              <label>{{ 'shared2.props.mobilePhone' | translate }}</label>
              <wp-text-box
                formControlName="mobilePhone"
                placeholder="{{ 'shared2.props.mobilePhone' | translate }}"
                extendedType="phone"
                [propagationMode]="propagationMode.onExitFromEditing"
                #mobilePhone
              />
            </div>

            <div class="form-group full-width">
              <label>{{ 'shared2.props.description' | translate }}</label>
              <wp-multiline-text-box
                formControlName="description"
                placeholder="{{ 'shared2.props.description' | translate }}"
                [propagationMode]="propagationMode.onExitFromEditing"
              />
            </div>

            <div class="form-check mt-2">
              <input
                formControlName="isActive"
                type="checkbox"
                class="form-check-input"
                id="isActive"
              />
              <label class="form-check-label" for="isActive">
                {{ 'shared2.props.isActive.value' | translate }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    @if (appService.getCustomFields('Contact').length) {
      <div ngbAccordionItem [tmtAccordionState]="'contact-advanced'">
        <h2 ngbAccordionHeader>
          <button class="group" ngbAccordionButton>
            {{ 'shared2.groups.advanced' | translate }}
          </button>
        </h2>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <wp-custom-fields
              [formGroup]="contactProfileService.form"
              [isVisibleHr]="false"
              [entityType]="'Contact'"
              [propagationMode]="propagationMode.onExitFromEditing"
              class="full-width"
            />
          </div>
        </div>
      </div>
    }
  </div>
</form>
