import {
  computed,
  DestroyRef,
  inject,
  Injectable,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ContactCardService } from 'src/app/contacts/card/contact-card.service';
import { Contact } from 'src/app/contacts/model/contact.model';
import { NotificationService } from 'src/app/core/notification.service';
import { Deal } from 'src/app/deals/model/deal.model';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';
import { Constants } from 'src/app/shared/globals/constants';
import { Exception } from 'src/app/shared/models/exception';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { emailValidator } from 'src/app/shared/validators/email';

@Injectable()
export class ContactProfileService {
  private readonly _isLoading = signal<boolean>(false);
  public readonly isLoading = computed(this._isLoading);

  public form: UntypedFormGroup;

  public get formRawValue(): Partial<Deal> {
    return this.form.getRawValue();
  }

  private readonly contactCardService = inject(ContactCardService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly customFieldService = inject(CustomFieldService);
  private readonly savingQueueService = inject(SavingQueueService);
  private readonly notificationService = inject(NotificationService);
  private readonly fb = inject(UntypedFormBuilder);

  constructor() {
    this.form = this.fb.group({
      firstName: [
        '',
        [
          Validators.required,
          Validators.maxLength(Constants.formNameMaxLength),
        ],
      ],
      lastName: ['', [Validators.maxLength(Constants.formNameMaxLength)]],
      patronymic: ['', [Validators.maxLength(Constants.formNameMaxLength)]],
      organizationId: null,
      roleId: null,
      position: ['', [Validators.maxLength(Constants.formNameMaxLength)]],
      description: ['', [Validators.maxLength(Constants.formTextMaxLength)]],
      email: ['', emailValidator()],
      mobilePhone: [
        '',
        [
          Validators.minLength(Constants.minPhoneLength),
          Validators.maxLength(Constants.maxPhoneLength),
        ],
      ],
      phone: [
        '',
        [
          Validators.minLength(Constants.minPhoneLength),
          Validators.maxLength(Constants.maxPhoneLength),
        ],
      ],
      isActive: null,
    });

    this.customFieldService.enrichFormGroup(this.form, 'Contact');

    this.savingQueueService.save$.pipe(takeUntilDestroyed()).subscribe(() => {
      this.contactCardService.updateContact(this.formRawValue);
      this.form.markAsPristine();
    });
    this.savingQueueService.error$.pipe(takeUntilDestroyed()).subscribe(() => {
      this.load();
    });

    this.subscribeToFormChanges();
  }

  /** Loads contact. */
  public async load(): Promise<void> {
    await this.savingQueueService.save();
    this.form.markAsPristine();
    this.form.markAsUntouched();

    this._isLoading.set(true);

    const query = this.buildQuery();

    this.contactCardService
      .loadContact(query)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (contact: Contact) => {
          this.form.patchValue(contact, { emitEvent: false });
          this._isLoading.set(false);
          if (!contact.editAllowed) {
            this.form.disable({ emitEvent: false });
          } else {
            this.form.enable({ emitEvent: false });
          }
          this.form.controls.organizationId.disable({
            emitEvent: false,
          });
        },
        error: (error: Exception) => {
          this._isLoading.set(false);
          this.notificationService.error(error.message);
        },
      });
  }

  /**
   * Builds OData query for contact entity
   *
   * @returns OData query
   */
  private buildQuery(): any {
    const query = {
      select: [
        'firstName',
        'lastName',
        'patronymic',
        'organizationId',
        'roleId',
        'position',
        'description',
        'email',
        'mobilePhone',
        'phone',
        'editAllowed',
        'isActive',
      ],
    };

    this.customFieldService.enrichQuery(query, 'Contact');

    return query;
  }

  /** Subscribes to form changes and saves form data. */
  private subscribeToFormChanges?(): void {
    this.form.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (this.form.invalid) {
          return;
        }

        const contactData: Partial<Contact> = this.formRawValue;

        this.savingQueueService.addToQueue(
          this.contactCardService.contactId,
          this.contactCardService.contactCollection
            .entity(this.contactCardService.contactId)
            .patch(contactData),
        );
      });
  }
}
