import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { BillCodeCardService } from 'src/app/settings-app/bill-codes/card/bill-code.service';

@Component({
  selector: 'tmt-bill-code-card',
  templateUrl: './bill-code-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [BillCodeCardService],
  standalone: false,
})
export class BillCodeCardComponent implements OnInit {
  constructor(
    public billCodeCardService: BillCodeCardService,
    private actionPanelService: ActionPanelService,
  ) {}

  public ngOnInit(): void {
    this.actionPanelService.set([
      {
        title: 'shared2.actions.save',
        hint: 'shared2.actions.save',
        name: 'save',
        iconClass: 'bi bi-save',
        isBusy: false,
        isVisible: false,
        handler: () => this.billCodeCardService.save(),
      },
      this.actionPanelService.getDefaultAction('setAsDefault', {
        handler: () => this.billCodeCardService.setAsDefault(),
      }),
    ]);
  }
}
