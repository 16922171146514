<div class="feed__toolbar">
  <wp-filter />

  <div class="group">
    @if (!workLogService.readonly) {
      <button
        type="button"
        class="btn btn-default"
        title="{{ 'components.workLogComponent.actions.create' | translate }}"
        (click)="workLogService.createTimeEntry()"
        data-test="create"
      >
        <i class="bi bi-plus-lg bi-15"></i>
        {{ 'components.workLogComponent.actions.create' | translate }}
      </button>
    }
  </div>
</div>

<div class="feed__content">
  <wp-loading-indicator
    [loading]="workLogService.isLoading()"
    [isOverlay]="true"
    [spinnerParams]="spinnerParams"
    [delay]="300"
  >
    @for (item of workLogService.timeEntries(); track item.id) {
      <tmt-work-log-item [timeEntry]="item" [readonly]="workLogService.isTimeEntryReadonly(item)" />
    } @empty {
      <div class="text-center text-body-secondary p-5">
        {{ 'shared2.messages.noDisplayData' | translate }}
      </div>
    }
  </wp-loading-indicator>
</div>
