<form class="modal-form" novalidate ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'timesheets.card.verifyingRules.header' | translate }}
    </h3>
    <button
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>

  <div class="modal-body">
    @for (group of errorGroups; track group.ruleType) {
      <div class="d-flex">
        @if (group.canOverride) {
          <span style="color: var(--bs-orange)" class="bi bi-info-circle fs-4"></span>
        } @else {
          <span style="color: var(--bs-danger)" class="bi bi-slash-circle fs-4"></span>
        }
        <ul class="ps-3">
          <div
            class="d-flex flex-column"
            [ngClass]="{
              'mb-2': group.ruleName || group.customMessage,
              'mb-1': !group.ruleName && !group.customMessage && group.messages.length === 1,
            }"
          >
            @if (group.ruleName) {
              <span class="fw-bold">{{ group?.ruleName }}</span>
            }
            @if (group.customMessage) {
              <span class="text-body-secondary">{{ group?.customMessage }}</span>
            }
          </div>

          @for (message of group.messages; track message) {
            <li>
              {{ message }}
            </li>
          }
        </ul>
      </div>
    }
  </div>
  <div class="modal-footer">
    @if (canOverride) {
      <button type="button" class="btn btn-primary" (click)="ignoreAndSubmit()">
        {{ 'timesheets.card.verifyingRules.ignoreAndSubmit' | translate }}
      </button>
    }

    <button type="button" class="btn btn-default" (click)="cancel()">
      {{ 'timesheets.card.verifyingRules.correct' | translate }}
    </button>
  </div>
</form>
