import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AssignmentTariffCellComponent } from 'src/app/projects/card/project-tasks/shared/task-card-modal/task-assignments/assignments-table/assignment-tariff-cell/assignment-tariff-cell.component';
import { SharedModule } from 'src/app/shared/shared.module';

@Component({
  selector: 'tmt-assignment-primary-tariff',
  imports: [SharedModule],
  template: `<span
    class="trim d-inline-block w-100 align-bottom"
    [title]="control?.getRawValue()?.name"
    >{{ control?.getRawValue()?.name }}</span
  >`,
  styles: `
    span {
      padding: 0 12px;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssignmentTariffReadonlyCellComponent extends AssignmentTariffCellComponent {}
