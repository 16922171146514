import { Component, effect, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ExpenseRequestLine } from 'src/app/shared/models/entities/base/expenses-request.model';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { NotificationService } from 'src/app/core/notification.service';
import { ExpenseRequestCardService } from 'src/app/expenses-requests/card/expenses-request-card.service';
import { Constants } from 'src/app/shared/globals/constants';
import { FinancialAccountsService } from 'src/app/core/financial-accounts.service';
import { FilesService } from 'src/app/shared/components/controls/file-box';

/**
 * Represents Expense request line View/Edit modal window content.
 * */
@Component({
  selector: 'tmt-expenses-request-line-modal',
  templateUrl: './line-modal.component.html',
  styleUrls: ['./line-modal.component.scss'],
  standalone: false,
  providers: [FilesService],
})
export class LineModalComponent implements OnInit {
  @Input() readonly: boolean;
  @Input() line: ExpenseRequestLine;

  public lineForm: UntypedFormGroup;
  public maxAttachmentSize = Constants.maxAttachmentSize;

  constructor(
    public financialAccountsService: FinancialAccountsService,
    public cardService: ExpenseRequestCardService,
    public blockUI: BlockUIService,
    private notification: NotificationService,
    private activeModal: NgbActiveModal,
    filesService: FilesService,
  ) {
    effect(() => {
      const files = filesService.files();
      this.lineForm.patchValue({ attachment: files }, { emitEvent: false });
    });
  }

  ngOnInit(): void {
    this.lineForm = this.cardService.getLineGroup(this.line, true);
    if (this.readonly) {
      this.lineForm.disable({ emitEvent: false });
    }
  }

  /**
   * Modal window Ok button click event handler.
   * Closes modal window and passes Expense request line form group as a result.
   * */
  ok = () => {
    this.lineForm.markAllAsTouched();

    if (this.lineForm.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }
    this.cardService.modalClosed$.next();
    this.activeModal.close(this.lineForm);
  };

  /**
   * Modal window Cancel/Dismiss button click event handler.
   * Closes modal window and passes Expense request line attachment as a result.
   * */
  cancel = () => {
    this.cardService.modalClosed$.next();
    this.activeModal.dismiss(this.lineForm.controls.attachment.value);
  };
}
