<div class="loading-container" [ngStyle]="spinnerStyleVariables">
  @if (isOverlay()) {
    @if (loading()) {
      @defer (when delayTimer$ | async) {
        <div class="loading--overlay-background"></div>
        <div class="loading-spinner loading-spinner--overlay"></div>
      }
    }

    <ng-content />
  }

  @if (!isOverlay() && loading()) {
    @defer (when delayTimer$ | async) {
      @if (isSpinnerMode()) {
        <div class="loading-spinner"></div>
      } @else {
        <div class="progress" [style.width]="progressWidthStyle() + '%'">
          <div
            class="progress-bar progress-bar-striped progress-bar-animated"
            role="progressbar"
            aria-valuenow="100"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      }
    }
  }
</div>
