import {
  Component,
  forwardRef,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewRef,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Inject,
  Optional,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  booleanAttribute,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
} from '@angular/forms';
import { fromEvent, pairwise, Subscription } from 'rxjs';
import { AbstractBox } from 'src/app/shared/components/controls/abstract-box/abstract-box';
import { TextBoxExtendedType } from 'src/app/shared/components/controls/text-box/text-box.model';
import { StandaloneFormControlDirective } from 'src/app/shared/directives/form-control.directive';
import { Constants } from 'src/app/shared/globals/constants';
import { PropagationMode } from 'src/app/shared/models/enums/control-propagation-mode.enum';
import { PhonePipe } from 'src/app/shared/pipes/phone.pipe';
import { PhoneService } from 'src/app/shared/services/phone.service';

/** Контрол ввода текста. */
@Component({
  selector: 'wp-text-box',
  templateUrl: './text-box.component.html',
  styleUrls: ['./text-box.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextBoxComponent),
      multi: true,
    },
  ],
  hostDirectives: [
    {
      directive: StandaloneFormControlDirective,
      inputs: ['formControl: control'],
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class TextBoxComponent
  extends AbstractBox
  implements ControlValueAccessor, OnInit, AfterViewInit, OnChanges, OnDestroy
{
  /** Заменить пустого текста. */
  @Input() placeholder: string;

  /** Признак режима только-чтение. */
  @Input({ transform: booleanAttribute }) readonly: boolean;

  /** Признак режима "пароль". */
  @Input() password = false;

  /** Признак установки автофокуса при отображении. */
  @Input() autofocus = false;

  /** Максимальная длина вводимого текста. */
  @Input() maxLength = Constants.formTextMaxLength;

  /** Initial value for input element after rendering. */
  @Input() initialValue?: unknown;

  /** Angular abstract control for binding to form outside of template. */
  @Input() control?: AbstractControl;

  /** Property for working in chosen link mode. */
  @Input() extendedType: TextBoxExtendedType;

  private keyboardSubscription: Subscription;

  @ViewChild('editor') editor: ElementRef<HTMLInputElement>;
  @ViewChild('clearBtn') clearBtn: ElementRef;

  public disabled = false;
  public viewType = 'text';
  public formControl = new UntypedFormControl(null);

  private _value = '';

  private get input(): HTMLInputElement | null {
    if (this.readonly) {
      return null;
    }
    return this.editor.nativeElement;
  }

  constructor(
    private ref: ChangeDetectorRef,
    @Optional() @Inject(NG_VALIDATORS) private validators: any,
    private phonePipe: PhonePipe,
    private phoneService: PhoneService,
  ) {
    super();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['readonly']) {
      this.disabled = this.readonly;
      this.ref.markForCheck();
    }
  }

  public ngOnInit(): void {
    if (this.password) {
      this.viewType = 'password';
    }

    this.formControl.valueChanges
      .pipe(pairwise(), takeUntilDestroyed(this.destroyRef))
      .subscribe(([oldValue, newValue]) => {
        newValue &&= newValue.trim();
        const isPhone = this.extendedType === 'phone';

        switch (this.propagationMode) {
          case PropagationMode.onInput: {
            let newControlValue: string;
            if (isPhone) {
              const newViewValue = this.phoneService.getFormattedPhoneValue(
                false,
                this.formControl.value,
                this.phoneService.extractNumbers(newValue).length >
                  Constants.maxPhoneLength
                  ? undefined
                  : oldValue,
              );
              this.formControl.setValue(newViewValue, { emitEvent: false });
              newControlValue = this.phoneService.extractNumbers(newViewValue);
            } else {
              newControlValue = newValue;
            }
            this.toggleControlDirty(this._value, newControlValue);
            if (newControlValue === this._value) return;
            this._value = newControlValue;
            this.propagateChange(this._value);
            return;
          }

          case PropagationMode.onExitFromEditing: {
            if (isPhone) {
              this.toggleControlDirty(
                this.phonePipe.transform(this._value),
                newValue,
              );
              this.formControl.setValue(
                this.phoneService.getFormattedPhoneValue(
                  false,
                  this.formControl.value,
                  oldValue,
                ),
                { emitEvent: false },
              );
            } else {
              this.toggleControlDirty(this._value, newValue);
            }
            return;
          }
        }
      });

    // Necessary for init value after valueChange subscription for init oldValue in pairwise
    this.formControl.setValue('');

    switch (this.extendedType) {
      case 'phone':
        this.placeholder = '+0 (000) 000-00-00';
        break;
      case 'email':
        this.placeholder = 'example@domain.com';
        break;
      case 'url':
        this.placeholder = 'https://example.com';
        break;
      default:
    }
  }

  public override ngAfterViewInit(): void {
    super.ngAfterViewInit();

    if (this.autofocus) {
      this.input?.focus();
    }

    this.applyInitialValue();
  }

  public ngOnDestroy(): void {
    if (this.propagationMode === PropagationMode.onExitFromEditing) {
      this.onExitEditing();
    }
    this.keyboardSubscription?.unsubscribe();
  }

  propagateChange = (_: any) => null;
  propagateTouch = () => null;

  public getTitle() {
    if (this.password) {
      return null;
    }

    return this.formControl.value;
  }

  writeValue(value: any): void {
    // Do not patch value if control in editing process.
    if (
      this.propagationMode === PropagationMode.onExitFromEditing &&
      (this.extendedType === 'phone'
        ? this._value !==
          this.phoneService.extractNumbers(this.formControl.value)
        : this._value !== this.formControl.value)
    ) {
      return;
    }

    this._value = value ? value : '';

    const formattedValue =
      this.extendedType === 'phone'
        ? this.phonePipe.transform(this._value)
        : this._value;

    this.formControl.setValue(formattedValue, { emitEvent: false });
    if (!(this.ref as ViewRef).destroyed) {
      this.ref.detectChanges();
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (!this.readonly) {
      this.disabled = isDisabled;
    }

    if (!(this.ref as ViewRef).destroyed) {
      this.ref.detectChanges();
    }
  }

  /** Input onFocus logic.*/
  public onFocus(): void {
    if (this.propagationMode === PropagationMode.onExitFromEditing) {
      this.initKeysSubscribes();
    }
  }

  /**
   * Handles the logic for when the input exits editing mode.
   *
   * @param resetValue - A boolean indicating whether to reset the form control's value to an empty string.
   * @param unsubscribe - A boolean indicating whether to unsubscribe from the keyboard events.
   */

  public onExitEditing(resetValue = false, unsubscribe = true): void {
    this.propagateTouch();
    if (unsubscribe) this.keyboardSubscription?.unsubscribe();
    let value = resetValue ? '' : this.formControl.value?.trim();

    if (resetValue) {
      this.formControl.setValue(value);
      if (this.propagationMode === PropagationMode.onExitFromEditing) {
        this.propagateChange(value);
      }

      return;
    }

    switch (this.extendedType) {
      case 'phone':
        value = this.getPhoneValue(value);
        break;
      case 'url':
        value = this.getUrlValue(value);
        this.formControl.setValue(value, { emitEvent: false });
        break;
    }
    if (this._value === value) {
      return;
    }

    this._value = value;
    this.propagateChange(value);
  }

  private getPhoneValue(value: string): string {
    const formatted = this.phoneService.getFormattedPhoneValue(true, value);
    this.formControl.setValue(formatted, { emitEvent: false });
    return this.phoneService.extractNumbers(formatted);
  }

  private getUrlValue(value: string): string {
    if (value && !/^https?:\/\//.test(value)) {
      value = `https://${value}`;
    }
    return value;
  }

  /** Apply initial value after rendering. */
  private applyInitialValue(): void {
    if (this.initialValue === undefined) {
      return;
    }
    if (!this.readonly && this.input) {
      const event = new Event('input');
      if (typeof this.initialValue === 'string') {
        this.input.value = this.initialValue;
        this.input.dispatchEvent(event);
        this.input.focus();
      } else if (this.initialValue === null) {
        this.input.value = '';
        this.input.dispatchEvent(event);
        this.input.focus();
      }
    }
    this.initialValue = undefined;
  }

  /** Initializes keyboard listener. */
  private initKeysSubscribes() {
    this.keyboardSubscription?.unsubscribe();
    this.keyboardSubscription = fromEvent(window, 'keydown').subscribe(
      (event: KeyboardEvent) => {
        if (!event.repeat) {
          if (event.code === 'Enter' || event.code === 'NumpadEnter') {
            this.onExitEditing(false, false);
          }
        }
      },
    );
  }
}
