<wp-loading-indicator [state]="state"></wp-loading-indicator>
<wp-data-not-saved-indicator [form]="form"></wp-data-not-saved-indicator>

<form [formGroup]="form" *ngIf="state === 'Ready'" class="form" style="width: 900px">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div formGroupName="organization">
          <label class="group">{{
            'settings.account.billingInfo.groupCompany.label' | translate
          }}</label>

          <div class="form-group" tmtIndicator>
            <label>{{ 'settings.account.billingInfo.props.companyName.label' | translate }}</label>
            <wp-text-box
              placeholder="{{ 'settings.account.billingInfo.props.companyName.label' | translate }}"
              formControlName="name"
            >
            </wp-text-box>
          </div>

          <div
            class="form-group"
            tmtIndicator
            *ngIf="form.getRawValue().country?.alpha3Code === 'RUS'"
          >
            <label>{{
              'settings.account.billingInfo.props.identificationNumber.label' | translate
            }}</label>
            <wp-text-box
              style="width: 200px"
              formControlName="identificationNumber"
              placeholder="{{
                'settings.account.billingInfo.props.identificationNumber.ph' | translate
              }}"
            >
            </wp-text-box>
          </div>

          <div
            class="form-group"
            tmtIndicator
            *ngIf="form.getRawValue().country?.alpha3Code === 'RUS'"
          >
            <label>{{
              'settings.account.billingInfo.props.bankIdentificationCode.label' | translate
            }}</label>
            <wp-text-box
              style="width: 200px"
              placeholder="{{
                'settings.account.billingInfo.props.bankIdentificationCode.ph' | translate
              }}"
              formControlName="bankIdentificationCode"
            ></wp-text-box>
          </div>

          <div
            class="form-group"
            tmtIndicator
            *ngIf="form.getRawValue().country?.alpha3Code === 'RUS'"
          >
            <label>{{ 'settings.account.billingInfo.props.bankAccount.label' | translate }}</label>
            <wp-text-box
              style="width: 200px"
              formControlName="bankAccount"
              placeholder="{{ 'settings.account.billingInfo.props.bankAccount.ph' | translate }}"
            >
            </wp-text-box>
          </div>
        </div>

        <label class="group pt-3">
          {{ 'settings.account.billingInfo.groupAddress.label' | translate }}
        </label>
        <ng-container formGroupName="postAddress">
          <div class="form-group" tmtIndicator>
            <label>{{ 'settings.account.billingInfo.props.city.label' | translate }}</label>
            <wp-text-box
              formControlName="city"
              placeholder="{{ 'settings.account.billingInfo.props.city.label' | translate }}"
            ></wp-text-box>
          </div>

          <div class="form-group" tmtIndicator>
            <label>{{ 'settings.account.billingInfo.props.index.label' | translate }}</label>
            <wp-text-box
              style="width: 200px"
              placeholder="{{ 'settings.account.billingInfo.props.index.placeholder' | translate }}"
              formControlName="index"
            >
            </wp-text-box>
          </div>

          <div class="form-group" tmtIndicator>
            <label>{{ 'settings.account.billingInfo.props.address.label' | translate }}</label>
            <wp-multiline-text-box
              rows="2"
              placeholder="{{
                'settings.account.billingInfo.props.address.placeholder' | translate
              }}"
              formControlName="address"
            >
            </wp-multiline-text-box>
          </div>
        </ng-container>

        <div
          class="form-check"
          *ngIf="form.getRawValue().country?.alpha3Code === 'RUS'"
          tmtIndicator
        >
          <input formControlName="useEDM" type="checkbox" class="form-check-input" id="useEDM" />
          <label class="form-check-label" for="useEDM">
            {{ 'settings.account.billingInfo.props.useEDM.label' | translate }}
          </label>
          <br />
          <small class="text-body-secondary">{{
            'settings.account.billingInfo.props.useEDM.help' | translate
          }}</small>
        </div>
      </div>
      <div class="col">
        <label class="group">
          {{ 'settings.account.billingInfo.groupRegion.label' | translate }}
        </label>
        <div class="form-group" tmtIndicator>
          <label>{{ 'settings.account.billingInfo.props.country.label' | translate }}</label>
          <wp-select-box
            [placeholder]="'settings.account.billingInfo.props.country.label' | translate"
            [values]="countries"
            [filterOnServer]="false"
            [allowNull]="false"
            formControlName="country"
          ></wp-select-box>
        </div>
        <div class="form-group">
          <label>{{ 'settings.account.billingInfo.props.currency.label' | translate }}</label>
          <p class="pt-1" [wpLoadingValue]="currency" [loading]="currencyLoading$ | async"></p>
        </div>

        <label class="group pt-3">
          {{ 'settings.account.billingInfo.groupContact.label' | translate }}
        </label>
        <ng-container formGroupName="contact">
          <div class="form-group" tmtIndicator>
            <label>{{ 'settings.account.billingInfo.props.contactName.label' | translate }}</label>
            <wp-text-box
              placeholder="{{ 'settings.account.billingInfo.props.contactName.label' | translate }}"
              formControlName="name"
            ></wp-text-box>
          </div>

          <div class="form-group" tmtIndicator>
            <label>{{ 'settings.account.billingInfo.props.contactEmail.label' | translate }}</label>
            <wp-text-box
              formControlName="email"
              placeholder="{{
                'settings.account.billingInfo.props.contactEmail.label' | translate
              }}"
            >
            </wp-text-box>

            <small class="text-body-secondary">{{
              'settings.account.billingInfo.props.contactEmail.help' | translate
            }}</small>
          </div>

          <div class="form-group" tmtIndicator>
            <label>{{ 'settings.account.billingInfo.props.contactPhone.label' | translate }}</label>
            <wp-text-box
              formControlName="phone"
              placeholder="{{
                'settings.account.billingInfo.props.contactPhone.placeholder' | translate
              }}"
              extendedType="phone"
            >
            </wp-text-box>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="actions" *ngIf="!readonly">
    <button
      type="button"
      class="btn btn-primary"
      wpButtonSpinner
      [isBusy]="isSaving"
      (click)="save()"
    >
      {{ 'shared.actions.save' | translate }}
    </button>
  </div>
</form>
