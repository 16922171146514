import { inject, Inject, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormGroup } from '@angular/forms';
import { catchError, forkJoin, map, merge, Observable, of } from 'rxjs';
import {
  GridOptions,
  SelectionType,
} from 'src/app/shared-features/grid/models/grid-options.model';
import { Exception } from 'src/app/shared/models/exception';
import { ContactCreateModalComponent } from 'src/app/contacts/contact-create/contact-create.component';
import { DealContactsToolbarComponent } from 'src/app/deals/card/deal-contacts/toolbar/deal-contacts-toolbar.component';
import { ClientContactsService } from 'src/app/clients/card/client-contact/core/client-contacts.service';
import { DealCardService } from 'src/app/deals/card/deal-card.service';
import { DealContact } from 'src/app/deals/model/deal.model';
import { DealAddContactsModalComponent } from 'src/app/deals/card/deal-contacts/deal-add-contacts-modal/deal-add-contacts-modal.component';
import { DealContactsSettings } from 'src/app/deals/card/deal-contacts/deal-contacts-settings';
import { Collection } from 'src/app/core/data.service';
import { Guid } from 'src/app/shared/helpers/guid';

@Injectable()
export class DealContactsService extends ClientContactsService {
  public override gridOptions: GridOptions = {
    resizableColumns: true,
    sorting: true,
    selectionType: SelectionType.row,
    toolbar: DealContactsToolbarComponent,
    commands: [
      {
        name: 'create',
        allowedFn: () => !this.readonlyDeal(),
        handlerFn: () => {
          this.createClientContact();
        },
      },
      {
        name: 'select',
        allowedFn: () => !this.readonlyDeal(),
        handlerFn: () => {
          this.selectClientContact();
        },
      },
      {
        name: 'delete',
        label: 'shared.actions.delete',
        allowedFn: (formGroup: UntypedFormGroup) =>
          !this.readonlyDeal() && !!formGroup,
        handlerFn: (formGroup: UntypedFormGroup) => {
          this.deleteContact(formGroup.value.id);
        },
      },
      { name: 'setUserView', handlerFn: () => this.setUserView() },
    ],
    rowCommands: [
      {
        name: 'delete',
        label: 'shared.actions.delete',
        allowedFn: () => !this.readonlyDeal(),
        handlerFn: (formGroup: UntypedFormGroup) =>
          this.deleteContact(formGroup.value.id),
      },
    ],
    view: this.listService.getGridView(),
  };

  private dealContactsSettings: DealContactsSettings;
  private dealContacts: DealContact[];
  private dealContactsCollection: Collection;
  private readonlyDeal = signal<boolean>(false);
  private readonly dealCardService = inject(DealCardService);

  private get dealId(): string {
    return this.dealCardService.dealId;
  }

  constructor(
    @Inject('organizationId') public override organizationId: string,
  ) {
    super(organizationId);
    this.dealContactsCollection = this.dataService.collection('DealContacts');
    this.readonlyDeal.set(!this.dealCardService.deal().editAllowed);
    merge(this.dealCardService.reloadTab$)
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.load();
      });
  }

  /** Loads deal contacts. */
  public override load(): void {
    this.gridService.setLoadingState(true);
    this.formArray.clear();

    const query = this.buildContactsQuery();

    this.dealContactsCollection
      .query<DealContact[]>(query)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (dealContacts: DealContact[]) => {
          this.dealContacts = dealContacts;
          const contacts = dealContacts?.map(
            (dealContact) => dealContact.contact,
          );
          if (contacts?.length) {
            this.processContacts(contacts, false);
          }
          this.gridService.setLoadingState(false);
        },
        error: (error: Exception) => {
          this.notificationService.error(error.message);
          this.gridService.setLoadingState(false);
        },
      });
  }

  /**
   * Sets onlyActive setting.
   *
   * @param onlyActive indicator to show active or all entities.
   */
  protected override setConfig(onlyActive: boolean) {
    this.dealContactsSettings.onlyActive = onlyActive;
    this.localConfigService.setConfig(
      DealContactsSettings,
      this.dealContactsSettings,
    );
  }

  /** Loads onlyActive setting */
  protected override loadSettings(): void {
    this.dealContactsSettings =
      this.localConfigService.getConfig(DealContactsSettings);
    this.onlyActive.set(this.dealContactsSettings.onlyActive);
  }

  /** Opens creation contact modal window. */
  protected override createClientContact(): void {
    const modalRef = this.modal.open(ContactCreateModalComponent);

    (modalRef.componentInstance as ContactCreateModalComponent).organizationId =
      this.organizationId;

    modalRef.result.then(
      (result) => {
        const newContact: DealContact = {
          id: Guid.generate(),
          contactId: result.id,
          dealId: this.dealId,
        };

        this.addContactToDeal(newContact).subscribe((isSaved) => {
          if (isSaved) {
            this.dealCardService.dealContacts.update((oldContacts) => [
              ...oldContacts,
              newContact,
            ]);
            this.load();
            this.notificationService.successLocal(
              'components.dealContactsService.messages.contactAdded',
            );
          }
        });
      },
      () => null,
    );
  }

  /**
   * Builds OData contacts query.
   * @returns OData contacts query object.
   */
  protected override buildContactsQuery(): any {
    const query: any = {
      select: ['id'],
      expand: {
        contact: {
          select: [
            'id',
            'name',
            'firstName',
            'lastName',
            'patronymic',
            'organizationId',
            'position',
            'description',
            'email',
            'mobilePhone',
            'phone',
            'isActive',
          ],
          expand: [{ role: { select: ['id', 'name'] } }],
        },
      },
      orderBy: this.getOrderBy(),
      filter: [
        {
          dealId: { type: 'guid', value: this.dealId },
        },
      ],
    };

    this.customFieldService.enrichQuery(query.expand.contact, 'Contact');

    if (this.onlyActive()) {
      query.filter.push('contact/isActive eq true');
    }

    return query;
  }

  /**
   * Gets orderBy for loading.
   *
   * @returns orderBy string.
   */
  protected override getOrderBy(): string {
    const column = this.gridService.order.column || 'firstName';
    const direction = this.gridService.order.reverse ? 'desc' : 'asc';

    return `contact/${column} ${direction}`;
  }

  /**
   * Deletes contact from deal.
   *
   * @param contactId id of deleting contact.
   */
  protected override deleteContact(contactId: string): void {
    const indexToDelete = this.dealContacts.findIndex(
      (contact) => contact.contact.id === contactId,
    );
    if (indexToDelete === -1) {
      return;
    }

    this.dealContactsCollection
      .entity(this.dealContacts[indexToDelete].id)
      .delete()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.onContactDeleted(contactId, indexToDelete);
          this.notificationService.successLocal(
            'components.dealContactsService.messages.contactDeleted',
          );
        },
        error: (error: Exception) =>
          this.notificationService.error(error.message),
      });
  }

  /**
   * Handles post-deletion logic for a contact.
   *
   * @param contactId id of deleting contact.
   */
  protected override onContactDeleted(
    contactId: string,
    contactIdIndex?: number,
  ): void {
    super.onContactDeleted(contactId);

    this.dealCardService.dealContacts.update((contacts) => [
      ...contacts.filter((c) => c.contactId !== contactId),
    ]);

    this.dealContacts.splice(contactIdIndex, 1);
  }

  /** Opens adding contacts modal window. */
  private selectClientContact(): void {
    const modalRef = this.modal.open(DealAddContactsModalComponent, {
      size: 'xxl',
    });
    const instance =
      modalRef.componentInstance as DealAddContactsModalComponent;
    instance.parentEntityId = this.dealCardService.deal()?.organization?.id;
    instance.existedEntities = this.dealCardService.dealContacts();
    modalRef.result.then(
      (contacts: DealContact[]) => {
        if (!contacts.length) {
          return;
        }

        const requests = contacts.map((contact) =>
          this.addContactToDeal({
            contactId: contact.contactId,
            dealId: this.dealId,
          }),
        );

        forkJoin(requests).subscribe((isSavedArray: boolean[]) => {
          const contactsToAdd: DealContact[] = [];
          isSavedArray.forEach((isSaved, index) => {
            if (isSaved) {
              contactsToAdd.push(<DealContact>{
                contactId: contacts[index].contactId,
                dealId: this.dealId,
              });
            }
          });

          this.dealCardService.dealContacts.update((oldContacts) => [
            ...oldContacts,
            ...contactsToAdd,
          ]);
          this.load();
          this.notificationService.successLocal(
            'components.dealContactsService.messages.contactsAdded',
          );
        });
      },
      () => null,
    );
  }

  /**
   * Adds contact to deal.
   *
   * @param contactId id or array of id's adding contact.
   */
  private addContactToDeal(
    dealContact: Partial<DealContact>,
  ): Observable<boolean> {
    if (!dealContact) {
      return of(false);
    }

    return this.dealContactsCollection.insert(dealContact).pipe(
      map(() => true),
      catchError((error: Exception) => {
        this.notificationService.error(error.message);
        return of(false);
      }),
      takeUntilDestroyed(this.destroyRef),
    );
  }
}
