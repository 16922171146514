<wp-loading-indicator [loading]="isLoading"></wp-loading-indicator>
<wp-data-not-saved-indicator [form]="profileForm"></wp-data-not-saved-indicator>

<form class="form" [hidden]="isLoading" [formGroup]="profileForm" novalidate autocomplete="off">
  <div class="container-fluid mx-0" style="width: 1200px">
    <div class="row" *ngIf="(this.isUserLocked$ | async) === true">
      <div class="align-items-center col-5 d-flex">
        <div class="form-group pt-2">
          <span class="badge text-bg-danger me-2">
            {{ 'settings.users.card.profile.props.locked' | translate }}
          </span>
        </div>
        <div class="form-group ml-4 pt-2">
          <button
            type="button"
            *ngIf="!readonly"
            class="btn btn-default"
            wpButtonSpinner
            [isBusy]="isUserUnlocking"
            (click)="unlockUser()"
          >
            {{ 'settings.users.card.actions.unlock' | translate }}
          </button>
        </div>
      </div>
    </div>
    @if (this.isUserLocked$ | async) {
      <hr />
    }
    <div class="row mt-2">
      <div class="col-10">
        <label class="group">
          {{ 'settings.users.card.profile.sections.user' | translate }}
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col-5">
        <div class="form-group" tmtIndicator>
          <label>{{ 'settings.users.card.profile.props.name.label' | translate }}</label>
          <wp-text-box
            formControlName="name"
            placeholder="{{ 'settings.users.card.profile.props.name.ph' | translate }}"
          />
        </div>

        <div class="form-group" tmtIndicator>
          <label>{{ 'settings.users.card.profile.props.email.label' | translate }}</label>
          <wp-email-box
            formControlName="email"
            placeholder="{{ 'settings.users.card.profile.props.email.ph' | translate }}"
          />
        </div>

        <div class="form-group" tmtIndicator>
          <button
            class="btn btn-link p-0"
            *ngIf="!showNotificationsEmail"
            (click)="showNotificationsEmail = true"
          >
            {{ 'settings.users.card.profile.actions.addNotificationsEmail' | translate }}
          </button>
          <ng-container *ngIf="showNotificationsEmail">
            <label>{{
              'settings.users.card.profile.props.notificationsEmail.label' | translate
            }}</label>
            <wp-text-box
              formControlName="notificationsEmail"
              placeholder="{{
                'settings.users.card.profile.props.notificationsEmail.ph' | translate
              }}"
            />
          </ng-container>
        </div>

        <div class="form-group" tmtIndicator>
          <label>{{ 'settings.users.card.profile.props.authProvider' | translate }}</label>
          <wp-select-box
            [allowNull]="false"
            [values]="authProviders$"
            formControlName="authProvider"
          />
        </div>
      </div>
      <div class="col-5 position-relative d-flex flex-column justify-content-between">
        <div class="form-group" style="vertical-align: bottom">
          <img
            [src]="entityId | avatar: null : avatarVersion"
            style="width: 133px; height: 133px"
            class="img-thumbnail"
          />
          <div *ngIf="!readonly" style="position: absolute; left: 170px; top: 0">
            <ngx-dropzone
              #drop
              style="height: 130px; width: 328px"
              [multiple]="false"
              accept=".png,.jpg,.jpeg,.webp"
              maxFileSize="3145728"
              (change)="onFileAdded($event)"
            >
              <ngx-dropzone-label>
                <button class="btn btn-default">
                  {{ 'shared.actions.select' | translate }}
                </button>
                <br /><br />
                <span class="text-body-secondary"> {{ 'shared.orDragFileHere' | translate }}</span>
              </ngx-dropzone-label>
            </ngx-dropzone>
            <wp-loading-indicator [loading]="avatarIsLoading"></wp-loading-indicator>
            <p class="text-body-secondary">
              {{ 'settings.users.card.profile.props.avatarRequirement' | translate }}
            </p>
          </div>
        </div>
        <div
          class="form-check mb-3"
          title="{{ 'settings.users.card.profile.props.isActive.hint' | translate }}"
        >
          <input
            formControlName="isActive"
            type="checkbox"
            class="form-check-input"
            id="isActive"
          />
          <label class="form-check-label" for="isActive">
            {{ 'settings.users.card.profile.props.isActive.label' | translate }}
          </label>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-10">
        <label class="group">
          {{ 'settings.users.card.profile.sections.employee' | translate }}
        </label>
      </div>
    </div>
    <div class="row">
      <div class="align-items-center col-5 d-flex">
        <div class="form-group" style="width: 150px">
          <label>{{ 'shared.props.code' | translate }}</label>
          <wp-text-box formControlName="code" placeholder="{{ 'shared.props.code' | translate }}" />
        </div>
      </div>
      <div class="col-5">
        <div class="form-group">
          <label>{{ 'settings.users.card.profile.props.position' | translate }}</label>
          <wp-text-box
            formControlName="position"
            placeholder="{{ 'settings.users.card.profile.props.position' | translate }}"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-5">
        <table>
          <tr>
            <td>
              <div class="form-group" style="width: 150px">
                <label>{{ 'settings.users.card.profile.props.firstWorkDay' | translate }}</label>
                <wp-date-box formControlName="firstWorkDay"></wp-date-box>
              </div>
            </td>
            <td style="padding-left: 30px">
              <div class="form-group" style="width: 150px">
                <label>{{ 'settings.users.card.profile.props.lastWorkDay' | translate }}</label>
                <wp-date-box formControlName="lastWorkDay"></wp-date-box>
              </div>
            </td>
          </tr>
        </table>
      </div>
      <div class="col-5">
        <div class="form-group" tmtIndicator>
          <label>{{ 'settings.users.card.profile.props.phone' | translate }}</label>
          <wp-text-box
            formControlName="phone"
            placeholder="{{ 'settings.users.card.profile.props.phone' | translate }}"
            extendedType="phone"
          />
        </div>
      </div>
    </div>

    @if (!isLoading) {
      <div class="row mt-4">
        <div class="col-10">
          <label class="group">
            {{ 'settings.users.card.profile.sections.resource' | translate }}
          </label>
        </div>
      </div>
      <tmt-profile-resource-controls [form]="profileForm" />
    }

    <wp-custom-fields [formGroup]="profileForm" [entityType]="'User'" columnClasses="col-5" />
  </div>

  <div class="actions">
    <button
      type="button"
      *ngIf="!readonly"
      class="btn btn-primary"
      wpButtonSpinner
      [isBusy]="isSaving"
      (click)="save()"
    >
      {{ 'shared.actions.save' | translate }}
    </button>
  </div>
</form>
