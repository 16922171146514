@if (!gridService.readonly) {
  <div class="toolbar-container pt-0">
    <div class="group">
      <button
        type="button"
        class="btn btn-default"
        title="{{ 'shared2.actions.addLine' | translate }}"
        (click)="gridService.execute('addAssignmentsLine')"
        data-test="addLine"
      >
        <i class="bi bi-plus-lg bi-15" aria-hidden="true"></i>
        {{ 'shared2.actions.addLine' | translate }}
      </button>
      <button
        type="button"
        class="btn btn-default"
        (click)="gridService.execute('deleteAssignment')"
        [disabled]="!(gridService.selectedGroup$ | async)"
        data-test="delete"
      >
        <span class="bi bi-trash3"></span>
      </button>
    </div>
    <button
      type="button"
      class="btn btn-default ml-auto"
      (click)="gridService.execute('setUserView')"
      title="{{ 'shared.actions.setView' | translate }}"
      data-test="setView"
    >
      <i class="bi bi-gear" aria-hidden="true"></i>
    </button>
  </div>
}
