/* eslint-disable @typescript-eslint/naming-convention */
import {
  Component,
  forwardRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  ViewRef,
  Renderer2,
  AfterViewInit,
  TemplateRef,
  Output,
  EventEmitter,
  Input,
  OnDestroy,
  signal,
  effect,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { EditorState } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import { history } from 'prosemirror-history';
import { baseKeymap } from 'prosemirror-commands';
import { keymap } from 'prosemirror-keymap';
import autocomplete, {
  KEEP_OPEN,
  AutocompleteAction,
  ActionKind,
  FromTo,
  closeAutocomplete,
} from 'prosemirror-autocomplete';
import codemark from 'prosemirror-codemark';
import {
  MarkdownParser,
  MarkdownSerializer,
  defaultMarkdownParser,
  defaultMarkdownSerializer,
} from 'prosemirror-markdown';
import { inputRules } from 'prosemirror-inputrules';

import {
  BehaviorSubject,
  Subject,
  debounceTime,
  fromEvent,
  switchMap,
  tap,
} from 'rxjs';
import { LinkyPipe } from 'ngx-linky';

import { DataService } from 'src/app/core/data.service';
import { Constants } from 'src/app/shared/globals/constants';
import { User } from 'src/app/shared/models/entities/settings/user.model';
import { ScrollToService } from 'src/app/shared/services/scroll-to.service';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { SharedModule } from 'src/app/shared/shared.module';
import {
  UserInfoComponentParams,
  UserInfoComponent,
} from 'src/app/shared/components/features/user-info';
import { AbstractBox } from 'src/app/shared/components/controls/abstract-box/abstract-box';
import { PropagationMode } from 'src/app/shared/models/enums/control-propagation-mode.enum';

import {
  RichEditorBoxMenuService,
  RichEditorBoxMenuComponent,
} from './rich-editor-box-menu';
import { md } from './markdown/markdown';
import { mergeListsPlugin } from './markdown/lists/merge-plugin';
import { makeLinksClickable } from './markdown/links/commands';
import { additionalMarks } from './markdown/marks/consts';
import { bulletListRule, orderedListRule } from './markdown/lists/inputrules';
import { customKeymap } from './markdown/keymap';
import { MentionView } from './markdown/mention/view';
import { insertMention, MentionSerializer } from './markdown/mention/commands';
import { newSchema } from './markdown/schema';

@Component({
  selector: 'tmt-rich-editor-box',
  templateUrl: './rich-editor-box.component.html',
  styleUrls: ['./rich-editor-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RichEditorBoxComponent),
      multi: true,
    },
    RichEditorBoxMenuService,
  ],
  imports: [CommonModule, SharedModule, RichEditorBoxMenuComponent],
})
export class RichEditorBoxComponent
  extends AbstractBox
  implements ControlValueAccessor, AfterViewInit, OnChanges, OnDestroy
{
  @ViewChild('popup') private suggestionsEl: TemplateRef<HTMLElement>;
  @ViewChild(RichEditorBoxMenuComponent)
  private menuBar: RichEditorBoxMenuComponent;

  @Input() public placeholder = 'shared.comments.placeholder';
  @Input() public loadLimit = 50;
  @Input() public mentionedUserIds: string[];
  /** When `false`, propagation mode is `onExitFromEditing` */
  @Input() public isAlwaysEditingMode = false;
  //TODO: Add removing from scheme
  @Input() public hasMentions = true;
  @Input() public emptyText = '';

  @Output() public mentionedUserIds$ = new EventEmitter<string[]>();
  @Output() public editing$ = new EventEmitter<boolean>();

  public editorView: EditorView;
  public selectedSuggestion: any;
  public suggestions: any = [];
  public suggestionsLoading$ = new BehaviorSubject<boolean>(true);
  public autocompleteValue$ = new Subject<string>();
  public popupId: string;
  public content = '';
  public readonly = signal<boolean>(false);
  public isFocused = signal<boolean | null>(null);
  public isEditButtonShown = signal<boolean>(true);
  public loadedPartly: boolean;
  public isEmpty: boolean;
  public propagateChange = (_: any) => null;
  public propagateTouch = () => null;

  private mentionIds = signal<string[]>([]);
  private range: FromTo | null;
  private markdownParser: MarkdownParser;
  private onPasteLinker = new LinkyPipe();
  private currentContent = '';

  public get renderedValue(): string {
    return md.render(this.content ?? '');
  }

  constructor(
    public richEditorBoxMenuService: RichEditorBoxMenuService,
    private dataService: DataService,
    private infoPopupService: InfoPopupService,
    private scrollToService: ScrollToService,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private el: ElementRef<HTMLElement>,
  ) {
    super();

    effect(() => {
      const isReadonly = this.readonly();

      this.mentionedUserIds$.emit(this.mentionIds());
      this.editing$.emit(!isReadonly);

      if (isReadonly) {
        this.infoPopupService.close();
      }
    });

    effect(() => {
      if (this.isFocused() === false) {
        this.onBlur();
      }
    });
  }

  public override ngAfterViewInit(): void {
    super.ngAfterViewInit();

    if (!this.isAlwaysEditingMode) {
      this.propagationMode = PropagationMode.onExitFromEditing;
    }

    // TODO: to separate file
    Object.keys(additionalMarks).map((mark) => {
      defaultMarkdownSerializer.marks[mark] = {
        open: additionalMarks[mark].open,
        close: additionalMarks[mark].close,
        expelEnclosingWhitespace: true,
        mixable: true,
      };
      defaultMarkdownParser.tokens[mark] = {
        mark,
      };
    });
    // TODO: to separate file
    defaultMarkdownSerializer.nodes['mention'] = MentionSerializer;
    defaultMarkdownParser.tokens['mention'] = {
      node: 'mention',
      getAttrs: (tok) => ({
        id: tok.attrGet('data-id'),
        label: tok.content,
      }),
    };
    // TODO: to separate file
    const notRegisteredNodes = ['image'];
    notRegisteredNodes.forEach((key) => {
      delete defaultMarkdownSerializer.nodes[key];
      delete defaultMarkdownParser.tokens[key];
    });
    // TODO: to separate file
    this.markdownParser = new MarkdownParser(
      newSchema,
      md,
      defaultMarkdownParser.tokens,
    );
    // TODO: to separate file
    const markdownSerializer = new MarkdownSerializer(
      defaultMarkdownSerializer['nodes'],
      defaultMarkdownSerializer['marks'],
      { escapeExtraCharacters: /(?<!\\)[-+#]/g },
    );

    const state = EditorState.create({
      schema: newSchema,
      plugins: [
        ...autocomplete({
          triggers: [{ name: 'mention', trigger: '@' }],
          reducer: (action) => this.handleAutocomplete(action),
        }),
        history(),
        keymap(customKeymap),
        keymap(baseKeymap),
        ...codemark({ markType: newSchema.marks.code }),
        mergeListsPlugin(),
        inputRules({
          rules: [
            bulletListRule(newSchema.nodes.bullet_list),
            orderedListRule(newSchema.nodes.ordered_list),
          ],
        }),
      ],
    });

    this.editorView = new EditorView(
      this.el.nativeElement.querySelector('.editor-container'),
      {
        state,
        dispatchTransaction: (transaction) => {
          if (
            !(transaction.doc.content.size - 2) &&
            this.currentContent?.length > 2
          ) {
            transaction.storedMarks = null;
          }

          this.editorView.updateState(this.editorView.state.apply(transaction));
          this.menuBar?.onEditorStateChange();
          this.currentContent = markdownSerializer.serialize(
            this.editorView.state.doc,
          );
          this.updateIsEmpty();

          if (this.isAlwaysEditingMode) {
            if (
              this.propagationMode === PropagationMode.onInput &&
              this.content !== this.currentContent
            ) {
              this.content = this.currentContent;
              this.propagateChange(this.content);
            }

            if (this.propagationMode === PropagationMode.onExitFromEditing) {
              this.toggleControlDirty(this.currentContent, this.content);
            }
          }
        },
        nodeViews: {
          mention: (node) =>
            new MentionView(node, this.renderer, this.mentionIds),
        },
        attributes: {
          class: 'comments-input', // TODO: make like options
        },
        transformPastedHTML: (html: string) => {
          // TODO :thinking_face:
          setTimeout(() => {
            makeLinksClickable(this.menuBar);
          });

          return html;
        },
        transformPastedText: (text) => {
          this.editorView.pasteHTML(
            this.onPasteLinker.transform(md.render(text), {
              stripPrefix: false,
            }),
          );
          return '';
        },
      },
    );

    this.initSubscribers();

    if (!this.isAlwaysEditingMode) {
      this.readonly.set(true);
      this.editorView.dom.style.display = 'none';
    }

    this.updateEditorContent();
    this.cdr.markForCheck();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['mentionedUserIds']) {
      this.mentionIds.set(this.mentionedUserIds ?? []);
    }
  }

  public ngOnDestroy(): void {
    this.editorView.destroy();

    if (this.propagationMode === PropagationMode.onExitFromEditing) {
      this.onBlur();
    }
  }

  public writeValue(value: any): void {
    // Do not patch value if control in editing process.
    if (
      this.propagationMode === PropagationMode.onExitFromEditing &&
      this.currentContent !== this.content
    ) {
      return;
    }

    value ??= '';
    this.content = value;
    this.currentContent = value;
    this.updateEditorContent();

    if (this.editorView?.dom && value) {
      setTimeout(() => {
        makeLinksClickable(this.menuBar);
      });
    }

    if (!(this.cdr as ViewRef).destroyed) {
      this.cdr.markForCheck();
    }
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.readonly.set(isDisabled);
    this.isEditButtonShown.set(!isDisabled);
    /** Editor's dom shown depends on isDisabled. */
    if (this.editorView) {
      this.editorView.dom.style.display = isDisabled ? 'none' : 'block';
    }
    /** When there is an edit button, enable an editor only by click. */
    if (!this.isAlwaysEditingMode) {
      this.readonly.set(true);
      if (this.editorView) {
        this.editorView.dom.style.display = 'none';
      }
    }

    if (!(this.cdr as ViewRef).destroyed) {
      this.cdr.markForCheck();
    }
  }

  public onBlur(): void {
    this.propagateTouch();

    if (
      this.propagationMode === PropagationMode.onExitFromEditing &&
      this.isAlwaysEditingMode &&
      this.content !== this.currentContent
    ) {
      this.content = this.currentContent;
      this.cdr.markForCheck();
      this.propagateChange(this.content);
    }
  }

  /**
   * Inserts node with selected suggestion.
   *
   * @param suggestion user or something else.
   */
  public onSuggestionClick(suggestion: any): void {
    insertMention(
      this.editorView,
      `@${suggestion?.email.split('@')[0]}`,
      suggestion.id,
      this.range,
    );
    closeAutocomplete(this.editorView);
    this.editorView.focus();
  }

  /** Makes editor editable. */
  public showEditor(): void {
    if (!this.isEditButtonShown()) {
      return;
    }

    this.editorView.dom.style.display = 'block';
    this.readonly.set(false);
    this.updateEditorContent();
    setTimeout(() => {
      makeLinksClickable(this.menuBar);
    });
    this.editorView.focus();
    this.ngControl.markAsDirty();
    this.cdr.detectChanges();
  }

  /** Makes editor readonly. */
  public makeReadonly(): void {
    this.editorView.dom.style.display = 'none';
    this.readonly.set(true);
    this.ngControl.markAsPristine();
  }

  /** Saves editor content and propagates change. */
  public save(): void {
    this.content = this.currentContent;
    this.propagateChange(this.content);
    this.makeReadonly();
  }

  /**
   * Opens user info if mention was clicked.
   *
   * @param event Mouse event.
   */
  public openUserInfo(event: MouseEvent): void {
    const target = event.target as HTMLElement;

    if (!target?.classList.contains('mention')) {
      return;
    }

    this.infoPopupService.open<UserInfoComponentParams>({
      target,
      data: {
        component: UserInfoComponent,
        params: {
          nickname: target.textContent,
        },
        injector: this.injector,
      },
    });
  }

  private openUsersPopup(): void {
    if (this.popupId) {
      this.infoPopupService.close(this.popupId);
    }

    this.popupId = this.infoPopupService.open({
      target: {
        getBoundingClientRect: () =>
          this.el.nativeElement
            .querySelector('.autocomplete')
            ?.getBoundingClientRect(),
        contextElement: this.el.nativeElement.querySelector('.ProseMirror'),
      },
      data: {
        templateRef: this.suggestionsEl,
      },
      containerStyles: {
        padding: 0,
        overflow: 'hidden',
      },
      isHideArrow: true,
      clickOutsideEnabled: false,
    });
  }

  private updateEditorContent(): void {
    this.editorView?.dispatch(
      this.editorView.state.tr.replaceWith(
        0,
        this.editorView.state.doc.content.size,
        this.markdownParser.parse(this.content ?? '').content,
      ),
    );
  }

  private scrollToSelectRow(): void {
    if (this.selectedSuggestion && this.popupId) {
      this.scrollToService.scrollTo(this.selectedSuggestion.id, 'suggestions');
    }
  }

  private insertText(text: string, range?: FromTo): void {
    const { from, to } = range ?? this.editorView.state.selection;
    this.editorView.dispatch(
      this.editorView.state.tr.deleteRange(from, to).insertText(text),
    );
  }

  /** Updates isEmpty on editor state change. */
  private updateIsEmpty(): void {
    const doc = this.editorView?.state?.doc;
    this.isEmpty =
      doc?.childCount === 1 &&
      (doc?.firstChild?.type?.name === 'paragraph' ||
        doc?.firstChild?.type?.name === 'heading') &&
      doc?.firstChild?.childCount === 0;
  }

  /**
   * Autocomplete plugin handler.
   *
   * @param action AutocompleteAction.
   * @returns `boolean` or `KEEP_OPEN` - to keep the suggestion open after selecting.
   */
  private handleAutocomplete(
    action: AutocompleteAction,
  ): boolean | typeof KEEP_OPEN {
    if (!this.hasMentions) {
      return false;
    }

    switch (action.kind) {
      case ActionKind.open:
        this.range = action.range;
        this.autocompleteValue$.next('');
        this.openUsersPopup();

        return true;
      case ActionKind.up: {
        const currentIndex = !this.selectedSuggestion
          ? 0
          : this.suggestions.findIndex(
              (el) => el.id === this.selectedSuggestion.id,
            );

        if (currentIndex) {
          this.scrollToSelectRow();
          this.selectedSuggestion = this.suggestions[currentIndex - 1];
          this.cdr.markForCheck();
        }

        return true;
      }
      case ActionKind.down: {
        const currentIndex = !this.selectedSuggestion
          ? 0
          : this.suggestions.findIndex(
              (el) => el.id === this.selectedSuggestion.id,
            );

        if (currentIndex !== this.suggestions.length - 1) {
          this.scrollToSelectRow();
          this.selectedSuggestion = this.suggestions[currentIndex + 1];
          this.cdr.markForCheck();
        }

        return true;
      }
      case ActionKind.filter:
        this.range = action.range;
        this.autocompleteValue$.next(action.filter);

        return KEEP_OPEN;
      case ActionKind.enter: {
        insertMention(
          this.editorView,
          `@${this.selectedSuggestion?.email.split('@')[0]}`,
          this.selectedSuggestion?.id,
          action.range,
        );

        return true;
      }
      case ActionKind.close:
        this.infoPopupService.close(this.popupId);

        return true;
      default:
        return false;
    }
  }

  private initSubscribers(): void {
    fromEvent(this.editorView.dom, 'focus')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.isFocused.set(true));

    fromEvent(this.editorView.dom, 'blur')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.isFocused.set(false));

    this.autocompleteValue$
      .pipe(
        tap(() => {
          this.suggestionsLoading$.next(true);
        }),
        debounceTime(Constants.textInputClientDebounce),
        switchMap((search) => {
          this.suggestionsLoading$.next(true);

          const dataParams: any = {
            top: this.loadLimit,
            select: ['id', 'name', 'email'],
            filter: [{ isActive: true }],
            orderBy: 'name',
          };

          if (search.trim()) {
            dataParams.filter.push({
              or: [
                {
                  'tolower(name)': {
                    contains: search.trim().toLowerCase(),
                  },
                },
                {
                  'tolower(email)': {
                    contains: search.trim().toLowerCase(),
                  },
                },
              ],
            });
          }

          return this.dataService.collection('Users').query<User[]>(dataParams);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((data) => {
        this.loadedPartly = data.length && data.length === this.loadLimit;

        this.suggestions = data;
        this.selectedSuggestion = this.suggestions[0];

        this.suggestionsLoading$.next(false);
      });
  }
}
